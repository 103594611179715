@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.zoomBatchCarouselContainer {
  #zoomBatchCarouselSlider {
    .sliderTray___-vHFQ {
      display: flex;
      gap: 8px;
      p {
        @include m.fontSize(text/sm/semibold);
        color: map-get(i.$appColor, "grey/700");
      }
    }
    #zoomBatchCarouselSlide {
      height: 340px;
      display: flex;
      padding: 16px;
      gap: 16px;
      overflow: auto;
      border: 1px solid map-get(i.$appColor, "grey/300");
      border-radius: 8px;
      .carousel__inner-slide {
        .MuiCard-root {
          box-shadow: none;
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 16px;
        }
        #zoomCardHeader {
          padding: 0;
          .MuiTypography-root {
            @include m.fontSize(text/md/semibold);
            color: map-get(i.$appColor, "grey/900");
          }
        }
        .zoomCardContent {
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .zoomCardContentDetail {
            display: flex;
            gap: 2px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            align-items: baseline;
            .zoomCardContentTestName {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              @include m.fontSize(text/xs/regular);
              color: map-get(i.$appColor, "grey/500");
            }
          }
        }
      }
    }
  }
  /* Carsouel navigation css */

  .zoomBatchCarouselControls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
    .zoomBatchCarouselControlsSec {
      // width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      padding: 4px;
      border-radius: 20px;
      border: 1px 0px 0px 0px;
      border: 1px solid #eaecf0;
      box-shadow: 0px 4px 4px 0px #1d1a1a1a;
      .zoomBatchCarouselBtn {
        border: none;
        color: map-get(i.$appColor, "grey/500");
        cursor: pointer;
        svg {
          path {
            stroke: map-get(i.$appColor, "grey/500");
          }
        }
      }
    }
  }

  .zoomBatchCarouselBtn:disabled {
    cursor: not-allowed;
    svg {
      path {
        stroke: map-get(i.$appColor, "grey/300");
      }
    }
  }

  .zoomBatchCarouselDots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }

  .zoomBatchCarouselDots .carousel__dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0 5px;
    background-color: map-get(i.$appColor, "grey/300");
    border-radius: 50%;
    cursor: pointer;
  }

  .zoomBatchCarouselDots .carousel__dot--selected {
    background-color: map-get(i.$appColor, "grey/500");
  }
}
