@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.warningNoticeForResume,
.warningNoticeForRetake,
.warningNoticeSubmission,
.warningNoticeForPastDue,
.warningNoticeForConductTrainer {
  width: 940px;
  padding: 32px;
  display: flex;
  text-align: left;
  border-radius: 12px;
  margin-bottom: 20px;
  background: map-get(i.$appBackGroundColor, "warning/50");

  .warningNoticeInfo {
    .warningNoticeInfoHeader {
      margin-left: 20px;
      @include m.fontSize(text/lg/semibold);

      .warningNoticeInfoHeaderSubText {
        margin: 8px 0 0 0;
        @include m.fontSize(text/sm/regular);
        color: map-get(i.$appColor, "grey/500");
      }
    }

    .warningNoticeInfoBody {
      text-wrap: wrap;
      margin-left: 20px;
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/500");

      .warningNoticeInfoBodyDivText,
      .warningNoticeInfoBodyUnorderedList {
        margin-top: 10px;
      }
    }
  }

  .submissionDetail {
    opacity: 60%;
    display: flex;
    padding: 24px;
    margin-top: 24px;
    border-radius: 10px;
    background: map-get(i.$appBackGroundColor, "white");

    .submissionDetailHeader {
      @include m.fontSize(text/sm/semibold);
      color: map-get(i.$appColor, "grey/700");

      .submissionDetailHeaderField,
      .submissionDetailHeaderSubField {
        display: flex;
        justify-content: space-between;

        .submissionDetailHeaderFieldColon {
          min-width: 50px;
          text-align: center;
        }
      }

      .submissionDetailHeaderSubField {
        margin-top: 10px;
      }
    }

    .submissionDetailValue {
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/700");

      .submissionDetailFieldValue {
        margin-top: 10px;
      }

      .submissionDetailValueButton {
        float: right;
        margin: 20px 0 0 0;
      }
    }
  }

  .warningNoticeSubmissionButton {
    float: right;
    margin: 228px 0 0 233px;
  }
}

.warningNoticeForRetake,
.warningNoticeForConductTrainer {
  background: map-get(i.$appBackGroundColor, "error/50");
}

.warningNoticeForConductTrainer,
.warningNoticeForPastDue,
.warningNoticeSubmission {
  margin: 40px 52px;
}

.warningNoticeSubmission {
  background: map-get(i.$appBackGroundColor, "success/50");
}
