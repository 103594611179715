@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.dashBoardMainContainer {
  overflow: auto;
  scrollbar-width: none;
  background: #f2f4f7;
  height: calc(100vh - 40px);

  .Dashboard-div {
    width: 100%;
    overflow: auto;
    min-height: calc(100vh - 369px);
  }

  .parentGreetContainer {
    display: flex;
    justify-content: center;

    .parentGreet {
      display: flex;
      width: 1024px;
      flex-direction: column;
      justify-content: flex-start;

      .greet {
        color: #344054;
      }

      .instruct {
        color: #98a2b3;
        @include m.fontSize(text/lg/semibold);
      }
    }
  }

  .Dashboard-div-batch-change {
    display: flex;
    margin: 16px 0 0 0;
    justify-content: center;
  }

  .dashboard-content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dashboard_pinned_all_container {
    display: flex;
    justify-content: center;
  }

  .dashboard_pinned_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 0 0;
    padding: 16px 24px;
    background: linear-gradient(#fff3b8, #ffee99);
    border: 1px solid #eaecf0;
    border-radius: 12px;
    width: 1024px;
    height: 88px;

    .pinned_left_container {
      .pinned_name_assigned {
        .pinned_test_name {
          @include m.fontSize(text/lg/semibold);
          color: map-get(i.$appBackGroundColor, "grey/700");
          padding: 3px;
          margin: 0 12px 0 0;
        }

        .pinned_label {
          @include m.fontSize(text/xs/medium);
          color: map-get(i.$appBackGroundColor, "error/500");
          padding: 2px 8px;
          border: 0.8px solid map-get(i.$appBackGroundColor, "error/500");
          border-radius: 16px;
          background: map-get(i.$appBackGroundColor, "brand/50");
        }
      }

      .pinned_info_aduedate {
        display: flex;
        margin: 10px 0 0 0;

        .pinned_infosvg {
          display: flex;
          align-items: center;
        }

        .pinned_duetime {
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appBackGroundColor, "error/500");
          padding: 2px;
          margin: 0 0 0 8px;
        }
      }
    }

    .pinned_right_container {
    }
  }

  .dashboard-batchdetail-div {
    display: flex;
    margin: 16px 0 0 0;
    justify-content: center;

    &.dashboard-batchdetail-card-expanded {
      width: 1024px;
      height: 735px;
    }

    & .dashboard-batchdetail-card {
      gap: 16px;
      width: 1024px;
      height: 127px;
      text-align: left;
      border-radius: 20px;
      padding: 16px 28px 19px 20px;

      &.dashboard-batchdetail-card-expanded {
        width: 1024px;
        height: fit-content;
      }

      & .dashboard-batchdetail-card-header {
        padding: 0;
        width: 976px;

        & .dashboard-batchdetail-card-header-test-button {
          gap: 8px;
          width: 83px;
          border: none;
          height: 36px;
          font-size: 14px;
          color: #ffffff;
          font-weight: 600;
          border-radius: 8px;
          font-family: "Inter";
          background-color: #f55533;
        }

        & .dashboard-batchdetail-card-header-test-button:hover {
          gap: 8px;
          width: 83px;
          border: none;
          height: 36px;
          font-size: 14px;
          color: #ffffff;
          font-weight: 600;
          border-radius: 8px;
          font-family: "Inter";
          background-color: #e54b2a;
        }

        & .dashboard-batchdetail-card-header-expand-button {
          width: 36px;
          width: 36px;
          height: 36px;
          height: 36px;
          border-radius: 8px;
          margin: 0 0 0 12px;
          border: 1px solid #d0d5dd;
        }
      }

      & .dashboard-batchdetail-card-content {
        gap: 12px;
        width: 976px;
        height: 40px;
        padding: 0 0 0 40px;

        & .dashboard-batchdetail-card-content-batchdetail {
          display: flex;
          margin: 16px 0 12px 0;
          justify-content: space-between;
        }

        & .dashboard-batchdetail-card-content-coursedetail {
          font-size: 14px;
          font-weight: 400;
          color: #475467;
          font-family: "Inter";
        }

        & .dashboard-batchdetail-card-content-percentage {
          font-size: 14px;
          font-weight: 500;
          color: #344054;
          font-family: "Inter";
        }
      }
    }
  }

  .event-asssignmentdue-leaderboard-container,
  .Trainers-container {
    display: flex;
    margin-top: 16px;
    justify-content: center;
  }

  .trainer-header {
    display: flex;
    margin-top: 16px;
    justify-content: center;

    span {
      width: 1024px;
      font-size: 20px;
      text-align: left;
      font-weight: 500;
      font-family: "Inter";
    }
  }

  .dashboard-batchdetail-collapse-desc {
    width: 791px;
    font-size: 16px;
    max-height: 70px;
    font-weight: 400;
    color: #344054;
    overflow: hidden;
    font-family: "Inter";
    display: -webkit-box;
    -webkit-line-clamp: 3;
    margin: 8px 0 24px 40px;
    -webkit-box-orient: vertical;
  }

  .dashboard-batchdetail-collapse-content {
    width: 94px;
    height: 30px;
    margin: 0 0 8px 12px;

    p {
      width: 78px;
      height: 30px;
      font-size: 20px;
      font-weight: 500;
      color: #344054;
      font-family: "Inter";
    }
  }

  .dashboard-batchdetail-collapse-lpname-div:hover {
    border-radius: 8px;
    background-color: #f2f4f7;
  }

  .dashboard-batchdetail-collapse-div {
    width: 960px;
    margin: 0 0 4px 12px;

    .dashboard-batchdetail-collapse-lpname-div {
      display: flex;
      min-height: 52px;
      padding: 0 !important;
      align-items: flex-start;
      justify-content: space-between;

      .dashboard-batchdetail-collapse-lpname {
        .dashboard-batchdetail-index-div {
          display: flex;

          .dashboard-batchdetail-index {
            width: 28px;
            height: 28px;
            display: flex;
            font-size: 14px;
            color: #f2f4f7;
            font-weight: 600;
            margin: 0 8px 0 0;
            align-items: center;
            border-radius: 28px;
            font-family: "Inter";
            justify-content: center;
            background: linear-gradient(#f55533, #b83d23);
          }
        }

        font-size: 18px;
        font-weight: 500;
        color: #344054;
        font-family: "Inter";
        padding: 12px 0 4px 16px;
      }

      .dashboard-batchdetail-collapse-chapter-cout {
        font-size: 16px;
        font-weight: 400;
        color: #667085;
        font-family: "Inter";
        margin: 0 0 12px 16px;
        padding: 0 32px 0 32px;
      }

      .dashboard-batchdetail-collapse-expandicon {
        width: 24px;
        height: 24px;
        color: #667085;
        margin: 14px 32px 0 0;
      }
    }
  }

  .expand-icon.rotated {
    /* Add transition for smooth rotation */

    transform: rotate(180deg);

    /* Rotate the icon 180 degrees */

    transition: transform 0.3s ease;
  }

  .dashboard-batchdetail-subcollapse-cardcontent {
    margin: 0 0 0 12px;
    padding: 0 !important;

    .dashboard-batchdetail-subcollapse-subheader {
      width: 936px;
      height: 38px;
      font-size: 14px;
      font-weight: 400;
      color: #475467;
      font-family: "Inter";
      padding: 8px 0 0 16px;
    }

    .dashboard-batchdetail-subcollapse-divider {
      width: 904px;
      margin: 0 0 0 16px;
      border: 1px solid #d0d5dd;
    }
  }

  .Dashboard-timeline-div {
    display: flex;
    margin: 16px 0 0 0;
    justify-content: center;
  }

  .dashboard-leaderboard-div {
    display: flex;
    margin: 16px 0 0 0;
    justify-content: center;
  }

  .leetcode-container {
    display: flex;
    margin: 16px 0 0 0;
    justify-content: center;
  }
}
