@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.uploadAssignmentMainContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;

  .btnCont{
    display: flex;
    justify-content: space-between;
  }

  .uploadAssignment,
  .uploadAssignmentSubmitted {
    display: flex;
    min-height: 236px;
    max-height: 312px;
    padding: 40px 20px;
    border-radius: 20px;
    justify-content: center;
    border: 1px solid map-get(i.$appColor, "grey/200");

    .uploadAssignmentSection {
      text-align: center;
      @include m.fontSize(text/md/bold);
      color: map-get(i.$appColor, "grey/700");

      .uploadAssignmentSupportedFormat {
        margin-top: 8px;
        @include m.fontSize(text/sm/regular);
        color: map-get(i.$appColor, "grey/400");
      }

      .uploadAssignmentButton {
        margin-top: 20px;
      }

      .uploadAssignmentFileInput {
        opacity: 0;
        top: 19%;
        left: 25%;
        height: 236px;
        bottom: 100px;
        cursor: pointer;
        position: absolute;
      }
    }
  }

  .uploadAssignmentSubmitted {
    border-bottom: none;
    border-radius: 20px 20px 0 0;
  }

  .uploadAssignmentSubmittedFile {
    padding: 16px 24px;
    border-radius: 0 0 20px 20px;
    border: 1px solid map-get(i.$appColor, "grey/200");
    background: map-get(i.$appBackGroundColor, "brand/25");

    .uploadAssignmentSubmittedFileDiv {
      display: flex;
      justify-content: space-between;

      .uploadAssignmentSubmittedFileDivForNameAndSize {
        display: flex;
        @include m.fontSize(text/sm/medium);
        color: map-get(i.$appColor, "grey/900");

        .uploadAssignmentSubmittedFileDivForNameAndSizeValue {
          margin-left: 8px;
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appColor, "grey/700");
        }
      }
    }
  }

  .uploadAssignment:hover {
    border: 1px dashed map-get(i.$appColor, "blue/600");
    background: map-get(i.$appBackGroundColor, "blue/25");

    .downloadSvgIconForUploadAssignment {
      rect {
        fill: map-get(i.$appColor, "blue/50");
      }

      path {
        stroke: map-get(i.$appColor, "blue/600");
      }
    }
  }

  .uploadAssignmentNotes,.uploadAssignmentVideoLink {
    resize: none;
    height: 120px;
    padding: 16px;
    margin: 20px 0px;
    border-radius: 20px;
    border: 1px solid map-get(i.$appColor, "grey/300");
  }

  .uploadAssignmentNotes::placeholder,.uploadAssignmentVideoLink::placeholder {
    @include m.fontSize(text/md/normal);
    color: map-get(i.$appColor, "grey/400");
  }

  .uploadAssignmentNotes:focus,.uploadAssignmentVideoLink:focus {
    outline: none;
    border: 1px solid rgba(55, 55, 57, 0.2);
  }
}
