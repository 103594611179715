.adminBatchCardContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0 0 20px;
  flex-direction: row;
  padding-bottom: 10px;
}

.adminBatchTableContainer {
  // display: flex;
  // flex-wrap: wrap;
  margin: 50px 0 0 20px;
  padding-bottom: 10px;
}

.adminBatchSearchBox {
  position: absolute;
  left: 20px;
  gap: 10px;
  border: none;
  width: 186px;
  height: 34px;
  outline: none;
  display: block;
  // margin: 0 20%;
  border-radius: 0;
  font-family: "Inter";
  background: #ffffff;
  padding: 9px 4px 9px 35px;
  border-bottom: 0.8px solid #667085;
}

#addBatchContainer {
  width: 50%;
  height: 98vh;
  margin: 5px 0;
  overflow: auto;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
}

.viewOptions {
  display: flex;
  gap: 20px;
  width: 86px;
  margin-top: 8px;
  margin-right: 20px;
  z-index: 5;

  .disableBatchesListView,
  .disableBatchesGridView {
    width: 28px;
    height: 28px;
    padding: 4px;
    gap: 10px;
  }

  .disableBatchesListView:hover,
  .disableBatchesGridView:hover {
    width: 28px;
    height: 28px;
    padding: 4px;
    gap: 10px;
    border-radius: 4px;
    background: #f9fafb;
  }

  .batchesListView,
  .batchesGridView {
    width: 28px;
    height: 28px;
    // top: 50px;
    // left: 32px;
    padding: 4px;
    gap: 10px;
    border-radius: 4px;
    background: #f2f4f7;
  }
}

.addBatchTitleSection {
  display: flex;
  justify-content: space-between;
}

.addBatchTitle {
  height: 27px;
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  line-height: 27px;
  margin: 10px 20px;
  font-style: normal;
  font-family: "Inter";
}

.addBatchCloseIcon {
  width: 20px;
  margin: 13px;
  height: 20px;
  cursor: pointer;
  color: #202124;
}

.addBatchHrLine {
  margin: 0;
  height: 0px;
  border: 1px solid #e4e5e7;
}

.form {
  margin: 0;
  padding: 0;
}

#adminBatchDetailsForm {
  width: 100%;
  overflow: auto;
  height: 78vh !important;
}

#batchNameLabel,
#batchTypeLabel,
#addUserNameLabel,
#batchEndDateLabel,
#learningPathLabel,
#batchSlackIdLabel,
#batchStartDateLabel,
#batchUploadThumbnailLabel {
  order: 0;
  flex: none;
  width: 90%;
  flex-grow: 0;
  height: 14px;
  display: flex;
  font-size: 12px;
  color: #202124;
  font-weight: 600;
  margin-left: 20px;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
  justify-content: space-between;
}

.batchTypeSec {
  display: flex;
  margin-left: 20px;
  gap: 20px;
}

#batchTypeLearning,
#batchTypeTesting {
  margin-left: 20px;
  cursor: pointer;
}

#batchUploadThumbnailDetail,
.batchTypeSec label {
  margin: 0;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
  justify-content: space-between;
}

.batchTypeSec label {
  margin: 5px 0 0 40px;
}

#addBatchContainer .react-datepicker-wrapper {
  width: 100%;
}

#learningPathSelect {
  z-index: 4;
  width: 90%;
  margin-left: 20px;
}

#batchDownloadUserTemplate {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin: 0 0 3px 5px;
}

#addBatchContainer .react-datepicker-popper {
  z-index: 5;
}

#addBatchContainer .tox.tox-tinymce {
  margin: 0 0 0 20px;
  width: 90% !important;
}

#uploadUser,
#batchEndDate,
.batchStartDate,
#batchNameInBatch,
#batchUploadThumbnail,
#batchSlackId {
  order: 1;
  gap: 10px;
  flex: none;
  width: 90%;
  flex-grow: 0;
  height: 32px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  font-style: normal;
  margin: 0 0 0 20px;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  background: #ffffff;
  box-sizing: border-box;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

#batchDesInBatch {
  order: 1;
  gap: 10px;
  flex: none;
  width: 90%;
  flex-grow: 0;
  height: 95px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  border-radius: 4px;
  font-style: normal;
  margin: 0 0 0 20px;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  background: #ffffff;
  box-sizing: border-box;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

#batchEndDate::placeholder,
.batchStartDate::placeholder,
#batchDesInBatch::placeholder,
#batchNameInBatch::placeholder {
  order: 0;
  flex: none;
  height: 20px;
  flex-grow: 0;
  font-size: 14px;
  color: #bdc1c6;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.addBatchBtn {
  z-index: 2;
  width: 100%;
  display: flex;
  margin-top: 1vh;
  padding-right: 10px;
  position: fixed;
  margin-bottom: 1vh;
  flex-direction: row;
  justify-content: space-between;
}

.adminBatchSearchBox {
  gap: 10px;
  border: none;
  width: 186px;
  height: 34px;
  outline: none;
  display: block;
  margin: 0 20px;
  border-radius: 0;
  font-family: "Inter";
  background: #ffffff;
  padding: 9px 4px 9px 35px;
  border-bottom: 0.8px solid #667085;
}

.allOptionsInBatch {
  display: flex;
  align-items: center;
}

#addBatch {
  margin-left: 20px;
  margin-right: 25px;
}

#adminBatchCard {
  width: 310px;
  cursor: pointer;
  min-height: 300px;
  border-radius: 10px;
  background: #202124;
  margin: 0 20px 20px 0;
}

#adminBatchImg {
  width: 268px;
  height: 170px;
  border-radius: 8px;
  margin: 20px 0 5px 14px;
}

.adminBatchName {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  line-height: 24px;
  font-style: normal;
  font-family: "Inter";
}

.adminBatchStartDate {
  height: 18px;
  font-size: 12px;
  margin-top: 3px;
  color: #bdc1c6;
  font-weight: 700;
  line-height: 18px;
  font-style: normal;
  font-family: "Inter";
}

#adminBatchDes {
  height: 45px;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  line-height: 15px;
  font-style: normal;
  font-family: "Inter";
  mix-blend-mode: normal;
}

.uploadUser {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  font-style: normal;
  font-family: "Inter";
}

.batchTag {
  margin: 0 40px 0 20px;

  .mockTestCreateTagsLabel {
    width: 90%;
    height: 14px;
    display: flex;
    font-size: 12px;
    color: #202124;
    font-weight: 900;
    line-height: 14px;
    font-style: normal;
    align-items: center;
    font-family: "Inter";
    justify-content: space-between;
  }

  .css-1nmdiq5-menu {
    z-index: 2;
  }
}

.adminAddBatchDetailsBtn {
  width: 100%;
  bottom: 20px;
  display: flex;
  margin-left: 20px;
  position: fixed;
  column-gap: 10px;
  padding: 10px 0 0 0;
  border-top: 1px solid #e4e5e7;
}

.bdslashbatch {
  cursor: pointer;
  color: #0d6efd;
  font-style: normal;
  font-family: "Inter";
}

.bdslashbatch:hover {
  text-decoration: underline;
}

.bdBatchName {
  height: 27px;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  text-align: left;
  line-height: 18px;
  font-style: normal;
  font-family: "Inter";
  margin: 0 0 10px 20px;
}

.bdNavBarSec {
  margin: 0;
  padding: 0;
  height: 49px;
  display: flex;
  align-items: center;
  background: #f2f4f7;
}

.bdNavBar {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
}

.bdNav {
  margin-left: 30px;
  display: inline-block;
}

.bdNavLink {
  font-size: 16px;
  color: #373739;
  font-weight: 700;
  text-align: center;
  font-style: normal;
  line-height: normal;
  font-family: "Inter";
  text-transform: capitalize;
  text-decoration: none !important;
}

.bdNavP {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.bdNavLink:hover {
  height: 20px;
  color: #f55533;
  transition: color 0.5s ease;
  text-decoration: none !important;
}

.bdNavactive {
  color: #f55533 !important;
}

.bdCourseLink {
  margin: 0;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  text-decoration: none;
  display: inline-block;
}

.bdCourseLink:hover {
  color: #202124;
  background: #eff0f1;
}

.bdCourseLink:focus {
  outline: none;
}

.bdHrLine {
  padding: 0;
  margin: 12px 20px 10px;
}

.bdInBatchDes {
  width: 75%;
  margin: 0 20px;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  text-align: left;
  line-height: 17px;
  font-style: normal;
  font-family: "Inter";
  mix-blend-mode: normal;
}

.bdRemoveThumbnailBtn {
  height: 20px;
  display: flex;
  font-size: 12px;
  cursor: pointer;
  color: #f66a4d;
  font-weight: 700;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  align-items: flex-end;
  margin: 26px 0 0 26px;
}

.bdCourseDetails {
  height: 40px;
  margin-left: 20px;
  width: max-content;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #e4e5e7;
}

.bdXmarkIcon {
  width: 15px;
  color: #f55533;
  cursor: pointer;
  margin: 10px 0 10px 0;
}

.bdXmarkIcon:hover {
  color: #fc3b10;
  transition: color 0.5s ease;
}

.bdCourseHeadSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  svg {
    margin-right: 30px;
  }
}

.bdCoursesInCourseTip {
  font-size: 14px;
  font-weight: 500;
  color: #667085;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
  margin: 0px 20px 10px 0;
}

.bdCoursesInCourseTipIcon {
  margin: 0 6px 0 0;
}

.bdCoursesInCourseName {
  height: 14px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
}

#bdCoursesInCourseSelectSec {
  margin: 0 0 10px 20px;
}

#bdCoursesInCourseSelect {
  width: 200px;
  font-family: "Inter";
}

#bdCourseInCourse {
  font-family: "Inter";
}

#bdCourseaddLP {
  height: 34px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  padding: 7px 20px;
  line-height: 20px;
  margin-right: 20px;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #0d6efd;
}

#count,
#nameCount {
  font-size: 12px;
  font-weight: 200;
  line-height: 20px;
  font-style: normal;
  margin: 0 0 0 250px;
  font-family: "Inter";
}

.form-check-input {
  box-shadow: none;
}

.archiveBatchSwitchDiv {
  // font-size: 16px;
  // font-family: Inter;
  // color: #000000;
  // font-weight: 400;
  // line-height: 20px;
  // font-style: normal;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin-left: 13px;
  // font-family: "Inter";

  //styleName: Text sm/Medium;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
}

#archiveBatchSwitch {
  // box-shadow: none;
  // width: 36px;
  // height: 20px;
  position: relative;
  box-shadow: none;
  width: 36px;
  height: 20px;
  display: flex;
  text-align: center;
  margin-right: 10px;
}

.adminBatchContainer {
  overflow: auto;
  margin-top: 7vh;
  height: calc(100vh - 7vh);
}

.loadingModalDiv {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
