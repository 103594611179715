@use "../Abstracts/mixins" as m;

.previousIcon,
.disablePreviousIcon {
  @include m.prevNextSvgIcon(left);
}

.disablePreviousIcon {
  pointer-events: none;
}

.disableNextIcon {
  @include m.prevNextSvgIcon(left, true);
}

.codingTableLeftSvgIcon {
  margin-right: 20px;
}
