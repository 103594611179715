@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.multiLevelSelect {
  position: relative;
  .MuiButton-root {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);
    cursor: pointer;
    height: 44px;
    padding: 10px 14px 10px 14px;
    width: 500px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #101828;
    letter-spacing: unset;
    text-transform: unset;
  }
  .MuiButton-root:hover {
    border-color: #d0d5dd;
    background: #ffffff;
    color: #101828;
  }

  .menus {
    display: flex;
    list-style: none;
  }

  .menu-items {
    position: relative;
    font-size: 14px;
  }

  .menu-items button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .menu-items {
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #101828;
    padding: 10px 14px 10px 14px;
    cursor: pointer;
    text-align: left;
  }

  .menu-items button{
    text-align: left;
    display: flex;
    justify-content: space-between;
    gap: 6px;
  }

  .menu-items:hover,
  .menu-items button:hover {
    background-color: #f2f2f2;
  }

  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .dropdown:first-child{
    left: 0;
  }

  .dropdown {
    position: absolute;
    // right: 0;
    left: 0;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
  }

  .dropdown.show {
    display: block;
  }

  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }
}

.multiLevelSelectMenu {
  .MuiPaper-elevation {
    .MuiMenu-list {
      li {
        margin: 0;
        font-family: Inter;
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #101828;
        padding: 10px 14px 10px 14px;
        cursor: pointer;
      }
    }
  }
}
