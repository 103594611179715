.instant-image-tool {
  z-index: 9999;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
}

#color-left-btn {
  width: 24px !important;
  height: 24px !important;
}

#color-btn-text {
  height: px !important;
  margin-top: -10px !important;
}

.instant-pdf-tool .react-pdf__Document.fullscreen .react-pdf__Page {
  margin: 0;
}

.cdx-alert__message span {
  text-wrap: wrap !important;
}

.sandpackUtils .selectTemplate .css-yf8vq0-MuiSelect-nativeInput {
  left: 10px;
  opacity: 1;
  border: 0px;
  width: 100%;
  bottom: 20px;
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
  background-color: #e5e5ea;
}

.instant-image-tool input {
  width: 110px;
}

.instant-pdf-tool input {
  cursor: pointer;
  width: 100px !important;
  height: 30px !important;
}

.instant-pdf-tool {
  display: flex;
  justify-content: flex-start;
}

.instant-video-tool input {
  width: 100px !important;
}

.instant-video-tool {
  display: flex;
  justify-content: start;
}

// .ce-block {
//   margin-bottom: 2em !important;
// }

// .ce-block__content {
//   margin-bottom: 1em;
// }

.ce-toolbar__actions {
  right: 100%;
  opacity: 0.5;
  position: absolute;
  margin-right: 140px;
  display: -ms-flexbox;
}

.pdfWrapper {
  width: 100%;
}

.pdfWrapper .normalview {
  z-index: -1;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}

.pdfWrapper .PdfViewBtn {
  z-index: 1;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: #fafafa;
  font-weight: 500;
  margin-top: 10px;
  line-height: 15px;
  border-radius: 4px;
  font-family: "Inter";
  background: #f44336;
}

.pdfIconContent {
  display: flex;
  padding: 10px;
  overflow: hidden;
  align-items: center;
  border: 1px solid #ddd;
}

.pdfIconContent h5 {
  margin: 0;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pdfIconContent h5::after {
  left: 0;
  top: 100%;
  opacity: 0;
  padding: 5px;
  color: #fff;
  position: absolute;
  border-radius: 3px;
  visibility: hidden;
  white-space: nowrap;
  content: attr(title);
  background-color: #333;
  transition: opacity 0.3s ease-in-out;
}

.pdfIconContent:hover h5::after {
  opacity: 1;
  visibility: visible;
}

.pdfName {
  flex-grow: 1;
  overflow-y: clip;
  margin-top: 14px;
  margin-left: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.instant-pdf-tool .pdfWrapper {
  width: 100% !important;
}

// .pdfWrapper .normalview .react-pdf__Page__canvas {
//   overflow-x: hidden;
//   width: 100% !important;
//   height: 400px !important;
// }

// .pdfWrapperfullscreen .react-pdf__Document {
//   overflow: auto !important;
// }

// .pdfWrapperfullscreen .fullscreenPage .react-pdf__Page {
//   max-width: 100%;
// }

// .pdfWrapper .react-pdf__Page canvas {
//   aspect-ratio: 16/9;
//   object-fit: contain;
//   mix-blend-mode: normal;
//   width: 100% !important;
// }

// .pdfWrapperfullscreen .react-pdf__Page canvas {
//   max-width: 60%;
//   margin-left: 20%;
//   width: 60% !important;
//   height: 80% !important;
// }

.ce-toolbar__actions--opened {
  opacity: 1;
}

.ce-block__content {
  left: 80px;
  max-width: 100%;
  position: relative;

  /* example value, adjust for your own use case */
}

.ace_editor {
  width: 100%;
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}

.reactEditorjs {
  margin-bottom: 2em;
}

/* styling button */

#toggle-edit-btn {
  color: #fff;
  font-size: 1.2rem;
  margin: 2rem auto;
  padding: 1rem 2rem;
  border-radius: 0.3rem;
  background-color: #4267b2;
  border: 1px solid #4267b2;
}

.ce-popover--opened {
  width: 248px !important;
}

.ce-popover__items {
  overflow-x: hidden !important;
}

.ce-popover-item:hover:not(.ce-popover-item--no-hover) {
  background: rgba(6, 113, 224, 0.2);
}

.ce-popover-item {
  gap: 10px;
  width: 237px;
  padding: 8px 10px;
}

.ce-popover-item__title {
  font-size: 16px;
  color: #373739;
  font-weight: 700;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  align-self: stretch;
}

.ce-popover-item__icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid rgba(55, 55, 57, 0.2);
}

#toggle-edit-btn:focus,
#toggle-edit-btn:hover {
  cursor: pointer;
}

/* styling app content container */

.admin-content {
  width: 80%;
  margin-top: 30px;
  text-align: left;
  margin-left: 38px;
  min-height: 100vh;
  background-color: white;
}

.admin-content .react-pdf__Page__textContent .textLayer {
  height: 400px !important;
}

.textLayer {
  width: 400px !important;
  height: 400px !important;
}

.admin-content .react-pdf__Page__annotations .annotationLayer {
  height: 220px !important;
}

.annotationLayer {
  width: 400px !important;
  height: 400px !important;
}

.pdfIconContent {
  gap: 30px;
  border: 1px;
  width: 370px;
  height: 75px;
  display: flex;
  border-radius: 7px;
  box-shadow: 0 1px 2px black;
  justify-content: space-between;
}

.student-content {
  padding-bottom: 10px;
  top: calc(7vh + 60px);
  width: 80%;
  margin: auto;
  // padding-top: 80px;
  user-select: none;
  text-align: left;
  // min-height: 100vh;
  position: relative;
  pointer-events: auto;
  -ms-user-select: none;
  -moz-user-select: none;
  background-color: white;
  -webkit-user-select: none;
}

.student-content1{

  width: 80%;
  margin: auto;
  // padding-top: 80px;
  user-select: none;
  text-align: left;
  // min-height: 100vh;
  position: relative;
  pointer-events: auto;
  -ms-user-select: none;
  -moz-user-select: none;
  background-color: white;
  -webkit-user-select: none;

}

@media (min-width: 651px) {
  .student-content
    .editor-container
    .codex-editor--narrow
    .codex-editor__redactor {
    margin-right: 0px !important;
  }
}

@media (min-width: 651px) {
  .student-content1
    .editor-container
    .codex-editor--narrow
    .codex-editor__redactor {
    margin-right: 0px !important;
  }
}

/* styling alert text when edit mode is enabled */

.edit-mode-alert {
  color: #c80000;
  text-decoration: underline;
}

.color-sign > * {
  max-width: 300px !important;
  column-gap: 30px !important;
  max-height: 100px !important;
}

/* styling text parsed */

.FileParse {
  display: flex;
  padding-bottom: 20px;
  justify-content: flex-start;
}

.instant-pdf-tool.withBorder iframe {
  border: 1px solid #e8e8eb;
}

.instant-pdf-tool.withBackground {
  padding: 10px;
  background: #eff2f5;
}

.sandpackUtils {
  height: 50px;
  display: flex;
  width: 100%;
  background: #e4e5e7;
  justify-content: flex-start;
}

.selectTemplate
  > .css-760lhl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  max-height: 60px;
  position: relative;
  margin: 5px !important;
  width: 160px !important;
}

.selectTemplate
  > .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  max-height: 60px;
  position: relative;
  margin: 5px !important;
  width: 160px !important;
}

.sandpackUtils > .toggleFullscreen {
  flex: 1;
  cursor: pointer;
  margin-top: 15px;
  text-align: right;
  position: relative;
  margin-right: 30px;
}

.student-content .editor-container .ce-block .ce-block__content {
  max-width: 100%;
  position: relative;
  left: 0px !important;

  /* example value, adjust for your own use case */
}

.student-content1 .editor-container .ce-block .ce-block__content {
  max-width: 100%;
  position: relative;
  left: 0px !important;

  /* example value, adjust for your own use case */
}

.instant-pdf-tool.withBackground iframe {
  display: block;
  max-width: 60%;
  margin: 0 auto 15px;
}

.instant-pdf-tool.stretched iframe {
  top: 20px;
  position: relative;
  width: 1024px !important;
  height: 700px !important;
}
