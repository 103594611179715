@use "../../Abstracts/Variables/index" as i;
@use "../../Abstracts/mixins" as m;

.headerDiv,
.headerDivLeftNav,
.headerDivSandPack,
.headerDivForRegistration {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  background: map-get(i.$appBackGroundColor, "grey/100");
}

.headerDivLeftNav {
  background: map-get(i.$appBackGroundColor, "white");
}

.headerDivForRegistration {
  background: map-get(i.$appBackGroundColor, "grey/200");
}

.headerDivLogo {
  display: inline-block;
  padding: 12px 0 12px 40px;

  .headerDivLogoInnerDiv {
    height: 28px;
    display: flex;
  }
}

.headerDivBadges {
  display: flex;
  margin: 10px 40px 10px 0;

  .headerAvatarIcon {
    padding: 0;
    margin: 0 0 0 8px;
  }

  .buttonSecondaryGreysm {
    margin-right: 15px;
  }
}

.headerDivSandPack {
  background: map-get(i.$appBackGroundColor, "grey/100");
}

#headerAvatarPopover {
  .MuiPaper-root {
    width: 260px;
    position: absolute;
    top: 50px !important;
    right: 24px;
    left: unset !important;
    border-radius: 10px;
    box-shadow: 0px 30px 40px 0px #00000026;
    .headerAvatarUserNameContainer,
    .headerAvatarViewProfileContainer,
    .headerAvatarLogoutContainer,
    .headerAvatarCompanyProfileContainer {
      padding: 18px 16px 0px 16px;
      gap: 89px;
      .headerAvatarDetailSec {
        padding: 0px 0px 12px 0px;
        display: flex;
        gap: 12px;
      }
      .headerAvatarUserNameSec {
        border-bottom: 0.7px solid #cecece;
        align-items: center;
        .headerAvatarUserName {
          font-family: Inter;
          font-size: 18px;
          font-weight: 500;
          line-height: 21.78px;
          letter-spacing: -0.01em;
          text-align: left;
          color: map-get(i.$appColor, "grey/800");
        }
      }
      .headerAvatarViewProfile,
      .headerAvatarLogout,
      .headerAvatarCompanyProfile {
        @include m.fontSize("text/md/medium");
        color: map-get(i.$appColor, "grey/700");
      }
    }
    .headerAvatarViewProfileContainer,
    .headerAvatarLogoutContainer,
    .headerAvatarCompanyProfileContainer {
      cursor: pointer;
    }
    .headerAvatarViewProfileContainer:hover,
    .headerAvatarLogoutContainer:hover,
    .headerAvatarCompanyProfileContainer:hover {
      background: map-get(i.$appColor, "grey/100");
    }
  }
}

#profileAvatarPopover {
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #profileAvatarIcon {
    display: flex;
    justify-content: center;
  }
  #profileAvatarBtn {
    padding: 8px 0;
    border-radius: 0;
  }
}
.versionum {
  @include m.fontSize("text/xs/medium");
  color: map-get(i.$appColor, "grey/500");
  text-align: center;
}
