#naviagteToPrevious {
  margin-left: 20px;
  margin-right: 20px;
}

.languageName {
  float: left;
  width: 862px;
  height: 52px;
  text-align: left;
  margin: 25px 0 60px 80px;
}

.admincontentpageone {
  display: flex;
  height: 500px;
  margin-top: 60px;
  position: relative;
  flex-direction: column;
}

.admincontentpageone .adminTestBackBtn {
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 20px;
}

#languageName {
  gap: 10px;
  width: 345px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

.languageName > label {
  width: 45px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}

.topic .select_modules {
  padding-top: 30px !important;
  padding-left: 60px !important;
  padding-bottom: 60px !important;
}

.nextbutton {
  top: 220px;
  left: 80px;
  position: absolute;
}

.cancelToback .btnMedium {
  float: left;
  z-index: 19;
  right: 110px;
  bottom: 20px;
  position: fixed;
}

.contentStudent {
  padding-top: 9px;
  position: relative;
  align-items: center;
  display: inline-flex;
}

.contentStudent h3 {
  font-size: 20px;
  font-weight: 700;
  margin-left: 43px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0em;
  display: inline-flex;
}

.previewBtn .btnMedium {
  top: 220px;
  left: 190px;
  position: absolute;
}

.StudentForm {
  z-index: 2;
  opacity: 1;
  width: 100%;
  height: 60px;
  display: flex;
  flex-shrink: 0;
  position: fixed;
  margin-top: 0px;
  padding-top: 9px;
  background-color: #eceeef;
}

.AdminContentWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: visible;
  width: 1320px !important;
}

// .StudentContentWrapper {
//   height: 92vh;
//   overflow: scroll;
//   position: relative;
//   width: 100% !important;
//   top: 35px;
// }

.buttonsForContent{
  display: flex;
  gap: 8px;
  float: left;
  right: 30px;
  z-index: 19;
  bottom: 20px;
  position: fixed;
  display:flex ;
  //width: 350px;
}

// #btnMedium1 {
//   f
// }
// #btnMedium2 {
//   float: left;
//   right: 30px;
//   z-index: 19;
//   bottom: 20px;
//   position: fixed;
//   display:flex ;
//   width: 350px;
// }

// .button .btnMedium {
//   float: left;
//   right: 30px;
//   z-index: 19;
//   bottom: 20px;
//   position: fixed;
// }

.studentBackButton {
  left: 40px;
  cursor: pointer;
  position: relative;
  align-items: center;
  display: inline-flex;
}

.studentBackButton svg {
  margin-right: 14px;
}

.MarkAsCompleted:last-child {
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 10px;
}

.topic > .css-1fdsijx-ValueContainer {
  padding: 0px !important;
  height: 35px !important;
}
