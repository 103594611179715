@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.detailCont,
.academicDetailContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .academicDetailSection {
    .academicDetail {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .academicDetailTitle {
        @include m.fontSize(text/lg/medium);
        color: map-get(i.$appColor, "grey/900");
      }
    }
  }
}

.detailCont {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

#academicDetailDialogContainer {
  .MuiDialog-paper {
    margin: 10px 0;
    width: 848px !important;
    max-height: 741px;
    min-height: 248px;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0px 20px 24px -4px #10182814;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .academicDetailDialogTitleSec {
    display: flex;
    justify-content: space-between;
    padding: 0;

    .academicDetailDialogTitle {
      margin: 0;
      @include m.fontSize(text/lg/semibold);
    }

    .academicDetailDialogCloseIcon {
      cursor: pointer;
    }
  }

  #academicDetailDialogContentSec {
    padding: 10px 0 0 0;
  }

  #academicDetailDialogBtnSec {
    padding: 0;
  }
}

.custom-modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.custom-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  overflow-y: scroll;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}