.ctntuploadlibTaps {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  font-style: normal;
  padding: 0 0 0 15px;
  font-family: "Inter";
  margin: 5px 0px 0 0px;
  text-decoration: none;
  border-bottom: #000000 !important;
}

.ctnterrors {
  margin: 0px;
  color: red;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-family: "Inter";
}

.content_name {
  float: left;
  width: 862px;
  height: 52px;
  text-align: left;
  margin: 25px 0 0 23px;
}

.content_name > label {
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}

#content_name {
  gap: 10px;
  width: 862px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

.content_description {
  width: 99%;
  float: left;
  text-align: left;
  margin: 25px 0 0 23px;
}

.content_description > label {
  width: 68px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}

.ctnteditor-tinymce {
  float: left;
  width: 862px;
  margin: 0px 0 0 23px;
}

.content_savebtn {
  right: 92%;
  width: 100%;
  float: right;
  position: relative;
  margin: 34px 10px 0 0;
}

.content_savebtn > button {
  float: right;
}

.content_savebtn button {
  width: 73px;
  border: none;
  height: 34px;
  color: #ffffff;
  border-radius: 4px;
  background: #f55533;
}

.content-navItem > .nav-link {
  color: #f55533 !important;
  background-color: #efefef;
}

.content-navItem .active {
  color: #ffffff !important;
  background-color: #f55533 !important;
}

.ctntshowinglist {
  width: 36%;
  float: left;
  height: auto;
}

.ctntfile-lists > li {
  display: flex;
  margin: 1.2em 0;
  list-style: none;
  border-radius: 5px;
  align-items: center;
  padding: 1.2em 1.5em;
  background-color: #f55e3038;
}

.ctntfile-lists > li > svg {
  color: #f55321;
}

.ctntfile-lists > li > svg:first-child {
  font-size: 1.2em;
  margin-right: 0.8em;
}

.ctntlistsName {
  flex: 1;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-size: 0.9em;
  color: #000000;
  font-family: "Inter";
  text-decoration: none;
}

.ctntlistsName:hover {
  color: #0d6efd;
  text-decoration: underline;
}

.ctnttrashIconforDeleteFile {
  color: #f55321;
}

.ctntcheckisDownload {
  margin: 0 0 0 20px;
}

.ctntembedLink {
  float: left;
  width: 862px;
  height: 65px;
  text-align: left;
  margin: 25px 0 0 23px;
}

.ctntembedLinkView {
  float: left;
  width: 862px;
  height: auto;
  text-align: left;
  margin: 25px 0 0 23px;
}

.ctntembedLink > label {
  width: 90px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 6px;
  font-family: "Inter";
}

#ctntembedLink {
  gap: 10px;
  width: 500px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  display: inline-block;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

.ctntembedLinkBtn {
  color: #ffffff;
  border-radius: 50%;
  margin: 0 0 0 15px;
  background: rgb(20, 117, 207);
}

.ctntembed-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ctntembed-list li {
  width: 500px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  margin-bottom: 5px;
  display: inline-block;
  word-wrap: break-word;
}
#addLibrary {
  width: 75px;
  height: 30px;
  float: right;
  padding: 0 20px;
  border: #f55533;
  border-radius: 4px;
  background: #f55533;
  margin: 5px 5px 5px 5px;
}

.libUploadIcon {
  margin-right: 8px;
}

.content_fileupload {
  width: 35%;
  float: left;
  height: 21px;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  margin: 25px 0 0 23px;
  flex-direction: column;
  justify-content: center;
}

.ce-code__textarea {
  font-family: "Inter";
  font-size: 16px !important;
  min-height: 400px !important;
}
