@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.scoreMainContainer {
  width: 166px;
  height: 36px;
  display: flex;
  margin: 0 24px;
  padding: 4px 12px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background: map-get(i.$appBackGroundColor, "white");

  .scoreLabel {
    @include m.fontSize(text/md/medium);
    color: map-get(i.$appColor, "grey/700");
  }

  .scoreValue {
    margin-left: 4px;
    @include m.fontSize(text/lg/medium);
    color: map-get(i.$appColor, "grey/900");
  }

  .scoreInfoSvgIcon {
    padding: 0;
    margin-left: 8px;
  }
}
