@use "./Variables/index" as i;

@mixin appColor($color) {
  color: map-get(i.$appColor, $color);
  background: map-get(i.$appBackGroundColor, $color);
}

@mixin button($size, $hierarchy, $hover, $disable, $destructive) {
  cursor: pointer;
  border-radius: 8px;

  @if $size == "sm" {
    height: map-get(i.$buttonHeight, $size);
    padding: map-get(i.$buttonPadding, $size);
    min-width: map-get(i.$buttonWidth, $size);
  } @else if $size == "md" {
    height: map-get(i.$buttonHeight, $size);
    padding: map-get(i.$buttonPadding, $size);
    min-width: map-get(i.$buttonWidth, $size);
  } @else if $size == "lg" {
    height: map-get(i.$buttonHeight, $size);
    padding: map-get(i.$buttonPadding, $size);
    min-width: map-get(i.$buttonWidth, $size);
  } @else if $size == "xl" {
    height: map-get(i.$buttonHeight, $size);
    padding: map-get(i.$buttonPadding, $size);
    min-width: map-get(i.$buttonWidth, $size);
  } @else if $size == "2xl" {
    height: map-get(i.$buttonHeight, $size);
    padding: map-get(i.$buttonPadding, $size);
    min-width: map-get(i.$buttonWidth, $size);
  } @else if $size == "lsm" {
    height: map-get(i.$buttonHeight, $hierarchy);
    min-width: map-get(i.$buttonWidth, $hierarchy);
  } @else if $size == "lmd" {
    height: map-get(i.$buttonHeight, $hierarchy);
    min-width: map-get(i.$buttonWidth, $hierarchy);
  } @else if $size == "llg" {
    height: map-get(i.$buttonHeight, $hierarchy);
    min-width: map-get(i.$buttonWidth, $hierarchy);
  } @else if $size == "lxl" {
    height: map-get(i.$buttonHeight, $hierarchy);
    min-width: map-get(i.$buttonWidth, $hierarchy);
  } @else if $size == "l2xl" {
    height: map-get(i.$buttonHeight, $hierarchy);
    min-width: map-get(i.$buttonWidth, $hierarchy);
  }

  @if $hierarchy == "primary" {
    @if $destructive {
      color: map-get(i.$appColor, "white");
      border: 1px solid map-get(i.$appColor, error/600);
      background: map-get(i.$appBackGroundColor, error/600);

      @if $hover {
        color: map-get(i.$appColor, "white");
        border: 1px solid map-get(i.$appColor, error/700);
        background: map-get(i.$appBackGroundColor, error/700);
      }

      @if $disable {
        cursor: no-drop;
        color: map-get(i.$appColor, "white");
        border: 1px solid map-get(i.$appColor, error/200);
        background: map-get(i.$appBackGroundColor, error/200);
      }
    } @else {
      color: map-get(i.$appColor, "white");
      border: 1px solid map-get(i.$appColor, brand/600);
      background: map-get(i.$appBackGroundColor, brand/600);

      @if $hover {
        color: map-get(i.$appColor, "white");
        border: 1px solid map-get(i.$appColor, brand/700);
        background: map-get(i.$appBackGroundColor, brand/700);
      }

      @if $disable {
        cursor: no-drop;
        color: map-get(i.$appColor, "white");
        border: 1px solid map-get(i.$appColor, brand/200);
        background: map-get(i.$appBackGroundColor, brand/200);
      }
    }
  } @else if $hierarchy == "secondaryColor" {
    @if $destructive {
      color: map-get(i.$appColor, error/700);
      border: 1px solid map-get(i.$appColor, error/50);
      background: map-get(i.$appBackGroundColor, error/50);

      @if $hover {
        color: map-get(i.$appColor, error/700);
        border: 1px solid map-get(i.$appColor, error/100);
        background: map-get(i.$appBackGroundColor, error/100);
      }

      @if $disable {
        cursor: no-drop;
        color: map-get(i.$appColor, error/300);
        border: 1px solid map-get(i.$appColor, error/25);
        background: map-get(i.$appBackGroundColor, error/25);
      }
    } @else {
      color: map-get(i.$appColor, brand/700);
      border: 1px solid map-get(i.$appColor, brand/50);
      background: map-get(i.$appBackGroundColor, brand/50);

      @if $hover {
        color: map-get(i.$appColor, brand/700);
        border: 1px solid map-get(i.$appColor, brand/100);
        background: map-get(i.$appBackGroundColor, brand/100);
      }

      @if $disable {
        cursor: no-drop;
        color: map-get(i.$appColor, brand/300);
        border: 1px solid map-get(i.$appColor, brand/25);
        background: map-get(i.$appBackGroundColor, brand/25);
      }
    }
  } @else if $hierarchy == "secondaryGrey" {
    @if $destructive {
      color: map-get(i.$appColor, "error/700");
      border: 1px solid map-get(i.$appColor, "error/300");
      background: map-get(i.$appBackGroundColor, "white");

      @if $hover {
        color: map-get(i.$appColor, "error/800");
        border: 1px solid map-get(i.$appColor, "error/300");
        background: map-get(i.$appBackGroundColor, "error/50");
      }

      @if $disable {
        cursor: no-drop;
        color: map-get(i.$appColor, "error/300");
        border: 1px solid map-get(i.$appColor, "error/200");
        background: map-get(i.$appBackGroundColor, "white");
      }
    } @else {
      color: map-get(i.$appColor, "grey/700");
      border: 1px solid map-get(i.$appColor, "grey/300");
      background: map-get(i.$appBackGroundColor, "white");

      @if $hover {
        color: map-get(i.$appColor, "grey/800");
        border: 1px solid map-get(i.$appColor, "grey/300");
        background: map-get(i.$appBackGroundColor, "grey/50");
      }

      @if $disable {
        cursor: no-drop;
        color: map-get(i.$appColor, "grey/300");
        border: 1px solid map-get(i.$appColor, "grey/200");
        background: map-get(i.$appBackGroundColor, "white");
      }
    }
  } @else if $hierarchy == "tertiaryColor" or $hierarchy == "linkColor" {
    @if $destructive {
      border: none;
      background: none;
      color: map-get(i.$appColor, "error/700");

      @if $hover and $hierarchy != "linkColor" {
        border: none;
        background: map-get(i.$appBackGroundColor, "error/50");
      } @else if $hover == true and $hierarchy == "linkColor" {
        color: map-get(i.$appColor, "error/800");
      }

      @if $disable {
        border: none;
        cursor: no-drop;
        background: none;
        color: map-get(i.$appColor, "error/300");
      }
    } @else {
      @if $destructive {
        border: none;
        background: none;
        color: map-get(i.$appColor, "brand/700");

        @if $hover and $hierarchy != "linkColor" {
          border: none;
          background: map-get(i.$appBackGroundColor, "brand/50");
        } @else if $hover == true and $hierarchy == "linkColor" {
          color: map-get(i.$appColor, "brand/800");
        }

        @if $disable {
          border: none;
          cursor: no-drop;
          background: none;
          color: map-get(i.$appColor, "grey/300");
        }
      } @else {
        border: none;
        background: none;
        color: map-get(i.$appColor, "brand/700");

        @if $hover and $hierarchy != "linkColor" {
          border: none;
          background: map-get(i.$appBackGroundColor, "brand/50");
        } @else if $hover == true and $hierarchy == "linkColor" {
          color: map-get(i.$appColor, "brand/800");
        }

        @if $disable {
          border: none;
          cursor: no-drop;
          background: none;
          color: map-get(i.$appColor, "grey/300");
        }
      }
    }
  } @else if $hierarchy == "tertiaryGrey" or $hierarchy == "linkGrey" {
    @if $destructive {
      border: none;
      background: none;
      color: map-get(i.$appColor, "error/700");

      @if $hover and $hierarchy != "linkGrey" {
        border: none;
        background: map-get(i.$appBackGroundColor, "error/50");
      } @else if $hover == true and $hierarchy == "linkGrey" {
        color: map-get(i.$appColor, "error/800");
      }

      @if $disable {
        border: none;
        cursor: no-drop;
        background: none;
        color: map-get(i.$appColor, "error/300");
      }
    } @else {
      border: none;
      background: none;
      color: map-get(i.$appColor, "grey/500");

      @if $hover and $hierarchy != "linkGrey" {
        border: none;
        background: map-get(i.$appBackGroundColor, "grey/50");
      } @else if $hover == true and $hierarchy == "linkGrey" {
        color: map-get(i.$appColor, "grey/600");
      }

      @if $disable {
        border: none;
        cursor: no-drop;
        background: none;
        color: map-get(i.$appColor, "grey/300");
      }
    }
  } @else {
    border: none;
    background: none;
    color: map-get(i.$appColor, "grey/500");

    @if $hover and $hierarchy != "linkGrey" {
      border: none;
      background: map-get(i.$appBackGroundColor, "grey/50");
    } @else if $hover == true and $hierarchy == "linkGrey" {
      color: map-get(i.$appColor, "grey/600");
    }

    @if $disable {
      border: none;
      cursor: no-drop;
      background: none;
      color: map-get(i.$appColor, "grey/300");
    }
  }
}

@mixin btn($color, $size) {
  color: map-get(i.$appColor, $color);
  margin: map-get(i.$btnMargin, $color);
  min-width: map-get(i.$btnWidth, $size);
  min-height: map-get(i.$btnHeight, $size);
  background: map-get(i.$appBackGroundColor, $color);
}

@mixin prevNextSvgIcon($name, $disable: false) {
  cursor: pointer;

  @if $name == "left" {
    margin: 0px 10px;
  } @else {
    margin-left: 10px;
  }

  @if $disable {
    pointer-events: none;
  }
}

@mixin fontSize($size) {
  font-family: Inter;
  font-style: normal;
  font-size: map-get(i.$fontSize, $size);
  font-weight: map-get(i.$fontWeight, $size);
  line-height: map-get(i.$lineHeight, $size);
}

@mixin multiLevelDropDown() {
  .react-dropdown-tree-select .dropdown .dropdown-trigger {
    border: none;
    padding: 0px;
    width: 172px;
    height: 32px;
    overflow: hidden;
    line-height: 20px;
    display: inline-block;
    box-shadow: unset;
    border: 1px solid #d0d5dd;
    input {
      background: unset;
      overflow: hidden !important;
      margin: 0;
    }
  }

  .dropdown-content {
    width: unset !important;
    max-height: unset !important;
    .infinite-scroll-component {
      max-height: 88vh !important;
    }
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after,
  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    top: -2px;
    width: 10px;
    height: 5px;
    // left: -30px;
    padding: 4px;
    position: relative;
    display: inline-block;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
    text-decoration: transparent;
    border-color: map-get(i.$appColor, "grey/500");
  }

  .react-dropdown-tree-select .dropdown .dropdown-content {
    padding: 0px;
    border-radius: 8px;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    top: 2px;
    transform: rotate(225deg);
  }

  .checkbox-item,
  .radio-item {
    accent-color: map-get(i.$appColor, "brand/500");
  }

  .tag-list {
    width: unset !important;
    .tag-item {
      margin: 0px;
      display: inline-block;

      .tag {
        display: none;
      }
    }
  }

  .tag-item .search {
    height: 32px;
    outline: none;
    max-width: 160px;
    margin-left: 12px;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid map-get(i.$appColor, "grey/300");
    background: map-get(i.$appBackGroundColor, "white");
  }

  .tag-item .search::placeholder {
    @include fontSize(text/sm/medium);
    color: map-get(i.$appColor, "grey/700");
  }

  #rdts4-2_li,
  #rdts1-0-0_li {
    padding-right: 20px;
  }

  .infinite-scroll-component .expanded {
    padding: 4px 10px 4px 20px;
  }

  .infinite-scroll-component .collapsed {
    top: 2px;
    font-size: larger;
    position: relative;
    padding: 4px 10px 4px 20px;
  }

  .infinite-scroll-component {
    label {
      margin: 10px 0px;
    }
  }
}
