@use "../Abstracts/Variables/index" as i;

.iconButton {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: unset;
  border: 1px solid;
  box-shadow: 0px 1px 2px 0px #1018280d;
  outline: none;
}
.iconButton:disabled svg,
.iconButton:disabled:hover svg {
  cursor: not-allowed;
}

/* Size style */
.iconButton.sm {
  padding: 8px;
  width: 36px;
  height: 36px;
}

.iconButton.md {
  padding: 10px;
  width: 40px;
  height: 40px;
}
.iconButton.lg {
  padding: 12px;
  width: 44px;
  height: 44px;
}
.iconButton.xl {
  padding: 14px;
  width: 48px;
  height: 48px;
}
.iconButton.xxl {
  padding: 16px;
  width: 56px;
  height: 56px;
}

/* Color */

/* Primary */
.iconButton.primary {
  border-color: map-get(i.$appColor, "brand/600");
  background: map-get(i.$appBackGroundColor, "brand/600");
  svg {
    path {
      stroke: map-get(i.$appColor, "white");
    }
  }
}

.iconButton.primary:disabled,
.iconButton.primary:disabled:hover {
  border-color: map-get(i.$appColor, "brand/200");
  background: map-get(i.$appBackGroundColor, "brand/200");
}
.iconButton.primary:hover {
  border-color: map-get(i.$appColor, "brand/700");
  background: map-get(i.$appBackGroundColor, "brand/700");
}
.iconButton.primary:focus {
  outline: none;
  box-shadow: 0px 0px 0px 4px #f4ebff;
  // box-shadow: 0px 1px 2px 0px #1018280D;
}
.iconButton.primary.destructive {
  border-color: map-get(i.$appColor, "error/600");
  background: map-get(i.$appBackGroundColor, "error/600");
  color: map-get(i.$appColor, "white");
}

.iconButton.primary.destructive:hover {
  border-color: map-get(i.$appColor, "error/700");
  background: map-get(i.$appBackGroundColor, "error/700");
}
.iconButton.primary.destructive:focus {
  outline: none;
  box-shadow: 0px 0px 0px 4px #fee4e2;
}

.iconButton.primary.destructive:disabled,
.iconButton.primary.destructive:disabled:hover {
  border-color: map-get(i.$appColor, "error/200");
  background: map-get(i.$appBackGroundColor, "error/200");
}

/* Secondary */
.iconButton.secondary {
  border-color: map-get(i.$appColor, "brand/50");
  background: map-get(i.$appBackGroundColor, "brand/50");
  svg {
    path {
      stroke: map-get(i.$appColor, "brand/700");
    }
  }
}

.iconButton.secondary:hover {
  border-color: map-get(i.$appColor, "brand/100");
  background: map-get(i.$appBackGroundColor, "brand/100");
}
.iconButton.secondary:focus {
  outline: none;
  box-shadow: 0px 0px 0px 4px #f4ebff;
  //   box-shadow: 0px 1px 2px 0px #1018280D;
}

.iconButton.secondary:disabled,
.iconButton.secondary:disabled:hover {
  border-color: map-get(i.$appColor, "brand/25");
  background: map-get(i.$appBackGroundColor, "brand/25");
  svg {
    path {
      stroke: map-get(i.$appColor, "brand/300");
    }
  }
}

.iconButton.secondary.destructive {
  border-color: map-get(i.$appColor, "error/50");
  background: map-get(i.$appBackGroundColor, "error/50");
  svg {
    path {
      stroke: map-get(i.$appColor, "error/700");
    }
  }
}

.iconButton.secondary.destructive:hover {
  border-color: map-get(i.$appColor, "error/100");
  background: map-get(i.$appBackGroundColor, "error/100");
  svg {
    path {
      stroke: map-get(i.$appColor, "error/800");
    }
  }
}
.iconButton.secondary.destructive:focus {
  outline: none;
  box-shadow: 0px 0px 0px 4px #fee4e2;
  //   box-shadow: 0px 1px 2px 0px #1018280D;
}
.iconButton.secondary.destructive:disabled,
.iconButton.secondary.destructive:disabled:hover {
  border-color: map-get(i.$appColor, "error/25");
  background: map-get(i.$appBackGroundColor, "error/25");
  svg {
    path {
      stroke: map-get(i.$appColor, "error/300");
    }
  }
}

/* Secondary grey */

.iconButton.secondaryGrey {
  border-color: map-get(i.$appColor, "grey/300");
  background: map-get(i.$appBackGroundColor, "white");
  svg {
    path {
      stroke: map-get(i.$appColor, "grey/700");
    }
  }
}

.iconButton.secondaryGrey:hover {
  border-color: map-get(i.$appColor, "grey/300");
  background: map-get(i.$appBackGroundColor, "grey/50");
  svg {
    path {
      stroke: map-get(i.$appColor, "grey/800");
    }
  }
}
.iconButton.secondaryGrey:focus {
  outline: none;
  box-shadow: 0px 0px 0px 4px #f2f4f7;
  //   box-shadow: 0px 1px 2px 0px #1018280D;
}

.iconButton.secondaryGrey:disabled,
.iconButton.secondaryGrey:disabled:hover {
  border-color: map-get(i.$appColor, "grey/200");
  background: map-get(i.$appBackGroundColor, "white");
  svg {
    path {
      stroke: map-get(i.$appColor, "grey/300");
    }
  }
}

.iconButton.secondaryGrey.destructive {
  border-color: map-get(i.$appColor, "error/300");
  background: map-get(i.$appBackGroundColor, "white");
  svg {
    path {
      stroke: map-get(i.$appColor, "error/700");
    }
  }
}

.iconButton.secondaryGrey.destructive:hover {
  border-color: map-get(i.$appColor, "error/300");
  background: map-get(i.$appBackGroundColor, "error/50");
  svg {
    path {
      stroke: map-get(i.$appColor, "error/800");
    }
  }
}
.iconButton.secondaryGrey.destructive:focus {
  outline: none;
  box-shadow: 0px 0px 0px 4px #fee4e2;
  // box-shadow: 0px 1px 2px 0px #1018280D;
}
.iconButton.secondaryGrey.destructive:disabled,
.iconButton.secondaryGrey.destructive:disabled:hover {
  border-color: map-get(i.$appColor, "error/200");
  background: map-get(i.$appBackGroundColor, "white");
  svg {
    path {
      stroke: map-get(i.$appColor, "error/300");
    }
  }
}

/* Tertiary  */
.iconButton.tertiary {
  border: unset;
  box-shadow: unset;
  svg {
    path {
      stroke: map-get(i.$appColor, "brand/700");
    }
  }
}

.iconButton.tertiary:hover {
  background: map-get(i.$appBackGroundColor, "brand/50");
}
// .iconButton.tertiary:focus {
//   outline: none;
//   box-shadow: unset;
//   //   box-shadow: 0px 1px 2px 0px #1018280D;
// }

.iconButton.tertiary:disabled,
.iconButton.tertiary:disabled:hover {
  svg {
    path {
      stroke: map-get(i.$appColor, "grey/300");
    }
  }
}

.iconButton.tertiary.destructive {
  svg {
    path {
      stroke: map-get(i.$appColor, "error/700");
    }
  }
}

.iconButton.tertiary.destructive:hover {
  background: map-get(i.$appBackGroundColor, "error/50");
}
// .iconButton.tertiary.destructive:focus {
//   outline: none;
//   box-shadow: 0px 0px 0px 4px #fee4e2;
//   // box-shadow: 0px 1px 2px 0px #1018280D;
// }
.iconButton.tertiary.destructive:disabled,
.iconButton.tertiary.destructive:disabled:hover {
  svg {
    path {
      stroke: map-get(i.$appColor, "error/300");
    }
  }
}

/* Tertiary grey */

.iconButton.tertiaryGrey {
  border: unset;
  box-shadow: unset;
  svg {
    path {
      stroke: map-get(i.$appColor, "grey/500");
    }
  }
}

.iconButton.tertiaryGrey:hover {
  background: map-get(i.$appBackGroundColor, "grey/50");
  svg {
    path {
      stroke: map-get(i.$appColor, "grey/600");
    }
  }
}
// .iconButton.tertiaryGrey:focus {
//   outline: none;
//   box-shadow: unset;
//   //   box-shadow: 0px 1px 2px 0px #1018280D;
// }

.iconButton.tertiaryGrey:disabled,
.iconButton.tertiaryGrey:disabled:hover {
  svg {
    path {
      stroke: map-get(i.$appColor, "grey/300");
    }
  }
}

.iconButton.tertiaryGrey.destructive {
  svg {
    path {
      stroke: map-get(i.$appColor, "error/700");
    }
  }
}

.iconButton.tertiaryGrey.destructive:hover {
  background: map-get(i.$appBackGroundColor, "error/50");
  svg {
    path {
      stroke: map-get(i.$appColor, "error/800");
    }
  }
}
// .iconButton.tertiaryGrey.destructive:focus {
//   outline: none;
//   box-shadow: 0px 0px 0px 4px #fee4e2;
//   // box-shadow: 0px 1px 2px 0px #1018280D;
// }
.iconButton.tertiaryGrey.destructive:disabled,
.iconButton.tertiaryGrey.destructive:disabled:hover {
  svg {
    path {
      stroke: map-get(i.$appColor, "error/300");
    }
  }
}
