@use "../../Abstracts/Variables/index" as i;
@use "../../Abstracts/mixins" as m;

.skillMapContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .skillMapBodySec {
    display: table;
    border-collapse: collapse;
    // .MuiFormControl-root {
    //   label {
    //     font-family: "Inter";
    //     color: map-get(i.$appColor, "grey/500");
    //   }
    //   #skillName {
    //     @include m.fontSize(text/md/regular);
    //     color: map-get(i.$appColor, "grey/900");
    //   }
    //   fieldset {
    //     border: 1px solid #98a2b3;
    //   }
    // }
    // .skillNameField {
    #skillName {
      width: 200px;
      // border: 1px solid map-get(i.$appColor,"grey/300");
      // border-radius: 8px;
      // padding: 10px 14px;
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/900");
    }
    // }
    .skillBodyContainer {
      // overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .skillBodyMapContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .skillBodyMapSec {
          padding: 24px;
          border: 1px solid map-get(i.$appColor, "grey/300");
          display: flex;
          flex-direction: column;
          gap: 24px;
          border-radius: 8px;
          .skillBodyAssignmentContainer {
            display: flex;
            flex-direction: column;
            gap: 24px;
            .skillBodyAssignmentSec {
              display: flex;
              flex-direction: column;
              gap: 10px;
              .skillBodyAssignmentTitle {
                display: flex;
                gap: 10px;
                align-items: center;
                @include m.fontSize(text/lg/medium);
                color: map-get(i.$appColor, "grey/700");
              }
              .skillBodyAssignmentInputSec {
                .dropdown {
                  .dropdown-trigger,.dropdown-content {
                    width: 400px;
                  }
                }
              }
              .skillBodyAssignments {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
              }
            }
          }
        }
        .skillBodyMapBtnSec {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
