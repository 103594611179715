.selectModules {
  z-index: 5;
  float: left;
  width: 400px;
  height: 54px;
  margin-left: 23px;
  position: relative;
}

.selectModules > label {
  float: left;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 6px;
  font-family: "Inter";
}
.adminModuleDetailName{
  display: flex;
}
.adminModuleWeightageImage{
  margin: 5px;
}
.adminModuleWeightageInput{
  width: 44px;
  height: 25px;
  padding: 5px;
  border-radius: 8px ;
  border: 1px solid #D0D5DD;  
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  outline: none;
  color: #667085;
  margin: 5px 8px;
}
/* Tooltip text (hidden by default) */
.tooltip-text {
  visibility: hidden; /* Hidden initially */
  background-color: #333; /* Dark background for tooltip */
  color: #fff; /* White text color */
  text-align: center;
  padding: 5px 8px;
  border-radius: 4px; /* Rounded edges */
  position: absolute;
 top: 125%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; /* Prevent text from wrapping */
  z-index: 1;
  opacity: 0; /* Fully transparent initially */
  transition: opacity 0.3s; /* Smooth transition for fade-in effect */
  font-size: 12px; /* Adjust the tooltip font size */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Tooltip arrow */
.tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Position at the bottom of the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent; /* Arrow pointing down */
}
.icon-wrapper {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
}
.icon {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease;
}

.default-icon {
  display: block;
  margin: 7px 0px 5px 0px;
}
.hover-icon {
  display: block;
  margin: 7px 0px 5px 0px;
}

.hover-icon {
 display: none;
}

.icon-wrapper:hover .default-icon {
  display: none;
}

.icon-wrapper:hover .hover-icon {
  display: block;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
