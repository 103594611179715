.backdrop-container {
    position: relative;
    width: 100%;
    height: 100%;

    .custom-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 64px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 52px 0 0 0;

        .countdownSec {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            background-color: rgba(182, 91, 107, 0.2);
            border-radius: 0 0 10px 10px;
            backdrop-filter: blur(10px);
          }
          
          .countdownCount {
            font-family: 'Inter';
            font-weight: 500;
            font-size: 60px;
            color: #101828;
            position: absolute;
            // top: 50%;
            // left: 50%; 
            // transform: translate(-50%, -50%);
          }

      }
  }