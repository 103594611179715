@use "../Abstracts/Variables/index" as i;

.warningMsg {
  max-width: 197px;
  border-radius: 8px;

  .dueTodayBody {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }

  .reTakeWarningMsg {
    display: flex;
    flex-direction: column;

    .reTakeWarningMsgHeader {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
    }

    .reTakeWarningMsgBody {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
