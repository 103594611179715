.btnMedium {
    border: none;
    display: flex;
    color: #FFF;
    padding: 16px;
    flex-shrink: 0;
    min-width: 40px;
    font-size: 16px;
    max-height: 40px;
    font-weight: 400;
    border-radius: 8px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    background: #F55533;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.btnMedium:hover {
    border: none;
    display: flex;
    color: #FFF;
    flex-shrink: 0;
    min-width: 40px;
    font-size: 16px;
    max-height: 40px;
    font-weight: 400;
    border-radius: 8px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    flex-direction: column;
    justify-content: center;
    padding: 16px !important;
    background: rgba(248, 136, 112, 0.97);
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.btnMediumOnClick {
    border: none;
    display: flex;
    color: #FFF;
    flex-shrink: 0;
    min-width: 40px;
    font-size: 16px;
    max-height: 40px;
    font-weight: 400;
    border-radius: 8px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    background: #C44428;
    flex-direction: column;
    justify-content: center;
    padding: 16px !important;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.btnMediumSecondary {
    display: flex;
    padding: 16px;
    flex-shrink: 0;
    min-width: 40px;
    font-size: 16px;
    color: #F55533;
    max-height: 40px;
    font-weight: 400;
    background: #FFF;
    border-radius: 8px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #F55533;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.btnMediumSecondary:hover {
    display: flex;
    flex-shrink: 0;
    min-width: 40px;
    font-size: 16px;
    max-height: 40px;
    color: #F55533;
    font-weight: 400;
    border-radius: 8px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    background: #F1DEDE;
    flex-direction: column;
    justify-content: center;
    padding: 16px !important;
    border: 1px solid #F55533;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.btnMediumOnClickSecondary {
    display: flex;
    flex-shrink: 0;
    min-width: 40px;
    font-size: 16px;
    max-height: 40px;
    font-weight: 400;
    color: #F55533;
    border-radius: 8px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    background: #EDC5C5;
    flex-direction: column;
    justify-content: center;
    padding: 16px !important;
    border: 1px solid #F55533;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.btnMedium:disabled,
.btnMediumSecondary:disabled {
    border: none;
    display: flex;
    padding: 22px;
    flex-shrink: 0;
    font-size: 16px;
    min-width: 40px;
    color: #373739;
    font-weight: 400;
    max-height: 40px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    border-radius: 10px;
    background: #F2F4F7;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.btnMedium:disabled:hover,
.btnMediumSecondary:disabled:hover {
    border: none;
    display: flex;
    color: #000;
    flex-shrink: 0;
    font-size: 16px;
    min-width: 40px;
    font-weight: 400;
    max-height: 40px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    border-radius: 10px;
    background: #DCE2EB;
    flex-direction: column;
    justify-content: center;
    padding: 22px !important;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}