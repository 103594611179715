.containerFluid {
  z-index: 5;
  // margin-top: 7vh;
  overflow: hidden;
}

.newUi {
  height: 75vh;
}

.topElements {
  height: 38px;
}

.addquesBtn > a > button > span {
  width: 98px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.addquesBtn {
  float: right;
  margin: 0 17px 0 16px;
}

#addQuestion {
  width: 138px;
  height: 38px;
  color: #ffffff;
  padding: 0 20px;
  border: #f55533;
  border-radius: 4px;
  background: #f55533;
}

.selectQuestion {
  float: right;
  width: 253px;
  height: 38px;
  margin-left: 1%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-style: normal;
  font-family: "Inter";
}

.searchBox {
  float: right;
  margin: 0 13px 0 0;
}

.searchBox > input {
  width: 186px;
  height: 38px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #ffffff;
  padding: 5px 0px 5px 30px;
  border: 1px solid #e4e5e7;
}

#searchIcon {
  top: 0;
  left: 25px;
  width: 16px;
  height: 16px;
  color: #bdc1c6;
  position: relative;
}

.qnSearch::placeholder {
  width: 47px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #bdc1c6;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.questionReportAdmin {
  margin: 10px 0 0 20px;
}

.questionReportAdmin > thead > tr > th {
  height: 40px;
  width: 1316px;
  text-align: left;
  box-shadow: inset 0px -1px 0px #bdc1c6;
}

.questionReportAdmin > thead > tr > th > p {
  font-size: 12px;
  font-weight: 500;
  color: #9aa0a6;
  line-height: 15px;
  font-style: normal;
  width: max-content;
  font-family: "Inter";
  margin: 13px 0 12px 28px;
  text-transform: uppercase;
}

.questionReportAdmin > tbody > tr {
  height: 40px;
  width: 1316px;
}

.questionReportAdmin > tbody > tr > td {
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  max-width: 100px;
  overflow: hidden;
  line-height: 20px;
  font-style: normal;
  padding-left: 28px;
  white-space: nowrap;
  font-family: "Inter";
  text-overflow: ellipsis;
}

.qnReportQnNameSec {
  width: 35%;
  height: inherit;
  p {
    margin: 0;
  }
  .qnReportQnName {
    width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .qnSharedTag {
    padding: 4px 8px;
    background: #e6e6e6;
    border-radius: 200px;
    font-family: Inter;
    font-size: 12px;
    width: fit-content;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #2c2c2c;
  }
}

#customSwitch {
  box-shadow: none;
}

.toggleSwitchQnlist {
  box-shadow: none;
  padding-left: 30px !important;
}

.ant-pagination-item-link {
  border: none;
  outline: none;
  margin: 5px 0;
  border-radius: 4px;
  background: #ffffff;
}

.ant-pagination-item-link:hover {
  background: #f2f3f5;
}

.ant-pagination-item-link:disabled {
  background: none;
}

.anticon {
  margin-top: -4px;
  vertical-align: middle !important;
}

.ant-pagination-item {
  margin: 0 10px;
  padding: 4px 10px;
}

.ant-pagination-item:hover {
  border-radius: 4px;
  background: #f2f3f5;
}

.ant-pagination-item-active {
  color: #0d6efd;
  border-radius: 4px;
  border: 1px solid #0d6efd;
}

.libTypeNames {
  width: 110px;
  height: 27px;
  font-size: 15px;
  font-weight: 700;
  color: #000000;
  margin: 5px 0 0 0;
  line-height: 27px;
  font-style: normal;
  font-family: "Inter";
}

.typeName {
  height: 27px;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  margin: 5px 0 0 0;
  line-height: 27px;
  font-style: normal;
  font-family: "Inter";
}

.divForLib {
  display: flex;
  margin-left: 45px;
}

input#rc_select_15,
span.ant-select-arrow,
span.ant-select-selection-item,
span.ant-select-selection-search {
  display: none;
}

li.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
  margin: 5px 0;
}
