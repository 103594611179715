@use "../../Abstracts/Variables/index" as i;
@use "../../Abstracts/mixins" as m;

.ccSecQnsContainer {
  .ccSecQnsHeadUtilsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .ccSecQnsHeaderContainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .ccSecQnsHeaderLeftSec {
        display: flex;
        gap: 12px;
        min-width: 315px;
        // .ccSecQnsHeaderSec {
        //   display: flex;
        //   align-items: center;
        //   gap: 8px;
        .ccSecQnsHeader {
          font-family: "Inter";
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: map-get(i.$appColor, "grey/500");
          cursor: pointer;
          align-self: center;
        }
        // .ccSecQnsHeader:hover {
        //   text-decoration: underline;
        //   transition: text-decoration 0.2s ease-in-out;
        //   color: #ff5533;
        // }
        // }
        .ccSecQnsHeaderSecName {
          font-family: Inter;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: map-get(i.$appColor, "grey/900");
          align-self: center;
        }
      }
      .ccSecQnsHeaderRightSec {
        display: flex;
        gap: 12px;
        .ccSecQnsHeaderFilter {
          position: relative;
          .difficultyFilterContainer {
            position: absolute;
            background: map-get(i.$appColor, "white");
            //   box-shadow: 0px 4px 6px -2px #10182808;
            box-shadow: 0px 12px 16px -4px #10182814;
            display: flex;
            flex-direction: column;
            // gap: 8px;
            width: 140px;
            min-height: 120px;
            z-index: 2;
            top: 40px;
            right: 0px;
            padding: 0;
            margin: 0;
            border-radius: 8px;
            .difficultyItem {
              list-style: none;
              padding: 10px 16px;
              @include m.fontSize(text/sm/medium);
              text-transform: capitalize;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0;
            }
            .difficultyItem.selected {
              background: map-get(i.$appBackGroundColor, "grey/50");
            }
            .difficultyItem:hover {
              background: map-get(i.$appColor, "grey/50");
              cursor: pointer;
            }
            .difficultyItem.easy {
              color: map-get(i.$appColor, "success/600");
            }
            .difficultyItem.medium {
              color: map-get(i.$appColor, "warning/500");
            }
            .difficultyItem.hard {
              color: map-get(i.$appColor, "error/600");
            }
            .difficultyItem.all {
              color: map-get(i.$appColor, "grey/700");
            }
          }
        }
      }
    }
  }

  .ccSecQnsTableContainer {
    // max-height: 500px;
    max-height: calc(100vh - 115px);
    // height: 60vh;
    overflow-y: scroll;
    // border: 1px solid #ccc;

    .tableHeadRow.serialNo,
    .tableData.serialNo {
      width: 80px;
    }
    .tableHeadRow.title,
    .tableData.title {
      max-width: 600px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tableHeadRow.score,
    .tableData.score {
      width: 140px;
    }
    .tableHeadRow.difficulty,
    .tableData.difficulty {
      width: 240px;
    }
    .tableBodyRow {
      height: 48px;
    }
    .tableBodyRow.opened:hover {
      border-bottom: unset;
    }
    .tableBodyRow.opened:hover + tr {
      border-bottom: 1px solid map-get(i.$appColor, "grey/300");
    }
    .tableBodyRow .tableData {
      padding: 12px 24px;
      border-bottom: 1px;
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/700");
      .ccSecQnsTableBodyTitleAndStatusSec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ccSecQnsTableBodyStatus {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ccSecQnsTableBodyStatus.solved {
          background: map-get(i.$appBackGroundColor, "success/100");
        }
        .ccSecQnsTableBodyStatus.attempted {
          background: map-get(i.$appBackGroundColor, "grey/100");
        }
      }
      .ccSecQnsTableBodyAttemptSec {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        .ccSecQnsTableBodyAttempt:hover {
          background: map-get(i.$appBackGroundColor, "grey/100") !important;
        }
        .ccSecQnsTableBodyAttempt {
          padding: 8px;
          border: 1px solid;
          gap: 2px;
          display: flex;
          border: 1px solid map-get(i.$appColor, "grey/300");
          flex-direction: column;
          border-radius: 8px;
          .ccSecQnsTableBodyAttemptCount {
            @include m.fontSize(text/xs/regular);
            color: map-get(i.$appColor, "grey/700");
          }
          .ccSecQnsTableBodyAttemptScoreSec {
            font-family: Inter;
            font-size: 10px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: map-get(i.$appColor, "grey/500");
          }
        }
      }
      .ccSecQnsTableBodyDifficultySec {
        display: flex;
        justify-content: space-between;
        p {
          align-self: center;
        }
        .ccSecQnsTableBodyDifficultyUtils {
          opacity: 0;
          display: flex;
          align-items: center;
          gap: 12px;
          .buttonSecondaryGreysm {
            padding: 8px 14px;
            min-width: 65px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .buttonSecondaryGreysm:hover {
            background: #ff5533;
            transition: background 0.5s ease-in-out;
            color: map-get(i.$appColor, "white");
          }
          .iconButton {
            height: 32px;
            svg {
              transition: transform 0.3s ease-in;
            }
          }
        }
        .ccSecQnsTableBodyDifficultyUtils.opened {
          .iconButton svg {
            transform: rotate(-180deg);
            transition: transform 0.3s ease-in;
          }
        }
      }
    }
    .tableBodyRow.expandRow {
      .tableData {
        padding: 0px 80px 16px 100px;
      }
    }
    .tableBodyRow.expandRow:hover {
      border-top: unset;
    }
    #odd {
      background-color: map-get(i.$appBackGroundColor, "grey/50");
    }
    #even {
      background-color: map-get(i.$appBackGroundColor, "white");
    }

    .tableData.difficulty .easy {
      color: map-get(i.$appColor, "success/600");
    }
    .tableData.difficulty .medium {
      color: map-get(i.$appColor, "warning/500");
    }
    .tableData.difficulty .difficult {
      color: map-get(i.$appColor, "error/600");
    }
    .tableBodyRow:hover,
    .tableBodyRow.expandRow:hover {
      .tableData.title {
        @include m.fontSize(text/sm/medium);
        color: map-get(i.$appColor, "grey/900");
      }
      .ccSecQnsTableBodyDifficultySec {
        .ccSecQnsTableBodyDifficultyUtils {
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }
      }
    }
  }
}

.ccSecQnsContainerSec {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
