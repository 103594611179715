@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.codingEditorMainContainer,
.codingEditorMainContainerFullScreen {
  .codingEditorHeader {
    top: 0;
    z-index: 1;
    height: 40px;
    display: flex;
    cursor: pointer;
    position: sticky;
    border-radius: 8px 8px 0 0;
    justify-content: space-between;
    background-color: map-get(i.$appBackGroundColor, "grey/50");

    .codingEditorLanguage {
      width: 140px;
      height: 24px;
      border-radius: 8px;
      margin: 8px 0 8px 16px;

      .css-1fdsijx-ValueContainer {
        @include m.fontSize(text/xs/regular);
        color: map-get(i.$appColor, "grey/700");
      }

      .css-15lsz6c-indicatorContainer {
        padding: 0px 8px;
      }

      .css-vlh69q-option {
        @include m.fontSize(text/sm/medium);
      }

      .css-tj5bde-Svg,
      .css-vlh69q-option {
        color: map-get(i.$appColor, "grey/700");
      }

      .css-vlh69q-option:hover,
      .css-vlh69q-option:active {
        background: map-get(i.$appBackGroundColor, "grey/50");
      }
    }

    .codingEditorHeaderRightDiv {
      display: flex;
      margin-right: 16px;
      align-items: center;
    }
  }

  .codingEditorButtonDiv {
    float: right;
    bottom: 16px;
    display: flex;
    position: sticky;
    margin-right: 24px;
  }

  .ace_editor * {
    overflow: hidden;
    text-align: left;
    letter-spacing: 0;
    line-height: 24px !important;
  }

  .ace_gutter-layer {
    line-height: 24px;
    background: map-get(i.$appBackGroundColor, "white");

    .ace_gutter-cell,
    .ace_gutter-cell_svg-icons {
      padding: 0;
      padding-left: 11px;
      @include m.fontSize(text/xs/medium);
      color: map-get(i.$appColor, "grey/500");
    }
  }

  .ace-monokai {
    background: map-get(i.$appBackGroundColor, "grey/900");
    .ace_gutter-layer {
      background: map-get(i.$appBackGroundColor, "grey/900");
      .ace_gutter-cell,
      .ace_gutter-cell_svg-icons {
        color: map-get(i.$appColor, "grey/50");
      }
    }
  }

  .ace-tomorrow .ace_gutter-active-line,
  .ace-tomorrow .ace_marker-layer .ace_active-line {
    background: #e5e5ea7a;
  }

  .ace-monokai .ace_gutter-active-line,
  .ace-monokai .ace_marker-layer .ace_active-line {
    background: #e5e5e533;
  }

  .ace_gutter {
    z-index: unset;
  }
}

.codingEditorMainContainerFullScreen {
  height: 100vh;

  .codingEditorButtonDiv {
    right: 0;
    bottom: 16px;
    position: absolute;
    margin-right: 24px;
  }

  .ace_editor {
    height: 94.5vh !important;
  }
}
