.WidgetConfigurationContainer {
  // display:flex;
  // flex-direction: column;
  // position: relative;
  // top: 70px;
  // left: 25px;
  // justify-content: flex-start;
  // align-items: flex-start;
  // height: 100%;
  // overflow: auto !important;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0px;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  .labelwidget {
    color: #202124;
    float: left;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 6px;
  }
  .widgetFormSelect{
    margin-top: 20px;
    // border: 1px solid #bdc1c6;
    // border-radius: 4px;
    max-height: 40px !important;
    min-width: 175px;
    max-width: 175px;
    // marginTop: "20px",
    // border: "1px solid #BDC1C6",
    // maxHeight: "40px !important",
    // minWidth:"175px",
    // maxWidth:"175px"
  }

  .assignmentSelect {
    display: flex;
    column-gap: 12px;
    margin-top: 20px;
    max-width: 150px;
    .tag-item{
      display: none;
    }
    // .tag-item:last-child{

    //   display: inline;
     
    // }
    // .react-dropdown-tree-select .dropdown .dropdown-content {
    //   position: absolute;
    //   padding: 4px;
    //   z-index: 5;
    //   background: #fff;
    //   border-top: 1px solid rgba(0, 0, 0, 0.05);
    //   -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
    //   box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
    //   width: fit-content;
    //   margin: 20px;
    // }
    .react-dropdown-tree-select .dropdown .dropdown-trigger {
      // padding: 4px;
      // line-height: 20px;
      // max-height: 200px;
      // display: inline-flex;
      // overflow: auto;
      // // border: 1px solid #b9b9b9;
      // border-radius: 4px;
      width: 250px;
  }
    // .infinite-scroll-component {
    //   padding: 10px;
    // }

   
    // .tag-item .search{
    //   border-bottom: none;
    //   padding: 4px;
    // }
    // .dropdown-content{
    //   width: 222px;
    //   padding: 5px;
    //   z-index: 5;
    // }
  }

  .widgetName,
  .widgetDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 6px;
    #widgetName {
      background: rgb(255, 255, 255);
      gap: 10px;
      height: 40px;
      outline: none;
      padding: 7px 0px 7px 10px;
      border: 1px solid rgb(189, 193, 198);
      border-radius: 4px;
      min-width: 60vw;
    }

    #widgetDescription {
      background: rgb(255, 255, 255);
      gap: 10px;
      min-height: 100px;
      outline: none;
      padding: 7px 0px 7px 10px;
      width: 60vw;
      border: 1px solid rgb(189, 193, 198);
      border-radius: 4px;
      height: 54px;
    }
  }
  // .assignmentSelect .tag-item{
  //   display: none;
  // }

  .batchSelect {
    display: flex;
    gap: 35px;
    align-items: center;
    // margin-bottom: 10px;
  }
  .dateRange {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .clgndbranch {
    display: flex;
    margin-top: 20px;
    column-gap: 20px;
    margin-bottom: 12px;
  }
  .collegeSelect,
  .branchchSelect {
    margin-top: 20px;
  }
  .labelwidget1 {
    margin-top: 12px;
    color: #202124;
    float: left;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }

  .additionalColumnsDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    margin-left: 10px;
    padding: 10px;
  }
  .columnConfigurationContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    .checkListContainer {
      margin-left: 20px;
      //   display: flex;
      //   flex-direction: column;
      position: relative;
      column-count: 4;
    }
  }
  .startandenddate {
    display: flex;
    padding: 10px;
  }
  .reportsToShowContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 10px;

    .reportsToShow {
      flex-direction: column;
      display: flex;
      padding-left: 20px;
      .containerforcollegeandbatch {
        display: flex;
        top: -15px;
        gap: 25px;
        left: 30px;
        position: relative;
      }
    }
    .orientationToShow {
      position: relative;
      left: 20px;
    }
  }

}
