@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

.companyProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 54px;
  height: calc(100vh - 54px);
  overflow: scroll;
  padding-bottom: 10px;
  background: map-get(i.$appBackGroundColor, "grey/100");
  .companyProfileContainer2 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px 0 0 0;
    .companyProfileBtnSec {
      display: flex;
      .companyProfileBtn {
        align-self: flex-start;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 42px;
        .companyProfileBtnText {
          margin: 0;
          color: map-get(i.$appColor, "grey/500");
          @include m.fontSize(text/xs/regular);
        }
      }
    }
    .companyProfileFormContainer {
      min-width: 1040px;
      .companyProfileFormSec {
        display: flex;
        gap: 12px;
        flex-direction: column;
        padding: 0 28px;
        .compnayProfileForm {
          display: flex;
          gap: 20px;
          .companyProfileLeftForm {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 70%;
            .companyProfileDetail {
              @include m.fontSize(text/md/regular);
              color: map-get(i.$appColor, "grey/900");
            }
            .companyProfileNameSec {
              width: 45%;
            }
            .companyProfileMobileAndDateSec {
              display: flex;
              justify-content: space-between;
              .companyProfileDateSec,
              .companyProfileMobileSec {
                width: 45%;
              }
            }
            .companyProfileAddressSec {
              #companyProfileAddress {
                min-height: 100px;
              }
            }
            .companyProfileCompanyObjSec {
              #companyProfileCompanyObj {
                min-height: 79px;
              }
              #companyProfileCompanyObjHText {
                margin-left: 14px;
              }
            }
          }
          .companyProfileRightForm {
            width: 30%;
          }
        }
        .companyProfileUploadFaviconContainer {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .companyProfileUploadFavicon {
            display: flex;
            gap: 10px;
            align-items: center;
            .companyProfileUploadFaviconBtnSec {
              position: relative;
              .companyProfileUploadFaviconBtn {
                position: absolute;
                display: block;
                top: 5px;
                opacity: 0;
                width: 180px;
                cursor: pointer;
              }
            }
            .faviconFileContainer {
              min-width: 143px;
              height: 22px;
              background: map-get(i.$appBackGroundColor, "grey/400");
              padding: 0 8px;
              display: flex;
              align-items: center;
              border-radius: 8px;
              gap: 4px;
              .faviconFileSec {
                .faviconFileName {
                  margin: 0;
                  font-family: Inter;
                  font-size: 12px;
                  font-style: italic;
                  font-weight: 400;
                  line-height: 18px;
                  text-align: left;
                  font-variation-settings: "slnt" -10;
                  color: #ffffff;
                }
              }
              .faviconFileBtnsec {
                display: flex;
                // margin: 0;
                border-left: 1px solid #ffffff;
                align-items: center;
                justify-content: center;
                svg {
                  margin-left: 4px;
                }
              }
            }
          }
          .companyProfileUploadFaviconBtnHText {
            margin: 0;
            @include m.fontSize(text/xs/regular);
            color: map-get(i.$appColor, "grey/500");
          }
        }
        .companyProfileBtnContainer {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
