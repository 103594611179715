@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.academicContainer {
  display: flex;
  flex-direction: column;
  .detailCont,.academicSection {
    display: flex;
    padding: 0px 28px 0px 28px;
    gap: 10px;
    min-height: calc(100vh - 26vh);
   .academicDetailsSection {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;    
    }
  }
  .detailCont{
    height: calc(100vh - 150px);
    overflow-y: auto;
  }
  .academicWorkBtnSec {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 24px 10px;
  }
}