@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

.settingsContainer {
  display: flex;
  background: map-get(i.$appBackGroundColor, "grey/100");
  gap: 4px;
  padding: 0 25px;
  height: 86.3vh;
  .settingsSection {
    display: flex;
    gap: 16px;
    width: 100%;
    .MuiPaper-root.profileNavSec {
      margin: 0;
      width: 20%;
      .profileNavTitle {
        font-size: 16px;
        line-height: 24px;
        color: map-get(i.$appColor, "grey/700");
      }
    }
  }
}
