@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.mock {
  display: block;
  cursor: pointer;
  font-size: 14px;
  color: #344054;
  font-weight: 500;
  text-align: left;
  line-height: 20px;
  font-family: Inter;
  text-transform: uppercase;
  text-decoration-line: none !important;

  .mockDropDown {
    z-index: 5;
    margin: 0px;
    display: none;
    padding-top: 10px;
    width: max-content;
    position: absolute;
    border-radius: 10px;
    padding-bottom: 10px;
    background: #ffffff;
    box-shadow: 0px 32px 64px -12px #10182824;

    .mockTypeLabel {
      height: 20px;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: #344054;
      line-height: 20px;
      margin-left: 12px;
      position: relative;
      font-family: Inter;
      margin-bottom: 10px;
      text-transform: math-auto;
    }

    .mockTypeDiv {
      gap: 10px;
      display: flex;
      font-size: 14px;
      color: #000000;
      font-weight: 400;
      text-align: left;
      line-height: 20px;
      padding-top: 10px;
      font-family: Inter;
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 10px;
      margin: 0px !important;
      text-transform: math-auto;
    }

    .mockTypeDiv:hover {
      background: #f2f4f7;
    }
  }
}

.mock:hover {
  color: #f55533;
  text-decoration: none;
  transition: color 0.5s ease;

  .mockDropDown {
    display: block;
  }
}

.active {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  line-height: 20px;
  font-family: Inter;
  text-transform: uppercase;
  text-decoration-line: none;
  color: #f96343 !important;
}
