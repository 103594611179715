.levels {
  float: left;
  width: 400px;
  height: 54px;
  text-align: left;
  margin: 0 0 0 60px;
}

.levels > label {
  float: left;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 6px;
  font-family: "Inter";
}

#levels {
  margin: 0;
  padding: 0;
  border: none;
  width: 400px;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  font-style: normal;
  border-radius: 4px;
  line-height: 20px;
  font-family: "Inter";
  display: inline-block;
  min-height: 37px !important;
}

.basicMultiSelect {
  width: 400px;
  height: 34px;
  margin: 0 0 0 23px;
  border-radius: 4px;
  background: #ffffff;
}

#levels .css-171ebc3-control,
#levels .css-1cgtlkh-control:hover,
#levels .css-1cgtlkh-control {
  margin-top: 0px;
}
