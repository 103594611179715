@import "~react-beautiful-horizontal-timeline/card.css";


.timeline-div{
    width: 1024px;
    height: 170px;
    background-color: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #EAECF0;

    .timeline-title-div{
        font-family: 'Inter';
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 12px 0 0 20px;
    }

    .timeline-timeline-progresscircle-div{
        display: flex;

        .timeline-progresscircle-div{
            width: 98px;
            height: 108px;
            background-color: #F9FAFB;
            border-radius: 8px;
        }
    }

    .timeline-with-navigateicon-div{
        width: 886px;
        height: 115px; //2nd time
        height: 100px;
        display: flex;
        align-items: center;
        margin: 12px 0 0 0;

    .wrapperAll{
      width: 99%;

      .fa-chevron-circle-left{
        // left: 10px;
      //   top: -3px;
      //   fill: #F04438;
      //   background-color: #12B76A;
        path{
          fill: #667085;
        }
      }

      .fa-chevron-circle-right{
      //   top: -3px;
        // right: -5px;
        path{
          fill: #667085;
        }
      }

      .axisWrapper{
        padding-top: 5px;
        height: 75px;
        // margin-top: 7px;
        width: 92%;

        .axis{
          border-top: 8px solid #F2F4F7 !important;
          // width: 99%;
          .specificEvent{
            max-width: 60px !important;
            .dot{
              // margin: -7px 0 0 10px;
              display: none;
            }

            .descriptionEventDetails{
              // display: inline-block;
              // position: relative;
              // right: -10px;
              // top: -20px;
              // z-index: 100;

              // text-align: -webkit-center;
              width: fit-content;

              .tool-tip{
                width: 63px;
                // height: 22px;
                background-color: #F2F4F7;
                border-radius: 16px;
                font-family: 'Inter';
                font-size: 12px;
                font-weight: 500;
                color: #344054;
                padding: 2px 8px 2px 8px;
                // margin: 0 0 0 60px;
              }

              .header-for-onhover-noresponse{
                // background: red;
                height: 14px;
                width: 14px;
                position: relative;
                top: -20px;
                right: -65%;
                z-index: 100;
                border-radius: 50%;
                background-color: #D0D5DD;
              }
              .header-for-onhover-absent{
                // background: red;
                height: 14px;
                width: 14px;
                position: relative;
                top: -20px;
                right: -65%;
                z-index: 100;
                border-radius: 50%;
                background-color: #F04438;
              }
              .header-for-onhover-present{
                // background: red;
                height: 14px;
                width: 14px;
                position: relative;
                top: -20px;
                right: -65%;
                z-index: 100;
                border-radius: 50%;
                background-color: #12B76A;
              }

            }

          }

        }
      }

    }
}
}


// .timeline-div{
//     width: 1024px;
//     height: 170px;
//     background-color: #FFFFFF;
//     border-radius: 20px;
//     border: 1px solid #EAECF0;

//     .timeline-title-div{
//         font-family: 'Inter';
//         font-size: 16px;
//         font-weight: 600;
//         text-align: left;
//         padding: 12px 0 0 20px;
//     }

//     .timeline-timeline-progresscircle-div{
//         display: flex;

//         .timeline-progresscircle-div{
//             width: 98px;
//             height: 108px;
//             background-color: #F9FAFB;
//             border-radius: 8px;
//         }
//     }

//     .timeline-with-navigateicon-div{
//         width: 886px;
//         height: 115px; //2nd time
//         height: 100px;
//         display: flex;
//         align-items: center;
//         margin: 12px 0 0 0;

//         .NavigateBeforeIcon-btn{
//             height: 18px;
//             width: 18px;
//             background-color: #FFFFFF;
//             border-radius: 56px;
//             // margin: 10px 0 0 10px;
//             margin: 0 0 0 10px;
//         }

//         .NavigateNextIcon-btn{
//             height: 18px;
//             width: 18px;
//             background-color: #FFFFFF;
//             border-radius: 56px;
//             // margin: 8px 0px 0 0;
//             margin: 0 0px 0 0;
//         }
//     }

//     .timeline-div-rotate{
//         // width: 100%; //881px
//         // // height: 130px; // 2nd time
//         // height: 100px; 
//         // display: flex;
//         // justify-content: center;
//         // position: relative;
//         // overflow: hidden;
//         // // width: 800px;

//         width: 100vw;
//         height: 100px;
//         display: flex;
//         justify-content: center;
//         position: relative;
//         overflow: hidden;

//         .timeline-div-timeline{
//             // // display: block;
//             // position: absolute;
//             // transform: rotate(-90deg);
//             // // max-height: 881px;
//             // height: 110vh;
//             // max-width: 75px;
//             // padding: 0;
//             // // left: 18%;
//             // // left: 7%; 2nd
//             // // left: 0;
//             // top: 50%;
//             // transform: translateY(-50%) rotate(-90deg);

//             position: absolute;
//             display: block;
//             transform: rotate(-90deg);
//             height: 100%;
//             max-width: 75px;
//             padding: 0;
//             left: 3%;
//             top: 50%;
//             transform: translateY(-50%) rotate(-90deg);

//             li{
//                 min-height: 50%;
//                 div{
//                     span{
//                         margin: 0;
//                         box-shadow: none;
//                     }
//                 }
//             }
//         }

//         .timeline-div-timeline-month{
//             transform: rotate(90deg);
//             white-space: nowrap;
//             height: 30px;
//             background-color: #F2F4F7;
//             border-radius: 16px;
//             font-family: 'Inter';
//             font-size: 12px;
//             font-weight: 500;
//             color: #344054;
//         }
//     }

// }

//     .session-timeline-dots-noresponse{
//         background-color: #D0D5DD !important;
//     }

//     .session-timeline-dots-present{
//         background-color: #12B76A !important;
//     }

//     .session-timeline-dots-absent{
//         background-color:#F04438 !important;
//     }

// @import "../../../../node_modules/react-beautiful-horizontal-timeline/card.css";

