.codeEditor {
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  max-height: 100vh;
  position: relative;
  background: #ffffff;
  padding-top: 0px !important;
}

.codeEditorUtils .codeLanguageSelectorAdmin {
  position: relative;
  left: 0px !important;
}

.adminOutput .loading {
  top: 20%;
  left: 50%;
  z-index: 19;
  position: absolute;
  transform: translate(-50%, -50%);
}

.editor-fullscreen .ace_content {
  border-right: 40px solid #f2f4f7;
  border-bottom: 10px solid #f2f4f7;
}

.editor-normal .ace_content {
  border-right: 40px solid #f2f4f7;
  border-bottom: 40px solid #f2f4f7;
}

.outputLabel {
  margin-top: 8px;
  margin-left: 42px;
  position: absolute;
}

.adminOutputFullScreen .loading {
  top: 35%;
  left: 50%;
  z-index: 19;
  position: absolute;
  transform: translate(-50%, -50%);
}

.codeEditorUtils {
  top: 0;
  height: 56px;
  display: flex;
  overflow: hidden;
  align-items: center;
  position: absolute;
  border-radius: 4px;
  width: -webkit-fill-available;
  justify-content: space-between;
  background: #f2f4f7 !important;
}

.subUtils {
  top: 0;
  left: 30px;
  z-index: 3;
  width: 140px;
  height: 56px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  align-items: baseline;
  justify-content: space-evenly;
  background: #f2f4f7 !important;
}

.tryandSubUtils {
  top: 0;
  z-index: 3;
  width: auto;
  height: 56px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  align-items: baseline;
  background: #f2f4f7 !important;
}

.editor-expand {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: none;
  position: relative;
  transition: max-height 0.3s ease-in-out;
}

.editor-fullscreen {
  margin-top: 55px;
}

.editor-normal {
  display: flex;
  margin-top: 55px;
  max-height: 400px;
  flex-direction: column;
  align-items: flex-start;
  transition: max-height 0.3s ease-in-out;
}

.Output {
  top: 45px;
  margin-left: 80px;
  position: absolute;
  font-size: "normal";
}

.Output pre {
  font-size: 15pt;
  font-family: "Inter";
}

.codeEditorLanguage {
  flex: 1;
  display: flex;
  max-width: 100px;
  color: #fafafa;
  margin-left: 40px;
}

.codeLanguageSelectorAdmin .MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.codeLanguageSelectorAdmin {
  height: 40px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: #373739;
  line-height: 150%;
  padding: 8px 0px;
  border-radius: 4px;
  font-style: normal;
  background: #fafafa;
  border: none !important;
  letter-spacing: 0.0044em;
  font-family: "Inter" !important;
  margin: 10px 0 8px 9.44px !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #373739;
}

.codeEditorDarkMode,
.codeEditorLightMode {
  color: #373739;
  cursor: pointer;
  border-radius: 0px;
  margin: 18.3px 0px 0 0;
  width: 17.93px !important;
  height: 19.45px !important;
}

.codeEditorResetCode {
  cursor: pointer;
  border-radius: 0px;
  height: 18px !important;
  width: 18.14px !important;
}

.codeEditorFullS {
  cursor: pointer;
  border-radius: 0px;
  height: 18px !important;
  width: 16.13px !important;
}

.codeEditorFullN {
  cursor: pointer;
  border-radius: 0px;
  height: 18px !important;
  width: 16.13px !important;
}

.ace_gutter-layer {
  width: 100%;
  line-height: 24px;
  position: absolute;
  background: #f2f4f7;
}

#blah2 .ace_editor .ace-tomorrow {
  width: 100% !important;
}

.ace-tomorrow .ace_gutter-active-line,
.ace-tomorrow .ace_marker-layer .ace_active-line {
  background: #e5e5ea7a;
}

.ace-monokai .ace_gutter-active-line,
.ace-monokai .ace_marker-layer .ace_active-line {
  background: #e5e5e533;
}

.ace_gutter {
  z-index: unset;
}

.TrycodeContent {
  gap: 10px;
  width: 146px;
  height: 40px;
  display: flex;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  margin: 3px 16px 3px 30px;
}

.adminOutputFullScreen {
  height: 30vh;
  padding: 10px; /* Optional: Add padding for better spacing */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
  position: relative;
  background-color: #f2f4f7;
}

.adminOutput {
  padding: 10px; /* Optional: Add padding for better spacing */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
  min-height: 150px; /* Set your desired minimum height */
  max-height: 300px; /* Set your desired maximum height */
  position: relative;
  background-color: #f2f4f7;
}

.codex-editor svg {
  max-height: 500%;
}

.TrycodeContentButton {
  order: 0;
  flex: none;
  float: left;
  border: none;
  width: 100px;
  height: 34px;
  flex-grow: 1;
  color: #ffffff;
  line-height: 150%;
  text-align: center;
  border-radius: 4px;
  align-self: stretch;
  background: #f55533;
  margin: 0px 20px 16px 16px;
}
