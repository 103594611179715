@use "../Abstracts/mixins" as m;

.nextIcon,
.disableNextIcon {
  @include m.prevNextSvgIcon(right);
}

.disableNextIcon {
  @include m.prevNextSvgIcon(right, true);
}
