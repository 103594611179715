@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.testDetailMainContainer {
  text-align: left;
  margin: 14px 52px 0 52px;

  .testDetail {
    display: flex;
    gap: 12px;
    .badgeContainer label {
      color: map-get(i.$appColor, "brand/700");
    }
    .testDetailInfo {
      padding: 2px 8px;
      margin-right: 12px;
      border-radius: 16px;
      @include m.fontSize(text/xs/medium);
      color: map-get(i.$appColor, "brand/700");
      background: map-get(i.$appBackGroundColor, "error/50");

      .testDetailInnerInfo {
        padding: 2px 0 0 4px;
      }
    }
  }

  .testDetailHorizontalLine {
    color: map-get(i.$appColor, "grey/600");
  }
}
