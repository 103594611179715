@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.buttonDestructivesm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, primary, false, false, true);
}

.buttonDestructivesm:hover {
  @include m.button(sm, primary, true, false, true);
}

.buttonDestructivesm:disabled {
  @include m.button(sm, primary, false, true, true);
}

.buttonsm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, primary, false, false, false);
}

.buttonsm:hover {
  @include m.button(sm, primary, true, false, false);
}

.buttonsm:disabled {
  @include m.button(sm, primary, false, true, false);
}

.buttonSecondarysm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, secondaryColor, false, false, false);
}

.buttonSecondarysm:hover {
  @include m.button(sm, secondaryColor, true, false, false);
}

.buttonSecondarysm:disabled {
  @include m.button(sm, secondaryColor, false, true, false);
}

.buttonSecondaryDestructivesm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, secondaryColor, false, false, true);
}

.buttonSecondaryDestructivesm:hover {
  @include m.button(sm, secondaryColor, true, false, true);
}

.buttonSecondaryDestructivesm:disabled {
  @include m.button(sm, secondaryColor, false, true, true);
}

.buttonSecondaryGreysm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, secondaryGrey, false, false, false);
}

.buttonSecondaryGreysm:hover {
  @include m.button(sm, secondaryGrey, true, false, false);
}

.buttonSecondaryGreysm:disabled {
  @include m.button(sm, secondaryGrey, false, true, false);
}

.buttonSecondaryGreyDestructivesm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, secondaryGrey, false, false, true);
}

.buttonSecondaryGreyDestructivesm:hover {
  @include m.button(sm, secondaryGrey, true, false, true);
}

.buttonSecondaryGreyDestructivesm:disabled {
  @include m.button(sm, secondaryGrey, false, true, true);
}

.buttonTertiaryColorsm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, tertiaryColor, false, false, false);
}

.buttonTertiaryColorsm:hover {
  @include m.button(sm, tertiaryColor, true, false, false);
}

.buttonTertiaryColorsm:disabled {
  @include m.button(sm, tertiaryColor, false, true, false);
}

.buttonTertiaryColorDestructivesm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, tertiaryColor, false, false, true);
}

.buttonTertiaryColorDestructivesm:hover {
  @include m.button(sm, tertiaryColor, true, false, true);
}

.buttonTertiaryColorDestructivesm:disabled {
  @include m.button(sm, tertiaryColor, false, true, true);
}

.buttonTertiaryGreysm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, tertiaryGrey, false, false, false);
}

.buttonTertiaryGreysm:hover {
  @include m.button(sm, tertiaryGrey, true, false, false);
}

.buttonTertiaryGreysm:disabled {
  @include m.button(sm, tertiaryGrey, false, true, false);
}

.buttonTertiaryGreyDestructivesm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(sm, tertiaryGrey, false, false, true);
}

.buttonTertiaryGreyDestructivesm:hover {
  @include m.button(sm, tertiaryGrey, true, false, true);
}

.buttonTertiaryGreyDestructivesm:disabled {
  @include m.button(sm, tertiaryGrey, false, true, true);
}

.buttonLinkColorlsm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(lsm, linkColor, false, false, false);
}

.buttonLinkColorlsm:hover {
  @include m.button(lsm, linkColor, true, false, false);
}

.buttonLinkColorlsm:disabled {
  @include m.button(lsm, linkColor, false, true, false);
}

.buttonLinkColorDestructivelsm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(lsm, linkColor, false, false, true);
}

.buttonLinkColorDestructivelsm:hover {
  @include m.button(lsm, linkColor, true, false, true);
}

.buttonLinkColorDestructivelsm:disabled {
  @include m.button(lsm, linkColor, false, true, true);
}

.buttonLinkGreylsm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(lsm, linkGrey, false, false, false);
}

.buttonLinkGreylsm:hover {
  @include m.button(lsm, linkGrey, true, false, false);
}

.buttonLinkGreylsm:disabled {
  @include m.button(lsm, linkGrey, false, true, false);
}

.buttonLinkGreyDestructivelsm {
  @include m.fontSize(text/sm/semibold);
  @include m.button(lsm, linkGrey, false, false, true);
}

.buttonLinkGreyDestructivelsm:hover {
  @include m.button(lsm, linkGrey, true, false, true);
}

.buttonLinkGreyDestructivelsm:disabled {
  @include m.button(lsm, linkGrey, false, true, true);
}

.buttonmd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, primary, false, false, false);
}

.buttonmd:hover {
  @include m.button(md, primary, true, false, false);
}

.buttonmd:disabled {
  @include m.button(md, primary, false, true, false);
}

.buttonDestructivemd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, primary, false, false, true);
}

.buttonDestructivemd:hover {
  @include m.button(md, primary, true, false, true);
}

.buttonDestructivemd:disabled {
  @include m.button(md, primary, false, true, true);
}

.buttonSecondarymd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, secondaryColor, false, false, false);
}

.buttonSecondarymd:hover {
  @include m.button(md, secondaryColor, true, false, false);
}

.buttonSecondarymd:disabled {
  @include m.button(md, secondaryColor, false, true, false);
}

.buttonSecondaryDestructivemd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, secondaryColor, false, false, true);
}

.buttonSecondaryDestructivemd:hover {
  @include m.button(md, secondaryColor, true, false, true);
}

.buttonSecondaryDestructivemd:disabled {
  @include m.button(md, secondaryColor, false, true, true);
}

.buttonSecondaryGreymd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, secondaryGrey, false, false, false);
}

.buttonSecondaryGreymd:hover {
  @include m.button(md, secondaryGrey, true, false, false);
}

.buttonSecondaryGreymd:disabled {
  @include m.button(md, secondaryGrey, false, true, false);
}

.buttonSecondaryGreyDestructivemd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, secondaryGrey, false, false, true);
}

.buttonSecondaryGreyDestructivemd:hover {
  @include m.button(md, secondaryGrey, true, false, true);
}

.buttonSecondaryGreyDestructivemd:disabled {
  @include m.button(md, secondaryGrey, false, true, true);
}

.buttonTertiaryColormd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, tertiaryColor, false, false, false);
}

.buttonTertiaryColormd:hover {
  @include m.button(md, tertiaryColor, true, false, false);
}

.buttonTertiaryColormd:disabled {
  @include m.button(md, tertiaryColor, false, true, false);
}

.buttonTertiaryColorDestructivemd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, tertiaryColor, false, false, true);
}

.buttonTertiaryColorDestructivemd:hover {
  @include m.button(md, tertiaryColor, true, false, true);
}

.buttonTertiaryColorDestructivemd:disabled {
  @include m.button(md, tertiaryColor, false, true, true);
}

.buttonTertiaryGreymd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, tertiaryGrey, false, false, false);
}

.buttonTertiaryGreymd:hover {
  @include m.button(md, tertiaryGrey, true, false, false);
}

.buttonTertiaryGreymd:disabled {
  @include m.button(md, tertiaryGrey, false, true, false);
}

.buttonTertiaryGreyDestructivemd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(md, tertiaryGrey, false, false, true);
}

.buttonTertiaryGreyDestructivemd:hover {
  @include m.button(md, tertiaryGrey, true, false, true);
}

.buttonTertiaryGreyDestructivemd:disabled {
  @include m.button(md, tertiaryGrey, false, true, true);
}

.buttonLinkColorlmd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(lmd, linkColor, false, false, false);
}

.buttonLinkColorlmd:hover {
  @include m.button(lmd, linkColor, true, false, false);
}

.buttonLinkColorlmd:disabled {
  @include m.button(lmd, linkColor, false, true, false);
}

.buttonLinkColorDestructivelmd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(lmd, linkColor, false, false, true);
}

.buttonLinkColorDestructivelmd:hover {
  @include m.button(lmd, linkColor, true, false, true);
}

.buttonLinkColorDestructivelmd:disabled {
  @include m.button(lmd, linkColor, false, true, true);
}

.buttonLinkGreylmd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(lmd, linkGrey, false, false, false);
}

.buttonLinkGreylmd:hover {
  @include m.button(lmd, linkGrey, true, false, false);
}

.buttonLinkGreylmd:disabled {
  @include m.button(lmd, linkGrey, false, true, false);
}

.buttonLinkGreyDestructivelmd {
  @include m.fontSize(text/sm/semibold);
  @include m.button(lmd, linkGrey, false, false, true);
}

.buttonLinkGreyDestructivelmd:hover {
  @include m.button(lmd, linkGrey, true, false, true);
}

.buttonLinkGreyDestructivelmd:disabled {
  @include m.button(lmd, linkGrey, false, true, true);
}

.buttonlg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, primary, false, false, false);
}

.buttonlg:hover {
  @include m.button(lg, primary, true, false, false);
}

.buttonlg:disabled {
  @include m.button(lg, primary, false, true, false);
}

.buttonDestructivelg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, primary, false, false, true);
}

.buttonDestructivelg:hover {
  @include m.button(lg, primary, true, false, true);
}

.buttonDestructivelg:disabled {
  @include m.button(lg, primary, false, true, true);
}

.buttonSecondarylg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, secondaryColor, false, false, false);
}

.buttonSecondarylg:hover {
  @include m.button(lg, secondaryColor, true, false, false);
}

.buttonSecondarylg:disabled {
  @include m.button(lg, secondaryColor, false, true, false);
}

.buttonSecondaryDestructivelg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, secondaryColor, false, false, true);
}

.buttonSecondaryDestructivelg:hover {
  @include m.button(lg, secondaryColor, true, false, true);
}

.buttonSecondaryDestructivelg:disabled {
  @include m.button(lg, secondaryColor, false, true, true);
}

.buttonSecondaryGreylg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, secondaryGrey, false, false, false);
}

.buttonSecondaryGreylg:hover {
  @include m.button(lg, secondaryGrey, true, false, false);
}

.buttonSecondaryGreylg:disabled {
  @include m.button(lg, secondaryGrey, false, true, false);
}

.buttonSecondaryGreyDestructivelg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, secondaryGrey, false, false, true);
}

.buttonSecondaryGreyDestructivelg:hover {
  @include m.button(lg, secondaryGrey, true, false, true);
}

.buttonSecondaryGreyDestructivelg:disabled {
  @include m.button(lg, secondaryGrey, false, true, true);
}

.buttonTertiaryColorlg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, tertiaryColor, false, false, false);
}

.buttonTertiaryColorlg:hover {
  @include m.button(lg, tertiaryColor, true, false, false);
}

.buttonTertiaryColorlg:disabled {
  @include m.button(lg, tertiaryColor, false, true, false);
}

.buttonTertiaryColorDestructivelg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, tertiaryColor, false, false, true);
}

.buttonTertiaryColorDestructivelg:hover {
  @include m.button(lg, tertiaryColor, true, false, true);
}

.buttonTertiaryColorDestructivelg:disabled {
  @include m.button(lg, tertiaryColor, false, true, true);
}

.buttonTertiaryGreylg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, tertiaryGrey, false, false, false);
}

.buttonTertiaryGreylg:hover {
  @include m.button(lg, tertiaryGrey, true, false, false);
}

.buttonTertiaryGreylg:disabled {
  @include m.button(lg, tertiaryGrey, false, true, false);
}

.buttonTertiaryGreyDestructivelg {
  @include m.fontSize(text/md/semibold);
  @include m.button(lg, tertiaryGrey, false, false, true);
}

.buttonTertiaryGreyDestructivelg:hover {
  @include m.button(lg, tertiaryGrey, true, false, true);
}

.buttonTertiaryGreyDestructivelg:disabled {
  @include m.button(lg, tertiaryGrey, false, true, true);
}

.buttonLinkColorllg {
  @include m.fontSize(text/md/semibold);
  @include m.button(llg, linkColor, false, false, false);
}

.buttonLinkColorllg:hover {
  @include m.button(llg, linkColor, true, false, false);
}

.buttonLinkColorllg:disabled {
  @include m.button(llg, linkColor, false, true, false);
}

.buttonLinkColorDestructivellg {
  @include m.fontSize(text/md/semibold);
  @include m.button(llg, linkColor, false, false, true);
}

.buttonLinkColorDestructivellg:hover {
  @include m.button(llg, linkColor, true, false, true);
}

.buttonLinkColorDestructivellg:disabled {
  @include m.button(llg, linkColor, false, true, true);
}

.buttonLinkGreyllg {
  @include m.fontSize(text/md/semibold);
  @include m.button(llg, linkGrey, false, false, false);
}

.buttonLinkGreyllg:hover {
  @include m.button(llg, linkGrey, true, false, false);
}

.buttonLinkGreyllg:disabled {
  @include m.button(llg, linkGrey, false, true, false);
}

.buttonLinkGreyDestructivellg {
  @include m.fontSize(text/md/semibold);
  @include m.button(llg, linkGrey, false, false, true);
}

.buttonLinkGreyDestructivellg:hover {
  @include m.button(llg, linkGrey, true, false, true);
}

.buttonLinkGreyDestructivellg:disabled {
  @include m.button(llg, linkGrey, false, true, true);
}

.buttonxl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, primary, false, false, false);
}

.buttonxl:hover {
  @include m.button(xl, primary, true, false, false);
}

.buttonxl:disabled {
  @include m.button(xl, primary, false, true, false);
}

.buttonDestructivexl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, primary, false, false, true);
}

.buttonDestructivexl:hover {
  @include m.button(xl, primary, true, false, true);
}

.buttonDestructivexl:disabled {
  @include m.button(xl, primary, false, true, true);
}

.buttonSecondaryxl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, secondaryColor, false, false, false);
}

.buttonSecondaryxl:hover {
  @include m.button(xl, secondaryColor, true, false, false);
}

.buttonSecondaryxl:disabled {
  @include m.button(xl, secondaryColor, false, true, false);
}

.buttonSecondaryDestructivexl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, secondaryColor, false, false, true);
}

.buttonSecondaryDestructivexl:hover {
  @include m.button(xl, secondaryColor, true, false, true);
}

.buttonSecondaryDestructivexl:disabled {
  @include m.button(xl, secondaryColor, false, true, true);
}

.buttonSecondaryGreyxl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, secondaryGrey, false, false, false);
}

.buttonSecondaryGreyxl:hover {
  @include m.button(xl, secondaryGrey, true, false, false);
}

.buttonSecondaryGreyxl:disabled {
  @include m.button(xl, secondaryGrey, false, true, false);
}

.buttonSecondaryGreyDestructivexl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, secondaryGrey, false, false, true);
}

.buttonSecondaryGreyDestructivexl:hover {
  @include m.button(xl, secondaryGrey, true, false, true);
}

.buttonSecondaryGreyDestructivexl:disabled {
  @include m.button(xl, secondaryGrey, false, true, true);
}

.buttonTertiaryColorxl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, tertiaryColor, false, false, false);
}

.buttonTertiaryColorxl:hover {
  @include m.button(xl, tertiaryColor, true, false, false);
}

.buttonTertiaryColorxl:disabled {
  @include m.button(xl, tertiaryColor, false, true, false);
}

.buttonTertiaryColorDestructivexl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, tertiaryColor, false, false, true);
}

.buttonTertiaryColorDestructivexl:hover {
  @include m.button(xl, tertiaryColor, true, false, true);
}

.buttonTertiaryColorDestructivexl:disabled {
  @include m.button(xl, tertiaryColor, false, true, true);
}

.buttonTertiaryGreyxl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, tertiaryGrey, false, false, false);
}

.buttonTertiaryGreyxl:hover {
  @include m.button(xl, tertiaryGrey, true, false, false);
}

.buttonTertiaryGreyxl:disabled {
  @include m.button(xl, tertiaryGrey, false, true, false);
}

.buttonTertiaryGreyDestructivexl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, tertiaryGrey, false, false, true);
}

.buttonTertiaryGreyDestructivexl:hover {
  @include m.button(xl, tertiaryGrey, true, false, true);
}

.buttonTertiaryGreyDestructivexl:disabled {
  @include m.button(xl, tertiaryGrey, false, true, true);
}

.buttonLinkColorlxl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, linkColor, false, false, false);
}

.buttonLinkColorlxl:hover {
  @include m.button(xl, linkColor, true, false, false);
}

.buttonLinkColorlxl:disabled {
  @include m.button(lxl, linkColor, false, true, false);
}

.buttonLinkColorDestructivelxl {
  @include m.fontSize(text/md/semibold);
  @include m.button(xl, linkColor, false, false, true);
}

.buttonLinkColorDestructivelxl:hover {
  @include m.button(xl, linkColor, true, false, true);
}

.buttonLinkColorDestructivelxl:disabled {
  @include m.button(lxl, linkColor, false, true, true);
}

.buttonLinkGreylxl {
  @include m.fontSize(text/md/semibold);
  @include m.button(lxl, linkGrey, false, false, false);
}

.buttonLinkGreylxl:hover {
  @include m.button(lxl, linkGrey, true, false, false);
}

.buttonLinkGreylxl:disabled {
  @include m.button(lxl, linkGrey, false, true, false);
}

.buttonLinkGreyDestructivelxl {
  @include m.fontSize(text/md/semibold);
  @include m.button(lxl, linkGrey, false, false, true);
}

.buttonLinkGreyDestructivelxl:hover {
  @include m.button(lxl, linkGrey, true, false, true);
}

.buttonLinkGreyDestructivelxl:disabled {
  @include m.button(lxl, linkGrey, false, true, true);
}

.button2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", primary, false, false, false);
}

.button2xl:hover {
  @include m.button("2xl", primary, true, false, false);
}

.button2xl:disabled {
  @include m.button("2xl", primary, false, true, false);
}

.buttonDestructive2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", primary, false, false, true);
}

.buttonDestructive2xl:hover {
  @include m.button("2xl", primary, true, false, true);
}

.buttonDestructive2xl:disabled {
  @include m.button("2xl", primary, false, true, true);
}

.buttonSecondary2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", secondaryColor, false, false, false);
}

.buttonSecondary2xl:hover {
  @include m.button("2xl", secondaryColor, true, false, false);
}

.buttonSecondary2xl:disabled {
  @include m.button("2xl", secondaryColor, false, true, false);
}

.buttonSecondaryDestructive2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", secondaryColor, false, false, true);
}

.buttonSecondaryDestructive2xl:hover {
  @include m.button("2xl", secondaryColor, true, false, true);
}

.buttonSecondaryDestructive2xl:disabled {
  @include m.button("2xl", secondaryColor, false, true, true);
}

.buttonSecondaryGrey2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", secondaryGrey, false, false, false);
}

.buttonSecondaryGrey2xl:hover {
  @include m.button("2xl", secondaryGrey, true, false, false);
}

.buttonSecondaryGrey2xl:disabled {
  @include m.button("2xl", secondaryGrey, false, true, false);
}

.buttonSecondaryGreyDestructive2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", secondaryGrey, false, false, true);
}

.buttonSecondaryGreyDestructive2xl:hover {
  @include m.button("2xl", secondaryGrey, true, false, true);
}

.buttonSecondaryGreyDestructive2xl:disabled {
  @include m.button("2xl", secondaryGrey, false, true, true);
}

.buttonTertiaryColor2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", tertiaryColor, false, false, false);
}

.buttonTertiaryColor2xl:hover {
  @include m.button("2xl", tertiaryColor, true, false, false);
}

.buttonTertiaryColor2xl:disabled {
  @include m.button("2xl", tertiaryColor, false, true, false);
}

.buttonTertiaryColorDestructive2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", tertiaryColor, false, false, true);
}

.buttonTertiaryColorDestructive2xl:hover {
  @include m.button("2xl", tertiaryColor, true, false, true);
}

.buttonTertiaryColorDestructive2xl:disabled {
  @include m.button("2xl", tertiaryColor, false, true, true);
}

.buttonTertiaryGrey2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", tertiaryGrey, false, false, false);
}

.buttonTertiaryGrey2xl:hover {
  @include m.button("2xl", tertiaryGrey, true, false, false);
}

.buttonTertiaryGrey2xl:disabled {
  @include m.button("2xl", tertiaryGrey, false, true, false);
}

.buttonTertiaryGreyDestructive2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("2xl", tertiaryGrey, false, false, true);
}

.buttonTertiaryGreyDestructive2xl:hover {
  @include m.button("2xl", tertiaryGrey, true, false, true);
}

.buttonTertiaryGreyDestructive2xl:disabled {
  @include m.button("2xl", tertiaryGrey, false, true, true);
}

.buttonLinkColorl2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("l2xl", linkColor, false, false, false);
}

.buttonLinkColorl2xl:hover {
  @include m.button("l2xl", linkColor, true, false, false);
}

.buttonLinkColorl2xl:disabled {
  @include m.button("l2xl", linkColor, false, true, false);
}

.buttonLinkColorDestructivel2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("l2xl", linkColor, false, false, true);
}

.buttonLinkColorDestructivel2xl:hover {
  @include m.button("l2xl", linkColor, true, false, true);
}

.buttonLinkColorDestructivel2xl:disabled {
  @include m.button("l2xl", linkColor, false, true, true);
}

.buttonLinkGreyl2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("l2xl", linkGrey, false, false, false);
}

.buttonLinkGreyl2xl:hover {
  @include m.button("l2xl", linkGrey, true, false, false);
}

.buttonLinkGreyl2xl:disabled {
  @include m.button("l2xl", linkGrey, false, true, false);
}

.buttonLinkGreyDestructivel2xl {
  @include m.fontSize(text/lg/semibold);
  @include m.button("l2xl", linkGrey, false, false, true);
}

.buttonLinkGreyDestructivel2xl:hover {
  @include m.button("l2xl", linkGrey, true, false, true);
}

.buttonLinkGreyDestructivel2xl:disabled {
  @include m.button("l2xl", linkGrey, false, true, true);
}

.buttonLeadingIcon {
  margin-right: 8px;
}
