.leaderboard-box-div{
    background-color: #FFFFFF;
    width: 1024px;
    border: 1px solid #E3E8EF;
    border-radius: 20px;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 0 12px;
    background-image: url('../../../Svg/Image/Frame\ 1000002098.png');
    background-size: cover;
    background-repeat: no-repeat;

    .leaderboard-empty-data-box{
        height: 50px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-weight: 500;
        color: #667085;
    }

    .leaderboard-box-header{
        height: 24px;
        font-family: 'Inter';
        font-size: 16px;
        font-weight: 600;
        margin: 16px 0 12px 16px;
    }

    .leaderboard-paper-table{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .leaderboard-table-topranker-box{
            width: 520px;
            height: 220px;
            margin: 0 0 32px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .leaderboard-firstrank{
                width: 160px;
                height: 196px;
                border-radius: 10px;
                background-color: #FFFFFF;
                position: absolute;
                right: 180px;
                top: 0;
                box-shadow: 0px 4px 6px -2px #10182808,0px 12px 16px -4px #10182814;

                .leaderboard-firstrank-avatar-badge{
                    margin: 16px 34px;
                }

                .leaderboard-firstrank-avatar{
                    width: 92px;
                    height: 92px;
                    box-shadow: 0px 0px 0px 4px #F4EBFF;
                }

                .leaderboard-firstrank-learnername{
                    max-width: 160px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                    font-family: 'Inter';
                    font-size: 14px;
                    font-weight: 600;
                    color: #293056;
                }

                .leaderboard-firstrank-pointsearned{
                    display: flex;
                    justify-content: center;
                    margin: 8px 0 0 0;

                    span{
                        height: 28px;
                        min-width: 59px;
                        padding: 4px 8px 4px 8px;
                        border-radius: 10px;
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 700;
                        background: #F9FAFB;
                        color: #F97256;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg{
                            margin: 4px;
                        }
                    }
                }
            }

            .leaderboard-secondrank{
                width: 160px;
                height: 184px;
                border-radius: 10px;
                background-color: #FFFFFF;
                position: absolute;
                left: 0;
                bottom: 0;
                box-shadow: 0px 4px 6px -2px #10182808,0px 12px 16px -4px #10182814;

                .leaderboard-secondrank-avatar-badge{
                    margin: 16px 40px;
                }

                .leaderboard-secondrank-avatar{
                    width: 80px;
                    height: 80px;
                    box-shadow: 0px 0px 0px 4px #F4EBFF;
                }

                .leaderboard-secondrank-learnername{
                    max-width: 160px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                    font-family: 'Inter';
                    font-size: 14px;
                    font-weight: 600;
                    color: #293056;
                }

                .leaderboard-secondrank-pointsearned{
                    display: flex;
                    justify-content: center;
                    margin: 8px 0 0 0;

                    span{
                        height: 28px;
                        min-width: 59px;
                        padding: 4px 8px 4px 8px;
                        border-radius: 10px;
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 700;
                        background: #F9FAFB;
                        color: #F97256;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg{
                            margin: 4px;
                        }
                        
                    }
                }
            }

            .leaderboard-thirdrank{
                width: 160px;
                height: 184px;
                border-radius: 10px;
                background-color: #FFFFFF;
                position: absolute;
                right: 0;
                bottom: 0;
                box-shadow: 0px 4px 6px -2px #10182808,0px 12px 16px -4px #10182814;

                .leaderboard-thirdrank-avatar-badge{
                    margin: 16px 40px;
                }

                .leaderboard-thirdrank-avatar{
                    width: 80px;
                    height: 80px;
                    box-shadow: 0px 0px 0px 4px #F4EBFF;
                }

                .leaderboard-thirdrank-learnername{
                    max-width: 160px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                    font-family: 'Inter';
                    font-size: 14px;
                    font-weight: 600;
                    color: #293056;
                }

                .leaderboard-thirdrank-pointsearned{
                    display: flex;
                    justify-content: center;
                    margin: 8px 0 0 0;

                    span{
                        height: 28px;
                        min-width: 59px;
                        padding: 4px 8px 4px 8px;
                        border-radius: 10px;
                        font-family: "Inter";
                        font-size: 14px;
                        font-weight: 700;
                        background: #F9FAFB;
                        color: #F97256;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg{
                            margin: 4px;
                        }

                    }
                }
            }
        }

        .leaderboard-table-container{
            width: 540px;
            display: flex;
            justify-content: center;
            margin: 0 0 24px 0;
            border-radius: 10px;
            box-shadow: 0px -1px 6px 3px #10182808, 0px 12px 16px -4px #10182814;

            .leaderboard-table{
                width: 508px;
                margin: 12px 0 8px 0;

                .leaderboard-table-head{

                    .leaderboard-table-head-row{

                        .leaderboard-table-head-ranktablecell{
                            width: 29px;
                        }

                        .leaderboard-table-head-nametablecell{
                            padding: 0 0 0 40px;
                            width: 425px;
                            max-width: 425px;
                        }

                        .leaderboard-table-head-pointstablecell{
                            width: 80px;
                            height: 18px !important;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 3px 0 0 0;

                            svg{
                                margin: 2px 0 3px 4px;
                            }
                        }

                        th{
                            font-family: 'Inter';
                            border-bottom: none;
                            height: 18px !important;
                            padding: 0;
                            font-size: 12px;
                            font-weight: 500 !important;
                            color: #667085;
                        }
                    }
                }

                .leaderboard-table-body{
                    padding: 12px 0 0 0;
                }

                .leaderboard-table-body{

                    .leaderboard-table-body-row{
                        height: 48px !important;
                        border-radius: 10px;

                        td{
                            font-family: 'Inter';
                            border-bottom: none;
                            padding: 0;
                            font-size: 16px;
                            font-weight: 600 !important;
                            color: #667085;
                        }

                        .leaderboard-table-body-row-ranktablecell{
                            text-align: center;
                        }

                        .leaderboard-table-body-row-nametablecell{
                            padding: 0 0 0 40px;
                            font-size: 14px;
                            font-weight: 500 !important;
                            color: #293056;
                        }

                        .leaderboard-table-body-row-pointstablecell{
                            width: 52px;
                            text-align: center;
                        }
    
                    }

                    .leaderboard-table-body-row-currentlearner{
                        height: 48px !important;
                        border-radius: 10px;
                        box-shadow: 0px 2px 4px -2px #1018280F,0px 0px 8px -2px #1018281A;

                        .leaderboard-table-body-row-ranktablecell{
                            text-align: center;
                            color: #344054;
                        }

                        .leaderboard-table-body-row-nametablecell{
                            padding: 0 0 0 40px;
                            font-size: 16px;
                            font-weight: 500 !important;
                            color: #293056;

                            .current-learner{
                                width: 30px;
                                height: 18px;
                                font-size: 12px;
                                font-weight: 400;
                                color: #667085;
                                margin: 0 0 0 4px;
                            }
                        }

                        .leaderboard-table-body-row-pointstablecell{
                            color:#FD710B;
                            width: 80px;
                            text-align: center;

                            svg{
                                margin: 0px 0px 2px 4px;
                                height: 10px;
                                width: 10px;
                                path{
                                    fill: #FD710B;
                                }
                                
                            }
                        }

                        
                        td{
                            font-family: 'Inter';
                            border-bottom: none;
                            padding: 0;
                            font-size: 16px;
                            font-weight: 600 !important;
                            color: #667085;
                        }
    
                    }
                }
            }

        }
    }

    // .leaderboard-box-grid{
    //     width: 164px;
    //     height: 42px;
    //     padding: 0px 0 20px 0;
    //     margin: 0 12px 14px 12px;
    // }

    // .leaderboard-box-name-batchname-grid{
    //     width: 124px;
    //     height: 42px;
    //     margin: 10px 0 0 20px;
    // }

    // .leaderboard-box-leaderboard-name{
    //     max-width: 195px;
    //     max-height: 20px;
    //     font-family: 'Inter';
    //     font-size: 14px;
    //     font-weight: 500;
    //     color: #344054;
    //     margin: 0 0 4px 0;
    //     white-space: nowrap;
    // }

    // .leaderboard-box-batch-name{
    //     font-family: 'Inter';
    //     font-size: 12px;
    //     font-weight: 400;
    //     color: #667085;
    // }

    // .leaderboard-order-box{
    //     font-family: 'Inter';
    //     font-size: 16px;
    //     font-weight: 600;
    //     color: #344054;
    //     padding: 12px 0 0 0;
    // }
}
