@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.approveMainContainer {
  top: 56px;
  right: 91px;
  z-index: 10;
  border-radius: 8px;
  position: absolute;

  .approveLabel {
    @include m.fontSize(text/md/semibold);
    color: map-get(i.$appColor, "grey/900");
  }

  .approveScoreDiv {
    display: flex;
    margin-top: 12px;
    text-align: center;

    .approveScoreDivInput {
      width: 100px;
      height: 50px;
      outline: none;
      text-align: center;
      border-radius: 5px 0px 0px 5px;
      @include m.fontSize(text/xl/semibold);
      color: map-get(i.$appColor, "grey/900");
      border: 1px solid map-get(i.$appColor, "grey/300");
      background: map-get(i.$appBackGroundColor, "white");
    }

    .approveScoreDivTotalMarks {
      width: 100px;
      height: 50px;
      padding: 10px;
      text-align: center;
      border-radius: 0 5px 5px 0px;
      @include m.fontSize(text/xl/semibold);
      color: map-get(i.$appColor, "grey/900");
      border: 1px solid map-get(i.$appColor, "grey/300");
      background: map-get(i.$appBackGroundColor, "grey/100");
    }
  }

  .approveInstructionDiv {
    margin-top: 4px;

    svg {
      margin-right: 10px;
      stroke: map-get(i.$appColor, "grey/700");
    }

    .approveInstructionDivLabel {
      @include m.fontSize(text/xs/regular);
      color: map-get(i.$appColor, "grey/700");
    }
  }

  .approveCommentsLabel {
    margin-top: 16px;
    @include m.fontSize(text/sm/medium);
    color: map-get(i.$appColor, "grey/700");
  }

  .approveButtonDiv {
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: flex-end;

    .buttonSecondaryGreysm {
      margin-right: 10px;
    }
  }

  .rejectCommentsForAssignmentFileUpload {
    height: 35px;
    display: flex;
    margin: 10px 14px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    background: map-get(i.$appBackGroundColor, "brand/200");

    .rejectCommentsForAssignmentFileUploadInnerDiv {
      display: flex;
      margin-left: 12px;
      align-items: center;
      @include m.fontSize(text/sm/regular);

      .rejectCommentsForAssignmentFileUploadFileName {
        margin-left: 5px;
        color: map-get(i.$appColor, "blue/500");
      }

      .rejectCommentsForAssignmentFileUploadFileSize {
        margin-left: 9px;
        color: map-get(i.$appColor, "grey/500");
      }
    }

    .rejectCommentsForAssignmentCloseSvgIcon {
      margin-right: 12px;
    }
  }

  .rejectCommentsForAssignmentAttachmentDiv {
    height: 46px;
    display: flex;
    padding-right: 5px;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid map-get(i.$appColor, "grey/300");

    .rejectCommentsForAssignmentAttachmentInput {
      top: 2px;
      opacity: 0;
      right: 26px;
      width: 24px;
      height: 24px;
      position: relative;
    }
  }
}

.approveCommentsTextArea {
  min-height: 154px;
  max-height: 254px;
  overflow-y: auto !important;
  width: 305px;
  resize: none;
  outline: none;
  margin-top: 6px;
  border-radius: 8px;
  padding: 10px 14px;

  @include m.fontSize(text/md/normal);
  border: 1px solid map-get(i.$appColor, "grey/300");
  background: map-get(i.$appBackGroundColor, "white");
}

.comments-score-section-score-approve-box2 {
  top: 50px;
  right: 112px;
  position: absolute;
}

.comments-score-section-score-approve-box-score-input-txtbox {
  width: 100px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  color: #373739;
  line-height: 24px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  background: #ffffff;
}

.comments-score-section-score-reject-box-score-extend-instructions {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #373739;
  line-height: 19px;
  font-style: normal;
  font-family: "Inter";
}