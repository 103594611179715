@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.pdfViewerContainer {
  width: 100%;
  .pdfViewerFileNameSec {
    display: flex;
    gap: 8px;
    align-items: center;
    .pdfViewerFileName {
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/700");
      margin: 0;
    }
  }
  .react-pdf__Document {
    .react-pdf__Page {
      display: flex;
      justify-content: center;
      min-width: 100%;
      .textLayer {
        display: none;
      }
    }
    .react-pdf__Page__canvas {
      height: fit-content !important;
    }
  }

  // .pdfWrapper .normalview .react-pdf__Page__canvas {
  //   overflow-x: hidden;
  //   width: 100% !important;
  //   height: 400px !important;
  // }

  // .pdfWrapperfullscreen .react-pdf__Document {
  //   overflow: auto !important;
  // }

  // .pdfWrapperfullscreen .fullscreenPage .react-pdf__Page {
  //   max-width: 100%;
  // }

  // .pdfWrapper .react-pdf__Page canvas {
  //   aspect-ratio: 16/9;
  //   object-fit: contain;
  //   mix-blend-mode: normal;
  //   width: 100% !important;
  // }

  // .pdfWrapperfullscreen .react-pdf__Page canvas {
  //   max-width: 60%;
  //   margin-left: 20%;
  //   width: 60% !important;
  //   height: 80% !important;
  // }
}
