@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;
.issueUtils {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 0 0.625em;
}
.issueTableContainer {
  padding: 10px;
  .issueTable {
    .issueTableHead {
      .issueTableHRow {
        background: map-get(i.$appColor, "grey/50");
        height: 44px;
        .issueTableHCell {
          text-align: left;
          @include m.fontSize(text/xs/medium);
          color: map-get(i.$appColor, "grey/500");
          padding: 12px;
        }
      }
    }
    .issueTableBRow {
      .issueTableBCell {
        @include m.fontSize(text/sm/regular);
        color: map-get(i.$appColor, "grey/500");
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 50px;
        border-bottom: 1px solid map-get(i.$appColor, "grey/200");
        padding: 12px;
      }
    }
    .issueTableHCellCheckbox,
    .issueTableBCellCheckbox {
      max-width: 25px;
    }
    .issueTableHCellName,
    .issueTableBCellName {
      max-width: 75px;
    }
    .issueTableHCellEmail,
    .issueTableBCellEmail {
      max-width: 100px;
    }
    .issueTableHCellMobile,
    .issueTableBCellMobile {
      max-width: 95px;
    }
    .issueTableBCellMobileSec {
      display: flex;
      align-items: center;
    }
    .issueTableBCellSummary {
      max-width: 200px;
    }
    .issueTableHCellDes,
    .issueTableBCellDes {
      max-width: 250px;
    }
    .issueTableHCellCDate,
    .issueTableBCellCDate {
      max-width: 150px;
      overflow: unset !important;
      display: flex;
      gap: 8px;
      align-items: center;
    }
    .issueTableHCellJiraid,
    .issueTableBCellJiraid {
      max-width: 50px;
    }
    .issueTableBCellNoData {
      text-align: center;
      @include m.fontSize(text/xs/medium);
      color: map-get(i.$appColor, "grey/500");
    }
  }
}
