@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.educationForm {
  .MuiFormControl-root {
    label {
      font-family: "Inter";
      color: map-get(i.$appColor, "grey/500");
    }
    #skillName {
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/900");
    }
    fieldset {
      border: 1px solid #98a2b3;
    }
  }
  .educationFormContainer,
  .educationFormBtnSec {
    display: flex;
    padding: 0 28px 26px;
    gap: 10px;
  }
  .educationFormSection {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  .educationFormCourse {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .educationFormCourseTitle {
    @include m.fontSize(text/lg/medium);
    color: map-get(i.$appColor, "grey/900");
  }
  .educationFormCourseFieldSec {
    display: flex;
    gap: 24px;
  }
  .educationFormCourseNameAndCgpaFieldSec {
    width: 75%;
  }
  .educationFormCourseDateFieldSec {
    width: 55.5%;
    .educationFormDivider {
      border: 1px solid map-get(i.$appColor, "grey/300");
      margin: 26px 28px 16px;
    }
    .MuiFormControl-root {
      flex: 1 0;
      height: 40px;
      label {
        margin: 0;
        top: -5px;
      }
      label[data-shrink="true"] {
        top: 0;
      }
    }
    .MuiInputBase-root {
      height: 40px;
      padding: 10px;
      overflow: hidden;
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/900");
    }
  }

  #educationFormClass10Name,
  #educationFormClass10Cgpa,
  #educationFormClass12Name,
  #educationFormClass12Cgpa,
  #educationFormClass12Branch,
  #educationFormUgName,
  #educationFormUgCgpa,
  #educationFormUgBranch,
  #educationFormPgName,
  #educationFormPgCgpa,
  #educationFormPgBranch,
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    @include m.fontSize(text/md/regular);
    color: map-get(i.$appColor, "grey/900");
  }

  .educationFormClass12BranchSec,
  .educationFormUgBranchSec,
  .educationFormPgBranchSec {
    width: 26.5%;
  }

  .educationFormBtnSec {
    justify-content: space-between;
    margin: 40px 0 24px 0;
    padding: 0 24px;
  }
}
