@use "../../Abstracts/Variables/index" as i;
@use "../../Abstracts/mixins" as m;

.issueFormContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: 96vh;
  overflow: auto;
  padding-bottom: 10px;
  .issueFormFields {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .inputFieldContainer {
      width: 500px;
    }
    .inputDropdownContainer {
      width: 360px;
      .inputDropdownMenuContainer {
        width: 360px;
        top: 80px;
      }
    }
    .issueFormSelectFieldSec {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      .inputDropdownMenuList {
        min-height: fit-content;
        max-height: 176px;
      }
    }
    .issueFormSelectModuleSec {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: fit-content;
      .issueFormSelectModuleLabel {
        margin: 0;
        padding: 0;
        @include m.fontSize(text/sm/medium);
        color: map-get(i.$appColor, "grey/700");
      }
      .dropdown {
        .dropdown-trigger {
          width: 500px;
        }
      }
    }
  }
  .issueFormBtnSec {
    display: flex;
    gap: 12px;
    button {
      width: 100%;
    }
  }
}

.doubtFormContainer{
  .issueFormSelectFieldSec{
    width: 500px;
    .inputDropdownContainer{
      width: 100%;
      .inputDropdownMenuContainer{
        width: 100%;
      }
    }
  }
}

.issueSuccessContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .issueSuccessIcon {
    margin: 160px auto 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    .issueSuccessText {
      @include m.fontSize(text/xl/medium);
      color: map-get(i.$appColor, "grey/900");
    }
  }
}
