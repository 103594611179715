@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.modalInstructionBodyunorderedListWithSuccess {
  text-align: center;
  padding-right: 20px;
  list-style-type: none;
}

.after-submit-container{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px 0 10px 0;

  .after-submit-header{
    @include m.fontSize(text/xl/semibold);
    line-height: 40px;
    color: map-get(i.$appBackGroundColor, "grey/700");
  }

  .after-submit-msg{
    // @include m.fontSize(text/md/regular);
    color: map-get(i.$appBackGroundColor, "grey/700");
    line-height: 40px;
    font-weight: 600;
  }
}

.modalInstructionBodyTextList {
  margin-top: 10px;
}
