.leaderBoardContainer,
.scoresContainer,
.attendanceContainer {
  position: relative;
  margin-left: 5%;
  margin-top: 16px;
  margin-bottom: 32px;

  // width: 1140px;
  width: 90%;
  // height: 839px;
  gap: 0px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280f;


  .loadingTable {
    top: 0% !important;
    z-index: 4 !important;
    width: 1140px !important;
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    // height: 660px !important;
    // z-index: 4 !important;
    // .loadingDiv{
    //   display: none;
    // }
  }

  .boardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .downloadSingleWidgetAlert {
      position: relative;
      /* float: right; */
      color: red;
      margin-left: 31vw;
      font-weight: 500;
    }

    .leaderBoardwidgetNamendDescription {
      display: flex;
      flex-direction: column;
      justify-items: flex-start;
      align-items: flex-start;
      margin: 20px 0px 19px 24px;
      gap: 8px;

      #leaderboardText1 {
        //styleName: Text lg/Medium;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        color: #101828;
      }

      #leaderboardText2 {
        //styleName: Text sm/Regular;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #344054;
      }
    }

    .downloadDropdown {
      display: flex;
      margin: 30px 35px 29px 0px;
      align-items: center;
      gap: 16px;
      position: relative;

      .boarddownloadicon {
        display: flex;
        align-items: center;
        width: 50.84px;
        height: 36px;
        padding: 8px;
        gap: 8px;
        border-radius: 8px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px 0px #1018280d;
      }
    }
  }

  .dummyLoad {
    min-height: 567px !important;
    max-height: 567px !important;
    position: relative;

    .loadinfileexp>.loadingDiv {
      margin-top: 170px;
      margin-left: 45vw;
      top: 0%;
      z-index: 3;
      width: 0%;
      justify-content: center;
      display: flex;
      left: 0%;
      position: absolute;
      align-items: center;
      flex-direction: column;
    }
  }

  .tableContainer {
    overflow-x: scroll;
    .nameofstudent {
      line-height: 20px;
      color: #101828;
      font-family: "Inter";
      font-size: 14px;
      text-align: left;
      font-weight: 500,
    }


    // .css-1yhpg23-MuiTableCell-root {
    //   padding: 10px;
    //   box-shadow: inset -1px 0px 0px 0 rgb(224, 224, 224);
    // }

    // .css-1ndpvdd-MuiTableCell-root {
    //   padding: 9px;
    //   box-shadow:
    //     inset -1px 0 0 0 rgb(224, 224, 224),
    //     inset 0 1px 0 0 rgb(224, 224, 224),
    // }



    // .css-1ygcj2i-MuiTableCell-root {
    //   padding: 10px;
    //   box-shadow:
    //     inset -1px 0 0 0 rgb(224, 224, 224),     /* Left border */
    //     inset 0 1px 0 0 rgb(224, 224, 224),      /* Top border */  
    // }


    // .css-1ex1afd-MuiTableCell-root {
    //   padding: 10px;
    //   box-shadow: inset -1px 0px 0px 0 rgb(224, 224, 224);
    // }


  }

  .boardFooter {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 12px 24px 16px 24px;
  }
}