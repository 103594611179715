@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.radioContainer {
  display: flex;
  position: relative;
  width: fit-content;
  cursor: pointer;
  gap: 8px;
  margin-bottom: 0;
  .radioInputSmall,
  .radioInputMedium {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    opacity: 0;
  }
  /* common  */
  .radioSmall,
  .radioMedium {
    transition: border-color 0.3s ease;
    position: relative;
    top: 2px;
  }
  #radioIconSmall,
  #radioIconMedium {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: map-get(i.$appColor, "brand/600");
  }

  .radioLabelSectionSmall,
  .radioLabelSectionMedium {
    display: flex;
    flex-direction: column;
  }

  /* If radio disabled */
  .radioInputSmall:disabled ~ .radioLabelSectionSmall #radioLabel,
  .radioInputMedium:disabled ~ .radioLabelSectionMedium #radioLabel {
    color: map-get(i.$appColor, "grey/300");
    cursor: not-allowed;
  }

  /* size small */
  .radioSmall {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid map-get(i.$appColor, "grey/300");
    border-radius: 8px;
  }
  .radioInputSmall:checked + .radioSmall {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .radioInputSmall:hover + .radioSmall {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .radioInputSmall:focus + .radioSmall {
    border: 1px solid map-get(i.$appColor, "brand/300");
    box-shadow: 0px 0px 0px 4px #f4ebff;
  }
  .radioInputSmall:disabled + .radioSmall {
    background: map-get(i.$appColor, "grey/100");
    border: 1px solid map-get(i.$appColor, "grey/200");
  }

  .radioInputSmall:checked:focus + .radioSmall {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .radioInputSmall:checked:disabled + .radioSmall {
    background: map-get(i.$appColor, "grey/100");
    border: 1px solid map-get(i.$appColor, "grey/200");
  }
  .radioInputSmall:checked:disabled + .radioSmall #radioIconSmall circle {
    fill: map-get(i.$appColor, "grey/200");
  }

  .radioInputSmall {
    width: 16px;
    height: 16px;
  }
//   #radioIconSmall {
//     width: 12px;
//     height: 12px;
//   }
  .radioLabelSectionSmall {
    .radioLabelSmall {
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/700");
    }

    .radioSupportTextSmall {
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/500");
    }
  }

  /* size medium */
  .radioMedium {
    width: 20px;
    height: 20px;
    border: 1px solid map-get(i.$appColor, "grey/300");
    border-radius: 10px;
  }
  .radioInputMedium:checked + .radioMedium {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .radioInputMedium:hover + .radioMedium {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .radioInputMedium:focus + .radioMedium {
    border: 1px solid map-get(i.$appColor, "brand/300");
    box-shadow: 0px 0px 0px 4px #F4EBFF;

  }
  .radioInputMedium:disabled + .radioMedium {
    background: map-get(i.$appColor, "grey/100");
    border: 1px solid map-get(i.$appColor, "grey/200");
  }

  .radioInputMedium:checked:focus + .radioMedium {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .radioInputMedium:checked:disabled + .radioMedium {
    background: map-get(i.$appColor, "grey/100");
    border: 1px solid map-get(i.$appColor, "grey/200");
  }
  .radioInputMedium:checked:disabled + .radioMedium #radioIconMedium circle {
    fill: map-get(i.$appColor, "grey/200");
  }
  .radioInputMedium {
    width: 20px;
    height: 20px;
  }
//   #radioIconMedium {
//     width: 14px;
//     height: 14px;
//   }
  .radioLabelSectionMedium {
    .radioLabelMedium {
      @include m.fontSize(text/md/medium);
      color: map-get(i.$appColor, "grey/700");
    }
    .radioSupportTextMedium {
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/500");
    }
  }
}
