@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

.companyLogoContainer {
  display: flex;
  gap: 8px;
  min-width: 158.15px;
  .companyLogo {
    width: 28.15px;
    height: 28px;
  }
  .companyLogoText {
    align-self: flex-end;
    @include m.fontSize("text/xs/regular");
    color: map-get(i.$appColor, "grey/500");
  }
  .companyLogoTextOnLeftNav{
    align-self: flex-end;
    @include m.fontSize("text/xs/regular");
    color: map-get(i.$appColor, "grey/100");
  }
}
