@use "../../../../Sass/Abstracts/Variables/index" as i;
@use "../../../../Sass/Abstracts/mixins" as m;

.dropdown {
  .dropdown-trigger {
    width: 100%;
    height: 44px;
    border: 1px solid map-get(i.$appColor, "grey/300");
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: 8px;
    .tag-list {
      width: 60%;
      .tag-item {
        display: none;
      }
      .tag-item:last-child {
        display: inline-block;
        width: 100%;
        input {
          @include m.fontSize(text/md/regular);
          color: map-get(i.$appColor, "grey/500");
          border: unset;
          width: 100%;
        }
      }
    }
  }
  .dropdown-trigger.arrow.top,
  .dropdown-trigger.arrow.bottom {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    gap: 8px;
  }
  .dropdown-trigger.arrow.top:after,
  .dropdown-trigger.arrow.bottom:after {
    content: " " !important;
    right: 10px;
    border: solid #667085;
    border-width: 0px 1.66px 1.66px 0;
    display: inline-block;
    padding: 3px;
    -webkit-text-decoration: transparent;
    text-decoration: transparent;
    height: 7px;
    width: 7px;
    position: relative;
  }
  .dropdown-trigger.arrow.bottom:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .dropdown-trigger.arrow.top:after {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
  }
  .dropdown-content {
    width: 100%;
    // min-height: fit-content;
    max-height: 320px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid map-get(i.$appColor, "grey/100");
    z-index: 5;
    ul {
      div {
        .infinite-scroll-component {
          overflow: unset !important;
          li {
            height: 44px;
            margin-left: 10px;
            padding: 10px 14px 10px 14px;
            // label {
            // text-overflow: ellipsis;
            // width: 100px;
            // overflow: hidden;
            // white-space: nowrap;
            @include m.fontSize(text/md/medium);
            color: map-get(i.$appColor, "grey/900");
            // }
          }
        }
      }
    }
  }
  .dropdown-trigger.disabled {
    input {
      background: unset;
    }
  }
  .dropdown-trigger.arrow.disabled.bottom:after {
    border-color: #d0d5dd;
    border-width: 0px 1.66px 1.66px 0;
  }
  .dropdown-trigger[aria-expanded="true"] {
    border: 1px solid map-get(i.$appColor, "brand/300");
    box-shadow: 0px 0px 0px 4px #f4ebff;
  }
}
