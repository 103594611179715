.headerRightNavSvgIcon {
  bottom: 1px;
  display: flex;
  cursor: pointer;
  position: relative;

  .settingSvgIcon,
  .notificationSvgIcon {
    padding: 0;
    width: 40px;
    height: 40px;
  }

  .blinking {
    animation: blink-animation 1s steps(3, start) infinite;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
}
