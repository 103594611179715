@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.dragAndDropUploadContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  .dragAndDropUploadLabel {
    @include m.fontSize(text/sm/medium);
    color: map-get(i.$appColor, "grey/700");
    margin: 0;
  }
  .dragAndDropUploadSec {
    min-height: 184px;
    padding: 24px 0;
    border-radius: 8px;
    border: 1px solid map-get(i.$appColor, "grey/300");
    background: map-get(i.$appBackGroundColor, "white");
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    position: relative;
    .dragAndDropUploadTopSec {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .dragAndDropUploadText {
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: map-get(i.$appColor, "grey/700");
      }
    }
    .dragAndDropUploadSupFormat,
    .dragAndDropUploadOrText {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: map-get(i.$appColor, "grey/400");
    }
    // .dragAndDropUploadBtn {
    // }
    .dragAndDropUploadInput {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  .dragAndDropUploadHintText {
    @include m.fontSize(text/sm/regular);
    color: map-get(i.$appColor, "grey/500");
  }
}

.dragAndDropUploadContainer.destructive{
  .dragAndDropUploadSec{
    border-color: map-get(i.$appColor,"error/300");
  }
  .dragAndDropUploadHintText{
    color: map-get(i.$appColor, "error/500")
  }
}

.uploadedFileContainer {
  height: 88px;
  min-width: 247px;
  padding: 8px 12px;
  border-radius: 8px;
  background: map-get(i.$appBackGroundColor, "grey/100");
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto;
  .uploadedFileSec {
    display: flex;
    gap: 40px;
    position: relative;
    .uploadedFileDetailSec {
      display: flex;
      gap: 12px;
      .uploadedFileImage {
        object-fit: fill;
        width: 39px;
        height: 38px;
      }
      .uploadedFileDetail {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 70%;
        .uploadedFileName {
          display: flex;
          gap: 4px;
          span {
            @include m.fontSize(text/sm/semibold);
            color: map-get(i.$appColor, "grey/700");
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .uploadedFileSize {
          @include m.fontSize(text/xs/regular);
          color: map-get(i.$appColor, "grey/500");
        }
      }
    }
    .uploadedFileRemoveIcon {
      position: absolute;
      right: 0;
    }
  }
  .uploadedFileStatusSec {
    display: flex;
    align-items: center;
    gap: 5px;
    .uploadedFileStatusText {
      @include m.fontSize(text/xs/regular);
      color: #ff000099;
    }
  }
}
