@use "../../Abstracts/Variables/index" as i;
@use "../../Abstracts/mixins" as m;

.ccSecContainer {
  // width: 288px;
  height: 68px;
  padding: 12px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid map-get(i.$appColor, "grey/200");
  background: map-get(i.$appBackGroundColor, "white");
  // flex: 1 0 288px;
  cursor: pointer;
  .ccSecContent {
    display: flex;
    align-items: center;
    gap: 20px;
    .ccSecContentFavion {
      width: 40px;
      height: 40px;
      padding: 4px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      //   box-shadow: 0px 1px 2px 0px #1018280f;
      box-shadow: 0px 1px 3px 0px #1018281a;
      background: map-get(i.$appBackGroundColor, "white");
    }
    .ccSecContentText {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: map-get(i.$appColor, "grey/700");
    }
  }
  .ccSecLeftChevron {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
  }
}

.ccSecContainer:hover {
  border: 1px solid map-get(i.$appColor, "grey/300");
  transition: border 0.5s ease-in-out;
  .ccSecLeftChevron {
    opacity: 1;
    visibility: visible;
  }
}
