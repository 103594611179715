.mcquploadlibTaps {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  font-style: normal;
  padding: 0 0 0 15px;
  font-family: "Inter";
  text-decoration: none;
  margin: 5px 0px 0 0px;
  border-bottom: #000000 !important;
}

.mcqerrors {
  margin: 0px;
  color: red;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-family: "Inter";
}

.radiobtnforMcq {
  gap: 130px;
  width: 100%;
  display: flex;
  font-size: 14px;
  align-items: center;
  font-family: "Inter";
  margin: 0 0 25px 23px;
}

.singleSelectformcq {
  float: left;
  width: 100px;
}

.singleSelectformcq > label {
  margin: 0 0 0 15px;
}

.mcq_fileupload {
  height: 0;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px 0px 0px;
}

.mcqUploadFileName {
  margin: 15px 0 0 0;
}

.mcqcontentUploadIcon {
  color: #1475cf;
  margin: 0 8px 0 0;
}

.mcqquestion_langname {
  float: left;
  width: 862px;
  height: 52px;
  text-align: left;
  margin: 25px 0 0 23px;
}

.mcqquestion_langname > label {
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 6px;
  font-family: "Inter";
}

#mcqquestion_langname {
  gap: 10px;
  width: 862px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

.mcqquestion_description {
  width: 99%;
  float: left;
  text-align: left;
  margin: 25px 0 0 23px;
}

.mcqquestion_description > label {
  width: 68px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 6px;
  font-family: "Inter";
}

.mcqeditor-tinymce {
  float: left;
  width: 862px;
  margin: 0px 0 0 23px;
}

.mcq_ans_exp_label > label {
  width: 68px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}

.mcq_ans_exp_label {
  text-align: left;
}

.mcq_ans_exp {
  float: left;
  width: 862px;
  margin: 0px 0 0 23px;
}

.mcqembedLinkView {
  float: left;
  width: 862px;
  height: auto;
  text-align: left;
  margin: 25px 0 0 23px;
}

.mcqembed-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mcqembed-list li {
  width: 500px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  margin-bottom: 5px;
  display: inline-block;
  word-wrap: break-word;
}

.mcqfaTrashCan {
  width: 16px;
  height: 16px;
  color: tomato;
}

.mcqQuestionOptions {
  width: 100%;
  margin-left: 10px;
  display: inline-block;
}

.singleOption {
  width: 50%;
  display: flex;
  margin: 15px 0 0 0;
  justify-content: space-evenly;
}

.mcqOptions {
  width: 500px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 3px 0px 3px 5px;
  border: 1px solid #bdc1c6;
}

.mcqcheckBoxdiv {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
  font-family: "Inter";
}

.deleteMcqOption {
  width: 15px;
  height: 15px;
  color: tomato;
}

#mcqaddOptBtn {
  width: 90px;
  float: left;
  height: 28px;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  border: #f55533;
  font-style: normal;
  border-radius: 4px;
  font-family: "Inter";
  background: #f55533;
  margin: 15px 0px 0px 15px;
}

.mcqcontent_fileupload {
  width: 35%;
  float: left;
  height: 21px;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  margin: 25px 0 0 23px;
  flex-direction: column;
  justify-content: center;
}

.mcqquestion-navItem > .nav-link {
  color: #f55533 !important;
  background-color: #efefef;
}

.mcqquestion-navItem .active {
  color: #ffffff !important;
  background-color: #f55533 !important;
}

.mcqshowinglist {
  width: 36%;
  float: left;
  height: auto;
}

.mcqfile-lists > li {
  display: flex;
  margin: 1.2em 0;
  list-style: none;
  border-radius: 5px;
  align-items: center;
  padding: 1.2em 1.5em;
  background-color: #f55e3038;
}

.mcqfile-lists > li > svg {
  color: #f55321;
}

.mcqfile-lists > li > svg:first-child {
  font-size: 1.2em;
  margin-right: 0.8em;
}

.mcqlistsName {
  flex: 1;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-size: 0.9em;
  color: #000000;
  font-family: "Inter";
  text-decoration: none;
}

.mcqlistsName:hover {
  color: #0d6efd;
  text-decoration: underline;
}

.mcqquestion_savebtn {
  right: 90%;
  width: 100%;
  float: right;
  position: relative;
  margin: 34px 10px 0 0;
}

.mcqquestion_savebtn > button {
  float: right;
}

.mcqquestion_savebtn button {
  position: relative;
  border: none;
  width: 100px;
  width: 120px;
  height: 34px;
  left: 20px;
  color: #ffffff;
  border-radius: 4px;
  background: #f55533;
}

.mcqtrashIconforDeleteFile {
  color: #f55321;
}

.mcqcheckisDownload {
  margin: 0 0 0 20px;
}
