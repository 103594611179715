@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.userTimeSpentReportChartMainContainer,
.userTimeSpentReportChartMainContainerForAdmin {
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px #1018281a;
  background: map-get(i.$appBackGroundColor, "white");

  .userTimeSpentReportChartFilterDiv {
    display: flex;
    margin-left: 48px;
    margin-bottom: 28px;
    justify-content: space-between;
    @include m.multiLevelDropDown();

    .userTimeSpentReportChartAverageTimeDiv {
      display: flex;
      flex-direction: column;
      @include m.fontSize(text/xs/medium);
      color: map-get(i.$appColor, "grey/700");

      .userTimeSpentReportChartAverageTime {
        @include m.fontSize(display/xs/bold);
        color: map-get(i.$appColor, "grey/700");
      }
    }

    .userTimeSpentReportChartSubFilterDiv {
      gap: 16px;
      display: flex;
    }
  }

  .userTimeSpentReportChartTimeChart {
    cursor: pointer;
  }

  .userTimeSpentReportChartNoData {
    padding: 20px;
    text-align: center;
    @include m.fontSize(display/xs/regular);
    color: map-get(i.$appColor, "grey/700");
  }
}

.userTimeSpentReportChartMainContainerForAdmin {
  height: 64vh;
}
