.popup-open {
  // top: 10%;
  // left: 25%;
  // position: fixed;
  // width: fit-content;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  max-height: 700px;
  overflow: auto;
  // height: fit-content;
  background: #ffffff;
  z-index: 100;


  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
}

.popup-title {
  // height: 52px;
  margin: 0 0 24px 0;
  & .popup-head-close-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .title {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    color: #101828;
    text-align: start;
  }

  & .desc {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    color: #667085;
    text-align: start;
  }

  // & .closebtn {
  //   margin: 10px 0 0 0;
  // }
}

.popup-content {
  min-width: 536px;
  margin: 24px;
}

.popup-body {
  min-width: 536px;
}

.popup-body-desc {
  width: 536px;
  text-align: start;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  color: #667085;
  margin: 0 0 32px 0;
}
