.leftNavOpenSvgIcon,
.leftNavCloseSvgIcon {
  z-index: 3;
  width: 55px;
  max-height: 7vh;
  cursor: pointer;
  position: absolute;
  margin: 60px 0 0 285px;
}

.leftNavCloseSvgIcon {
  margin: 60px 0 0 0;
}
