.adminLpContainer {
  overflow: auto;
  margin-top: 7vh;
  // padding-top: 10px;
  height: calc(100vh - 7vh);
}

#adminLpBtnContainer {
  z-index: 2;
  right: 20px;
  width: 100%;
  margin: 5px 0;
  display: flex;
  // position: fixed;
  flex-direction: row;
  justify-content: space-between;
}

.viewOptions {
  display: flex;
  gap: 20px;
  width: 86px;
  margin-top: 8px;
  margin-right: 20px;
  z-index: 5;

  .disableLpListView,
  .disableLpGridView {
    width: 28px;
    height: 28px;
    padding: 4px;
    gap: 10px;
  }

  .disableLpListView:hover,
  .disableLpGridView:hover {
    width: 28px;
    height: 28px;
    padding: 4px;
    gap: 10px;
    border-radius: 4px;
    background: #f9fafb;
  }

  .learningPathListView,
  .lpGridView {
    width: 28px;
    height: 28px;
    padding: 4px;
    gap: 10px;
    border-radius: 4px;
    background: #f2f4f7;
  }
}

.adminLpSearchBox {
  position: absolute;
  left: 220px;
  gap: 10px;
  border: none;
  width: 186px;
  height: 34px;
  outline: none;
  display: block;
  // margin: 0 20%;
  border-radius: 0;
  font-family: "Inter";
  background: #ffffff;
  padding: 9px 4px 9px 35px;
  border-bottom: 0.8px solid #667085;
}

.adminLpSearchIcon {
  top: 12px;
  left: 40px;
  width: 12.44px;
  height: 12.44px;
  color: #bdc1c6;
  position: relative;
}

.adminLpSearchBox::placeholder {
  // width: 47px;
  // height: 20px;
  // font-size: 14px;
  // font-weight: 400;
  // color: #bdc1c6;
  // line-height: 20px;
  // font-style: normal;
  // font-family: "Inter";
  //styleName: Text xs/Regular;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #667085;
}

#adminAddLpBtn {
  width: 170px;
  height: 34px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  padding: 7px 20px;
  border-radius: 4px;
  font-style: normal;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  background: #0d6efd;
}

.lpPlusIcon {
  margin-right: 5px;
}

.lpListView {
  margin: 11px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.lpListIcon {
  color: #26344a;
  margin-right: 7.8px;
}

#adminLpCardContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  flex-direction: row;
}

.adminLpTableContainer {
  //width: 100%;
  // padding-top: 30px;
  margin: 0 0 0 20px;
  padding-bottom: 10px;
}

.archiveLpSwitchDiv {
  font-size: 16px;
  font-family: Inter;
  color: #000000;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
  font-family: "Inter";
}

#archiveBatchSwitchlps {
  box-shadow: none;
}

#adminLpCard {
  width: 400px;
  margin: 20px;
  cursor: pointer;
  min-height: 164px;
  border-radius: 6px;
  background: #202124;
  box-sizing: border-box;
  border: 1px solid #202124;
  box-shadow: 4px 5px 4px rgba(81, 78, 78, 0.38);
}

#adminLpCardTitle {
  display: flex;
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  align-items: flex-end;
}

#adminLpHrLine {
  width: 100%;
  height: 2px;
  margin-top: 20px;
  background: #f55533;
}

#adminLpCardText {
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  text-align: left;
  color: #ffffff;
  line-height: 20px;
  white-space: wrap;
  font-style: normal;
  font-family: "Inter";
}

#adminAddLpContainer {
  width: 100%;
}

#addLpHeader {
  display: flex;
  flex-direction: column;
}

#addLpTitleSection {
  display: flex;
  justify-content: space-between;
}

#addLpTitle {
  width: 203px;
  height: 27px;
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  line-height: 27px;
  font-style: normal;
  font-family: "Inter";
  margin: 20px 0 20px 20px;
}

.addLpCloseIcon {
  cursor: pointer;
  color: #202124;
  margin: 28px 30px 20px;
}

.addLpHrLine1 {
  width: 100%;
  height: 0px;
  border: 1px solid #e4e5e7;
}

.addLpProgress {
  display: flex;
  flex-direction: row;
}

.addLpP1Section,
.addLpP2Section,
.addLpP3Section {
  display: flex;
  flex-direction: row;
}

.addLpP1 {
  order: 0;
  gap: 10px;
  flex: none;
  padding: 8px;
  width: 110px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  background: #f55533;
  margin: 13px 0 0 11px;
}

.addLpIcon1 {
  order: 0;
  gap: 10px;
  flex: none;
  width: 24px;
  flex-grow: 0;
  height: 24px;
  display: flex;
  padding: 10px;
  margin: 8px 0px;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  background: #ffffff;
  justify-content: center;
}

.addLpListIcon {
  color: #f55533;
}

.addLpP1Title {
  order: 1;
  flex: none;
  width: 59px;
  flex-grow: 0;
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 16px;
  font-style: normal;
  font-family: "Inter";
  letter-spacing: 0.02em;
  margin: 12px 12px 12px 0;
}

.addLpPhr1,
.addLpPhr2 {
  order: 1;
  flex: none;
  height: 0px;
  width: 22px;
  flex-grow: 0;
  margin: 35px 0;
  border: 1px solid #bdc1c6;
}

.addLpP2 {
  order: 0;
  gap: 10px;
  flex: none;
  padding: 8px;
  width: 198px;
  flex-grow: 0;
  height: 40px;
  display: flex;
  cursor: pointer;
  margin: 13px 0 0px;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  background: #eff0f1;
}

.addLpIcon2 {
  order: 0;
  gap: 10px;
  flex: none;
  width: 24px;
  flex-grow: 0;
  height: 24px;
  display: flex;
  padding: 10px;
  margin: 8px 0px;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  background: #bdc1c6;
  justify-content: center;
}

.addLpColIcon {
  margin: 6px;
  color: #ffffff;
}

.addLpP2Title {
  order: 1;
  flex: none;
  flex-grow: 0;
  height: 16px;
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  line-height: 16px;
  font-style: normal;
  font-family: "Inter";
  letter-spacing: 0.02em;
  margin: 12px 12px 12px 0;
}

.addLpP3 {
  order: 0;
  gap: 10px;
  flex: none;
  padding: 8px;
  width: 110px;
  flex-grow: 0;
  height: 40px;
  display: flex;
  margin: 13px 0 0px;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  background: #eff0f1;
}

.addLpIcon3 {
  order: 0;
  gap: 10px;
  flex: none;
  width: 24px;
  height: 24px;
  flex-grow: 0;
  display: flex;
  padding: 10px;
  margin: 8px 0px;
  border-radius: 100px;
  flex-direction: row;
  align-items: center;
  background: #bdc1c6;
  justify-content: center;
}

.addLpCheckIcon {
  color: #ffffff;
}

.addLpP3Title {
  order: 1;
  flex: none;
  width: 50px;
  flex-grow: 0;
  height: 16px;
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  line-height: 16px;
  font-style: normal;
  font-family: "Inter";
  letter-spacing: 0.02em;
  margin: 12px 12px 12px 0;
}

.adminLpBackBtn {
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 20px;
}

.adminLpBack {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  text-transform: uppercase;
}

.adminLpDesSection,
.adminLpModSection,
.adminLpIconSection,
.mockGroupTestTagDiv,
.adminLpInputSection {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
}

.mockGroupTestTagDiv {
  margin-left: 20px;
}

.adminLpDesLabel,
.addLpModulesLabel,
.adminNewLpNameLabel,
.adminLpIconLabel {
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  text-align: left;
  line-height: 14px;
  margin-left: 20px;
  font-style: normal;
  font-family: "Inter";
}

.adminLpDesMaxChar {
  float: right;
  margin: 0 20px 0 0;
}

#countLp {
  font-size: 12px;
  font-weight: 500;
  color: #202124;
  line-height: 15px;
  font-style: normal;
  font-family: "Inter";
}

.addLpModulesLabel {
  margin-top: 10px;
}

.adminNewLpName {
  height: 34px;
  margin: 8px 20px;
  border-radius: 4px;
  padding-left: 10px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #bdc1c6;
}

.adminLpDes,
.adminLpContent {
  order: 1;
  gap: 10px;
  flex: none;
  resize: none;
  flex-grow: 0;
  display: flex;
  margin: 8px 20px;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

.adminLpDes {
  height: 105px;
}

#adminLpIcon {
  border: 1px solid #000000;
  margin: 8px 20px;
  border-radius: 4px;
}

.adminLpIconPreview {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-left: 20px;
}

#addLpModules {
  height: 34px;
  border: none;
  margin: 8px 20px;
  border-radius: 4px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #bdc1c6;
}

.addLpHrLine2 {
  width: 100%;
  height: 0px;
  bottom: 70px;
  position: absolute;
  border: 1px solid #e4e5e7;
}

.adminLpBtnSec {
  right: 20px;
  bottom: 15px;
  display: flex;
  position: fixed;
  background: #ffffff;
}

.adminLpNextBtn {
  order: 5;
  flex: none;
  width: 96px;
  height: 34px;
  flex-grow: 0;
  border: none;
  display: flex;
  color: #ffffff;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  background: #f55533;
  padding: 7px 20px 7px 28px;
}

.adminLpProceedBtn {
  order: 5;
  flex: none;
  width: 96px;
  height: 34px;
  flex-grow: 0;
  border: none;
  display: flex;
  color: #ffffff;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  background: #f55533;
  padding: 7px 20px 7px 20px;
}

.adminLpCancelBtn {
  order: 1;
  flex: none;
  width: 86px;
  flex-grow: 0;
  height: 34px;
  display: flex;
  color: #202124;
  padding: 7px 20px;
  border-radius: 4px;
  margin-right: 10px;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border: 1px solid #bdc1c6;
}

.addModuleBtn {
  float: right;
  width: 150px;
  height: 30px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  font-style: normal;
  border-radius: 4px;
  font-family: "Inter";
  background: #0c70e4;
  margin: -57px 20px 0px 0;
}

.addModule {
  float: right;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  color: #0c70e4;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  align-items: flex-end;
  margin: 0px 20px 5px 0;
}

.module {
  overflow: auto;
  min-height: calc(100vh - 40vh);
  max-height: calc(100vh - 32.5vh);
}

.addModDetails {
  width: 100%;
  margin-top: 0;
  cursor: default;
  margin-bottom: -15px;
  background: #e9ecef;
}

.addModNameModule {
  float: left;
  cursor: grab;
  height: 50px;
  width: 300px;
  text-align: left;
}

.module .addModName {
  width: 250px;
  padding: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #202124;
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
  font-family: "Inter";
  background: #e9ecef;
  margin: 10px 0 15px 12px;
}

.addModNamereadOnly {
  width: 250px;
  padding: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #202124;
  font-weight: 700;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  background: #e9ecef;
  margin: 10px 0 15px 12px;
}

.addModNamereadOnly::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #bdc1c6;
  line-height: 18px;
  font-style: normal;
  font-family: "Inter";
}

.moduleDuedatename {
  width: 90px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  margin: 11px 0 0 0;
  font-family: "Inter";
  background: #e9ecef;
}

.moduleDuedate {
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  color: #202124;
  line-height: 20px;
  margin: 10px 0 0 0;
  font-style: normal;
  font-family: "Inter";
  background: #e9ecef;
}

.module .addModName::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #bdc1c6;
  line-height: 18px;
  font-style: normal;
  font-family: "Inter";
}

.module .addModName:hover {
  outline: none;
  color: #202124;
  border-radius: 4px;
  background: #ffffff;
  border-color: #0c70e4;
  border: 1px solid #bdc1c6;
}

.modIcon {
  display: flex;
  cursor: pointer;
  margin-top: -5px;
  background: #e9ecef;
}

.modIconforAdd {
  display: flex;
  cursor: pointer;
  margin-top: 7px;
  padding-bottom: 13px;
  background: #e9ecef;
}

.modName {
  top: 11px;
  font-size: 14px;
  font-weight: 700;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  position: relative;
  font-family: "Inter";
  margin: 15px 0 15px 12px;
}

.addModIcon {
  padding: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #202124;
  margin: 15px 10px 0 0;
}

.addModIconforAddlp {
  padding: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #202124;
  margin: 10px 10px 0 0;
}

.deleteModIcon {
  padding: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #f55533;
  margin: 15px 10px 0 0;
}

.deleteModIconforAddlp {
  padding: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #f55533;
  margin: 10px 10px 0 0;
}

.module .ant-table-content table {
  width: 96%;
  border-spacing: 0 3px;
  margin: -5px 15px 12px;
  border-collapse: separate;
}

.module .ant-table-row {
  width: 100%;
  cursor: grab;
  border: none;
  margin-left: 20px;
  border-radius: 4px;
  padding-bottom: 10px;
  background: #e9ecef;
}

.module .ant-table-thead {
  margin: 0;
  padding: 0;
  width: 50px;
  font-size: 12px;
  max-height: 20px;
  font-weight: 400;
  color: #bdc1c6;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  font-family: "Inter";
  background: #f2f3f5;
  box-shadow: inset 0px -1px 0px #e4e5e7;
}

.module .ant-table-cell {
  padding: 0;
  text-align: center !important;
}

.module .ant-empty-image,
.module .ant-empty-description {
  max-height: 20px !important;
}

.module .addModDetails .ant-table-tbody .ant-table-cell {
  height: 40px;
  background: #f8f9fa;
  box-shadow: inset 0px -1px 0px #e4e5e7;
}

.gripIcon {
  cursor: grab;
  color: #26344a;
  padding: 7px 5px 0;
  text-align: center;
  background-color: #f2f3f5;
}

.addCategory {
  float: left;
  width: 100px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #0c70e4;
  margin: 8px 0 0 0;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.addCategoryforAddlp {
  width: 100px;
  height: 20px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  color: #0c70e4;
  line-height: 20px;
  margin: 8px 0 0 0;
  font-style: normal;
  font-family: "Inter";
}

th.ant-table-tbody {
  background-color: #0c70e4;
}

.moduleDetails,
.moduleDetails:disabled {
  width: 99%;
  padding: 0;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  font-family: "Inter";
  background: #f8f9fa;
}

.moduleDetailsforTopic,
.moduleDetailsforTopic:disabled {
  width: 99%;
  padding: 0;
  height: 42px;
  border: none;
  font-size: 14px;
  color: #202124;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  background: #f2f3f5;
}

.moduleTotalDetails:disabled {
  padding: 0;
  width: 100%;
  height: 42px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  background: #f2f3f5;
}

.moduleDetailsforcell {
  padding: 0;
  width: 100%;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  background: #f8f9fa;
}

.moduleDetails:focus {
  margin: 0;
  padding: 0;
  width: 98%;
  height: 40px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  border: 1px solid rgb(113, 113, 253);
}

#iconMod {
  height: 40px;
  margin-left: -3px;
  background-color: #f8f9fa;
}

#trashIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #f55533;
  margin: 12px 5px 0 7px;
}

.oldLpPath {
  text-align: left;
  margin: 20px 0 12px 20px;
}

#oldLp {
  font-size: 12px;
  font-weight: 700;
  color: #0d6efd;
  line-height: 16px;
  font-style: normal;
  font-family: "Inter";
  text-decoration: none;
}

.oldLpName {
  font-size: 12px;
  font-weight: 700;
  color: #5f6368;
  line-height: 16px;
  font-style: normal;
  font-family: "Inter";
}

.publishLpTopicIcon {
  width: 18px;
  height: 12px;
  cursor: pointer;
  margin: 14px 10px;
}

.editLpTopicIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  color: #202124;
  padding-top: 13px;
}

.saveLpTopicIcon {
  padding: 1px;
  cursor: pointer;
  color: #02ab46;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #02ab46;
}

.cancelLpTopicIcon {
  cursor: pointer;
  color: #f55533;
  margin-top: 10px;
  padding: 1px 4px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #f55533;
}

.lpUpdateBtn {
  width: 96px;
  margin: 2px;
  height: 34px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #f55533;
}

.lpTopicExpandIcon {
  padding: 0;
  width: 12px;
  height: 15px;
  cursor: pointer;
  color: #202124;
  margin: 12px 10px 0 0;
}

.addTopicConfig {
  width: 150px;
  padding: 5px 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #0c70e4;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  margin: -10px 0 10px -16px;
}

.topicIcon {
  height: 42px;
  display: flex;
  background-color: #f2f3f5;
}

.addTopic {
  width: 100px;
  height: 20px;
  font-size: 14px;
  cursor: pointer;
  color: #0c70e4;
  font-weight: 400;
  line-height: 20px;
  margin: 10px 0 0 0;
  font-style: normal;
  font-family: "Inter";
}

.css-tlfecz-indicatorContainer {
  padding: 0;
}

.moduleDetails > div {
  background-color: #f8f9fa !important;
}

.addModDetails input[type="number"]::-webkit-inner-spin-button,
.addModDetails input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.lpDuration,
.lpDuration:disabled {
  width: 30px;
}

.lpDuration:focus {
  width: 30px;
}

.durationSelection,
.durationSelection:disabled {
  right: 3px;
  width: 66px;
  position: relative;
}

.durationSelection:focus {
  width: 66px;
}

.durationSelection option {
  padding: 10px 0;
}

.adminmodule-container .adminModuleAddModIcon {
  float: left;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  color: #0c70e4;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  margin: 0px 20px 5px 20px;
}

.adminmodule-container .ant-table-content table {
  width: 96%;
  border-spacing: 0 3px;
  margin: -5px 20px 12px;
  border-collapse: separate;
}

.adminmodule-container .ant-table-tbody .ant-table-cell {
  border-radius: 4px;
  background: #f2f3f5;
}

.adminmodule-container .adminModuleDetailsSec .ant-table-tbody .ant-table-cell {
  box-shadow: unset;
  display: flex;
  align-items: flex-end;
}

.adminmodule-container .ant-empty-description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 20px;
  font-style: normal;
  font-family: "Inter";
}

.adminmodule-container .ant-empty-image {
  margin: 0;
  height: 0;
  padding: 0;
}

.adminModuleContainer {
  height: 50px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.adminModuleLeftSec {
  width: 50%;
  display: flex;
  align-items: center;
}

.adminmodule-container .adminModuleMoveIcon {
  cursor: grab;
  color: #26344a;
  margin: 0px 10px 0 10px;
}

.adminmodule-container .adminModuleName,
.adminmodule-container .adminModuleDuedays {
  padding: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  background: #f2f3f5;
  text-overflow: ellipsis;
}

.adminmodule-container .adminModuleDuedays {
  width: 40px;
  text-align: right;
}

.adminmodule-container .adminModuleDuedaysText {
  font-size: 14px;
  font-weight: 700;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  background: #f2f3f5;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.adminmodule-container .adminModuleName::placeholder,
.adminmodule-container .adminModuleDuedays::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #bdc1c6;
  line-height: 18px;
  font-style: normal;
  font-family: "Inter";
}

.adminmodule-container .adminModuleName:hover,
.adminmodule-container .adminModuleDuedays:hover {
  outline: none;
  color: #202124;
  border-radius: 4px;
  background: #ffffff;
  border-color: #0c70e4;
  border: 1px solid #bdc1c6;
}
// .adminmodule-container .adminModuleName:hover{
//   border: 1px solid #bdc1c6;
// }
// .adminmodule-container .adminModuleDuedays:hover{
//   border-bottom: 1px solid #bdc1c6;
// }

.adminmodule-container .adminModuleName:disabled:hover,
.adminmodule-container .adminModuleDuedays:disabled:hover {
  border: none;
  outline: none;
  color: #202124;
  background: none;
}

.adminmodule-container .adminModuleDetailType {
  margin: 8px;
  outline: none;
  font-size: 12px;
  font-weight: 400;
  color: #b66969;
  font-style: normal;
  border-radius: 5px;
  font-family: "Inter";
  background: #f3cece;
  padding: 0px 5px 5px;
}

.adminmodule-container .adminModuleIconSec {
  width: 50%;
  display: flex;
  align-items: center;
  margin: 0px 10px 0 0px;
  justify-content: flex-end;
}

.adminmodule-container .adminModuleAddBtns {
  display: flex;
  margin-left: 10px;
}

.adminModuleAddTest,
.adminModuleAddCoding,
.adminModuleAddAssignment,
.adminmodule-container .adminModuleAddContent {
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  color: #0c70e4;
  line-height: 20px;
  font-style: normal;
  padding-bottom: 5px;
  font-family: "Inter";
  margin: 5px 10px 0 20px;
}

.adminModuleExpandIcon {
  margin: 0px 10px 0 0;
}

.adminModuleDeleteIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #f55533;
}

.adminmodule-container .adminModuleDetails {
  width: 88vw;
  padding: 0;
  height: 36px;
  display: flex;
  margin-top: 10px;
  border-radius: 4px;
  margin-right: 10px;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
  box-shadow: 1px 2px 3px #e4e5e7;
}

.adminmodule-container .adminModuleDetailName {
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  margin: 2px 0 0 5px;
  font-family: "Inter";
}
.adminModuleDetailName {
  position: relative; /* For proper positioning of the tooltip */

  .adminModuleDetailModuleName {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px; /* Adjust as needed */
    display: inline-block;
  }

  .moduleName-tooltip {
    visibility: hidden; /* Initially hidden */
    opacity: 0; /* Fully transparent */
    background-color: #333; /* Tooltip background */
    color: #fff; /* Tooltip text color */
    text-align: center;
    padding: 5px 8px;
    border-radius: 4px;
    position: absolute;
    top: 125%; /* Tooltip below the text */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1000; /* Ensure it's above other elements */
    transition: opacity 0.3s ease-in-out;

    /* Arrow for tooltip */
    &::after {
      content: "";
      position: absolute;
      // top: -5px;
      left: 50%;
      bottom: 100%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #333 transparent;
    }
  }

  &:hover .moduleName-tooltip {
    visibility: visible; /* Show the tooltip */
    opacity: 1; /* Fully opaque */
  }
}
.adminmodule-container .adminModuleDetailDesc {
  font-size: 12px;
  font-weight: 400;
  color: #202124;
  line-height: 20px;
  margin-left: 30px;
  font-style: normal;
  font-family: "Inter";
}

.adminmodule-container .adminModuleDetailMoveIcon {
  cursor: grab;
  color: #26344a;
  margin: 6px 10px 0 10px;
}

.adminmodule-container .adminModuleDetailTypeIcon {
  margin: 2px;
}

.adminmodule-container .adminModuleDetailsEditIcon {
  width: 12.5px;
  height: 12.5px;
  cursor: pointer;
  color: #202124;
  margin: 0px 10px 0 0;
}

.adminmodule-container .adminModuleDetailsDeleteIcon {
  width: 12.5px;
  height: 12.5px;
  cursor: pointer;
  color: #f55533;
  margin: 0px 10px 0 0;
}

.adminmodule-container .adminModuleDetailPublishIcon {
  width: 12.5px;
  height: 12.5px;
  cursor: pointer;
  margin: 0px 10px 0 10px;
}

.adminmodule-container .adminModuleDueDate {
  font-size: 14px;
  font-weight: 700;
  color: #202124;
  line-height: 20px;
  margin-right: 10px;
  font-style: normal;
  font-family: "Inter";
}

.adminmodule-container .adminModuleDetailDueDate {
  width: 100px;
  border: none;
  text-align: left;
  margin: 2px 0px 0;
}

.adminmodule-container .adminModuleDetailDueDate:hover {
  border: 1px solid;
  border-radius: 4px;
  border-color: #0c70e4;
}

#adminLpModuleExtTimeBox > div:nth-child(3) > div:first-child {
  width: 400px;
  height: 510px;
}

.adminLpModuleExtTimeBoxBtn {
  right: 10px;
  bottom: 10px;
  position: absolute;
}

#adminLpModuleExtTimeTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  font-style: normal;
  padding: 0 0 15px 0;
  font-family: "Inter";
  color: rgb(32, 33, 36);
}

.adminLpModuleExtTimeLabel {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  font-style: normal;
  margin: 10px 0 0 0;
  font-family: "Inter";
  color: rgb(32, 33, 36);
}

.adminModuleDetailDueDate {
  padding: 5px;
  outline: none;
  margin: 10px 0 0 0;
  border-radius: 4px;
}

.adminLpModuleExtTimeBoxWarningSec {
  margin: 10px 0 0 0;
}

#adminLpModuleExtTimeWarningIcon {
  width: 15px;
  height: 15px;
  color: #ffcc00;
  margin: 0 0 4px 0;
}

.adminLpModuleExtTimeBoxWarningSec {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  font-style: normal;
  font-family: "Inter";
  color: rgb(32, 33, 36);
}

.adminLpModuleDueDateInput {
  width: 150px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  line-height: 20px;
  border-radius: 4px;
  font-style: normal;
  margin: 2px 0 0 5px;
  font-family: "Inter";
}

.adminLpModuleDueDateInput:hover {
  border: 1px solid #0c70e4;
}

#adminLpModuleExtTimeBoxSaveBtn {
  background: #f55533;
}

#adminLpModuleExtTimeBoxCancelBtn {
  color: #202124;
  background: #fff;
}

#adminLpModuleExtTimeBoxSaveBtn,
#adminLpModuleExtTimeBoxCancelBtn {
  outline: none;
  margin: 0px 10px 0 0;
  font-family: "Inter";
  border: 1px solid #000000;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  /* Semi-transparent background */
  backdrop-filter: blur(50%) !important;
  /* Apply blur effect to the background */
  z-index: 999;
}

.adminModuleWeightageInput {
  width: 50px;
  border: none;
  text-align: center;
}

.admin-Module-Details-EditIcon-btn {
  padding: 0 !important;
  margin: 0 10px 0 0 !important;
}

.admin-Module-Details-deleteIcon-btn {
  padding: 0 !important;
  margin: 0 10px 0 0 !important;

  svg {
    margin: 0 !important;
  }
}

.course-detail-iconbtn-after-publish {
  padding: 0 !important;
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0 !important;

  svg {
    height: 30px;
    width: 30px;
    margin: 0 0 4px 0;

    path {
      stroke: #344054;
    }

    rect {
      fill: none;
    }
  }
}

.admin-Module-Details-Optional-btn {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  background-color: #f2f4f7;
  color: #344054;
  padding: 2px 8px;
  border-radius: 16px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.admin-Module-Details-Isoptional-btn {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  background-color: #32d583;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 16px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

/* Prevent hiding issues */
.admin-Module-Details-Isoptional-btn,
.admin-Module-Details-Optional-btn {
  visibility: visible !important;
  opacity: 1 !important;
  display: inline-block !important;
}

.Icons {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.adminModuleDetails:hover .Icons {
  opacity: 1;
}
