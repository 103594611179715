@use "../../Abstracts/mixins" as m;
@use "../../Abstracts//Variables/index" as i;

.jobFormMainContainer {
  .MuiPaper-root {
    overflow: hidden;
    padding-bottom: 40px;
    height: calc(100vh - 50px);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: map-get(i.$appBackGroundColor, "grey/50");
  }

  .jobFormDrawer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .jobFormHeaderSec {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
      height: 72px;
      box-shadow: 0px 4px 8px -2px #1018281a;
      background: map-get(i.$appColor, "white");

      .jobFormHeader {
        @include m.fontSize(text/xl/semibold);
        color: map-get(i.$appColor, "grey/700");
      }

      .jobFormHeaderBtn {
        gap: 10px;
        display: flex;
      }
    }

    .jobFormContainer {
      height: 80vh;
      display: flex;
      overflow: auto;
      justify-content: center;

      .jobForm {
        gap: 20px;
        width: 600px;
        display: flex;
        flex-direction: column;

        .inputFieldContainer {
          width: 100%;
        }

        .internshipStartDate {
          gap: 6px;
          display: flex;
          flex-direction: column;

          label {
            margin: 0;
            @include m.fontSize(text/sm/medium);
            color: map-get(i.$appColor, "grey/700");
          }

          .MuiFormControl-root {
            width: 100%;

            .MuiInputBase-root {
              height: 44px;
              outline: none;
              padding: 10px 14px;
              border-radius: 8px;
              border: 1px solid map-get(i.$appColor, "grey/300");
              background: map-get(i.$appBackGroundColor, "white");

              fieldset {
                border: none;
              }
            }

            .MuiInputBase-root:hover {
              border: 1px solid map-get(i.$appColor, "grey/300") !important ;
            }

            .MuiInputBase-root:focus {
              box-shadow: 0px 0px 0px 4px #f4ebff !important;
              border: 1px solid map-get(i.$appColor, "brand/300") !important;
            }
          }
        }

        .jobMode,
        .jobEntry,
        .jobPayment {
          gap: 4px;
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
            padding: 0;
            @include m.fontSize(text/sm/medium);
            color: map-get(i.$appColor, "grey/700");
          }
        }

        .jobPayment {
          flex-direction: row;
          padding-bottom: 10px;

          p {
            padding: 3px 0;
          }
        }

        #jobDescription,
        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
          @include m.fontSize(text/md/regular);
          color: map-get(i.$appColor, "grey/900");
        }

        #jobDescription {
          min-height: 100px !important;
        }

        .jobModeRadioBtn,
        .jobEntryRadioBtn {
          gap: 20px;
          display: flex;
        }

        .startDatePicker {
          .MuiInputBase-root {
            height: 40px;
            padding: 10px;
            color: #333;
            font-size: 14px;
          }

          .MuiFormControl-root {
            label {
              margin: 0;
              font-size: 14px;
              font-weight: 500;
              color: #344054;
              line-height: 20px;
              font-family: Inter;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}
