.paginationCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
}

.next-button {
    position: absolute;
    right: 24px;
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
}

.prev-button {
    position: absolute;
    left: 24px;
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
}

.prev-button:disabled,
.next-buton:disabled {
    color: #6c757d;
    cursor: not-allowed;
}

.page-numbers {
    display: flex;
    align-items: center;
}

.page-number {
    width: 40px;
    height: 40px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    background-color: #ffffff;
    // border-color: #ffffff;
    color: #667085;
    padding: 12px;
    border: none;
    // border-radius: 20px;
}

.page-number.active {
    width: 40px;
    height: 40px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    // vertical-align: middle;
    background-color: #fcebe8;
    border-color: #fcebe8;
    color: #f55533;
    padding: 12px;
    border-radius: 20px;
}

.ellipsis {
    color: #6c757d;
    margin: 0 5px;
}