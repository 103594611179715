@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.userTimeSpentReportMainContainer,
.userTimeSpentReportMainContainerForAdmin {
  height: 86vh;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  margin-top: 12px;
  position: relative;
  scrollbar-width: none;
  justify-content: center;

  .userTimeSpentReportContainer {
    width: 133vh;
  }
}

.userTimeSpentReportMainContainerForAdmin {
  width: 100%;
  height: 70vh;
  overflow-x: auto;
  margin: 12px 23px 0px 23px;

  .userTimeSpentReportContainerForAdmin {
    width: 100%;
  }
}
