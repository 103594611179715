@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.approveSvgIcon {
  cursor: pointer;
  margin-right: 12px;
}

.approveSvgIcon:hover {
  fill: map-get(i.$appColor, "success/600");
}
