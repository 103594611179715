@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.assignmentSubmissionMainContainer {
  border-radius: 20px;
  border: 1px solid map-get(i.$appColor, "grey/200");

  .assignmentSubmissionTable {
    display: flex;
    @include m.fontSize(text/sm/medium);

    .assignmentSubmissionTableHeader {
      min-width: 444px;
      color: map-get(i.$appColor, "grey/900");

      .assignmentSubmissionTableHeaderField,
      .assignmentSubmissionTableHeaderLastField {
        padding: 20px 24px;
        border-bottom: 1px solid map-get(i.$appColor, "grey/200");
      }

      .assignmentSubmissionTableHeaderLastField {
        border: none;
      }
    }

    .assignmentSubmissionTableBody {
      width: 100%;
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/700");

      .assignmentSubmissionTableBodyValue,
      .assignmentSubmissionTableBodyLastValue {
        padding: 20px 24px 20px 0px;
        border-bottom: 1px solid map-get(i.$appColor, "grey/200");
      }

      .assignmentSubmissionTableBodyLastValue {
        border: none;
      }
    }
  }

  .assignmentSubmissionTableFooter {
    height: 43px;
    display: flex;
    padding: 12px 20px;
    border-radius: 0px 0px 20px 20px;
    @include m.fontSize(text/sm/medium);
    color: map-get(i.$appColor, "error/500");
    background: map-get(i.$appBackGroundColor, "error/100");
  }
}
