@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.tooltipContainer {
  position: relative;
  display: inline-block;
  .tooltipBox {
    position: absolute;
    border-radius: 8px;
    z-index: 9999;
    opacity: 0;
    padding: 8px 12px;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
    box-shadow: 0px 4px 6px -2px #10182808;
    // box-shadow: 0px 12px 16px -4px #10182814;
  }

  .tooltipBox.dark {
    background: map-get(i.$appBackGroundColor, "grey/900");
    color: map-get(i.$appColor, "white");
  }
  .tooltipBox.light {
    color: map-get(i.$appColor, "grey/900");
    background: map-get(i.$appBackGroundColor, "white");
  }

  .tooltipArrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
  }

  .tooltipText {
    @include m.fontSize(text/xs/semibold);
  }

  /* Top positions */
  .tooltip.topLeft {
    bottom: 100%;
    left: 0;
    margin-bottom: 12px;
  }

  .tooltip.topCenter,
  .tooltip.top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 12px;
  }

  .tooltip.topRight {
    bottom: 100%;
    right: 0;
    margin-bottom: 12px;
  }

  /* Right positions */
  //   .tooltip.rightTop {
  //     top: 0;
  //     left: 100%;
  //     margin-left: 12px;
  //   }

  .tooltip.right {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 12px;
  }

  //   .tooltip.rightBottom {
  //     bottom: 0;
  //     left: 100%;
  //     margin-left: 12px;
  //   }

  /* Bottom positions */
  .tooltip.bottomLeft {
    top: 100%;
    left: 0;
    margin-top: 12px;
  }

  .tooltip.bottomCenter,
  .tooltip.bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 12px;
  }

  .tooltip.bottomRight {
    top: 100%;
    right: 0;
    margin-top: 12px;
  }

  /* Left positions */
  //   .tooltip.leftTop {
  //     top: 0;
  //     right: 100%;
  //     margin-right: 12px;
  //   }

  .tooltip.left {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 12px;
  }

  //   .tooltip.leftBottom {
  //     bottom: 0;
  //     right: 100%;
  //     margin-right: 12px;
  //   }

  /* Arrow positions matching tooltip position */
  .tooltipArrow.topLeft {
    border-width: 8px 8px 0 8px;
    bottom: -8px;
    left: 10%;
  }

  .tooltipArrow.topCenter,
  .tooltipArrow.top {
    border-width: 8px 8px 0 8px;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .tooltipArrow.topRight {
    border-width: 8px 8px 0 8px;
    bottom: -8px;
    right: 10%;
  }

  .tooltipArrow.topLeft.dark,
  .tooltipArrow.topCenter.dark,
  .tooltipArrow.topRight.dark,
  .tooltipArrow.top.dark {
    border-color: map-get(i.$appColor, "grey/900") transparent transparent
      transparent;
  }

  .tooltipArrow.topLeft.light,
  .tooltipArrow.topCenter.light,
  .tooltipArrow.topRight.light,
  .tooltipArrow.top.light {
    border-color: map-get(i.$appColor, "white") transparent transparent
      transparent;
  }

  /* Right positions */
  //   .tooltipArrow.rightTop {
  //     border-width: 8px 8px 8px 0;
  //     left: -8px;
  //     top: 10%;
  //   }

  .tooltipArrow.right {
    border-width: 8px 8px;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
  }

  //   .tooltipArrow.rightBottom {
  //     border-width: 8px 8px 8px 0;
  //     left: -8px;
  //     bottom: 10%;
  //   }

  .tooltipArrow.right.dark {
    border-color: transparent map-get(i.$appColor, "grey/900") transparent
      transparent;
  }

  .tooltipArrow.right.light {
    border-color: transparent map-get(i.$appColor, "white") transparent
      transparent;
  }

  /* Bottom positions */
  .tooltipArrow.bottomLeft {
    border-width: 0 8px 8px 8px;
    top: -8px;
    left: 10%;
  }

  .tooltipArrow.bottomCenter,
  .tooltipArrow.bottom {
    border-width: 0 8px 8px 8px;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .tooltipArrow.bottomRight {
    border-width: 0 8px 8px 8px;
    top: -8px;
    right: 10%;
  }

  .tooltipArrow.bottomLeft.dark,
  .tooltipArrow.bottomCenter.dark,
  .tooltipArrow.bottomRight.dark,
  .tooltipArrow.bottom.dark {
    border-color: transparent transparent map-get(i.$appColor, "grey/900")
      transparent;
  }

  .tooltipArrow.bottomLeft.light,
  .tooltipArrow.bottomCenter.light,
  .tooltipArrow.bottomRight.light,
  .tooltipArrow.bottom.light {
    border-color: transparent transparent map-get(i.$appColor, "white")
      transparent;
  }

  /* Left positions */
  //   .tooltipArrow.leftTop {
  //     border-width: 8px 0 8px 8px;
  //     right: -8px;
  //     top: 10%;
  //   }

  .tooltipArrow.left {
    border-width: 8px 8px;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
  }

  //   .tooltipArrow.leftBottom {
  //     border-width: 8px 0 8px 8px;
  //     right: -8px;
  //     bottom: 10%;
  //   }
  .tooltipArrow.left.dark {
    border-color: transparent transparent transparent
      map-get(i.$appColor, "grey/900");
  }

  .tooltipArrow.left.light {
    border-color: transparent transparent transparent
      map-get(i.$appColor, "white");
  }
}

.tooltipContainer:hover .tooltipBox {
  opacity: 1;
  visibility: visible;
}
