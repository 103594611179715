@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.hackathonContainer {
  max-width: 832px;
  margin: 101px auto 0 auto;

  .hackathonSection {
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .hackathonHeaderSec {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .hackathonHeader {
        font-size: 24px;
        font-weight: 500;
        text-align: left;
        line-height: 18px;
        font-family: Inter;
        text-decoration-skip-ink: none;
        text-underline-position: from-font;
        color: map-get(i.$appColor, "grey/700");
      }
    }

    .hackathonCardsContainer {
      gap: 28px;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      padding-bottom: 10px;
  
    }
    .no-hackathons {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 120px);
      p {
        color: rgb(102, 112, 133);
        font-size: 24px;
        margin: 0;
      }
    }
  }
}
