@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.userTestResultReportTableMainContainer,
.userTestResultReportTableMainContainerForAdmin {
  height: 87vh;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  position: relative;
  scrollbar-width: none;
  justify-content: center;

  .userTestResultReportTableNoResponse{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .userTestResultReportTableContainer,
  .userTestResultReportTableContainerForAdmin {
    width: 133vh;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px #1018281a;
    background: map-get(i.$appBackGroundColor, "white");

    .userTestResultReportTableLearningPathName {
      gap: 16px;
      padding: 20px 24px 19px 24px;
      @include m.fontSize(text/lg/semibold);
      color: map-get(i.$appColor, "grey/700");
    }

    .userTestResultReportTableTestMainHeader {
      display: flex;
      justify-content: space-between;

      .userTestResultReportTableTestHeader {
        width: 100%;
        display: flex;
        padding: 17px 0px 13px 24px;
        justify-content: space-between;
        @include m.fontSize(text/xs/medium);
        color: map-get(i.$appColor, "grey/700");
        background: map-get(i.$appBackGroundColor, "grey/50");

        .userTestResultReportTableTest {
          width: 100%;
        }

        .userTestResultReportTableTotalPoints {
          width: 20%;
        }

        .userTestResultReportTableMyScore {
          width: 17%;
        }
      }

      .userTestResultReportTablePointsScored {
        width: 24%;
        padding: 16px 0px;
        text-align: center;
        border-radius: 8px;
        @include m.fontSize(text/sm/semibold);
        color: map-get(i.$appColor, "grey/100");
        border: 1px solid map-get(i.$appColor, "grey/200");
        background: linear-gradient(94.9deg, #ff7153 0.38%, #d84829 103.07%);
      }
    }

    .userTestResultReportTableTestBody {
      .userTestResultReportTableTestBodyModuleName {
        padding: 8px 24px;
        @include m.fontSize(text/md/medium);
        color: map-get(i.$appColor, "brand/500");
        background: map-get(i.$appBackGroundColor, "brand/25");
      }

      .userTestResultReportTableTestBodyData {
        display: flex;
        padding: 14px 0px 14px 24px;
        justify-content: space-between;
        border-bottom: 1px solid map-get(i.$appColor, "grey/200");

        .userTestResultReportTableTestBodyDataTestLabelDiv {
          width: 100%;
          display: flex;

          .userTestResultReportTableTestBodyDataTestSvgIcon {
            border-radius: 8px;
            padding: 7px 10px 10px 10px;
            border: 1px solid map-get(i.$appColor, "grey/200");
          }

          .userTestResultReportTableTestBodyDataTestName {
            width: 66%;
            padding-left: 13.67px;
            @include m.fontSize(text/md/medium);
            color: map-get(i.$appColor, "grey/900");

            .userTestResultReportTableTestBodyDataTestNameLabel {
              max-height: 25px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .userTestResultReportTableTestBodyDataTestWeightage,
            .userTestResultReportTableTestBodyDataTestAssignedDate {
              @include m.fontSize(text/sm/regular);
              color: map-get(i.$appColor, "grey/500");
            }
          }

          .userTestResultReportTableTestBodyDataTestMyScore,
          .userTestResultReportTableTestBodyDataTestWeightage,
          .userTestResultReportTableTestBodyDataTestPointsEarned {
            width: 11%;
            margin-top: 10px;
            text-align: center;
            @include m.fontSize(text/sm/regular);
            color: map-get(i.$appColor, "grey/500");
          }

          .userTestResultReportTableTestBodyDataTestMyScore {
            width: 19%;
          }

          .userTestResultReportTableTestBodyDataTestPointsEarned {
            width: 24%;
            @include m.fontSize(text/md/semibold);
            color: map-get(i.$appColor, "grey/700");
          }
        }
      }
    }

    .userTestResultReportTableTestBodyDataTotalTestPointsEarned {
      display: flex;
      justify-content: flex-end;

      .userTestResultReportTableTestBodyDataTotalTestPointsEarnedLabel {
        padding: 22px 48px;
        @include m.fontSize(text/lg/semibold);
        color: map-get(i.$appColor, "grey/700");
      }

      .userTestResultReportTableTestBodyDataTotalTestPointsEarnedValue {
        padding: 24px 90px;
        @include m.fontSize(text/md/semibold);
        color: map-get(i.$appColor, "grey/700");
      }
    }
  }

  .userTestResultReportTableContainerForAdmin {
    width: 100%;
    margin: 20px 23px 0px 23px;
  }
}

.userTestResultReportTableMainContainerForAdmin {
  width: 100%;
  max-height: 72vh;
  overflow-x: auto;
}
