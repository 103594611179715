.langTaps {
  width: 1296px;
  display: flex;
  border-radius: 4px;
  margin: 0 0 0 23px;
  background: #ffffff;
  flex-direction: column;
  border: 1px solid #bdc1c6;
}

.languages {
  float: left;
  display: inline-flex;
}

.langLists {
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  padding-bottom: 10px;
  font-family: "Inter";
  margin: 8px 0px 0px 26px;
}

.langLists.active {
  color: #f55533;
  border-bottom: 1px solid #f55533;
}

.langLine {
  height: 1px;
  width: 1263px;
  margin: 0 0 0 18px;
  background: #d9d9d9;
}

.boilerplateCode {
  float: left !important;
  text-align: left !important;
  margin: 20px 0 0 18px !important;
}

.boilerplateCode label {
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter";
  margin: 10px 0 6px 0;
}

.labelAndBoilerplate {
  width: 700px;
  display: flex;
}

.generatetBoilerplate {
  margin-left: 50px;
  margin-bottom: 20px;
}

.generatetBoilerplate .btn.btn-primary {
  border: none;
  color: #ffffff;
  border-radius: 4px;
  background: #f55533;
}

.solutionCode {
  float: left;
  text-align: left;
  margin: 20px 0 20px 18px;
}

.solutionCode label {
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  margin: 0 0 6px 0;
  font-style: normal;
  font-family: "Inter";
}

.testBtn {
  float: left;
  margin: 20px 0 15px 18px;
}

.testBtn button {
  width: 69px;
  height: 34px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #f55533;
}
