@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

div.Header {
  top: 0px;
  margin: 0;
  left: 0px;
  padding: 0;
  z-index: 6;
  width: 100%;
  height: 54px;
  display: flex;
  position: fixed;
  color: #ffffff;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
}

#logInName {
  color: #ffffff;
}

div > div > span#adminHeaderLogoName {
  top: 5px;
  left: 34px;
  width: 136px;
  height: 26px;
  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  position: absolute;
  font-style: normal;
  font-family: "Inter";
}

.appVersion {
  display: flex;
  font-size: 8px;
  font-weight: 400;
  color: #0c0c0c;
  position: relative;
  font-style: normal;
  font-family: "Inter";
}

#adminHeaderLogo {
  left: 1vw;
  z-index: 2;
  height: 35px;
  display: flex;
  cursor: pointer;
  text-align: left;
  position: relative;
  align-items: center;
}

.adminHeaderBatchName {
  width: 220px;
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  line-height: normal;
  font-style: normal;
  white-space: nowrap;
  margin: 0px 0 0 5px;
  font-family: "Inter";
  text-overflow: ellipsis;
}

div.Pages {
  gap: 3vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home {
  top: 10px;
  height: 33px;
  font-size: 12px;
  margin: 0px 30px;
  font-weight: 600;
  color: #ffffff;
  line-height: 14px;
  text-align: center;
  position: relative;
  font-style: normal;
  letter-spacing: 1px;
  padding-bottom: 16px;
  font-family: "Inter";
  text-transform: uppercase;
  text-decoration-line: none;
}

.tests {
  order: 1;
  top: 10px;
  height: 33px;
  font-size: 12px;
  font-weight: 600;
  margin: 0px 30px;
  color: #ffffff;
  line-height: 14px;
  position: relative;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 16px;
  font-family: "Inter";
  text-transform: uppercase;
  text-decoration-line: none;
}

.live,
.dashboard,
.questions,
.learningPaths,.jobs,.hackathons {
  display: block;
  font-size: 14px;
  color: #344054;
  font-weight: 500;
  text-align: left;
  line-height: 20px;
  font-family: Inter;
  text-transform: uppercase;
  text-decoration-line: none !important;
}

.dashboard {
  order: 1;
}

.questions:hover .headerDropdownContent {
  display: block;
}

.candidate {
  order: 3;
  top: 10px;
  height: 33px;
  font-weight: 700;
  margin: 0px 30px;
  color: #ffffff;
  font-size: 2.24vh;
  line-height: 14px;
  position: relative;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 16px;
  font-family: "Inter";
  text-transform: uppercase;
  text-decoration-line: none;
}

.batches {
  order: 4;
  top: 10px;
  height: 14px;
  font-weight: 700;
  color: #ffffff;
  margin: 0px 30px;
  font-size: 2.24vh;
  line-height: 14px;
  position: relative;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Inter";
  padding-bottom: 16px;
  text-transform: uppercase;
  text-decoration-line: none !important;
}

#reports {
  order: 5;
  top: 10px;
  height: 14px;
  font-weight: 700;
  margin: 0px 30px;
  font-size: 2.24vh;
  line-height: 14px;
  position: relative;
  font-family: Inter;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration-line: none;
}

#invite {
  order: 5;
  top: 10px;
  height: 33px;
  margin: 0px 30px;
  font-weight: 700;
  line-height: 14px;
  font-size: 2.24vh;
  position: relative;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Inter";
  text-transform: uppercase;
  text-decoration-line: none;
}

.tests {
  order: 6;
  top: 10px;
  height: 33px;
  font-size: 12px;
  font-weight: 600;
  margin: 0px 30px;
  color: #ffffff;
  line-height: 14px;
  position: relative;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Inter";
  padding-bottom: 16px;
  text-transform: uppercase;
  text-decoration-line: none;
}

.users {
  order: 5;
  top: 10px;
  height: 33px;
  font-size: 12px;
  font-weight: 600;
  margin: 0px 30px;
  color: #ffffff;
  line-height: 14px;
  position: relative;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 16px;
  font-family: "Inter";
  text-transform: uppercase;
  text-decoration-line: none;
}

#profilepic {
  top: 15%;
  width: 30px;
  right: 2.2%;
  left: 95.61%;
  height: 30px;
  padding: 1px;
  bottom: 94.87%;
  border-width: 5px;
  position: absolute;
  border-radius: 50px;
}

.home:hover,
.live:hover,
.tests:hover,
.users:hover,
.invite:hover,
.batches:hover,
.questions:hover,
.candidate:hover,
.dashboard:hover,
.learningPaths:hover,.jobs:hover,
.hackathons:hover {
  color: #f55533;
  text-decoration: none;
  transition: color 0.5s ease;
}

.hover_trans {
  color: #f55533;
  position: relative;
  display: inline-block;
}

.hover_trans:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  line-height: 20px;
  font-family: Inter;
  text-transform: uppercase;
  text-decoration-line: none;
  color: #f96343 !important;
}

.adminNotifyBadge {
  top: 12px;
  right: 66px;
  float: right;
  height: 15px;
  min-width: 8px;
  max-width: 20px;
  font-weight: 400;
  position: absolute;
  border-radius: 24px;
  font-size: 12px !important;
}

.adminNotifyCount {
  right: 10px;
  bottom: 4px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  position: relative;
  font-style: normal;
  font-family: "Inter";
}

.adminNotify-container {
  width: 300px;
  box-shadow: inset 0px -1px 0px #e4e5e7;
}

.adminNotifyHead {
  margin: 0;
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  font-style: normal;
  font-family: "Inter";
  box-shadow: inset 0px -1px 0px #bdc1c6;
}

.adminNotifyContent {
  margin: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  font-style: normal;
  font-family: "Inter";
}

.adminNotifyHrLine {
  margin: 0;
  padding: 0;
}

.adminNotificationSec .notifications .container {
  padding: 0;
  right: 10px;
  width: 400px;
  cursor: default;
}

.notifications .icon .image {
  border-radius: 20px !important;
}

.notifications .header {
  display: flex;
  background: #e9e9e9;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
}

.notifications .container .header .header-title {
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  font-weight: 700 !important;
  color: #000000 !important;
}

.notifications .container .header .header-option:hover {
  cursor: default !important;
  text-decoration: none !important;
}

.adminNotificationActionSec {
  display: flex;
  justify-content: space-between;
}

.notifications .card {
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-bottom: 5px;
}

.notifications .card:hover {
  background: #f2f3f5;
}

.notifications .card .card-link .image {
  display: none;
}

.notifications .card .card-link .time {
  margin-top: 0;
  margin-left: 50px;
}

.adminNotificationSec .dropdown-toggle::after {
  display: none !important;
}

.adminNotificationUserImg {
  width: 35px;
  float: left;
  height: 35px;
  color: #fff;
  font-size: 14px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-family: "Inter";
  background: #f55533;
  margin: -2px 5px 0 0px;
}

.adminNotificationMark,
.adminNotificationClear {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 20px;
  padding-bottom: 0;
  font-style: normal;
  margin-right: 10px;
  font-family: "Inter";
}

.adminNotificationMark:hover,
.adminNotificationClear:hover {
  text-decoration: underline;
}

.adminNotificationMsg {
  font-size: 12px;
  color: #202124;
  font-weight: 400;
  line-height: 16px;
  font-style: normal;
  font-family: "Inter";
  margin: 5px 0 0 50px;
}

.notifications .card .content .message {
  margin: 0 !important;
}

.notifications .empty-notifications {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.dropdown .dropbtn {
  margin: 0;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  padding: 14px 16px;
  font-family: inherit;
  background-color: inherit;
}

.headerDropdownContent {
  z-index: 5;
  margin: 0px;
  display: none;
  padding-top: 10px;
  width: max-content;
  position: absolute;
  border-radius: 10px;
  padding-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 32px 64px -12px #10182824;
}

.headerDropdownContent .libtypediv {
  gap: 10px;
  display: flex;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  padding-top: 10px;
  font-family: Inter;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  margin: 0px !important;
  text-transform: math-auto;
}

.headerDropdownContent .libtypediv:hover {
  background-color: #f2f4f7;
}

#libraryTestTitle {
  height: 20px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #344054;
  line-height: 20px;
  margin-left: 12px;
  position: relative;
  font-family: Inter;
  margin-bottom: 10px;
  text-transform: math-auto;
}

#questions {
  cursor: pointer;
}

.Header .nav-item.dropdown {
  margin-right: 1vw;
}

.adminHeaderLogoWithVersion {
  display: flex;
  font-size: 10px;
  flex-direction: row;
  align-items: flex-end;
}

.nav-item.dropdown > a > img {
  width: 32px;
  height: 32px;
  border-radius: 10px;
}

li > .logInProfile1 {
  width: 32px;
  height: 32px;
  border-radius: 10px;
}

.adminHeaderRightSec{
  display: flex;
  align-items: center;
  gap:10px;
}