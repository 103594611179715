.profileOnPopup{
    display: flex;
    gap: 16px;
    width: 100%;
    // align-items: center;
    // justify-content: center;
}
.detailCont{
    // height: calc(100vh - 150px);
    height: calc(100vh - 190px);
    overflow-y: auto;
}