@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;
#profileUploadProfileCard {
  min-height: 275px;
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  box-shadow: none;
  #profileUploadProfile {
    height: 200px;
  }
}
.profileUploadActionContainer {
  display: flex;
  flex-direction: column;
  gap:8px;
  #profileUploadProfileActionSec {
    display: flex;
    justify-content: center;
    position: relative;
    .profileUploadProfileUploadBtn {
      height: 46px;
      position: absolute;
      opacity: 0;
      // left: 90px;
      width: 100%;
      cursor: pointer;
    }
  }
  #profileUploadProfileContentSec {
    padding: 0;
    margin: 0;
    #profileUploadProfileCaption {
      @include m.fontSize(text/xs/regular);
      color: map-get(i.$appColor, "grey/500");
      text-align: center;
    }
  }
}
