@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.codingPageMainContainer,
.codingPageMainContainerFullScreen {
  width: 100%;
  padding-top: 4px;
  overflow: hidden;
  height: calc(100vh - 52px);
  background: map-get(i.$appBackGroundColor, "grey/100");

  .codingpageVerticalSplitPane {
    .codingProblemPane {
      min-width: 200px;
      max-width: 504px;
      overflow-y: auto;
      border-radius: 8px;
      scrollbar-width: none;
      margin: 0px 4px 8px 24px;
      min-height: calc(100vh - 64px);
      border: 1px solid map-get(i.$appColor, "grey/300");
      background: map-get(i.$appBackGroundColor, "white");
    }
  }

  .codingpageHorizontalSplitPane {
    .codingEditorPane,
    .codingEditorPaneDarkTheme {
      min-width: 802px;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 8px;
      margin: 0 24px 0 0;
      scrollbar-width: none;
      min-height: 300px !important;
      border: 1px solid map-get(i.$appColor, "grey/300");
      background: map-get(i.$appBackGroundColor, "white");
    }

    .codingEditorPaneDarkTheme {
      background: map-get(i.$appBackGroundColor, "grey/900");
    }

    .codingOutputPane {
      min-width: 802px;
      overflow-y: auto;
      border-radius: 8px;
      margin: 0 24px 8px 0;
      scrollbar-width: none;
      min-height: calc(100vh - 592px) !important;
      border: 1px solid map-get(i.$appColor, "grey/300");
      background: map-get(i.$appBackGroundColor, "white");
    }
  }

  .cjjWdp {
    border: none;
    position: relative;
    opacity: 0 !important;
    width: auto !important;
    padding: 5px 8px !important;
  }

  .cjjWdp:hover {
    border: none !important;
    transition: none !important;
  }

  .hEACtv {
    cursor: row-resize;
    margin: 0px !important;
    background-color: #f2f4f7 !important;
  }
}

.codingPageMainContainerFullScreen {
  padding: 0;
  display: contents;

  .codingpageHorizontalSplitPane {
    height: 100vh;

    .codingEditorPane {
      margin: 0;
      border: none;
    }
  }
}
