@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.instructionPageMainContainer {
  text-align: left;
  margin: 20px 0 0 52px;

  .instructionPage {
    height: 75vh;
    overflow-y: scroll;
    padding-right: 52px;
    scrollbar-width: none;

    .instructionPageHeader,
    .instructionPageSubHeader {
      @include m.fontSize(text/md/semibold);
      color: map-get(i.$appColor, "grey/800");
    }

    .instructionPageBodyList {
      @include m.fontSize(text/sm/regular);
      line-height: 28px;
      margin-bottom: 0px;

      .instructionPageBodyListUnordered {
        list-style-type: none;

        .instructionPageBodyUnorderedList {
          display: flex;
          align-items: center;

          .instructionPageBodyUnorderedListViewLegend,
          .instructionPageBodyUnorderedListReviewLegend,
          .instructionPageBodyUnorderedListNotViewLegend,
          .instructionPageBodyUnorderedListAnsweredLegend {
            width: 24px;
            height: 24px;
            margin-top: 12px;
            border-radius: 50%;
            background: map-get(i.$appBackGroundColor, "grey/400");
          }

          .instructionPageBodyUnorderedListViewLegend {
            background: map-get(i.$appBackGroundColor, "warning/400");
          }

          .instructionPageBodyUnorderedListReviewLegend {
            background: map-get(i.$appBackGroundColor, "error/400");
          }

          .instructionPageBodyUnorderedListAnsweredLegend {
            margin-bottom: 20px;
            background: map-get(i.$appBackGroundColor, "success/400");
          }

          .instructionPageBodyUnorderedListLegendText,
          .instructionPageBodyUnorderedListLegendAnsweredText {
            margin: 12px 0 0 12px;
            @include m.fontSize(text/sm/regular);
            color: map-get(i.$appColor, "grey/900");
          }

          .instructionPageBodyUnorderedListLegendAnsweredText {
            margin: 0px 0 10px 12px;
          }
        }
      }
    }

    .instructionPageWebCamDiv {
      display: flex;
    }

    .instructionPageWebCam {
      height: 201px;
      margin-left: 20px;
      border-radius: 8px;
    }

    .additionalInstructionDiv {
      .instructionPageSubHeader {
        margin-top: 20px;
      }

      .tox-tinymce {
        border: none;
      }
    }

    .instructionPageFooter {
      display: flex;
      margin-bottom: 60px;
      justify-content: flex-end;

      .instructionPageFooterButton {
        margin: 10px 18px;
        color: map-get(i.$appColor, primary);
        @include m.fontSize(text/md/semibold);
      }
    }
  }
}

.instructionPageBodyListInnerText {
  font-weight: 500;
}
