@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.ccContainer {
  width: 100%;
  display: flex;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 80px;
  box-sizing: border-box;
  justify-content: center;
  height: calc(100vh - 40px);

  .ccSection {
    gap: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .ccTitle {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    // margin-top: 10px;
    line-height: 18px;
    font-family: Inter;
    text-decoration-skip-ink: none;
    text-underline-position: from-font;
    color: map-get(i.$appColor, "grey/700");
  }

  .ccSectionsContainer {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    max-height: calc(100vh - 64px);
    overflow: auto;
  }
}
