@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.bdBatchDetailSec {
  padding-top: 7vh;
}

.bdBatchDetails {
  margin: 0 20px;
  border-radius: 4px;
  padding-bottom: 20px;
  background: #f2f3f5;
  box-shadow: inset 0px -1px 0px #e4e5e7;
}

.bdInBatchName {
  height: 14px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
  padding: 29px 0px 15px 21px;
}

.bdInBatchDetHead {
  display: flex;
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Inter";
}

.bdInBatchStartDateHead {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
  margin: 8px 0 5px 20px;
}

.bdInBatchStartDate {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 17px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
  margin: 8px 0 5px 5px;
}

.bdUserHeadSecIn {
  float: right;
  bottom: 55px;
  margin-left: auto;
  position: relative;
}

.upload-btn-wrapper {
  position: relative;
}

.upload-btn-wrapper input[type="file"] {
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
}

.bdUserUploadIconBtn {
  width: 37px;
  height: 34px;
  border: none;
  border-radius: 4px;
  margin: 0 -5px 25px 0;
  background: #e4e5e7;
}

#bdUserUploadIcon {
  width: 12px;
  height: 12px;
  color: #26344a;
}

.addUserBtn {
  display: inline-block;
  width: 160px;
}

#bdAddUserBtn {
  height: 34px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 7px 20px;
  line-height: 20px;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #0d6efd;
  margin: -5px 26px 0 -20px;
}

#UserDetailsContainer {
  width: 40%;
  margin: 5px 0;
  padding-bottom: 24px; 
  height: 92vh;
  overflow: auto;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ffffff;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  .tox-editor-header{
    z-index :1 !important;
  }
}

.addUsersTitleSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.adduserTitle {
  position: relative;
  top: 21px;
  height: 27px;
  margin-left: 20px;
  margin-bottom: 20px;
  // font-size: 22px;
  // font-weight: 700;
  // color: #000000;
  // line-height: 27px;
  // font-style: normal;
  // font-family: "Inter";
  //styleName: Text lg/Medium;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #101828;


}

.selectTypeOfadd {
  position: relative;
  display: flex;
  margin-left: 20px;
  margin-bottom: 20px;
  gap: 24px;
}

.userFormDialogContainer {
  .MuiDialog-container {
    .MuiPaper-root {
      padding: 24px;
      width: 800px;
      border-radius: 10px;
    }
  }
}

.buttonContainerUserdetails {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  flex-direction: row-reverse;
  padding-right: 24px;
}

.scrollableUserDetailsSection {
  overflow: auto;
}

.addUserCloseIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: #202124;
  margin: 25px 27px 26px 0;
}

.addUserHrLine {
  margin: 0;
  height: 0px;
  border: 1px solid #e4e5e7;
}

.addUserform {
  width: 90%;
  margin: 22px 25px;
}

.nameContainer {
  display: flex;
  gap: 24px;
}

.branchndCollege {
  display: flex;
  gap: 24px;
}

#roleLabel,
#userEmailLabel,
#userPhoneLabel,
#userBranchLabel,
#userCollegeLabel,
#userLastNameLabel,
#userFirstNameLabel {
  order: 0;
  flex: none;
  flex-grow: 0;
  height: 14px;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
}

#usersEmail,
#usersPhone {

  // order: 1;
  // margin: 0;
  // gap: 10px;
  // flex: none;
  // width: 60%;
  // height: 32px;
  // flex-grow: 0;
  // display: flex;
  // font-size: 14px;
  // font-weight: 400;
  // color: #000000;
  // line-height: 20px;
  // border-radius: 4px;
  // font-style: normal;
  // flex-direction: row;
  // align-items: center;
  // font-family: "Inter";
  // background: #ffffff;
  // box-sizing: border-box;
  // padding: 7px 0px 7px 10px;
  // border: 1px solid #bdc1c6;
  @include m.fontSize(text/md/regular);
  color: map-get(i.$appColor, 'grey/900');
  // width: 50%;

}

.multiuploadbtnContainer {
  display: flex;
  position: relative;
  gap: 24px;
  margin-bottom: 40px;
}

.uploadUserstFileInput {
  opacity: 0;
  // width: 250px;
  top: 19%;
  // left: 25%;
  // height: 236px;
  bottom: 100px;
  cursor: pointer;
  position: absolute;
  width: 100%;
}

#usersFirstname,
#usersLastname,
#usersBranch,
#usersCollege {
  @include m.fontSize(text/md/regular);
  color: map-get(i.$appColor, 'grey/900');
}

.inputFieldUserContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

// .MuiTextField-root .MuiInputBase-input{
//   height: 44px;
// }


// #userBranch,
// #userCollege {
//   order: 1;
//   margin: 0;
//   gap: 10px;
//   flex: none;
//   width: 100%;
//   height: 32px;
//   flex-grow: 0;
//   display: flex;
//   font-size: 14px;
//   font-weight: 400;
//   color: #000000;
//   line-height: 20px;
//   border-radius: 4px;
//   font-style: normal;
//   flex-direction: row;
//   align-items: center;
//   font-family: "Inter";
//   background: #ffffff;
//   box-sizing: border-box;
//   padding: 7px 0px 7px 10px;
//   border: 1px solid #bdc1c6;
// }

#batchDesLabel {
  height: 14px;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  margin-left: 20px;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
  justify-content: space-between;
}

.line {
  margin: 2px 0 10px;
}

#saveBatchBtn {
  border: none;
  background: #f55533;
}

#saveBatchBtn:hover {
  border: none;
  background: rgba(248, 136, 112, 0.97);
}

#cancelBatchBtn {
  color: #f55533;
  margin: 0 0 0 20px;
  background: #ffffff;
  border: 1px solid #f55533;
}

#cancelBatchBtn:hover {
  color: #f55533;
  background: #f1dede;
  border: 1px solid #f55533;
}

.bdBatchThumbnailSec {
  border-radius: 4px;
  padding-bottom: 3px;
  margin: 8px 20px 0px;
  background: #f2f3f5;
  box-shadow: inset 0px -1px 0px #e4e5e7;
}

.bdBatchThumbnailHead {
  height: 14px;
  display: flex;
  padding: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
}

.bdBatchThumbnailDetail {
  display: flex;
  margin: 0 20px 20px 20px;
}

.bdBatchThumbnail {
  width: 280px;
  height: 185px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #c5c8cd;
}

.bdCourseDetails,
.bdInBatchDetails {
  display: flex;
  /* margin-bottom: -4px; */
  flex-direction: row;
}

.bdModuleName {
  font-size: 14px;
  font-weight: 700;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.bdTopicName {
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  margin-left: 5px;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.bdChangeThumbnailBtn {
  order: 0;
  flex: none;
  height: 34px;
  flex-grow: 0;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 20px;
  padding: 7px 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  align-self: flex-end;
  background: #0d6efd;
  align-items: flex-end;
}

.bdCourseName,
.bdTrainers,
#bdDetailsSettingTitle {
  height: 14px;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  line-height: 14px;
  font-style: normal;
  align-items: center;
  font-family: "Inter";
  margin: 10px 0 10px 20px;
}

.bdDetailsMaintainOrderSec {
  margin-left: 30px;
}

.bdDetailsMaintainOrderSec label .MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
  font-family: "Inter" !important;
}

#bdDetailsMaintainOrderCaption {
  margin: 0;
  padding: 0;
  bottom: 10px;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-left: 30px;
  font-style: normal;
  position: relative;
  font-family: "Inter";
}

.bdCourseNameInput {
  width: 246px;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding-left: 10px;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #ffffff;
  box-sizing: border-box;
  margin: 3px 192px 0 10px;
  border: 1px solid #bdc1c6;
}

.bdCourseNameInput::placeholder {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #bdc1c6;
  line-height: 20px;
  padding-left: 5px;
  font-style: normal;
  font-family: "Inter";
}

#courseLearningPathSelect {
  font-family: "Inter";
}

.bdCheckIcon {
  width: 15px;
  margin: 10px;
  padding: 2px;
  cursor: pointer;
  color: #04b462;
  border: 1px solid;
  border-radius: 11px;
}

.bdCheckIcon:hover {
  color: #05b160;
  transition: color 0.5s ease;
}

.archivebatchswitch-div {
  margin: 0 20px;
  display: flex;
}

.add-user-select-role-option {
  display: flex;
}

#add-user-select-role {
  display: flex;
  align-items: center;
  gap: 1vw;
}

#archive-batch-switch {
  box-shadow: none;
}

#bdUploadUserFile {
  color: #000000;
  font-size: 14px;
  font-family: "Inter";
}

.bdUploadUserWarningMsgForEmailTextSec {
  margin: 10px 0;
  display: flex;
}

#bdUploadUserWarningMsgForEmailIcon {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 3px 0 0 0;
}

.bdUploadUserWarningMsgForEmailText {
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 2px 0 0 10px;
}

.bdUserUploadModalBtn {
  margin-top: 10px;
  float: right;
}

#bdUserUploadModalSaveBtn {
  display: inline-flex;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #f55533;
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
}

#bdUserUploadModalCancelBtn {
  display: inline-flex;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #e5e5ea;
  color: #373739;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.trainerData {
  display: flex;
  flex-direction: column;
  position: relative;
}

.allTrainers {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 10px 0 10px 30px;
}

.tritem {
  position: relative;
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 5px;
}

.tritem .faXmark {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}