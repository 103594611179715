@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.testLabel {
  width: 23%;
  padding: 1% 0;
  text-align: right;

  .takeTestLabel {
    @include m.fontSize(text/sm/semibold);
    color: map-get(i.$appColor, "success/500");
  }

  .conductTrainerLabel {
    @include m.fontSize(text/sm/semibold);
    color: map-get(i.$appColor, "error/600");
  }

  .continueLabel {
    @include m.fontSize(text/sm/semibold);
    color: map-get(i.$appColor, "brand/700");
  }

  .retakeTestLabel {
    @include m.fontSize(text/sm/semibold);
    color: map-get(i.$appColor, "error/700");
  }
}
