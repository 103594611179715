@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.menuBarFooterMainContainer {
  width: 231px;
  bottom: 24px;
  position: fixed;
  padding: 0px 16px;

  .menuBarFooterMenuList {
    .css-jun1iy {
      margin-right: 12px;
    }

    .ps-menu-button {
      height: 40px;
      padding: 0px 8px;
      margin-bottom: 6px;
      border-radius: 8px;
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appBackGroundColor, "grey/500");
    }

    .ps-menu-button:hover {
      background: map-get(i.$appBackGroundColor, "grey/100");

      .css-12w9als {
        display: flex;
        align-items: center;
      }
    }

    .ps-menu-button.ps-active {
      background: map-get(i.$appBackGroundColor, "brand/25");

      .menuSvgIconForBatches path,
      .menuSvgIconForInterview path,
      .menuSvgIconForHackathon path {
        fill: map-get(i.$appBackGroundColor, "brand/500");
      }

      svg path {
        stroke: map-get(i.$appBackGroundColor, "brand/500");
      }
    }
  }

  .menuBarFooterSubMenu {
    top: 100%;
    width: 199px;
    bottom: auto;
    @include m.fontSize(text/md/medium);

    .ps-submenu-content {
      display: none;
    }

    .ps-submenu-content.ps-open {
      top: auto;
      z-index: 1;
      bottom: 100%;
      height: 50px;
      width: 199px;
      display: block;
      position: absolute !important;
      border-radius: 8px 8px 0px 0px;
      box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1), 2px 0 10px rgba(0, 0, 0, 0.1);
    }

    .ps-menu-button {
      padding: 0 8px;
      border-radius: 8px;

      .menuBarFooterProfileImg {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }

    .ps-menu-button:hover {
      background: map-get(i.$appBackGroundColor, "grey/100");
    }

    .ps-menu-button.ps-active {
      border-radius: 8px;
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/700");
      background: map-get(i.$appBackGroundColor, "grey/100");
    }
  }

  .menuBarFooterSubMenu.ps-open {
    border-radius: 0px 0px 8px 8px;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.1),
      0 4px 8px rgba(0, 0, 0, 0.1);
  }
}
