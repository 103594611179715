.descuploadlibTaps {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  font-style: normal;
  padding: 0 0 0 15px;
  font-family: "Inter";
  margin: 5px 0px 0 0px;
  text-decoration: none;
  border-bottom: #000000 !important;
}

.addBtnToAddTopicAndComplexity {
  top: 18px;
  left: 20px;
  display: flex;
  position: relative;
  text-align: justify;
  justify-content: flex-start;
}

.selectEvalRulesinSaq {
  width: 350px;
  margin: 0px 0 0 23px;
}

.selectEvalRulesinSaq > label {
  width: 100px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  position: relative;
  font-style: normal;
  margin-right: 280px;
  font-family: "Inter";
}

.plusIcontoAddOutputArea {
  left: 910px;
  display: flex;
  cursor: pointer;
  position: absolute;
}

.cancelOutputforSaq {
  top: -20px;
  left: 870px;
  display: flex;
  position: relative;
}

.descerrors {
  color: red;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-family: "Inter";
}

.radiobtnforDesc {
  gap: 130px;
  width: 100%;
  display: flex;
  font-size: 14px;
  align-items: center;
  font-family: "Inter";
  margin: 0 0 25px 23px;
}

.singleSelectforDesc {
  float: left;
  width: 100px;
}

.singleSelectforDesc > label {
  margin: 0 0 0 15px;
}

.question_updatebtn {
  float: left;
  margin: 34px 10px 0 25px;
}

.question_updatebtn button {
  border: none;
  width: 100px;
  height: 34px;
  color: #ffffff;
  border-radius: 4px;
  background: #f55533;
}

.desc_fileupload {
  height: 0;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px 0px 0px;
}

.descUploadFileName {
  margin: 15px 0 0 0;
}

.desccontentUploadIcon {
  color: #1475cf;
  margin: 0 8px 0 0;
}

.descquestion_langname {
  float: left;
  width: 862px;
  height: 52px;
  text-align: left;
  margin: 25px 0 0 23px;
}

.descquestion_langname > label {
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 6px;
  font-family: "Inter";
}

#descquestion_langname {
  gap: 10px;
  width: 862px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

.descquestion_description {
  width: 99%;
  float: left;
  text-align: left;
  margin: 25px 0 0 23px;
}

.descquestion_description > label {
  width: 68px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}

.desceditor-tinymce {
  float: left;
  width: 862px;
  margin: 0px 0 17px 23px;
}

.saqlaq_answer {
  float: left;
  width: 862px;
  text-align: left;
  margin: 25px 0 25px 23px;
}

.saqlaq_answer > label {
  width: 100%;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}

#saqlaq_answer {
  resize: none;
  width: 862px;
  border-radius: 4px;
  background: #ffffff;
  min-height: 95px !important;
  border: 1px solid #bdc1c6;
}

.desccontent_fileupload {
  width: 35%;
  float: left;
  height: 21px;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  margin: 25px 0 0 23px;
  flex-direction: column;
  justify-content: center;
}

.descquestion-navItem > .nav-link {
  color: #f55533 !important;
  background-color: #efefef;
}

.descquestion-navItem .active {
  color: #ffffff !important;
  background-color: #f55533 !important;
}

.descshowinglist {
  width: 36%;
  float: left;
  height: auto;
}

.descfile-lists > li {
  display: flex;
  margin: 1.2em 0;
  list-style: none;
  border-radius: 5px;
  align-items: center;
  padding: 1.2em 1.5em;
  background-color: #f55e3038;
}

.descfile-lists > li > svg {
  color: #f55321;
}

.descfile-lists > li > svg:first-child {
  font-size: 1.2em;
  margin-right: 0.8em;
}

.desclistsName {
  flex: 1;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  font-size: 0.9em;
  color: #000000;
  font-family: "Inter";
  text-decoration: none;
}

.desclistsName:hover {
  color: #0d6efd;
  text-decoration: underline;
}

.desctrashIconforDeleteFile {
  color: #f55321;
}

.desccheckisDownload {
  margin: 0 0 0 20px;
}

.descquestion_savebtn {
  right: 90%;
  width: 100%;
  float: right;
  position: relative;
  margin: 34px 10px 0 0;
}

.descquestion_savebtn > button {
  float: right;
}

.descquestion_savebtn button {
  position: relative;
  border: none;
  width: 100px;
  width: 120px; 
  height: 34px; 
  left: 20px;
  color: #ffffff;
  border-radius: 4px;
  background: #f55533;
}

#descaddLibrary {
  width: 75px;
  height: 30px;
  float: right;
  padding: 0 20px;
  border: #f55533;
  border-radius: 4px;
  background: #f55533;
  margin: 5px 5px 5px 5px;
}
