@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.raisedIssueContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  padding: 0px 70px 40px 70px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  .raisedIssueHeaderSec {
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      gap: 10px;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
        @include m.fontSize(text/xl/medium);
        color: map-get(i.$appColor, "grey/900");
      }
    }
  }
  .raisedIssueTableContainer {
    height: calc(100vh - 94px);
    .tableData.status {
      p {
        text-transform: capitalize;
      }
      .pending {
        color: map-get(i.$appColor, "error/500");
      }
      .resolved {
        color: map-get(i.$appColor, "success/600");
      }
    }

    .tableData.serialNo,
    .tableHead.serialNo {
      width: 60px;
    }
    .tableData.category,
    .tableHead.category,
    .tableData.batchname,
    .tableHead.batchname,
    .tableData.summary,
    .tableHead.summary {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tableHead.description,
    .tableData.description {
      max-width: 265px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tableHead.status,
    .tableData.status {
      width: 140px;
    }
  }
  .modalContent {
    gap: 12px;
    width: 806px;
    height: calc(100vh - 4vh);
    margin: auto;
    padding: 14px 24px;
  }
  .modalBodyContainer {
    height: inherit;
    overflow: auto;
  }
  .modalBodyContent {
    gap: 0;
  }
}
.raisedIssueFormModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  .raisedIssueFormModal {
    background: white;
    width: 806px;
    padding: 14px 24px;
    border-radius: 12px;
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    display: flex;
    gap: 12px;
    max-height: 96vh;
    flex-direction: column;
    .modalTitle {
      margin: 0;
    }
  }
}
