@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.assignmentPage,
.assignmentPageForAdmin {
  height: 81vh;
  overflow-y: auto;
  padding: 24px 52px;

  .assignmentPageDescription {
    .tox-tinymce {
      border: none;
    }

    .assignmentPageSubmissionHeading {
      margin: 24px 0 0 0;
      @include m.fontSize(text/lg/semibold);
      color: map-get(i.$appColor, "grey/700");
    }
  }

  .assignmentPageReferenceLink {
    margin-top: 20px;

    .assignmentPageReferenceLinkDocument .react-pdf__Document {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    // .assignmentPageReferenceLinkDocument .react-pdf__Page {
    //   max-width: 100%;
    // }

    // .assignmentPageReferenceLinkDocument .react-pdf__Page canvas {
    //   // max-width: 372px;
    //   // height: 211px !important;
    //   height: 100vh !important;
    // }

    // .assignmentPageReferenceLinkDocument .react-pdf__Document.fullScreen {
    //   align-items: center;
    //   width: 100% !important;
    //   overflow: auto !important;
    // }
    // .fullScreen {
    //   overflow: auto !important;
    //   // display: flex;
    //   // justify-content: center;
    //   // flex-direction: column;
    // }
    // .react-pdf__Page {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    // }

    // .assignmentReferenceLinkDocument.react-pdf__Document.fullScreen
    //   .react-pdf__Page {
    //   margin: 0;
    // }

    // .assignmentReferenceLinkDocument.react-pdf__Document.fullScreen
    //   .react-pdf__Page
    //   canvas {
    //   max-width: 100%;
    //   height: auto !important;
    // }

    .assignmentPageReferenceLinkImageDownloadDiv {
      display: flex;

      .assignmentPageReferenceLinkImage {
        width: 523px;
        height: 261px;
        border-radius: 10px;
      }
    }

    .assignmentPageReferenceLinkPdfWrapper {
      display: flex;
      padding: 24px 20px;
      border-radius: 20px;
      justify-content: space-between;
      border: 1px solid map-get(i.$appColor, "grey/200");

      .assignmentPageReferenceLinkPdfDiv {
        flex-grow: 1;
        margin-left: 16px;

        .assignmentPageReferenceLinkPdfDivName,
        .assignmentPageReferenceLinkPdfDivLabel {
          margin: 0;
          @include m.fontSize(text/md/semibold);
          color: map-get(i.$appColor, "grey/700");
        }

        .assignmentPageReferenceLinkPdfDivName {
          @include m.fontSize(text/sm/medium);
          color: map-get(i.$appColor, "grey/500");
        }
      }

      .assignmentPageReferenceLinkButtonDiv {
        margin: 8px 0;
      }
    }
  }

  .assignmentPageFooter {
    float: right;
    margin-bottom: 90px;

    .assignmentPageFooterButton {
      margin: 8px 14px;
      color: map-get(i.$appColor, primary);
      @include m.fontSize(text/sm/semibold);
    }
  }

  .assignmentPageSubmissionButtonDiv {
    .assignmentPageSubmissionButton {
      display: flex;
      margin-bottom: 12px;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}

.assignmentPageForAdmin {
  height: 77vh;
}
