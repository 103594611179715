.admin-view-comments-section-header-close {
  cursor: pointer;
  margin-right: 11px;
}

// .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
//   margin-top: 50px;
//   padding-bottom: 50px;
//   width: 332px !important;
// }

.admin-view-comments-section-comment-div-comment-givenbyname {
  font-size: 16px;
  font-weight: 700;
  color: #373739;
  line-height: 19px;
  font-style: normal;
  font-family: "Inter";
  display: inline-block;
}

.admin-view-comments-section-comment-div-comment-time {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  font-family: "Inter";
  color: rgba(55, 55, 57, 0.5);
}

.admin-view-comments-section-comment-div-comment-givencomment {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  font-style: normal;
  text-align: justify;
  font-family: "Inter";
}

.admin-view-comments-section-comment-div-comment-header {
  display: flex;
}

.admin-view-comments-section-comment-div-comment-header-givenbyname-time {
  width: 100%;
  margin-left: 8px;
}

.admin-view-comments-section-comment-div-comment {
  display: flex;
  margin-bottom: 30px;
}

.admin-view-comments-section-header-label {
  font-size: 16px;
  font-weight: 700;
  color: #373739;
  line-height: 19px;
  margin-left: 29px;
  font-style: normal;
  font-family: "Inter";
}

.admin-view-comments-section .css-15htiv1 {
  padding: 0px;
}

.admin-view-comments-section-header {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(55, 55, 57, 0.2);
}

.comments-component-textarea {
  width: 95%;
  margin: 10px;
  border: none;
  resize: none;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter";
  height: 140px !important;
  background-color: #f2f4f7;
  padding: 10px 10px 50px 10px;
}

.comments-component-textarea:focus {
  border: none;
  outline: none;
}

.comments-component-textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #9c9da0;
  line-height: 28px;
  font-style: normal;
  font-family: "Inter";
}

.admin-view-comments-section-admin-comment-input {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}

.inputwrapper {
  width: 310px;
  flex-shrink: 0;
  min-height: 170px;
  border-radius: 4px;
  background: #f2f4f7;
}

.comments-file-container {
  width: 285px;
  height: 47px;
  display: flex;
  flex-shrink: 0;
  background: #fff;
  border-radius: 10px;
  align-items: center;
  margin: 0 0 10px 12px;
  justify-content: space-between;
}

.comments-file-sec {
  display: flex;
  margin-left: 12px;
  align-items: center;
}

#comments-file-image-xmark-icon {
  cursor: pointer;
  margin-right: 12px;
}

#comments-file-image-name {
  font-size: 14px;
  color: #0671e0;
  margin-left: 5px;
  line-height: 24px;
  font-family: "Inter";
}

#comments-file-image-size {
  font-size: 14px;
  margin-left: 9px;
  line-height: 24px;
  font-family: "Inter";
  color: rgba(55, 55, 57, 0.7);
}

.comments-utils-area {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(55, 55, 57, 0.08);
}

.comments-utils-area2 {
  height: 46px;
  display: flex;
  padding-right: 5px;
  align-items: center;
  justify-content: flex-end;
}

.comments-utils-key-to-submit {
  font-size: 12px;
  color: #9c9da0;
  font-weight: 700;
  line-height: 28px;
  margin-left: 24px;
  font-family: "Inter";
}

.comments-utils-key-to-submit2 {
  justify-self: flex-end;
}

.comments-utits-attachfileinput {
  top: 2px;
  opacity: 0;
  right: 26px;
  position: relative;
  width: 24px !important;
  height: 24px !important;
}

.comments-utits-attachfileicon {
  color: #373739;
  width: 24px !important;
  height: 24px !important;
}

.admin-view-comments-section-comment-div {
  margin: 33px 0 33px 14px;
}

#assigmentCommentSubmitInstruction {
  font-size: 12px;
  font-weight: 700;
  color: #9c9da0;
  line-height: 28px;
  margin-left: 24px;
  font-style: normal;
  font-family: "Inter";
}

.admin-view-comments-section-comment-div-comment-givenattachment {
  width: 240px;
  height: 127px;
  flex-shrink: 0;
  cursor: pointer;
  margin-top: 5px;
  background: lightgray 50% / cover no-repeat;
}

.admin-view-comments-section-ed-icons {
  width: 20%;
  float: right;
  display: block;
}

.admin-view-comments-section-editicon {
  margin: 0;
  padding: 0 !important;
}

.admin-view-comments-section-deleteicon {
  margin: 0;
  padding: 0 !important;
}

.edit-Comment-admin-textarea {
  width: 80%;
  resize: none;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter";
}
