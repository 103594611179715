.recommendedWebsitesMainContainer {
    display: flex;
    width: Hug (451px)px;
    height: 100vh;
    padding: 24px 28px 24px 20px;
    gap: 0px;
    background: #393939;
    box-shadow: 0px 2px 4px -2px #1018280F;
    box-shadow: 0px 4px 8px -2px #1018281A;

    .recommendedWebsitesContainer {
        display: flex;
        flex-direction: column;
        margin: 0 0 24px 0;
        background-color: #393939;
        gap: 16px;

        .titleOfSectionContainer {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            margin-left: 12px;

            .titleOfSection {
                font-family: Inter;
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #FFFFFF;
            }
            .closeIcon:hover path{
                stroke: #98a2b3;
            }
        }

        .description {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #EAECF0;
            margin-left: 12px;
        }

        .recommendedWebsites {
            display: flex;
            gap: 4px;
            flex-direction: column;

            .recommendedWebsite {
                display: flex;
                padding: 8px 12px 8px 12px;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                .iconandtitle {
                    display: flex;
                    gap: 12px;
                    align-items: center;

                    .websiteTitle {
                        //styleName: Text sm/Medium;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #FFFFFF;
                        margin-bottom: 0px !important;
                    }
                }
            }

            .recommendedWebsite:hover {
                display: flex;
                padding: 8px 12px 8px 12px;
                gap: 0px;
                border-radius: 8px;
                justify-content: space-between;
                background: #4D4D4D;
            }

        }
    }
}