.filter-container,
.filter-container-admin {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 18px 0px rgba(24, 25, 28, 0.08);
  border-radius: 8px;
  padding: 40px 20px;
  min-width: 235px;
  height: 80vh;
  scrollbar-width: none;
  overflow-y: auto;

  .hrline {
    margin: 0;
    border: 0.6px solid #eaecf0;
  }

  .salaryRange {
    display: flex;
    width: 180px;
    gap: 0px;
    justify-content: space-between;
    margin-left: 6px;

    .salaryText {
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #667085;
    }
  }

  .filter-heading {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #101828;
    margin-bottom: 0;
  }

  .filtCont {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .styled-label{
      color: #101828;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-bottom: 0;
    }


    .filter-label,
    .filter-heading,
    .filter-apply {
      font-family: "Inter";
    }

    .filter-option {
      display: flex;
      align-items: center;
      gap: 8px;

      .filter-label {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-bottom: 0;
      }

      .custom-checkbox {
        appearance: none;
        width: 16px;
        height: 16px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        border-radius: 4px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 12px; */
        position: relative;
        cursor: pointer;
      }
      .custom-checkbox:checked {
        background: rgba(252, 235, 232, 1);
        border: 1.67px solid rgba(245, 85, 51, 1);
      }
      
      .custom-checkbox:checked::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 10px;
        border: solid #f55533;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        left: 4px;
        top: 0px;
      }
    }
  }
}

.filter-container-admin {
  border: 1px solid #d0d5dd;
  width: 280px
}

#jobDetailsContainer {
  display: flex;
  width: 800px;
  padding: 40px 32px 24px 32px;
  gap: 20px;
  height: 100vh;
  overflow: auto;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
}

.styled-label {
  color: #101828;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 0;
}

// .experience-slider {
//   width: 100%;
//   -webkit-appearance: none;
//   height: 6px;
//   background: #eaecf0;
//   outline: none;
//   opacity: 0.7;
//   transition: opacity 0.2s, background 0.2s;
// }

// .experience-slider::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   appearance: none;
//   width: 16px;
//   height: 16px;
//   background: #ffffff;
//   border: 1px solid #f55533;
//   cursor: pointer;
//   border-radius: 50%;
// }

// .briefcase-icon,
// .location-icon {
//   width: 18px;
//   height: 16px;
//   fill: none;
//   stroke: #667085;
//   stroke-width: 1.6;
//   stroke-linecap: round;
//   stroke-linejoin: round;
// }

// .styled-line {
//   width: 100%;
//   height: 1px;
//   background-color: rgba(0, 0, 0, 0.18);
//   margin: 20px 0;
// }





// .search-bar-container {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 0px 80px;
//   gap: 10px;
//   width: 100%;
//   height: 52px;
//   margin-bottom: 40px;
// }

// .advance-filter-container {
//   position: absolute;
//   top: 84px;
//   left: 171px;
//   right: 171px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0px 12px;
//   gap: 12.67px;
//   height: 52px;
//   background: #ffffff;
//   box-shadow: 0px 8px 24px rgba(109, 46, 0, 0.04);
//   border-radius: 8px;
//   margin-bottom: 40px;
// }

// .input-field {
//   width: 100%;
//   height: 48px;
//   background: #ffffff;
//   border-radius: 5px;
//   border: none;
//   padding: 12px 12px 12px 48px;
//   font-family: "Inter";
//   font-size: 16px;
//   color: #9199a3;
// }

// .input-field::placeholder {
//   color: #9199a3;
// }

// .divider {
//   width: 1px;
//   height: 24px;
//   background-color: #edeff5;
// }

// .search-icon,
// .map-pin-icon {
//   position: absolute;
//   left: 12px;
//   top: 50%;
//   transform: translateY(-50%);
// }

// .search-icon svg,
// .map-pin-icon svg {
//   width: 24px;
//   height: 24px;
//   fill: none;
//   stroke: #f55533;
//   stroke-width: 2;
// }