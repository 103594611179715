.questionInputInputs {
  padding: 5px;
  display: flex;
  border: 1px solid;
  margin-bottom: 5px;
  align-items: center;
}

.questionInputInputsLabel {
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter";
}

.outputsCol {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outputs {
  margin: 7px;
  display: flex;
}

.saveCancel {
  width: 37px;
  height: 46px;
}
