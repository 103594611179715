@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.codingPageProblemMainContainer {
  display: flex;
  cursor: pointer;
  flex-direction: column;

  .codingPageProblemHeader {
    max-width: 464px;
    margin: 12px 16px 12px 24px;

    .codingPageProblemQuestionDiv {
      display: flex;
      justify-content: space-between;

      .codingPageProblemQuestionName {
        width: 400px;
        white-space: break-spaces;
        @include m.fontSize(text/md/semibold);
        color: map-get(i.$appColor, "grey/900");
      }
    }

    .codingPageProblemReactQuill {
      margin: 12px 0 0 0;

      p {
        padding: 8px 0px;
        text-align: justify;
        @include m.fontSize(text/sm/regular);
        color: map-get(i.$appColor, "grey/700");
      }

      .ql-toolbar {
        display: none;
      }

      .ql-container {
        border: none;
      }

      .ql-editor {
        padding: 0;
      }
    }
  }

  .codingPageProblemTestCasesDiv {
    max-width: 464px;
    padding: 0px 24px 16px 24px;

    .codingPageProblemTestCasesDivHeader {
      margin: 0 0 12px 0;
      @include m.fontSize(text/sm/semibold);
      color: map-get(i.$appColor, "grey/700");
    }

    .codingPageProblemTestCasesDivBody {
      .codingPageProblemTestCasesDivBodyContainer {
        max-width: 456px;
        border-radius: 8px;
        margin: 0 0 12px 0;
        border: 1px solid map-get(i.$appColor, "grey/300");

        .codingPageProblemTestCasesDivBodyContainerExampleDiv {
          height: 20px;
          max-width: 389px;
          color: #101828;
          margin: 12px 51px 10px 16px;
          @include m.fontSize(text/sm/medium);
        }

        .codingPageProblemTestCasesDivBodyContainerInputDiv {
          .codingPageProblemTestCasesDivBodyContainerInputTitleDiv {
            .codingPageProblemTestCasesDivBodyContainerInputTitle {
              width: 100%;
              display: flex;

              .codingPageProblemTestCasesDivBodyContainerInputVariable {
                height: 20px;
                max-width: 50%;
                min-width: 30px;
                overflow: hidden;
                text-wrap: nowrap;
                margin: 0 0 10px 17px;
                text-overflow: ellipsis;
                @include m.fontSize(text/sm/medium);
                color: map-get(i.$appColor, "grey/900");
              }

              .codingPageProblemTestCasesDivBodyContainerInputValue {
                max-width: 50%;
                height: fit-content;
                margin: 0 0 10px 11px;
                word-wrap: break-word;
                word-break: break-word;
                @include m.fontSize(text/sm/regular);
                color: map-get(i.$appColor, "grey/700");
              }
            }
          }
        }

        .codingPageProblemTestCasesDivBodyContainerOutputVariable {
          margin: 0 0 8px 16px;
          @include m.fontSize(text/sm/medium);
          color: map-get(i.$appColor, "grey/900");
        }

        .codingPageProblemTestCasesDivBodyContainerOutputValue {
          text-align: left;
          line-break: anywhere;
          margin: 0 0 16px 16px;
          white-space: break-spaces;
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appColor, "grey/700");
        }
      }
    }
  }

  .problemContainerBackButtonAdmin {
    z-index: 2;
    width: 100%;
    height: 6vh;
    display: flex;
    position: fixed;
    cursor: pointer;
    font-size: 14px;
    color: #0671e0;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    align-items: center;
    font-family: "Inter";
    background: #f2f4f7;
  }
}
