@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.reportLpName {
  margin-left: 30px;
  @include m.fontSize(text/md/semibold);
}
.reportLpName::first-letter{
  text-transform: capitalize;
}
