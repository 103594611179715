.Header {
  top: 0;
  z-index: 5;
  width: 100%;
  max-height: 7vh;
  min-height: 7vh;
  position: fixed;
  color: #ffffff;
  background-color: #0d0d3d;
}





.headerforsp {
  top: 0;
  z-index: 5;
  width: 100%;
  max-height: 7vh;
  min-height: 7vh;
  position: fixed;
  color: #ffffff;
  background-color: #181818;
}

.dropdown-toggle::after {
  color: #202124;
}

.sandpacksubmit {
  display: flex;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  margin-left: 12px;
  line-height: 20px;
  margin-right: 8px;
  font-family: Inter;
  font-style: normal;
  align-items: center;
  color: rgb(234, 236, 240);
}

.sandpacksubmit:hover {
  display: flex;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  margin-left: 12px;
  line-height: 20px;
  margin-right: 8px;
  font-family: Inter;
  font-style: normal;
  align-items: center;
  color: #f55533 !important;
}

#headerLogo {
  left: 1vw;
  bottom: 7px;
  width: 4.9vh;
  height: 4.9vh;
  cursor: pointer;
  position: absolute;
}

.logo {
  float: left;
  height: 26px;
  margin: 10px;
}

.logInProfile {
  height: 6vh;
  bottom: 2.5px;
  position: relative;
  border-radius: 50%;
}

.logInProfile1 {
  top: 15px;
  width: 40px;
  right: 55px;
  height: 40px;
  position: absolute;
  border-radius: 50px;
}

#logInName {
  top: 10px;
  right: 120px;
  color: black;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  font-family: "Inter";
}

.logout {
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: 600;
  color: #202124;
  text-align: center;
  font-style: normal;
  font-family: "Inter";
  text-decoration: underline;
}

.logout:hover,
.logoutLink:hover {
  text-decoration: none;
}

.logoutUserName {
  width: 100%;
  font-size: 14px;
  margin-top: 50px;
  text-align: center;
  font-style: normal;
  margin-bottom: 10px;
  font-family: "Inter";
}

.timer {
  top: 10px;
  height: 14px;
  right: 130px;
  line-height: 2;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  position: absolute;
  letter-spacing: 2px;
  font-family: "Inter";
}

.logoutLink {
  width: 100%;
}

div > div > span#headerLogoName {
  top: 5px;
  text-align: left;
  font-size: 3.5vh;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
  position: absolute;
  font-style: normal;
  font-family: "Inter";
}

.codingNav {
  display: flex;
  padding-top: 10px;
  margin-bottom: 0%;
  color: #aeb0b2 !important;
  margin-left: 238px !important;
}

.topicbc,
.modulebc {
  font-size: 16px;
  line-height: 24px;
  color: #aeb0b2 !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
  font-family: "Inter" !important;
}

.selectTopicHeader .css-1dimb5e-singleValue,
.selectQuestionHeader .css-1dimb5e-singleValue {
  max-width: 100%;
  color: #ffffff;
  margin-left: 2px;
  overflow: hidden;
  margin-right: 2px;
  grid-area: 1/1/2/3;
  white-space: nowrap;
  box-sizing: border-box;
  text-overflow: ellipsis;
}

.questionbc {
  margin-top: 6px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  font-family: "Inter";
}

.questionbcViewCode {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  font-weight: 400;
  font-family: "Inter";
}

#qnNo {
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  font-family: "Inter";
  background: #0d0d3d;
}

#qnNo:focus {
  border: none;
  outline: none;
}

#headerTopicSelect .css-tj5bde-Svg {
  margin-top: 2px;
  color: #aeb0b2;
}

#headerTopicSelect .css-14el2xx-placeholder {
  color: #aeb0b2;
}

#headerTopicSelect .css-qc6sy-singleValue {
  color: #aeb0b2;
}

#headerQuestionSelect .css-tj5bde-Svg {
  margin-top: 2px;
  color: #ffffff;
}

#headerQuestionSelect .css-14el2xx-placeholder {
  color: #ffffff;
}

#headerQuestionSelect .css-qc6sy-singleValue {
  color: #ffffff;
}

#appHeader .headerBody {
  height: 7vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.containerforsandpackutils {
  right: 0;
  height: 7vh;
  display: flex;
  background: none;
  position: absolute;
  align-items: center;
}

.containerforsandpackutils:hover {
  right: 0;
  height: 7vh;
  display: flex;
  position: absolute;
  align-items: center;
  background: #2a2424;
}

.headerNotificationAndProfilePic {
  gap: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.5vw;
}

.sandpacktogglefs {
  cursor: pointer;
  color: #ffffff;
  margin: 0 10px 0 7px;
}

.sandpacktogglefs:hover .togglefssp {
  stroke: #f55533 !important;
}

.headerLogoAndName {
  display: flex;
  margin-left: 1vw;
  align-items: center;
}

.headerLogoAndNameName {
  font-size: 3vh;
  font-weight: 500;
  color: #ffffff;
  margin-left: 5px;
  line-height: 24px;
  font-style: normal;
  font-family: "Inter";
}

.adminNotificationSec {
  top: 0.5vh;
  position: relative;
}

.studentsandpacksubmit {
  right: 1.5vh;
  position: absolute;
}

.headerBreadcrumbs {
  position: absolute;
}

.savesandpack {
  left: 6px;
  cursor: pointer;
  position: relative;
  margin: 0 5px 0 0;
}

.savesandpack:hover .savesp {
  stroke: #f55533 !important;
}

#latestsessions_notifications {
  cursor: pointer;
}

.disablesandpacksubmit {
  display: none;
}
