.assignment-due-box-div {
  background-color: #ffffff;
  width: 564px;
  height: 248px;
  border: 1px solid #e3e8ef;
  border-radius: 20px;
  text-align: left;
  margin: 0 0 0 12px;

  .assignment-due-box-header {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    margin: 16px 0 16px 16px;
  }

  .assignment-due-container-div {
    width: 516px;
    height: 58px;
    margin: 0 0 0 24px;
    // justify-content: space-evenly;
  }

  .assignment-due-empty-container {
    width: 516px;
    height: 175px;
    margin: 0 0 0 24px;
    display: flex;
    align-items: center;
    div {
      width: 516px;
      text-align: center;
      font-family: "Inter";
      font-size: 18px;
      font-weight: 500;
      color: #667085;
      div {
        width: 516px;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        color: #344054;
        margin: 10px 0 0 0;
      }
    }
  }
}

.assignment-due-box-div.singlebox {
  width: 1024px;
  margin: 0;
}

.assignment-due-container-icon {
  background-color: #f2f4f7;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  margin: 0 0 0 16px;
  svg {
    // fill: #344054;
    margin: 5px 0 0 5px;
  }
}

.assignment-due-container-assignment-detail {
  margin: 0 0 0 12px;
  width: 288px;
  .assignment-due-container-assignment-detail-box {
    .assignment-due-container-assignment-name {
      font-family: "Inter";
      font-weight: 500;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .assignment-due-container-assignment-detail-date {
      font-family: "Inter";
      font-weight: 400;
      font-size: 12px;
      color: #475467;
    }
  }
}

.assignment-due-container-assignment-status-box {
  height: 22px;
  width: fit-content;
  border-radius: 16px;
  margin: 0 0 0 50px;
  // div {
  //   font-family: "Inter";
  //   font-size: 12px;
  //   font-weight: 500;
  //   padding: 2px 8px 2px 8px;
  //   color: #f04438;
  // }

  .div-PastDue{
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px 2px 8px;
    background-color: #FEF3F2;
    color: #F04438;
    border-radius: 16px;
    cursor: pointer;
  }

  .div-DueToday{
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px 2px 8px;
    background-color: #FFF6ED;
    color: #C4320A;
    border-radius: 16px;
    cursor: pointer;
  }

  .div-NoDue{
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px 2px 8px;
    background-color: #ECFDF3;
    color: #027A48;
    border-radius: 16px;
    cursor: pointer;
  }
}

.assignment-due-pagination-box {
  height: 12px;
  // width: 480px;
  display: flex;
  align-items: center;
  justify-content: center;

  .assignment-due-pagination-box-arrowback,
  .assignment-due-pagination-box-arrowforward {
    height: 12px;
    width: 12px;
    color: #344054;
  }

  .assignment-due-pagination-box-arrowback.disabled,
  .assignment-due-pagination-box-arrowforward.disabled {
    height: 12px;
    width: 12px;
    color: #d0d5dd;
  }

  .assignment-due-pagination-box-dots {
    cursor: pointer;
    color: #d0d5dd;
    margin: 0 6px 3px 6px;
  }

  .assignment-due-pagination-box-dots.selected {
    cursor: pointer;
    color: #344054;
    margin: 0 6px 3px 6px;
  }
}
