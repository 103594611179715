.mainAdminDashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  .dashboardBack {
    display: flex;
    justify-content: space-between;
    // width: 1024px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    .previousIcon {
      margin: 0px;
    }
  }

  .adminDashboardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .adminDashboardNamendDescription {
      display: flex;
      flex-direction: column;
      justify-items: flex-start;
      align-items: flex-start;
      margin: 0px 0px 0px 5%;
      gap: 8px;
      #adminDashboardText1 {
        font-family: Inter;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: #101828;
      }
      #adminDashboardText2 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #344054;

      }
    }
    .maindashboardOptions {
        position: relative;
      display: flex;
      gap: 8px;
      width: 124px;
      height: 36px;
      align-items: baseline;
      right: 3%;
    }
    .infoMessageDownload{
      position: relative;
    /* float: right; */
    color: red;
    margin-left: 31vw;
    font-weight: 500;
    }
  }
}
