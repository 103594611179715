@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.testPageMain {
  display: flex;
  overflow: hidden;
  background: map-get(i.$appBackGroundColor, "white");
}

.leftNavMainContainer,
.leftNavMainContainer .css-dip3t8,
.leftNavMainContainer .css-1wvake5 {
  width: 340px;
  display: flex;
  height: 100vh;
  text-align: left;
  overflow-y: clip;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: map-get(i.$appColor, primary);
  background: map-get(i.$appBackGroundColor, leftNav);

  .css-dip3t8:hover {
    overflow-y: visible;
  }

  .leftNavheaderDiv {
    z-index: 1;
    width: 340px;
    position: fixed;
    background: map-get(i.$appBackGroundColor, leftNav);
  }

  .leftNavheader {
    padding: 32px;
  }

  .leftNavMenu {
    margin-top: 240px;
  }

  .leftNavLearningPath {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;

    .leftNavLearningPathName {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      padding: 16px 32px;
    }

    .leftNavLearningPathInfo {
      padding: 0 32px;
      display: flex;
      justify-content: space-between;
    }

    .leftNavLearningPathModule {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: map-get(i.$appColor, "grey/300");
    }

    .leftNavLearningPathPercentage {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      line-height: 20px;
      padding-left: 81px;
    }
  }
}

.leftNavMainContainer.css-1wvake5.ps-collapsed {
  display: none;
}

.leftNavMainContainer .css-18unl23,
.leftNavMainContainer .css-18unl23 .css-ewdv3l:hover,
.leftNavMainContainer .css-16jesut > .ps-menu-button,
.leftNavMainContainer .css-16jesut > .ps-menu-button:hover {
  padding: 12px 0 0 32px;
  background: map-get(i.$appBackGroundColor, leftNav);
}

.leftNavMainContainer .css-16jesut > .ps-menu-button,
.leftNavMainContainer .css-16jesut > .ps-menu-button:hover {
  width: 340px;
  padding: 6px 0px 12px 25px;
}

.leftNavMainContainer .css-18unl23,
.leftNavMainContainer .css-nx2aea,
.leftNavMainContainer .css-18unl23 .css-ewdv3l:hover {
  padding: 0px;
  background: map-get(i.$appBackGroundColor, leftNav);
}

.leftNavMainContainer .css-1t8x7v1 > .ps-menu-button,
.leftNavMainContainer .css-1tqrhto > .ps-menu-button {
  padding-left: 32px;
}

.leftNavMainContainer .css-1tqrhto > .ps-menu-button:hover,
.leftNavMainContainer .css-16jesut > .ps-menu-button:hover,
.leftNavMainContainer .css-14xmt93 > .ps-menu-button:hover,
.leftNavMainContainer .css-1t8x7v1 > .ps-menu-button:hover {
  background: map-get(i.$appBackGroundColor, leftNavHover);
}

.leftNavMainContainer .css-wx7wi4 {
  width: 20px;
  margin-right: 5px;
}

.leftNavLearningPathTestName .css-wx7wi4 {
  width: 20px;
  margin-right: 0px;
  padding-right: 10px;
}

.leftNavMainContainer .css-12w9als {
  display: flex;
  justify-content: space-between;
}

.leftNavMainContainer .css-12w9als:active,
.leftNavLearningPathTestName .ps-menu-label.ps-active.css-12w9als {
  padding-top: 2px;
  @include m.fontSize(text/sm/regular);
  color: map-get(i.$appColor, brand/500);
}

.leftNavLearningPathTestName .ps-menu-label.css-12w9als {
  max-width: 340px;
  max-height: 25px;
  overflow: hidden;
  padding: 2px 0px;
  text-wrap: balance;
  text-overflow: ellipsis;
  @include m.fontSize(text/sm/regular);
}

.leftNavMainContainer .css-1cuxlhl {
  margin: 5px 40px 5px 0;
}

.leftNavMainContainer .css-honxw6,
.leftNavMainContainer .css-jn69v9 {
  width: 8px;
  height: 8px;
  margin-left: 10px;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
}

.leftNavMainContainer .css-8tsoh4 > .ps-menu-button {
  padding-right: 0px;
}

.leftNavLearningPathTestName.css-e87rjw > .ps-menu-button,
.leftNavLearningPathTestName.css-14xmt93 > .ps-menu-button {
  padding-left: 32px;
  color: map-get(i.$appColor, "grey/600");
}

.leftNavLearningPathModuleName .ps-menu-button.ps-disabled {
  color: map-get(i.$appColor, "grey/600");
}

.leftNavLearningPathModuleName {
  min-width: 340px;
  max-width: 340px;
  @include m.fontSize(text/md/semibold);
}

.leftNavLearningPathModuleName .ps-menu-icon.ps-disabled.css-wx7wi4 {
  padding-left: 10px;
}

.leftNavLearningPathTestName .ps-menu-icon.ps-disabled.css-wx7wi4 {
  padding-left: 0px;
}

.rightNavMainContainer,
.rightNavMainContainerCollapsed {
  width: 100%;
  margin-left: 100px;
}

.rightNavMainContainerCollapsed {
  margin-left: 0px;
}
