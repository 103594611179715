/* .searchMainCont {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;

} */

.searchandfiltercontainer,.searchandfiltercontaineradmin {
  display: flex;
  height: 52px;
  padding: 0px 12px 0px 12px;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
  width: 100%;
  border: 1px #ffffff;
  background: #ffffff;
  box-shadow: 0px 8px 24px 0px #6d2e000a;
}

.searchandfiltercontaineradmin{
  border: 1px solid #D0D5DD;
  height: 54px;
  width: 60%;
}


.searchjob,
.locatejob,
.searchjob:focus {
  display: flex;
  gap: 8px;
  align-items: center;
}

.searchInp,
.locationInp {
  /* display: flex;
  border: none;
  height: 48px;
  width: 238px;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #9199a3;
   */
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #9199a3;
  outline-color: #ffffff;
  border: none;
  height: 49px;
  width: 238px;
  background: #ffffff;
  padding: 5px;
}

.hrtagSearch {
  transform: rotate(-90deg);
  margin: 0;
  border: 0.6px solid #edeff5;
  width: 37px;
}
