.questionIo {
  margin: 0;
  display: inline-block;
}

.ioTableTable {
  width: 860px;
  margin-left: 16px;
  border: 1px solid #bdc1c6;
}

#addOutputs {
  bottom: 5px;
  right: 20px;
  display: flex;
  cursor: pointer;
  position: absolute;
}

.cancelOutput {
  cursor: pointer;
  margin-left: 10px;
}

.ioTableTableHead {
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter";
  background: #cccccc;
}

.ioTableIo {
  width: 350px;
  border: 1px solid #bdc1c6;
}

.ioTableIsEx {
  width: 80px;
  border: 1px solid #bdc1c6;
}

.questionInput {
  width: 350px;
  padding: 5px;
  min-height: 45px !important;
  border: 1px solid rgb(235, 236, 237);
}

.questionIOutput {
  width: 350px;
  position: relative;
  min-height: 45px !important;
  border: 1px solid rgb(235, 236, 237);
}

.addOutputs {
  margin: 10px;
  display: flex;
}

.addOutputs textarea {
  width: 50%;
}

.ioTableComponentTextAreaOp {
  width: 100%;
  border: none;
  resize: none;
  color: #000;
  font-size: 12px;
  font-family: "Inter";
  padding: 2px 2px 2px 2px;
}

.sampleIo {
  float: left;
  width: 80px;
  height: 45px;
}

.questionAddBtn {
  float: left;
  width: 80px;
  height: 45px;
  border: 1px solid rgb(235, 236, 237);
}

.ioAddBtn {
  padding: 0;
  width: 79px;
  height: 43px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  background: #f55533;
}

.questionInputInputs {
  padding: 5px;
  display: flex;
  border: 1px solid;
  margin-bottom: 5px;
  align-items: center;
}

.questionInputInputsLabel {
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter";
}

.testIo {
  width: 860px;
  margin-left: 16px;
  display: -webkit-box;
  border: 1px solid #bdc1c6;
  box-shadow: 0 10px 10px 1px #ddd;
}

.ioTableComponentTextAreaIp {
  width: 100%;
  border: none;
  resize: none;
  color: #000;
  font-size: 12px;
  font-family: "Inter";
  padding: 2px 2px 2px 2px;
}
