@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.multiLevelDropDownContainer,
.multiLevelDropDownContainerForLearner {
  display: flex;
  @include m.multiLevelDropDown();

  .multiLevelDropDownBreadCrumbsDiv {
    display: flex;

    div {
      margin-top: 5px;
      overflow: hidden;
    }
  }
}

.userTimeSpendReportMainContainer,
.multiLevelDropDownContainerForLearner {
  .react-dropdown-tree-select .dropdown .dropdown-trigger {
    width: 165px;
    input{
      background: unset;
    }
  }
  
  .react-dropdown-tree-select .dropdown .dropdown-content{
    width: unset;
  }
  .tag-item .search {
    margin-left: 0px;
  }
}
