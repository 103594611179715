@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.codingTableMainContainer {
  margin: 24px 52px;

  .css-1oqimao.Mui-expanded,
  .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    padding: 0px;
    display: flex;
    min-height: 44px;
    max-height: 44px;
    text-align: center;
    align-items: center;
    padding-right: 35px;
    border-radius: 8px 8px 0 0;
    justify-content: space-between;
    background: map-get(i.$appBackGroundColor, "grey/50");
    border-bottom: 1px solid map-get(i.$appColor, "grey/200");
  }

  .css-1aj41gs.Mui-disabled,
  .css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-disabled {
    border-radius: 8px;
    color: map-get(i.$appColor, "grey/500");
    border: 1px solid map-get(i.$appColor, "grey/200");
    background: map-get(i.$appBackGroundColor, "white");
  }

  .css-u7qq7e,
  .css-1aj41gs,
  .css-15v22id-MuiAccordionDetails-root,
  .css-1086bdv-MuiPaper-root-MuiAccordion-root {
    padding: 0px;
    box-shadow: none;
  }

  .css-1aj41gs,
  .css-1086bdv-MuiPaper-root-MuiAccordion-root {
    margin: 0px;
    background: none;
  }

  .css-1oqimao.Mui-disabled,
  .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-disabled {
    opacity: 1;
  }

  .codingTableHeader {
    @include m.fontSize(text/xs/medium);

    .codingTableHeaderLabel {
      width: 25%;
    }
  }

  .codingTableBodyMainContainer {
    text-align: center;

    .codingTableBody {
      height: 60px;
      padding: 0px;
      @include m.fontSize(text/xs/medium);

      .codingTableBodyLabel {
        width: 25%;
      }
    }
  }

  .codingTableFooter {
    float: right;
  }
}

.codingTableBody:hover {
  background: map-get(i.$appBackGroundColor, "grey/50");
}

.codingTableBodyMainContainer
  .MuiAccordionSummary-expandIconWrapper.css-1fx8m19,
.codingTableBodyMainContainer
  .css-yw020d-MuiAccordionSummary-expandIconWrapper {
  transition: none;
  -webkit-transition: none;
}

.codingTableBodyMainContainer .css-1oqimao.Mui-expanded,
.codingTableBodyMainContainer
  .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  padding: 0px;
  display: flex;
  min-height: 60px;
  max-height: 60px;
  background: none;
  border-radius: 0px;
  align-items: center;
  border-bottom: none;
  justify-content: space-between;
  border-top: 1px solid map-get(i.$appColor, "grey/200");
}

.codingTableBodyMainContainer
  .MuiAccordionSummary-expandIconWrapper.css-1fx8m19.Mui-expanded
  .codingTableLeftSvgIcon,
.codingTableBodyMainContainer
  .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded
  .codingTableLeftSvgIcon {
  margin-left: 20px;
  transform: rotate(-90deg);
}

.codingTableBodyTopicLabel {
  padding: 1%;
  @include m.fontSize(text/sm/medium);
  color: map-get(i.$appColor, "grey/900");
}

.codingTableBodyCompleteLabel {
  padding: 1% 0;
  @include m.fontSize(text/sm/regular);
  color: map-get(i.$appColor, "grey/500");
}

.codingTableInnerBody {
  border: none;
  display: grid;
  margin: 0 24px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

  .codingTableInnerBodyQuestionList,
  .codingTableInnerBodyQuestionList,
  .codingTableInnerBodyResumeQuestion,
  .codingTableInnerBodyRetakeQuestion,
  .codingTableInnerBodyTakeTestQuestion {
    width: 91px;
    height: 52px;
    padding: 8px;
    border-radius: 8px;
    margin: 0 0 12px 0px;
    @include m.fontSize(text/xs/regular);
    color: map-get(i.$appColor, "grey/700");
    border: 1px solid map-get(i.$appColor, "grey/200");
    cursor: pointer;

    .codingTableInnerBodyResumeTest,
    .codingTableInnerBodyRetakeTest,
    .codingTableInnerBodyTakeTestTest,
    .codingTableInnerBodyQuestionListScore {
      @include m.fontSize(text/xs/regular);
      color: map-get(i.$appColor, "grey/500");
    }
  }
}

.codingTableInnerBodyQuestionList:hover {
  color: map-get(i.$appColor, "success/700");
  border: 1px solid map-get(i.$appColor, "success/200");
  background: map-get(i.$appBackGroundColor, "success/50");

  .codingTableInnerBodyQuestionListScore {
    color: map-get(i.$appColor, "success/500");
  }
}

.codingTableInnerBody .codingTableInnerBodyRetakeQuestion {
  color: map-get(i.$appColor, "error/700");
  border: 1px solid map-get(i.$appColor, "error/200");
  background: map-get(i.$appBackGroundColor, "error/50");

  .codingTableInnerBodyRetakeTest {
    color: map-get(i.$appColor, "error/500");
  }
}

.codingTableInnerBody .codingTableInnerBodyResumeQuestion {
  color: map-get(i.$appColor, "brand/700");
  border: 1px solid map-get(i.$appColor, "brand/200");
  background: map-get(i.$appBackGroundColor, "brand/50");
  cursor: pointer;

  .codingTableInnerBodyResumeTest {
    color: map-get(i.$appColor, "brand/500");
  }
}

.codingTableInnerBody .codingTableInnerBodyTakeTestQuestion {
  color: map-get(i.$appColor, "success/700");
  border: 1px solid map-get(i.$appColor, "success/200");
  background: map-get(i.$appBackGroundColor, "success/50");

  .codingTableInnerBodyTakeTestTest {
    color: map-get(i.$appColor, "success/500");
  }
}
