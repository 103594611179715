@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.instructionPageBodyMainContainer {
  display: flex;
  justify-content: space-between;

  .instructionPageBodyUl {
    gap: 8px;
    width: 70%;
    display: flex;
    flex-direction: column;
    @include m.fontSize(text/md/regular);
    color: map-get(i.$appColor, "grey/700");

    .instructionPageBodyUlForCC {
      gap: 8px;
      width: 70%;
      display: flex;
      flex-direction: column;
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/700");
    }
  }
}
