.scrollable-body {
  width: 96vw;
  margin-left: 2vw;
}

/* CSS for the table */
.scrollable-body table {
  border-spacing: 0px;
  border-collapse: initial;
  border: 0px solid rgba(55, 55, 57, 0.2);
  font-family: "Inter";
}

.scrollable-body th,
.scrollable-body td {
  text-align: center;
  border: 0.5px solid rgba(55, 55, 57, 0.2) !important;
}

.scrollable-body th {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  text-align: center;
  /* border: 1px solid rgba(55, 55, 57, 0.20); */
  /* z-index: 3; */
}

.scrollable-body td {
  text-align: center;
  /* border: 1px solid rgba(55, 55, 57, 0.20);; */
  margin: 0px;
  padding: 0px;
  background-color: #f2f4f7;
}

/* CSS for the scrollable body */
.scrollable-body {
  min-height: 300px;
  max-height: 600px;
  overflow: auto;
}

.scrollable-body .report-cell-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* border: 1px solid rgba(55, 55, 57, 0.20); */
  background-color: #f2f4f7;
}

.scrollable-body .report-cell-score {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 51px;
  /* z-index: -1; */
  /* background-color:#F2F4F7 ; */
}

.scrollable-body .report-score-text {
  color: #373739;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* background-color:#F2F4F7 ; */
}

.scrollable-body .report-cell-credibility {
  height: 10px;
  width: 10px;
  position: sticky;
  margin: 10px 0px 15px 10px;
  right: -25%;
  top: -30%;
  animation: blinker 1s linear infinite;
  background-color: red;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.scrollable-body .report-table-header {
  text-align: center;
}

/* .scrollable-body .report-thead-th{
      text-align: center;
      position: sticky;
      height: 68px;
      width: 51px;
    } */

/* .scrollable-body thead tr:last-child .report-thead-th{
      height: 68px;
      width: 51px;
    } */

.scrollable-body .report-thead-th-div {
  text-align: center;

  position: sticky;
}

.scrollable-body .report-thead-th-div-span {
  text-align: center;
  /* display: flex; */
  /* align-items: center; */
  /* flex-direction: row; */
  justify-content: space-evenly;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  /* line-height: 24px; */
  /* height: 44px; */
  /* width: 51px; */
  background-color: white;
  padding: 8px;
}

.report-thead-th-div-span-icon {
  margin: 0px;
  border: 0px;
}

.css-ptiqhd-MuiSvgIcon-root {
  margin: 0 0 0 0 !important;
}

.scrollable-body table td:first-child {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
  /* width:189px; */
  min-width: 189px;
  height: 44px;
  text-align: -webkit-center;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}

.scrollable-body table th:first-child {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
  /* width:189px; */
  min-width: 189px;
  height: 44px;
  text-align: -webkit-center;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
}

.mcqmaincontainer-instruction-head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #000000;
}

.report-options {
  width: 96vw;
  display: flex;
  justify-content: space-between;
  // gap: 75px;
  flex-direction: row;
  margin-left: 2vw;
  border: 1px solid rgba(55, 55, 57, 0.2);
  padding: 10px;
  flex-wrap: wrap;
}

.report-options-refresh,.table-options-get-excel-button {
  display: flex;
  align-items: center;
}

.report-options-refresh-wrapper {
  border: 1px solid #d0d5dd;
  background: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #1018280d;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
}

.report-options-performance-label {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0044em;
  text-align: left;
  color: #0671e0;
}

.table-options-get-excel-button {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
  .MuiButtonBase-root{
    display: flex;
    gap:4px 
  }
}

.report-options-export-button-label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}

#admin-reports-table-head-div {
  position: sticky;
  top: 0;
  z-index: 2;
  /* color: #373739; */
  /* body */
}

/* .react-dropdown-tree-select{
  z-index: 4;
} */

/* .report-selection-type-module{
  max-width: 150px;
} */

/* 
.report-selection-type-module li{
text-align: left;

} */

/* 
.report-selection-type-module .dropdown-content{
  width: 222px;
  padding: 5px;
  z-index: 5;
} */

/* .report-selection-type-module .infinite-scroll-component {
  padding: 10px;
} */

/* .report-selection-type-module .search {
  max-width: 200px;
  min-height: 40px;
  border: none;
  outline: none;
  background: #FAFAFA;
  color: #373739;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0044em;
  text-align: left;
  padding: 8px 16px 8px 16px;
} */

/* .report-selection-type-module .tag-item{

  display: none;
} */

/* .report-selection-type-module .tag-item:last-child{

  display: inline;
  padding: 0;
  margin: 0;
} */

/* .report-selection-type-module .react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 4px;
  line-height: 20px;
  display: inline-flex;
  border: 0px solid #b9b9b9;
} */

/* .report-selection-type-module .react-dropdown-tree-select .dropdown .dropdown-content {
  position: absolute;
  padding: 4px;
  z-index: 5;
  background: #ffffff;
  border-top: 0px solid rgba(0,0,0,.05);
  -webkit-box-shadow: 0 5px 8px rgba(0,0,0,.15);
  box-shadow: 0 5px 8px rgba(0,0,0,.15);
} */

.report-selection-any {
  max-width: 150px;
  background: #fafafa;
}

.report-selection-any li {
  text-align: left;
}

.report-selection-any .dropdown-content {
  width: 222px;
  padding: 5px;
  z-index: 5;
}

.report-selection-any .infinite-scroll-component {
  padding: 10px;
}

.report-selection-any .search {
  max-width: 120px;
  min-height: 40px;
  border: none;
  /* border-bottom: 1px solid #ccc; */
  outline: none;
  background: #fafafa;
  color: #373739;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0044em;
  text-align: left;
  padding: 8px 16px 8px 16px;
}

.report-selection-any .tag-item {
  display: none;
}

.report-selection-any .tag-item:last-child {
  display: inline;
  padding: 0;
  margin: 0;
}

.report-selection-any .react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 4px;
  line-height: 20px;
  /* height: 79px; */
  display: inline-flex;
  border: 0px solid #b9b9b9;
  z-index: 5;
  position: relative;
}

.report-selection-any .react-dropdown-tree-select .dropdown .dropdown-content {
  position: absolute;
  padding: 4px;
  z-index: 5;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  width: fit-content;
}

.report-table-data-cell {
  height: 44px;
  width: 50px;
  background-color: #f2f4f7;
}

.header-hide {
  display: none;
}

.report-learner-name {
  color: #101828;
  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding-left: 20px;
}

.css-y45dm6-MuiButtonBase-root-MuiIconButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  /* border-radius: 50%; */
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 4px;
  /* background-color: #F2F4F7; */
}

.react-dropdown-tree-select.dropdown a {
  text-decoration: none;
  color: #373739;
}

.tag-list.tag-item.search::placeholder {
  color: red;
}

.report-selection-any .search::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
}

.report-selection-type-module .search::placeholder {
  /* //styleName: body; */
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
}

.css-1ld18pb-MuiPaper-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 480px;
  max-height: 40px;
  background-color: #f2f4f7;
  box-shadow: none;
}

/* ///////////////////////////////////////////////////////////////////////////////// */

.report-selection-type-module {
  /* max-width: 150px; */
}

.report-selection-type-module li {
  text-align: left;
}

.report-selection-type-module .dropdown-content {
  width: 222px;
  padding: 5px;
  z-index: 5;
}

.report-selection-type-module .infinite-scroll-component {
  padding: 10px;
}

.report-selection-type-module .search {
  max-width: 120px;
  min-height: 40px;
  border: none;
  outline: none;
  background: #fafafa;
  color: #373739;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0044em;
  text-align: left;
  padding: 8px 16px 8px 16px;
}

.report-selection-type-module .tag-item {
  display: none;
}

.report-selection-type-module .tag-item:last-child {
  display: inline;
  padding: 0;
  margin: 0;
}

.report-selection-type-module
  .react-dropdown-tree-select
  .dropdown
  .dropdown-trigger {
  padding: 4px;
  line-height: 20px;
  /* height: 79px; */
  display: inline-flex;
  border: 0px solid #b9b9b9;
  z-index: 5;
  position: relative;
}

.report-selection-type-module
  .react-dropdown-tree-select
  .dropdown
  .dropdown-content {
  position: absolute;
  padding: 4px;
  z-index: 5;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  width: fit-content;
}

.report-selection-type-module .search::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
}

/* ////////////////////////////////////////////////////////////////////// */

.report-selection-type-module-status {
  /* max-width: 150px; */
}

.report-selection-type-module-status li {
  text-align: left;
}

.report-selection-type-module-status .dropdown-content {
  width: unset;
  padding: 5px;
  z-index: 5;
}

.report-selection-type-module-status .infinite-scroll-component {
  padding: 10px;
}

.report-selection-type-module-status .search {
  max-width: 120px;
  min-height: 40px;
  border: none;
  outline: none;
  background: #fafafa;
  color: #373739;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0044em;
  text-align: left;
  padding: 8px 16px 8px 16px;
}

.report-selection-type-module-status .tag-item {
  /* display: none; */
}

.report-selection-type-module-status .tag-item:last-child {
  display: inline;
  padding: 0;
  margin: 0;
}

.report-selection-type-module-status
  .react-dropdown-tree-select
  .dropdown
  .dropdown-trigger {
  padding: 4px;
  line-height: 20px;
  display: inline-flex;
  border: 0px solid #b9b9b9;
  /* width:15vw; */
}

.report-selection-type-module-status
  .react-dropdown-tree-select
  .dropdown
  .dropdown-content {
  position: absolute;
  padding: 4px;
  z-index: 5;
  background: #ffffff;
  border-top: 0px solid rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
}

.report-selection-type-module-status .search::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
}

.report-selection-type-module .dropdown {
  background: #fafafa;
}

.report-selection-type-module-status .dropdown {
  background: #fafafa;
}

.infinite-scroll-component .collapsed {
  font-size: larger;
  position: relative;
  top: 2px;
}

.infinite-scroll-component .expanded {
  font-size: larger;
  position: relative;
  top: 2px;
}

.report-global-filter form {
  margin: 0px !important;
}
