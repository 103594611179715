.mcq-question-number-box {
  border: none;
  height: 32px;
  width: 32px;
  /* margin: 10px; */
  /* margin-left: 5px; */
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  border-radius: 25px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* color: wheat; */
  justify-content: space-around;
}

.questionbox-showthis-yes {
  border: 2px solid black;
  height: 40px;
  width: 40px;
  /* position: relative; */
  /* left: 1px; */
  /* margin: 10px; */
  /* margin-left: 5px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 40px;
}

.questionbox-showthis-no {
  border: 1px solid white;
  height: 40px;
  width: 40px;
  /* position: relative; */
  /* left: 1px; */
  /* margin: 10px; */
  /* margin-left: 5px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 40px;
  color:white
}