@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

.badgeContainer {
  border-radius: 16px;
  padding: 2px 8px;
  display: flex;
  gap: 6px;
  align-items: center;
  label {
    padding: 0;
    margin: 0;
  }
}

.badgeContainer.iconOnly{
    padding: 6px;
}

/* Badge size */
.badgeContainer.sm {
  width:fit-content;
  height: 22px;
  label {
    @include m.fontSize(text/xs/medium);
  }
}
.badgeContainer.md {
  width:fit-content;
  height: 24px;
  label {
    @include m.fontSize(text/sm/medium);
  }
}
.badgeContainer.lg {
  width:fit-content;
  height: 28px;
  label {
    @include m.fontSize(text/sm/medium);
  }
}

/* Badge background and text color */
.badgeContainer.grey {
  background: map-get(i.$appBackGroundColor, "grey/100");
  label {
    color: map-get(i.$appColor, "grey/700");
  }
}
.badgeContainer.primary {
  background: map-get(i.$appBackGroundColor, "brand/50");
  label {
    color: map-get(i.$appColor, "brand/700");
  }
}
.badgeContainer.error {
  background: map-get(i.$appBackGroundColor, "error/50");
  label {
    color: map-get(i.$appColor, "error/700");
  }
}
.badgeContainer.warning {
  background: map-get(i.$appBackGroundColor, "warning/50");
  label {
    color: map-get(i.$appColor, "warning/700");
  }
}
.badgeContainer.success {
  background: map-get(i.$appBackGroundColor, "success/50");
  label {
    color: map-get(i.$appColor, "success/700");
  }
}
.badgeContainer.bluegrey {
  background: map-get(i.$appBackGroundColor, "bluegrey/50");
  label {
    color: map-get(i.$appColor, "bluegrey/700");
  }
}
.badgeContainer.bluelight {
  background: map-get(i.$appBackGroundColor, "bluelight/50");
  label {
    color: map-get(i.$appColor, "bluelight/700");
  }
}
.badgeContainer.blue {
  background: map-get(i.$appBackGroundColor, "blue/50");
  label {
    color: map-get(i.$appColor, "blue/700");
  }
}
.badgeContainer.indigo {
  background: map-get(i.$appBackGroundColor, "indigo/50");
  label {
    color: map-get(i.$appColor, "indigo/700");
  }
}
.badgeContainer.purple {
  background: map-get(i.$appBackGroundColor, "purple/50");
  label {
    color: map-get(i.$appColor, "purple/700");
  }
}
.badgeContainer.pink {
  background: map-get(i.$appBackGroundColor, "pink/50");
  label {
    color: map-get(i.$appColor, "pink/700");
  }
}
.badgeContainer.rose {
  background: map-get(i.$appBackGroundColor, "rose/50");
  label {
    color: map-get(i.$appColor, "rose/700");
  }
}
.badgeContainer.orange {
  background: map-get(i.$appBackGroundColor, "orange/50");
  label {
    color: map-get(i.$appColor, "orange/700");
  }
}
