@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.sharedTopicsContainer {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  .sharedTopicContainer {
    width: 372px;
    height: 114px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    border: 1px solid map-get(i.$appColor, "grey/300");
    border-radius: 8px;
    padding: 16px 24px;
    .sharedTopicTitle {
      @include m.fontSize(text/xl/medium);
      color: map-get(i.$appColor, "grey/700");
    }
    .sharedTopicComplexityContainer {
      display: flex;
      gap: 12px;
      .badgeContainer.md {
        height: 32px;
      }
      label.success {
        color: map-get(i.$appColor, "success/600");
      }
      label.warning {
        color: map-get(i.$appColor, "warning/500");
      }
      label.error {
        color: map-get(i.$appColor, "error/600");
      }
      .sharedTopicComplexityCount {
        min-width: 22px;
        height: 22px;
        border-radius: 50%;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
      }
      .sharedTopicComplexityCount.easy {
        background: map-get(i.$appColor, "success/600");
      }
      .sharedTopicComplexityCount.medium {
        background: map-get(i.$appColor, "warning/500");
      }
      .sharedTopicComplexityCount.difficult {
        background: map-get(i.$appColor, "error/600");
      }
    }
  }
  .sharedTopicContainer.noTestTypes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 250px;
    .sharedCount {
      width: 42px;
      height: 28px;
      padding: 4px 12px 4px 12px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: map-get(i.$appBackGroundColor, "grey/100");
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey700");
    }
  }
  .sharedTopicsNoDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 261px;
    width: 100%;
    gap: 4px;
    .sharedTopicsNoDataText {
      font-family: Inter;
      font-size: 24px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      color: map-get(i.$appColor, "grey/500");
    }
  }
}
