@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.menuBarMainContainer {
  display: flex;
  overflow: hidden;

  .css-1wvake5 {
    width: 231px;
    height: 100vh;
    min-width: 231px;
    position: absolute;
  }

  .menuBarMainSideBar {
    box-shadow: 0px 1px 2px 0px #1018280f;
    box-shadow: 0px 1px 3px 0px #1018281a;

    .menuBarMainInnerDiv {
      padding: 24px 16px;
    }

    .menuBarMainMenu {
      gap: 12px;
      display: flex;
      overflow-y: auto;
      margin-top: 40px;
      scrollbar-width: none;
      height: calc(100vh - 225px);
      justify-content: space-around;

      .menuBarSubMenu,
      .menuBarMainMenuList {
        margin-bottom: 6px;

        .css-jun1iy {
          margin-right: 12px;
        }

        .ps-menu-button {
          height: 40px;
          width: 199px;
          padding: 0px 8px;
          border-radius: 8px;
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appBackGroundColor, "grey/500");
        }

        .ps-menu-button:hover {
          background: map-get(i.$appBackGroundColor, "grey/100");

          .css-12w9als {
            display: flex;
            align-items: center;
          }
        }

        .ps-menu-button.ps-active {
          background: map-get(i.$appBackGroundColor, "brand/25");

          .menuSvgIconForBatches path,
          .menuSvgIconForInterview path,
          .menuSvgIconForHackathon path {
            fill: map-get(i.$appBackGroundColor, "brand/500");
          }

          svg path {
            stroke: map-get(i.$appBackGroundColor, "brand/500");
          }
        }
      }

      .menuBarSubMenu {
        .css-wx7wi4 {
          width: 20px;
          min-width: 20px;
          margin-right: 12px;
        }

        .ps-submenu-content.ps-open {
          margin-top: 6px;
          overflow-y: auto;
          max-height: 130px;
          scrollbar-width: none;
        }

        .css-honxw6,
        .css-jn69v9 {
          width: 8px;
          height: 8px;
        }

        .menuBarSubMenuList {
          .ps-menu-button {
            height: 40px;
            width: 174px;
            margin-left: 25px;
            padding-left: 25px;
            margin-bottom: 6px;
            @include m.fontSize(text/sm/medium);
            color: map-get(i.$appColor, "grey/500");
          }

          .ps-menu-button:hover {
            border-radius: 8px;
            background: map-get(i.$appBackGroundColor, "grey/50");
          }

          .ps-menu-button.ps-active {
            border-radius: 8px;
            @include m.fontSize(text/sm/medium);
            color: map-get(i.$appColor, "grey/700");
            background: map-get(i.$appBackGroundColor, "grey/100");
          }
        }
      }
    }

    .css-dip3t8 {
      background: map-get(i.$appBackGroundColor, "white");
    }
  }

  .menuBarBody {
    width: 100vw;
    padding-top: 40px;
    margin-left: 231px;
    background: map-get(i.$appBackGroundColor, "grey/100");
  }
}
