@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.hackathonFormMainContainer {
  z-index: 2;
  .MuiPaper-root {
    height: calc(100vh - 50px);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // background: map-get(i.$appBackGroundColor, "grey/50");
    padding-bottom: 40px;
    z-index: 2;
  }
  .hackathonFormDrawer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .hackathonFormHeaderSec {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
      height: 72px;
      background: map-get(i.$appColor, "white");
      // box-shadow: 0px 2px 4px -2px #1018280F;
      box-shadow: 0px 4px 8px -2px #1018281a;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 4;
      .hackathonFormHeader {
        @include m.fontSize(text/xl/semibold);
        color: map-get(i.$appColor, "grey/700");
      }
      .hackathonFormHeaderBtn {
        display: flex;
        gap: 10px;
      }
    }
    .hackathonFormContainer {
      margin: 0 auto;
      .hackathonForm {
        width: 600px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .inputFieldContainer {
          width: 100%;
        }
        .hackathonDateSec {
          display: flex;
          gap: 40px;
          .hackathonDate {
            display: flex;
            flex-direction: column;
            gap: 6px;
            width: 50%;
            label {
              @include m.fontSize(text/sm/medium);
              color: map-get(i.$appColor, "grey/700");
              margin: 0;
            }
            .MuiFormControl-root {
              width: 100%;
              .MuiInputBase-root {
                height: 44px;
                padding: 10px 14px;
                border: 1px solid map-get(i.$appColor, "grey/300");
                border-radius: 8px;
                outline: none;
                background: map-get(i.$appBackGroundColor, "white");
                fieldset {
                  border: none;
                }
              }
              .MuiInputBase-root:hover {
                border: 1px solid map-get(i.$appColor, "grey/300") !important ;
              }
              .MuiInputBase-root:focus {
                border: 1px solid map-get(i.$appColor, "brand/300") !important;
                box-shadow: 0px 0px 0px 4px #f4ebff !important;

                // box-shadow: 0px 1px 2px 0px #1018280d;
              }
            }
          }
        }
        .hackathonMode,
        .hackathonEntry {
          display: flex;
          flex-direction: column;
          gap: 4px;
          p {
            margin: 0;
            padding: 0;
            @include m.fontSize(text/sm/medium);
            color: map-get(i.$appColor, "grey/700");
          }
        }
        .hackathonModeRadioBtn,
        .hackathonEntryRadioBtn {
          display: flex;
          gap: 20px;
        }
      }
    }
  }
}
