.dapp-header {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .dbutton {
    /* background: #000; */
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    color: #fff;
    /* padding: 12px 25px; */
    border: none;
    cursor: pointer;
  }
  
  
  .ddropdown-submenu {
    position: relative;
  }
  
  .ddropdown-submenu .ddropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
  }
  
  .action_list_ddmenu_ul {
    list-style: none;
    padding: unset;
    margin: unset;
    bottom: 100%;
    position: absolute;
    display: none;
    background-color: white;
    color: #000;
  }
  
  .action_list_ddmenu_li {
    padding: 14px;
    transition: all linear 0.3s;
    width: max-content;
    position: relative;
  
  }
  
  .action_list_ddmenu_li:hover {
    background: rgb(0, 0, 0);
    cursor: pointer;
    color: #fff;
  }
  
  .action_list_ddmenu_ul {
    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    transition: all linear 0.3s;
  }
  
  #zoom_student_options_id:hover .action_list_ddmenu_ul{
    display: block;
  } 
  
  
  .zoom_student_options_id{
            width: 150px;;
  }
  
  
  
  .navbar-nav .nav-link{
    color: #fff;
  }
  .dropend .dropdown-toggle{
    color: rgb(255, 255, 255);
    margin-left: 1em;
    color: #262626;
  }
  .dropdown-item:hover{
    background-color: rgb(50, 47, 45);
    color: #fff;
  }
  .dropdown .dropdown-menu{
    display: none;
  }
  .dropdown:hover > .dropdown-menu, .dropend:hover > .dropdown-menu{
    display: block;
    margin-top: .125em;
    margin-left: .125em;
  }
  /* @media screen and (min-width:992px) {
    .dropend:hover > .dropdown-menu{
      position: absolute;
      top: 0;
      left: 100%;
    }
    .dropend .dropdown-toggle{
      margin-left: .5em;
    }
  } */
  
  
  /* .dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #00ff33;
  } */
  
  
  .participant-lists{
  
  color: #262626;
  text-decoration: none;
  background-color: #00ff33;
  
  }
  
  
  .participant-lists>li>a:hover, .participant-lists>li>a:focus {
  color: #262626 !important;
  text-decoration: none !important;
  background-color: #00ff33 !important;
  }
  
  
  .dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {
  color: #262626 !important;
  text-decoration: none !important;
  background-color: #00ff33 !important;
  }
  
  
  #wc-loading{
  display: none !important;
  }
  
  
  // .btn-secondary{
  // background-color: rgba(0, 0, 0, 0);
  // padding: 2px 0px 0px 0px !important;
  // font-size: 12px !important;
  // margin-top: 2.4px !important;
  //   font-size: 12px!important;
  //   width: 80px !important;
  // }
  
  
  
  .zoomstudentremoved {
  /* color: darkred !important; */
  background: yellow;
  }
  
  
  
  
  .zoombutton{
  
    cursor: pointer;
      width: 80px;
      position: relative;
      top: 5px;
  }
  
  .gradiouslogo-warp{
    position: relative;
    top:2px;
  }
  
  .gradiouslogo{
    margin-bottom: 1px;
  }