.loadingDiv {
  top: 50%;
  z-index: 9999;
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
}

.loadingPng {
  height: 50px;
}
