.uploadgcatfbcont {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;

    .prcont {
        display: flex;
        gap: 16px;
        flex-direction: column;

        .desc {
            margin-bottom: 0px;
        }

        .radiobtnCont {
            display: flex;
            gap: 24px;
        }
    }

    .downloadTemplate,
    .upLoadTemplate,
    .upLoadTemplateAfter {
        display: flex;
        padding: 16px 20px 16px 20px;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;

        .upload-div {
            position: relative;

            .upload-div-input {
                width: 105px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
            }
        }

        .textForTemplateCont {
            display: flex;
            flex-direction: column;
            // width: 368px;
            gap: 8px;

            .btextfortemplate {
                font-family: Inter;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                color: #344054;
                margin-bottom: 0px;
            }

            .ntextfortemplate {
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #667085;
                margin-bottom: 0px;
            }

            .etextfortemplate {
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #F04438;
                margin-bottom: 0px;

            }
        }





        // .tempdownloadbtn{

        // }
    }

    .upLoadTemplate,.downloadTemplate {
        border: 1px solid #EAECF0;
        border-radius: 8px;
    }

    .upLoadTemplateAfter {
        border-top: 1px solid #EAECF0;
        border-left: 1px solid #EAECF0;
        border-right: 1px solid #EAECF0;

        border-radius: 8px 8px 0 0;
    }

    .afterfileloadedcont {
        display: flex;
        height: 40px;
        background: #FBEFED;
        padding: 8px 24px 8px 24px;
        gap: 8px;
        justify-content: space-between;
        border-bottom: 1px solid #EAECF0;
        border-left: 1px solid #EAECF0;
        border-right: 1px solid #EAECF0;
        border-radius: 0 0 8px 8px;

        .filename {
            font-family: Inter;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;
        }
    }

    .btnCont {
        display: flex;
        // gap: 12px;
        justify-content: space-between;
    }
}