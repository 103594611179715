@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.profileNavSec{
    .profileNavTitle{
        margin: 16px 0 0 20px;
        // width: 116px;
        color: map-get(i.$appColor ,"grey/900");
        @include m.fontSize(text/lg/medium)
    }  
    #profileNavMenuList{
        #profileNavMenuItemSec{
            text-decoration: none;
            #profileNavMenuItem,#profileNavMenuActiveItem{
                margin: 10px 20px 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // width: 220px;
                height: 44px;
                padding: 10px 12px 10px 12px;
                gap: 8px;
                border-radius: 8px;
                .MuiListItemText-primary{
                    @include m.fontSize(text/md/medium);
                }
            }
            #profileNavMenuActiveItem{
                background-color: map-get(i.$appColor, "brand/500" );
                color: map-get(i.$appColor, "grey/25" ) ;
            }
            #profileNavMenuItem{
                color:map-get(i.$appColor, "grey/500" ) 
            }
            .MuiListItemIcon-root{
                min-width: 20px;
            }
        }
    }   
}