@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.userTimeSpentReportTableMainContainer,
.userTimeSpentReportTableMainContainerForAdmin {
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px #1018281a;
  background: map-get(i.$appBackGroundColor, "white");

  .userTimeSpentReportTableLearningPathName {
    gap: 16px;
    opacity: 0px;
    padding: 20px 24px 19px 24px;
    @include m.fontSize(text/lg/semibold);
    color: map-get(i.$appColor, "grey/700");
  }

  .userTimeSpentReportTableTestHeader {
    display: flex;
    padding: 13px 24px;
    justify-content: space-between;
    @include m.fontSize(text/xs/medium);
    color: map-get(i.$appColor, "grey/700");
    background: map-get(i.$appBackGroundColor, "grey/50");

    .userTimeSpentReportTableTestHeaderLabelForTimeSpent {
      width: 28%;
      text-align: center;
    }
  }

  .userTimeSpentReportTableTestBody {
    .userTimeSpentReportTableTestBodyModuleName {
      padding: 8px 24px;
      @include m.fontSize(text/md/medium);
      color: map-get(i.$appColor, "brand/500");
      background: map-get(i.$appBackGroundColor, "brand/25");
    }

    .userTimeSpentReportTableTestBodyData {
      display: flex;
      padding: 14px 24px;
      justify-content: space-between;
      border-bottom: 1px solid map-get(i.$appColor, "grey/200");

      .userTimeSpentReportTableTestBodyDataTestLabelDiv {
        width: 73%;
        display: flex;

        .userTimeSpentReportTableTestBodyDataTestSvgIcon {
          border-radius: 8px;
          padding: 7px 10px 10px 10px;
          border: 1px solid map-get(i.$appColor, "grey/200");
        }

        .userTimeSpentReportTableTestBodyDataTestName {
          padding-left: 13.67px;
          @include m.fontSize(text/md/medium);
          color: map-get(i.$appColor, "grey/900");

          .userTimeSpentReportTableTestBodyDataTestPublishedDate {
            @include m.fontSize(text/sm/regular);
            color: map-get(i.$appColor, "grey/500");
          }
        }
      }

      .userTimeSpentReportTableTestBodyDataTestTimeSpent {
        width: 30%;
        display: flex;
        padding: 14px 74px 0px 100px;
        justify-content: space-around;
        @include m.fontSize(text/sm/regular);
        color: map-get(i.$appColor, "grey/700");
      }
    }
  }
}

.userTimeSpentReportTableMainContainerForAdmin {
  margin-top: 60px;
}
