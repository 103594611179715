.upload_btn_tablecontent {
    justify-content: right;
    flex-direction: row;
    display: flex;
    margin-bottom: 10px;

    .upload-div {
        position: relative;
        margin-right: 20px;

        .upload-div-input {
            position: absolute;
            opacity: 0;
            left: 0;
            width: 130px;
            height: 35px;
            cursor: pointer;
        }
    }

    .afterfileloadedcont {
        display: flex;
        background-color: #FBEFED;
        margin-right: 10px;
        border-radius: 8px;
        padding: 0 10px;
        width: 120px;
        font-family:Inter;
            font-weight :400;
            font-size: 14px;
            font-style: normal;
            line-height: 20px;
            cursor:pointer;
            align-items: center;
       

        p {
            margin:0;
            width:85px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            
            
        }

    }
    .timeLine_Excel_SubmitBtn{
        margin-right:10px;
    }
}