@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

.libdContainer {
  background: map-get(i.$appBackGroundColor, "white");
  margin-top: 50px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .libdTitle {
    svg {
      width: 24px;
      height: 24px;
      path {
        width: 21px;
        height: 16.33px;
      }
    }
    display: flex;
    align-items: center;
    gap: 10px;
    @include m.fontSize(display/xs/medium);
    color: map-get(i.$appColor, "grey/900");
  }
}

.libdTabsAndUtilsContainter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--divider, #0000001f);
  .libdTabs {
    display: flex;
    .libdSharedQues {
      display: flex;
      gap: 8px;
    }
    .libdTab {
      padding: 9px 16px;
      font-family: "Inter";
      font-weight: 500;
      line-height: 24px;
      font-size: 14px;
      color: #00000099;
      cursor: pointer;
      text-decoration: none;
      p {
        margin: 0;
      }
    }
    .libdTab.active {
      color: map-get(i.$appColor, "brand/500");
      border-bottom: 2px solid map-get(i.$appColor, "brand/500");
    }
  }
  .libdUtils {
    display: flex;
    gap: 16px;
    justify-content: center;
    .inputDropdownContainer {
      .inputDropdownInputContainer {
        width: 275px;

        .inputDropdownInputSec {
          height: 36px;
          min-width: 250px;
        }
      }
    }
    .inputDropdownMenuContainer {
      width: 275px;
      ul {
        height: 300px;
      }
    }
  }
}
