@use "../Abstracts/Variables/index" as i;

.css-1spb1s5,
.css-ja5taz-MuiTooltip-tooltip {
  font-size: 12px !important;
  margin-top: 8px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  padding: 8px 12px !important;
  border-radius: 8px !important;
  // color: map-get(i.$appColor, "grey/700") !important;
  // background: map-get(i.$appBackGroundColor, "white") !important;
}
