@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.commentsMainContainer {
  width: 460px;
  height: 700px;
  margin: 16px 20px;

  .commentsHeader {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;

    .commentsHeaderLabel {
      height: 28px;
      font-size: 18px;
      font-weight: 500;
      color: #101828;
      font-family: "Inter";
    }

    svg {
      cursor: pointer;
    }
  }

  .commentsBody {
    overflow: auto;
    min-height: 325px;
    max-height: 325px;

    .commentsBodyDivEditIcon,
    .commentsBodyDivEditIconOuterDiv {
      width: 72px;
      float: right;

      svg {
        cursor: pointer;
      }
    }

    .commentsBodyDivEditIconOuterDiv {
      width: 40px;
    }

    .commentsBodyDiv {
      display: flex;
      margin-bottom: 30px;

      .commentsBodyDivProfile {
        padding: 0;
        width: 40px;
        height: 40px;
      }

      .commentsBodyDivHeader {
        width: 412px;
        margin-left: 12px;

        .commentsBodyDivHeaderInnerDiv {
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .commentsBodyDivHeaderInnerDivName {
          width: 250px;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          text-overflow: ellipsis;
          @include m.fontSize(text/md/medium);
          color: map-get(i.$appColor, "grey/700");
        }

        .commentsBodyDivHeaderTime {
          height: 18px;
          @include m.fontSize(text/xs/regular);
          color: map-get(i.$appColor, "grey/500");
        }

        .commentsBodyCommentsDiv {
          margin: 8px 0 0 0;

          .commentsBodyCommentsDivSpan {
            overflow-wrap: break-word;
            @include m.fontSize(text/md/regular);
            color: map-get(i.$appColor, "grey/500");
          }

          .test-edited-Comment-admin-textarea {
            width: 95%;
            height: 128px !important;
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            padding: 10px 14px;
            resize: none;
          }

          .test-edited-Comment-admin-textarea:focus {
            outline: none;
          }

          .test-edited-Comment-admin-textarea::placeholder {
            font-size: 16px;
            font-weight: 400;
            color: #667085;
            line-height: 28px;
            font-style: normal;
            font-family: "Inter";
          }
        }
      }
    }
  }

  .test-view-comments-section-admin-comment-input {
    .test-view-comments-section-admin-comment-title {
      font-size: 18px;
      font-weight: 500;
      color: #101828;
      margin: 0 0 16px 0;
      font-family: "Inter";
    }

    .test-view-comments-inputwrapper {
      width: 460px;
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      padding: 10px 14px;
      margin: 0 0 10px 0;

      .test-comments-component-textarea {
        width: 424px;
        border: none;
        resize: none;
        height: 128px !important;
      }

      .test-comments-component-textarea:focus {
        outline: none;
      }

      .test-comments-component-textarea::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #667085;
        line-height: 28px;
        font-style: normal;
        font-family: "Inter";
      }

      .test-comments-utils-area {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 8px 0;
      }

      .test-comments-utils-area2 {
        height: 25px;
        display: flex;
        padding-right: 5px;
        align-items: center;
        justify-content: flex-end;
      }

      .test-comments-file-container-div {
        max-height: 100px;
        overflow: auto;

        .test-comments-file-container {
          width: 420px;
          height: 34px;
          display: flex;
          background: #f8e0db;
          border-radius: 10px;
          align-items: center;
          margin: 10px 0 0 0;
          justify-content: space-between;
        }
      }
    }
  }

  .commentsFooter {
    float: right;
  }
}
