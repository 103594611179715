@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.gradiousevaluation_parent{
    width: 1040px;
    height: 100%;
    border: 1px solid map-get(i.$appColor, "grey/300");
    border-radius: 10px;

    .gcat_skill_div{
        padding: 0 28px;

        .gcat_div{
            display: flex;
            justify-content: space-between;
    
            .agc_div{
                width: 260px;
    
                .label_gact{
                    @include m.fontSize(text/sm/medium);
                    color: map-get(i.$appColor, "grey/700");
                    margin: 0 0 4px 0;
                }
    
                .gcat_input_div{
                    width: 260px;
                    height: 44px;
                    @include m.fontSize(text/md/medium);
                    color: map-get(i.$appColor, "grey/700");
                    border: 1px solid map-get(i.$appColor, "grey/300");
                    padding: 10px 14px;
                    border-radius: 8px;
    
                    .editable_input_span{
                        height: 22px;
                        border: none;
                        outline: none;
                        text-align: center;
                    }
                }
            }
        }
    
        .skill_div{
            margin: 32px 0 0 0;
        }
    
        .overallfeedback_div{
            margin: 16px 0 0 0;
    
            .overallfeedback_label{
                @include m.fontSize(text/md/medium);
                color: map-get(i.$appColor, "grey/700");
                margin: 0 0 4px 0;
            }
    
            .overallfeedback_textarea{
                width: 100%;
                border: 1px solid map-get(i.$appBackGroundColor, "grey/300");
                color: map-get(i.$appColor, "grey/700");
                @include m.fontSize(text/md/normal);
                border-radius: 8px;
                background: #FFFFFF;
                min-height: 120px !important;
                padding: 10px 14px;
                resize: none;
                outline: none;
            }
        }

    }

    .save_resumebtn_div{
        margin: 24px 0 24px 0;
        padding: 0 24px 0 0;
        float: right;
    }
}