@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.comments-score-section-score-reject-box2 {
  top: 50px;
  z-index: 4;
  right: 10px;
  display: block;
  position: absolute;
}

.comments-score-section-score-reject-box-score-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
}

.comments-score-section-score-reject-box-score-input {
  top: 99px;
  left: 971px;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  align-content: stretch;
  border: 1px solid #8e9093;
  justify-content: space-evenly;
  border-radius: 5px 0px 0px 5px;
}

.comments-score-section-score-reject-box-score-targetscore {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  background: #d9d9d9;
  box-sizing: border-box;
  border: 1px solid #8e9093;
}

.comments-score-section-score-reject-box-score-input-txtbox {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  box-sizing: border-box;
  border: 1px solid #8e9093;
}

.the-ace-editor {
  margin-top: 15px;
}

.react-datepicker__view-calendar-icon input {
  width: 220px;
  height: 37.82px;
  border-radius: 10px;
  border: 1px solid #8e9093;
}

.codingDateLabel {
  font-size: 16px;
  font-weight: 700;
  color: #373739;
  text-align: left;
  line-height: 19px;
  font-style: normal;
  font-family: "Inter";
}

.react-datepicker-wrapper {
  border: 0;
}

.reject-component-textarea {
  outline: none;
  padding: 15px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid white;
}

.comments-score-section-score-reject-box-score-extend-datetime-des-mcq {
  margin-top: 10px;
  position: relative;
}

.rejectMainContainer {
  top: 25px;
  z-index: 2;
  right: 20px;
  display: block;
  position: absolute;

  .rejectTestMainContainer {
    display: flex;
    justify-content: space-between;

    .rejectTestInnerDiv {
      display: flex;
      flex-direction: column;
    }

    .rejectTestMainContainerInnerDiv {
      table {
        width: 400px;
        border-radius: 8px;
        border-collapse: collapse;
        border: 1px solid map-get(i.$appColor, "grey/200");

        th {
          padding: 8px 24px;
          @include m.fontSize(text/md/medium);
          color: map-get(i.$appColor, "grey/900");
          background: map-get(i.$appBackGroundColor, "grey/50");
        }

        .rejectTestTableLabel {
          width: 162px;
          text-align: left;
          padding: 10px 24px;
          @include m.fontSize(text/sm/medium);
          color: map-get(i.$appColor, "grey/900");
          border: 1px solid map-get(i.$appColor, "grey/200");
          border-right: none;
        }

        .rejectTestTableValue {
          width: 250px;
          text-align: left;
          padding: 10px 24px;
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appColor, "grey/700");
          border: 1px solid map-get(i.$appColor, "grey/200");
          border-left: none;
        }
      }
    }

    .rejectTestOptionDiv {
      text-align: left;
      margin-left: 40px;

      .rejectTestOptionInnerDiv {
        width: 400px;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid map-get(i.$appColor, "grey/200");

        .rejectTestOptionInnerDivHeader {
          @include m.fontSize(text/lg/semibold);
          color: map-get(i.$appColor, "grey/900");
        }
        .rejectTestOptionLabel,
        .rejectTestOptionLabelForGivenTime {
          display: flex;
          gap: 8px;
        }

        .rejectTestOptionLabel,
        .rejectTestOptionLabelForGivenTime {
          margin-top: 20px;
          @include m.fontSize(text/sm/medium);
          color: map-get(i.$appColor, "grey/700");

          .rejectTextRadioOption,
          .rejectTestWithRemainingTime {
            // width: 20px;
            height: 20px;
            margin: 0px 8px 0 0;
            vertical-align: middle;
            accent-color: map-get(i.$appColor, "brand/700");
          }

          .rejectTestWithRemainingTime {
            color: map-get(i.$appColor, "grey/500");
          }

          .rejectTestWithGivenTime {
            width: 60px;
            outline: none;
            border-radius: 8px;
            text-align: center;
            border: 1px solid map-get(i.$appColor, "grey/200");
            background: map-get(i.$appBackGroundColor, "white");
          }

          // svg {
          //   margin-left: 8px;
          // }
        }
      }
    }

    .rejectTestExtendDateDiv {
      width: 400px;
      border-radius: 8px;
      padding: 16px 12px;
      margin: 16px 0 0 40px;
      border: 1px solid map-get(i.$appColor, "grey/200");

      .rejectTestExtendDateDivLabel {
        @include m.fontSize(text/lg/semibold);
        color: map-get(i.$appColor, "grey/900");
      }

      .rejectTestDatePicker {
        outline: none;
        // margin-top: 16px;
        padding: 8px 12px;
        text-align: center;
        @include m.fontSize(text/sm/medium);
        color: map-get(i.$appColor, "grey/500");
        border: 1px solid map-get(i.$appColor, "grey/300");
      }
    }

    .rejectTestFooter {
      margin: 16px 0 0 40px;

      .rejectTestFooterConfirmationDiv {
        @include m.fontSize(text/sm/medium);
        color: map-get(i.$appColor, "grey/700");

        .rejectTestConfirmationCheckBox {
          svg {
            path {
              fill: map-get(i.$appColor, "brand/700");
              border: 1px solid map-get(i.$appColor, "grey/300");
            }
          }
        }

        .rejectTestConfirmationCheckBox.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover {
          background: map-get(i.$appBackGroundColor, "brand/50");
        }

        .rejectTestConfirmationCheckBox.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
          color: map-get(i.$appColor, "brand/700");
        }
      }

      .rejectTestFooterButtonDiv {
        display: flex;
        margin-top: 16px;
        align-items: center;
        justify-content: flex-end;

        .buttonDestructivesm {
          margin-left: 10px;
        }
      }
    }
  }

  .rejectHeaderForAssignment {
    float: left;
    display: flex;
    text-align: justify;
    @include m.fontSize(text/sm/regular);
    color: map-get(i.$appColor, "grey/700");

    .rejectInstructionForAssignment {
      width: 476px;
      margin-left: 16px;
    }
  }

  .rejectCommentsForAssignment {
    margin-top: 24px;
    display: -webkit-inline-box;

    .rejectCommentsForAssignmentTextAreaDiv {
      width: 552px;
      flex-shrink: 0;
      min-height: 140px;
      border-radius: 8px;
      border: 1px solid map-get(i.$appColor, "grey/300");
      background: map-get(i.$appBackGroundColor, "white");

      .rejectCommentsForAssignmentTextArea {
        width: 550px;
        border: none;
        outline: none;
        max-height: 140px;
        padding: 10px 14px;
        overflow: auto !important;
        border-radius: 8px 8px 0 0;
        @include m.fontSize(text/md/normal);
        color: map-get(i.$appColor, "grey/500");
      }
    }

    .rejectCommentsForAssignmentFileUpload {
      height: 35px;
      display: flex;
      margin: 10px 14px;
      border-radius: 8px;
      align-items: center;
      justify-content: space-between;
      background: map-get(i.$appBackGroundColor, "brand/200");

      .rejectCommentsForAssignmentFileUploadInnerDiv {
        display: flex;
        margin-left: 12px;
        align-items: center;
        @include m.fontSize(text/sm/regular);

        .rejectCommentsForAssignmentFileUploadFileName {
          margin-left: 5px;
          color: map-get(i.$appColor, "blue/500");
        }

        .rejectCommentsForAssignmentFileUploadFileSize {
          margin-left: 9px;
          color: map-get(i.$appColor, "grey/500");
        }
      }

      .rejectCommentsForAssignmentCloseSvgIcon {
        margin-right: 12px;
      }
    }

    .rejectCommentsForAssignmentAttachmentDiv {
      height: 46px;
      display: flex;
      padding-right: 5px;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid map-get(i.$appColor, "grey/300");

      .rejectCommentsForAssignmentAttachmentInput {
        top: 2px;
        opacity: 0;
        right: 26px;
        width: 24px;
        height: 24px;
        position: relative;
      }
    }
  }

  .rejectCommentsForAssignmentFooter {
    display: table;

    .rejectCommentsForAssignmentFooterCheckBoxDiv {
      display: flex;
      margin-top: 24px;

      .rejectCommentsForAssignmentFooterCheckBoxLabel {
        @include m.fontSize(text/sm/medium);
        color: solid map-get(i.$appColor, "grey/500");
      }
    }

    .rejectTestExtendDateDivLabel {
      margin-top: 24px;
      @include m.fontSize(text/lg/semibold);
      color: map-get(i.$appColor, "grey/900");
    }

    .rejectTestDatePicker {
      outline: none;
      margin-top: 16px;
      padding: 8px 12px;
      text-align: center;
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/500");
      border: 1px solid map-get(i.$appColor, "grey/300");
    }
  }

  .rejectTestFooterButtonDiv {
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: flex-end;

    .buttonDestructivesm {
      margin-left: 10px;
    }
  }
}

.scrollable-body::-webkit-scrollbar {
  height: 6px;
}

.css-1ld18pb-MuiPaper-root {
  width: 480px;
  bottom: -4px;
  display: flex;
  margin-top: 0px;
  box-shadow: none;
  border-radius: 4px;
  position: relative;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-align: center;
  background-color: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  background-color: #f2f4f7;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.popup-table th,
.popup-table td {
  text-align: center;
  padding-left: 10px;
  align-items: center;
  padding-right: 10px;
  border: 1px solid black;
}

.popup-table .yes {
  color: green;
}

.popup-table .no {
  color: red;
}

.reject-retake-comment {
  color: red;
}
