.component-one {
  position: relative;
}

.component-one .MuiBackdrop-root {
  position: absolute;
}

.commontest-mainpage-div {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  position: relative;

  .commontest-mainpage-header {
    height: 52px;
    display: flex;
    justify-content: space-between;
    background-color: #f9fafb;
    border-radius: 10px 10px 0 0;

    .commontest-mainpage-header-change-section {
      height: 32px;
      width: 160px;
      margin: 7px 16px;

      .commontest-mainpage-header-back-btn-dropdown {
        display: flex;
        align-items: center;
      }

      .header-change-section-form {
        .header-change-section-select {
          min-width: 160px;
          max-width: 160px;
          min-height: 32px;
          max-height: 32px;
          margin: 0;
          font-family: "Inter";
          font-size: 14px;
          font-weight: 400;
          color: #344054;
          background: #ffffff;
        }
      }

      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-color: #d0d5dd !important;
        border-width: 1px !important;
      }
    }

    .Show-answer-div {
      margin: 14px 16px;
    }

    .commontest-mainpage-header-section {
      display: flex;

      .commontest-mainpage-header-section-title {
        height: 24px;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 600;
        color: #101828;
        margin: 14px 0 12px 24px;
      }

      .commontest-mainpage-header-section-name {
        height: 25px;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        color: #101828;
        margin: 12.5px 0 12.5px 8px;
      }
    }

    .commontest-mainpage-header-test-view-mode {
      display: flex;

      .commontest-mainpage-header-test-view-mode-gridbtn {
        height: 36px;
        width: 36px;
        margin: 8px 0 0 0;
        border-radius: 8px;
      }

      .commontest-mainpage-header-test-view-mode-listbtn {
        height: 36px;
        width: 36px;
        margin: 8px 24px 0 8px;
        border-radius: 8px;
      }

      .commontest-mainpage-header-test-view-mode-listbtn:active,
      .commontest-mainpage-header-test-view-mode-gridbtn:active {
        background-color: #ffffff;
        border: 1px solid #d0d5dd;
      }
    }

    .video-qn-timer-div {
      width: 175px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px 0px;

      .video-qn-timer-label {
        font-family: "Inter";
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        color: #101828;
      }

      .video-qn-timer {
        // width: 85px;
        // height: 36px;
        display: flex;
        padding: 4px 12px;
        border-radius: 8px;
        // margin-right: 20px;
        color: #101828;
        border: 1px solid #d0d5dd;
        background: #ffffff;

        width: 61px;
        height: 28px;
        margin: 2px 0 2px 6px;
        font-family: "Inter";
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        justify-content: center;
        align-items: center;
      }
    }
  }

  /* video qn */

  .commontest-mainpage-body-videoqn {
    height: calc(100% - 52px);
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .commontest-mainpage-single-question-div {
      width: 100%;

      .commontest-mainpage-single-question-box {
        .commontest-mainpage-single-question-header-div {
          margin: 24px;

          .commontest-mainpage-single-question-title-div {
            text-align: center;

            .commontest-mainpage-single-question-title-qnname {
              width: 100%;
              font-family: "Inter";
              // font-size: 18px;
              font-weight: 500;
              color: #344054;
              text-align: center;
            }
          }
        }
      }

      .commontest-mainpage-single-question-button-group {
        .commontest-mainpage-single-question-button-group-div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          margin: 0 0 32px 0;

          div {
            margin: 0 24px 0 24px;
          }
        }
      }
    }
  }

  .commontest-mainpage-body {
    height: calc(100% - 52px);
    overflow: auto;

    .commontest-mainpage-single-question-div {
      .commontest-mainpage-single-question-box {
        .commontest-mainpage-single-question-header-div {
          margin: 24px;

          .commontest-mainpage-single-question-title-div {
            display: flex;
            align-items: start;
            gap: 0.5vw;

            .commontest-mainpage-single-question-title-reviewlater {
              padding: 0;
            }

            .commontest-mainpage-single-question-title-qnno {
              // height: 24px;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 700;
              color: #344054;
            }

            .commontest-mainpage-single-question-title-qnname {
              width: 85%;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 500;
              color: #344054;
            }

            .commontest-mainpage-single-question-title-mark {
              font-family: "Inter";
              font-size: 14px;
              font-weight: 400;
              color: #667085;
            }
          }
        }

        .commontest-mainpage-single-question-detail-box {
          .text-area-AnswerDiv {
            width: 80%;
            margin: 0 0 0 60px;
          }

          .commontest-mainpage-single-question-options {
            margin: 0px 100px 12px 60px;
            max-width: 800px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #d0d5dd;

            .commontest-mainpage-single-question-options-input-label-div {
              max-width: 776px;
              height: 20px;
              margin: 10px;
              display: flex;
              align-items: center;

              .commontest-mainpage-single-question-option-label {
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                color: #101828;
                margin: 0 0 0 8px;
              }
            }

            .commontest-mainpage-single-question-options-input {
              height: 16px;
              width: 16px;
              color: #d0d5dd !important;
              margin: 0;
            }

            .commontest-mainpage-single-question-options-input:checked {
              height: 16px;
              width: 16px;
              accent-color: #f96343 !important;
            }
          }
        }
      }

      .commontest-mainpage-single-question-button-group {
        .commontest-mainpage-single-question-button-group-div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          margin: 0 0 32px 0;

          div {
            margin: 0 24px 0 24px;
          }
        }
      }
    }
  }

  /* multiple question view */
  .commontest-mainpage-multiple-question-options {
    margin: 24px 0 0 0;

    .commontest-mainpage-multiple-question-container {
      .commontest-mainpage-multiple-question-container-loop {
        .commontest-mainpage-multiple-question-container-block {
          .commontest-mainpage-multiple-question-box {
            .commontest-mainpage-multiple-question-box-title {
              display: flex;
              align-items: start;
              gap: 0.5vw;
              margin: 24px;

              .commontest-mainpage-multiple-question-title-reviewlater {
                padding: 0;
              }

              .commontest-mainpage-single-question-title-qnno {
                font-family: "Inter";
                font-size: 16px;
                font-weight: 700;
                color: #344054;
              }

              .commontest-mainpage-single-question-title-qnname {
                width: 85%;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 500;
                color: #344054;
              }

              .commontest-mainpage-single-question-title-mark {
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                color: #667085;
              }
            }

            .admin-Marks-Input-Div {
              display: flex;
              justify-content: center;
              margin: 10px 0 0 0;

              .admin-Marks-Input-DivItems {
                display: flex;
                flex-direction: row-reverse;
                width: 80%;

                .admin-Marks-Input {
                  display: flex;
                  width: 140px;
                  height: 32px;
                  font-size: 16px;
                  font-weight: 400;
                  font-family: "Inter";
                  color: #101828;
                  border: 1px solid #d0d5dd;
                  align-items: center;
                  border-radius: 8px;
                  justify-content: center;
                  margin: 0 10px 0 24px;
                  padding: 4px 12px;

                  .saq-Laq-Marks-Entry {
                    width: 30px;
                    height: 20px;
                    text-align: right;
                    border: none;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: "Inter";
                    color: #101828;
                    text-align: center;
                  }
                }
              }
            }
          }

          .commontest-mainpage-single-question-option-detail-box {
            .multiQuestionContainerDescription {
              width: 80%;
              margin: 0 0 0 60px;
              font-family: "Inter";
              font-size: 14px;
              font-weight: 500;
              color: #344054;
            }

            .commontest-mainpage-single-question-option-details-div {
              // Desc
              .text-area-AnswerDiv {
                width: 80%;
                margin: 0 0 0 60px;

                .videoqn-admin-answer {
                  width: 50%;
                }

                .video_loading{
                  display: flex;
                  justify-content: center;
                  
                    span{
                      height: 58px !important;
                      width: 58px !important;
                      margin: 3px;
                      color: #F55533 !important;
                    }
                
                    .css-1rn30mb-MuiCircularProgress-root{
                        color: #F55533 !important;
                    }
                }

                .desc-admin-answer-div {
                  .desc-admin-answer-label-div {
                    max-width: 840px;
                    // margin: 0px 60px 0 60px;
                    font-family: "Inter";
                    font-size: 14px;
                    font-weight: 500;
                    color: #344054;
                  }

                  .desc-admin-answer {
                    width: 100%;
                    // margin: 0px 0px 0 60px;
                    resize: none;
                    font-size: 15px;
                    font-weight: 400;
                    border-radius: 8px;
                    font-family: "Inter";
                    border: 1px solid #d0d5dd;
                    background-color: #ffffff;
                    padding: 10px 14px;
                  }
                }
              }
              // --desc

              .commontest-mainpage-single-question-option-each-div {
                margin: 0px 100px 12px 60px;
                max-width: 800px;
                min-height: 44px;
                border-radius: 8px;
                border: 1px solid #d0d5dd;

                .commontest-mainpage-multiple-question-options-input-label-div {
                  max-width: 776px;
                  min-height: 20px;
                  margin: 10px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .options-input-label-div {
                    display: flex;
                    align-items: center;
                    width: 95%;

                    .commontest-mainpage-multiple-question-options-input {
                      height: 16px;
                      width: 16px;
                      color: #d0d5dd !important;
                      margin: 0;
                    }

                    .commontest-mainpage-multiple-question-options-input:checked {
                      height: 16px;
                      width: 16px;
                      // accent-color: #F96343 !important;
                    }

                    .commontest-mainpage-multiple-question-options-label {
                      font-family: "Inter";
                      font-size: 14px;
                      font-weight: 400;
                      color: #101828;
                      margin: 0 0 0 8px;
                      width: 90%;
                      word-break: break-word;
                    }
                  }
                }
              }
            }
          }
        }

        .commontest-mainpage-single-multiple-question-answer-score-div {
          .mcq-admin-answer-div {
            .mcq-admin-answer-label-div {
              max-width: 840px;
              margin: 0px 60px 0 60px;
              font-family: "Inter";
              font-size: 14px;
              font-weight: 500;
              color: #344054;
            }

            .mcq-admin-answer {
              width: 80%;
              margin: 0px 0px 0 60px;
              resize: none;
              font-size: 15px;
              font-weight: 400;
              border-radius: 8px;
              font-family: "Inter";
              border: 1px solid #d0d5dd;
              background-color: #ffffff;
              padding: 10px 14px;
            }

            .tox-tinymce {
              width: 80%;
              margin: 0px 0px 0 60px;
              border: 1px solid #d0d5dd !important;
              font-family: "Inter" !important;
              font-size: 15px;
              font-weight: 400;
              border-radius: 8px !important;
            }
          }

          .commontest-mainpage-single-multiple-question-divider-div {
            max-width: 876px;
            border-top: 1px solid #d0d5dd;
            margin: 24px 45px;
          }
        }
      }
    }
  }
}

.testContainerChildLeftHeaderSectionTitle2 {
  display: flex;

  .testContainerChildLeftHeaderSectionTitleLabel {
    height: 24px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    color: #101828;
    margin: 14px 0 12px 24px;
  }

  .testContainerChildLeftHeaderSectionTitleName {
    height: 25px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    color: #101828;
    margin: 12.5px 0 12.5px 8px;
  }
  .singleViewQuestionBoxTitleMarks {
    height: 25px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    color: #101828;
    margin: 12.5px 0 12.5px 8px;
  }
}

.singleQuestionContainerDescription {
  width: 80%;
  margin: 0 0 0 60px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  color: #344054;
}
