@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.personalInfoSec,
.detailCont,
.companyProfileSec {
  .personalInfoTitleSec {
    height: 60px;
    padding: 16px 20px 16px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    .personalInfoTitle {
      margin: 0;
      @include m.fontSize(text/lg/medium);
    }
  }
  .personalInfoForm {
    display: flex;
    gap: 39px;
    margin: 0 28px;
    .personalInfoLeftForm {
      display: flex;
      flex-wrap: wrap;
      width: 75%;
      gap: 32px;
      height: fit-content;
      .MuiFormControl-root {
        label {
          font-family: "Inter";
          color: map-get(i.$appColor, "grey/500");
        }
        #skillName {
          @include m.fontSize(text/md/regular);
          color: map-get(i.$appColor, "grey/900");
        }
        fieldset {
          border: 1px solid #98a2b3;
        }
      }
      .personalInfoNameSec {
        display: flex;
        gap: 40px;
        width: 100%;
      }
      #personalInfoFName,
      #personalInfoLName,
      #personalInfoEmail,
      #personalInfoDOB,
      .personalInfoPhone,
      #personalInfoAddress,
      .personalInfoIntro,
      .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        @include m.fontSize(text/md/regular);
        color: map-get(i.$appColor, "grey/900");
      }
      #personalInfoFName,
      #personalInfoLName {
        width: 50%;
      }
      .personalInfoPhoneDOBSec {
        display: flex;
        gap: 40px;
        width: 100%;
        .personalInfoPhoneSec {
          display: flex;
          border-radius: 4px;
          width: 50%;
          .personalInfoCode {
            padding: 10px 12px 10px 14px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid map-get(i.$appColor, "grey/300");
            border-radius: 8px 0 0 8px;
            @include m.fontSize(text/md/regular);
          }
          .personalInfoPhone {
            width: calc(100% - 54px);
            height: 40px;
            padding: 10px 0px 10px 14px;
            border: 1px solid map-get(i.$appColor, "grey/300");
            border-radius: 0px 4px 4px 0px;
            border-left: none;
            outline: none;
          }
        }
        .personalInfoDOBSec {
          display: flex;
          width: 48%;
          .MuiFormControl-root {
            flex: 1 0;
            height: 40px;
            label {
              margin: 0;
              top: -5px;
            }
            label[data-shrink=true]{
              top: 0;
            }
          }
          .MuiInputBase-root {
            height: 40px;
            padding: 10px;
            overflow: hidden;
            @include m.fontSize(text/md/regular);
            color: map-get(i.$appColor, "grey/900");
          }
        }
      }
      #personalInfoAddress {
        min-height: 100px !important;
      }
      .personalInfoIntroSec {
        width: 100%;
        #personalInfoIntroTitle {
          @include m.fontSize(text/xs/regular);
          color: map-get(i.$appColor, "grey/700");
        }
        .personalInfoIntroFieldSec {
          display: flex;
          margin-top: 6px;
          .personalInfoIntroCode {
            padding: 10px 12px 10px 14px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid map-get(i.$appColor, "grey/300");
            border-radius: 4px 0 0 4px;
            @include m.fontSize(text/md/regular);
            color: map-get(i.$appColor, "grey/900");
          }
          .personalInfoIntro {
            height: 40px;
            padding: 10px 14px 10px 14px;
            border: none;
            border: 1px solid map-get(i.$appColor, "grey/300");
            border-radius: 0 4px 4px 0;
            border-left: none;
            outline: none;
          }
        }
      }
      .personalInfoCareerObjSec {
        display: flex;
        flex-direction: column;
        width: 100%;
        #personalInfoCareerObj {
          min-height: 77px !important;
        }
        #personalInfoCareerObjHText {
          padding: 3px 14px 0;
          @include m.fontSize(text/xs/regular);
          color: #00000099;
        }
      }
    }
    .personalInfoRightForm {
      margin: 0;
      width: 25%;
    }
  }
  .personalInfoBtnContainer {
    margin: 24px 0 22px 0;
    width: 100%;
    text-align: right;
  }
  .personalInfoBtnSec {
    margin-right: 24px;
  }
}

.detailCont{
  height: calc(100vh - 150px);
  overflow-y: auto;
}
