@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

// .collapseContainer {
.collapseContainer {
  border: 1px solid map-get(i.$appColor, "grey/300");
  overflow: hidden;
  width: 100%;
  height: 68px;
  display: table-row;
  background: map-get(i.$appBackGroundColor, "white");
  border-left: none;
  border-right: none;
  .collapseHeader {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    .collapseTitle {
      margin: 0;
      padding: 0;
      @include m.fontSize(text/xl/semibold);
      color: map-get(i.$appColor, "grey/700");
    }
    .collapseRightContainer {
      display: flex;
      gap: 16px;
      .collapseRightDeleteIcon {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background: unset;
        border: none;
      }
      .collapseRightDeleteIcon:hover {
        background: map-get(i.$appBackGroundColor, "grey/50");
      }
      .collapseButton {
        background: none;
        border: none;
        font-size: 20px;
        line-height: 20px;
        cursor: pointer;
      }
    }
  }
}
.collapseContainer.open {
  border-bottom: unset;
}
.collapseContent {
  overflow: hidden;
  transition: max-height 0.3s ease, display 0.3s ease;
  max-height: 0;
  display: none;
}

.collapseContent.open {
  display: block;
  padding: 16px 24px;
  overflow: auto;
}
// }
