@use "../Abstracts/mixins" as m;

.common-btn-div {
  display: flex;
  justify-content: flex-end;
}

.common-btn {
  border: none;
  @include m.fontSize(text/sm/semibold);
  font-size: 16px !important;
  // font-weight: 600;
  border-radius: 8px;

  &.small {
    &.prim {
      @include m.btn(primary, small);
    }

    &.sec {
      @include m.btn(secondary, small);
    }
  }

  &.medium {
    &.prim {
      @include m.btn(primary, medium);
    }

    &.sec {
      @include m.btn(secondary, medium);
    }
  }

  &.large {
    &.prim {
      @include m.btn(primary, large);
    }

    &.sec {
      @include m.btn(secondary, large);
    }
  }
}