@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

.modalBodyContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .modalBodyContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .modalBodyHeader,
  .modalBodyMain,
  .modalBodyFooter {
    display: flex;
    flex-wrap: wrap;
  }
  .modalBodyHeader,
  .modalBodyMain {
    gap: 8px;
  }
  .modalBodyMain {
    flex-direction: column;
  }
  .modalBodyFooter {
    gap: 12px;
    width: 100%;
  }
}
