.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  cursor: pointer;
  padding: 5px 12px;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}

.InputForColumns {
  gap: 10px;
  width: 80px;
  height: 34px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 7px 10px 7px 10px;
  border: 1px solid #bdc1c6;
}

.queEntInpParInpBox {
  gap: 10px;
  width: 200px;
  height: 34px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

.queEntInpParSecVarTyp {
  top: -20px;
  float: left;
  width: 200px;
  text-align: left;
  position: relative;
}

.queEntInpParLab {
  left: -575px;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  margin-left: 25px;
  font-style: normal;
  margin-bottom: 6px;
  position: relative;
  margin-bottom: 0px;
  font-family: "Inter";
}

.queEntInpParSecVarNam {
  float: left;
  text-align: left;
  margin-left: 16px;
}

.queEntInpParSec {
  gap: 20px;
  width: 620px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 0px;
  align-items: flex-start;
}

.queEntInpParSecAddButAct {
  padding: 0;
  margin: 0px;
  width: 79px;
  height: 34px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  background: #d6d6d6;
}

.queEntInpParSecAddButDis {
  padding: 0;
  width: 79px;
  margin: 0px;
  height: 34px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  background: #f55533;
}

.queEntInpVarTab th {
  font-size: 12px;
}

.queEntInpVarTab .ant-table-cell {
  border: 1px solid #bdc1c6;
}

.table-inputvars-delete-button {
  color: #0d6efd;
}

.queEntInpParSecAddButDiv {
  margin: 0px;
}

.queEntInpVarTab {
  margin-left: 16px;
  margin-bottom: 20px;
}

.queEntInpParSecVarTyp .css-tj5bde-Svg {
  margin-top: 0px;
}
