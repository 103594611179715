@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.projectFormContainer {
    height: 450px;
    // width: 848px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 10px 0 0 0;
    #projectFormTitle,
    #projectFormStartdate,
    #projectFormEnddate,
    #projectFormExplaination,
    #projectFormContribution,
    #projectFormLearn {
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/900");
    }
    #projectFormExplainationHText,
    #projectFormContributionHText,
    #projectFormLearnHText {
      @include m.fontSize(text/xs/regular);
      color: #00000099;
      padding-left: 14px;
    }
    .projectFormDateSec {
      display: flex;
      gap: 40px;
      .MuiFormControl-root {
        flex: 1 0;
        height: 40px;
      }
      .MuiInputBase-root {
        height: 40px;
        padding: 10px;
        overflow: hidden;
      }
      
    }
  }
  