.upcoming-box-div {
  background-color: #ffffff;
  width: 440px;
  height: 248px;
  border: 1px solid #e3e8ef;
  border-radius: 20px;
  text-align: left;
  overflow-y: auto;

  .upcoming-box-header {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    margin: 16px 0 16px 16px;
  }

  .upcoming-box-date-month-grid {
    padding: 4px 12px 4px 20px;
  }

  .upcoming-box-date-month {
    width: 44px;
    height: 48px;
    border-radius: 8px;
    background-color: #f2f4f7;
    text-align: center;

    .upcoming-box-date {
      font-family: "Inter";
      width: 100%;
      font-size: 12px;
      font-weight: 600;
      color: #344054;
      padding: 6px 0 0 0;
    }

    .upcoming-box-month {
      font-family: "Inter";
      width: 100%;
      font-size: 12px;
      font-weight: 600;
      color: #344054;
    }
  }

  .upcoming-box-title-time-grid {
    width: 150px;
    height: 42px;
    margin: 10px 0 0 20px;
  }

  .upcoming-box-event {
    max-width: 195px;
    max-height: 20px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin: 0 0 4px 0;
    white-space: nowrap;
  }

  .upcoming-box-time {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    color: #667085;
  }

  .upcoming-event-pagination-box {
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0 0 0;

    .assignment-due-pagination-box-arrowback,
    .assignment-due-pagination-box-arrowforward {
      height: 12px;
      width: 12px;
      color: #344054;
    }

    .assignment-due-pagination-box-arrowback.disabled,
    .assignment-due-pagination-box-arrowforward.disabled {
      height: 12px;
      width: 12px;
      color: #d0d5dd;
    }

    .assignment-due-pagination-box-dots {
      cursor: pointer;
      color: #d0d5dd;
      margin: 0 6px 3px 6px;
    }

    .assignment-due-pagination-box-dots.selected {
      cursor: pointer;
      color: #344054;
      margin: 0 6px 3px 6px;
    }
  }

  .upcoming-event-empty-box {
    width: 392px;
    height: 175px;
    margin: 0 0 0 24px;
    display: flex;
    align-items: center;
    div {
      width: 392px;
      text-align: center;
      font-family: "Inter";
      font-size: 18px;
      font-weight: 500;
      color: #667085;
    }
  }
}
