@use "./Pages/test";
@use "./Pages/users";
@use "./Pages/batch";
@use "./Pages/report";
@use "./Pages/ccHome";
@use "./Pages/setting";
@use "./Pages/timeLine";
@use "./Pages/leetCode";
@use "./Pages/listView";
@use "./Pages/interview";
@use "./Pages/dashBoard";
@use "./Components/popup";
@use "./Components/radio";
@use "./Components/modal";
@use "./Components/badge";
@use "./Pages/userReport";
@use "./Pages/codingPage";
@use "./Pages/contentPage";
@use "./Components/reject";
@use "./Components/button";
@use "./Pages/mockTestPage";
@use "./Components/buttons";
@use "./Components/loading";
@use "./Components/overlay";
@use "./Components/approve";
@use "./Layout/Score/score";
@use "./Components/tooltip";
@use "./Layout/User/resume";
@use "./Pages/profile.scss";
@use "./Components/checkbox";
@use "./Layout/Profile/work";
@use "./Components/collapse";
@use "./Components/comments";
@use "./Pages/browserSupport";
@use "./Layout/Notify/notify";
@use "./Pages/libraryDetails";
@use "./Components/modalBody";
@use "./Components/pdfViewer";
@use "./Pages/assignmentPage";
@use "./Components/testLabel";
@use "./Layout/Header/header";
@use "./Components/RejectBulk";
@use "./Components/warningMsg";
@use "./Layout/Home/rightMain";
@use "./Components/parentInfo";
@use "./Components/iconButton";
@use "./Components/inputField";
@use "./Components/hackathons";
@use "./Components/issueTable";
@use "./Pages/instructionPage";
@use "./Layout/Score/scoreLog";
@use "./Components/statusLabel";
@use "./Components/testDueDate";
@use "./Layout/Home/leftNavBar";
@use "./Components/progressBar";
@use "./Components/studentList";
@use "./Components/backSvgIcon";
@use "./Pages/registrationPage";
@use "./Layout/MenuBar/menuBar";
@use "./Components/buttonGroup";
@use "./Components/profileuser";
@use "./Components/companyLogo";
@use "./Components/adminHeader";
@use "./Layout/User/userDetails";
@use "./Components/MenuDropDown";
@use "./Components/MainDropDown";
@use "./Components/AngleLeftSvg";
@use "./Components/academicWork";
@use "./Components/themeSvgIcon";
@use "./Components/resetSvgIcon";
@use "./Components/deleteSvgIcon";
@use "./Layout/Notify/notifyBody";
@use "./Components/reviewSvgIcon";
@use "./Layout/Zoom/ZoomAct.scss";
@use "./Components/batchDropdown";
@use "./Components/AngleRightSvg";
@use "./Components/rejectSvgIcon";
@use "./Components/educationFrom";
@use "./Components/vidRecordCard";
@use "./Components/inputDropdown";
@use "./Layout/User/uploadgcatfb";
@use "./Components//toggleButton";
@use "./Layout/Dashboard/trainer";
@use "./Components/publishcourse";
@use "./Components/warningSvgIcon";
@use "./Components/commentSvgIcon";
@use "./Components/approveSvgIcon";
@use "./Components/leftNavSvgIcon";
@use "./Components/profileSideNav";
@use "./Components/socialProfiles";
@use "./Components/companyProfile";
@use "./Components/DashboardTable";
@use "./Components/halfDonutChart";
@use "./Layout/Dashboard/timeline";
@use "./Components/DownloadOptions";
@use "./Components//AdminDashboard";
@use "./Components/downloadSvgIcon";
@use "./Components/inputGroupField";
@use "./Layout/Profile/projectForm";
@use "./Layout/Zoom/ZoomPopup.scss";
@use "./Components/complexityLabel";
@use "./Components/personalInfoForm";
@use "./Layout/Profile/certificates";
@use "./Layout/Profile/achievements";
@use "./Components/paginationCustom";
@use "./Components/videoSectionView";
@use "./Components/chevronUpSvgIcon";
@use "./Layout/MenuBar/menuBarFooter";
@use "./Components/fullScreenSvgIcon";
@use "./Layout/Profile/profileUpload";
@use "./Components/dragAndDropUpload";
@use "./Layout/Dashboard/leaderBoard";
@use "./Layout/JobsInternship/jobForm";
@use "./Layout/Profile/academicDetail";
@use "./Layout/UserReport/monthPicker";
@use "./Layout/CodingPage/codingTable";
@use "./Layout/Zoom/WarningPopup.scss";
@use "./Components/multiLevelDropDown";
@use "./Layout/CodingPage/codingEditor";
@use "./Layout/CodingPage/codingOutput";
@use "./Layout/Dashboard/progrssCircle";
@use "./Layout/Dashboard/sessionDetail";
@use "./Layout/Dashboard/dashboardForm";
@use "./Components/WidgetConfiguration";
@use "./Layout/Dashboard/upcomingEvent";
@use "./Layout/CodingPage/codingHeader";
@use "./Layout/Dashboard/tableWithTotal";
@use "./Layout/CodingPage/codingProblem";
@use "./Layout/Dashboard/assignmentsDue";
@use "./Layout/Hackathons/hackathonCard";
@use "./Layout/Hackathons/hackathonForm";
@use "./Layout/GradiousEvaluation/skill";
@use "./Layout/JobsInternship/jobDetails";
@use "./Layout/Codingchallenge/ccSection";
@use "./Layout/Library/sharedTopics.scss";
@use "./Layout/BatchSettings/batchDetails";
@use "./Layout/BatchSettings/skillMapping";
@use "./Layout/CommonTests/commonTestPage";
@use "./Layout/InstructionPage/testDetail";
@use "./Components/LeaderBoardndPercentile";
@use "./Layout/BatchSettings/batchTrainers";
@use "./Layout/CommonTests/backdropCounter";
@use "./Layout/Zoom/ZoomBatchCarousel.scss";
@use "./Layout/MockTestPage/Admin/mockTest";
@use "./Layout/Header/headerRightNavSvgIcon";
@use "./Components/warningNoticeBodySvgIcon";
@use "./Layout/InstructionPage/warningNotice";
@use "./Layout/Notify/codingPageSuccessNotify";
@use "./Layout/CommonTests/commonTestMainPage";
@use "./Layout/Codingchallenge/ccSecQuestions";
@use "./Layout/ReleasePopup/releasePopup.scss";
@use "./Layout/UserReport/userTimeSpendReport";
@use "./Layout/MockTestPage/Admin/mockTestList";
@use "./Layout/AssignmentPage/uploadAssignment";
@use "./Layout/CodingPage/codingHeaderAdminSide";
@use "./Layout/CommonTests/commonTestQuestionBox";
@use "./Layout/MockTestPage/Admin/mockTestConfig";
@use "./Layout/AssignmentPage/sandpackassignment";
@use "./Layout/AssignmentPage/recommendedWebsites";
@use "./Layout/CommonTests/commonTestpageQuesions";
@use "./Layout/CodingPage/codingHeaderLearnerSide";
@use "./Layout/InstructionPage/instructionPageBody";
@use "./Layout/UserReport/userTimeSpentReportChart";
@use "./Layout/UserReport/userTimeSpentReportTable";
@use "./Layout/StudentTestPage/studentTestContainer";
@use "./Layout/UserReport/userTestResultReportTable";
@use "./Layout/MockTestPage/Student/mockTestLibrary";
@use "./Layout/MockTestPage/Student/mockTestResults";
@use "./Layout/GradiousEvaluation/gradiousEvaluation";
@use "./Layout/AssignmentPage/assignmentSubmissionTable";
@use "../Components/Admin/Learningpath/Learningpath.scss";
@use "./Layout/FloatingActionButton/FloatingActionBtn.scss";
@use "./Layout/CodingPage/codingPageLearnerSideBreadCrumbs";
@use "./Layout/StudentTestPage/studentTestBodyPageSingleView";
@use "./Layout/JobsInternship/jobCard";
@use "./Layout/JobsInternship/filterJobs";
// @use "./Layout/MockTestPage/Student/mockBanner";
@use "./Components/textarea";
@use "./Components/table";
@use "./Pages/raisedIssueTable";
@use "./Layout/raiseAnIssue/issueForm";
@use "./Layout/Codingchallenge/ccSecQnsUtils";
@use "./Components/toast";
@use "./Components/multiLevelSelect";

body {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-family: "Inter";
  scrollbar-width: none;
  scroll-behavior: smooth;
  box-sizing: content-box;
}

svg {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #ccc;
}
