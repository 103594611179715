@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.toggleBtnContainer {
  display: flex;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  gap:8px;

  /* Small toggle btn */
  .toggleBtn.small {
    width: 36px;
    height: 20px;
    .toggleBtnThumb {
      width: 16px;
      height: 16px;
    }
  }

  /* Medium toggle btn */
  .toggleBtn.medium {
    width: 44px;
    height: 24px;
    .toggleBtnThumb {
      width: 20px;
      height: 20px;
    }
  }

  /* Common style for toggle btn and thumb */
  .toggleBtn {
    padding: 2px;
    border-radius: 12px;
    outline: none;
    border: none;
    position: relative;
    .toggleBtnThumb {
      border-radius: 99px;
      margin: 0;
      background: map-get(i.$appColor, "white");
      box-shadow: 0px 1px 2px 0px #1018280f;
      box-shadow: 0px 1px 3px 0px #1018281a;
      transition: left 0.15s ease;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  /*If toggle btn theme in untoggled scenerio*/
  .toggleBtn.dark {
    background: map-get(i.$appColor, "grey/100");
  }
  .toggleBtn.dark:hover {
    background: map-get(i.$appColor, "grey/200");
  }
  .toggleBtn.dark:focus,
  .toggleBtn.light:focus {
    box-shadow: 0px 0px 0px 4px #f4ebff;
  }
  .toggleBtn.light {
    background: map-get(i.$appColor, "brand/50");
  }
  .toggleBtn.light:hover {
    background: map-get(i.$appColor, "brand/100");
  }
  .toggleBtn:disabled {
    background: map-get(i.$appColor, "grey/100") !important;
    cursor: not-allowed;
  }

  /* If toggle btn is toggled */
  .toggleBtn.toggled {
    margin: 0;
    transition: background 0.15s ease;
    .toggleBtnThumb {
      margin: 0;
    }
  }
  .toggleBtn.toggled.small {
    .toggleBtnThumb {
      left: calc(36px - 18px);
    }
  }
  .toggleBtn.toggled.medium {
    .toggleBtnThumb {
      left: calc(44px - 22px);
    }
  }
  .toggleBtn.toggled.dark {
    background: map-get(i.$appColor, "brand/600");
  }
  .toggleBtn.toggled.light {
    background: map-get(i.$appColor, "brand/200");
  }

  /* Toggle btn label and supporting text */
  .toggleBtnLabelSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* Toggle btn small label and supporting text */
  .toggleBtnLabelSection.small {
    .toggleBtnLabel {
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/700");
    }
    .toggleBtnSupportingText {
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/500");
    }
  }

  /* Toggle btn medium label and supporting text */
  .toggleBtnLabelSection.medium {
    .toggleBtnLabel {
      @include m.fontSize(text/md/medium);
      color: map-get(i.$appColor, "grey/700");
    }
    .toggleBtnSupportingText {
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/500");
    }
  }
}

.toggleBtnContainer.disabled {
  cursor: not-allowed ;
}
