@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

#naviagteToPrevious {
  margin-left: 20px;
  margin-right: 20px;
}

.languageName {
  float: left;
  width: 862px;
  height: 52px;
  text-align: left;
  margin: 25px 0 60px 80px;
}

.admincontentpageone {
  display: flex;
  height: 500px;
  margin-top: 60px;
  position: relative;
  flex-direction: column;
}

.admincontentpageone .adminTestBackBtn {
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 20px;
}

#languageName {
  gap: 10px;
  width: 345px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

.languageName > label {
  width: 45px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}

.topic .select_modules {
  padding-top: 30px !important;
  padding-left: 60px !important;
  padding-bottom: 60px !important;
}

.nextbutton {
  top: 220px;
  left: 80px;
  position: absolute;
}

.cancelToback .btnMedium {
  float: left;
  z-index: 19;
  right: 110px;
  bottom: 20px;
  position: fixed;
}

.contentStudent {
  padding-top: 9px;
  position: relative;
  align-items: center;
  display: inline-flex;
}

.contentStudent h3 {
  font-size: 20px;
  font-weight: 700;
  margin-left: 43px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0em;
  display: inline-flex;
}

.previewBtn .btnMedium {
  top: 220px;
  left: 190px;
  position: absolute;
}

.StudentForm {
  z-index: 2;
  opacity: 1;
  width: 100%;
  height: 60px;
  display: flex;
  flex-shrink: 0;
  position: fixed;
  margin-top: 0px;
  padding-top: 9px;
  background-color: #eceeef;
}

.AdminContentWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: visible;
  width: 1320px !important;
}

.contentPage,
.StudentContentWrapper {
  top: 35px;
  width: 100%;
  height: 92vh;
  overflow: scroll;
  position: relative;
}

.contentPage,
.StudentContentWrapper1 {
  width: 100%;
  height: 92vh;
  overflow: scroll;
  position: relative;
  scrollbar-width: none;
}

.contentPage {
  float: left;
  height: 85vh;
  display: flex;
  margin: 0 0 0 52px;
  flex-direction: column;
}

.button .btnMedium {
  float: right;
  right: 30px;
  z-index: 19;
  bottom: 20px;
  position: fixed;
}

.studentBackButton {
  left: 40px;
  cursor: pointer;
  position: relative;
  align-items: center;
  display: inline-flex;
}

.studentBackButton svg {
  margin-right: 14px;
}

.markAsCompleted {
  z-index: 1;
  width: 100%;
  right: 10px;
  bottom: 15px;
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.topic > .css-1fdsijx-ValueContainer {
  padding: 0px !important;
  height: 35px !important;
}

.content-name-btn {
  top: 45px;
  z-index: 2;
  position: fixed;
  background: #ffffff;
  padding: 10px 52px 10px 10px;
}

.videoPlay,
.beforeFullScreen {
  float: left;
  width: 960px;
  height: 500px;
  cursor: pointer;
  overflow: hidden;
  background: #f2f3f5;
  margin: 0 52px 30px 62px;
  border: 2px solid gainsboro;
}

.videoPlay {
  height: max-content;
}

.oldContent {
  width: 69vw;
  display: flex;
  margin: 35px 0px;
  padding: 0 0 10px 0;
  flex-direction: column;

  .codingPageProblemReactQuill {
    margin: 12px 0 0 0;

    p {
      padding: 8px 0px;
      text-align: justify;
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/700");
    }

    .ql-toolbar {
      display: none;
    }

    .ql-container {
      border: none;
    }

    .ql-editor {
      padding: 0;
    }
  }
}

.linkIcon,
.videoIcon {
  float: left;
  padding: 10px;
  color: #add8e6;
  border-radius: 50%;
  margin: 0px 12px 1% 0;
  border: solid 1px grey;
}

.videoIcon {
  color: #e03858;
}

.content-iframe-div {
  // width: 52%;
  // display: flex;
  // margin: 0 52px;
  position: relative;
  justify-content: center;

  .assignmentReferenceLinkDocument.react-pdf__Document {
    display: flex;
    margin-left: 62px;
    align-items: flex-start;
  }
}
