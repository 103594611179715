.modalTitle {
  font-size: 22px;
  color: #373739;
  font-weight: 700;
  font-style: normal;
  font-family: "Inter";
  margin: 0 0 30px 0px;
}

.modalButtonDivSuccess {
  display: flex;
  width: fit-content;
  margin: 28px 0 0 400px;
  flex-direction: column;
}

.modalButtonDivConfirmNotify {
  display: flex;
  margin: 0px 47.2px 46px 0;
  flex-direction: row-reverse;
}

.modalButton2 {
  margin-right: 27px;
}

.modalButton2Success {
  margin-top: 11.87px;
}

.modalMsg {
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter";
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.modalMsgForTestCasesFailed {
  top: 50px;
  right: 0px;
  width: 353px;
  color: #000;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  position: absolute;
  font-family: "Inter";
}

.testCasesFailedImg {
  width: 350px;
  height: 260px;
  padding: 0px 0px 0px 17px;
}
