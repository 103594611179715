.Mcq-desc-div-wrapper {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 52px);
  background-color: #f2f4f7;

  .Mcq-desc-div {
    height: 90vh;
    width: 100%;
    padding: 4px 24px 0px 24px;
    display: flex;

    .Mcq-desc-mainpage-div {
      // width: 960px;
      // height: 100%;
      width: 70%;
      height: calc(100vh - 64px);
    }

    .Mcq-desc-question-page-div {
      // width: 346px;
      // height: 100%;
      width: 30%;
      height: calc(100vh - 64px);
      margin: 0 0 0 12px;
    }

    .cjjWdp {
      border: none;
      position: relative;
      opacity: 0 !important;
      width: auto !important;
      padding: 5px 8px !important;
    }

    .cjjWdp:hover {
      border: none !important;
      transition: none !important;
    }

    .hEACtv {
      margin: 0px !important;
      background-color: #f2f4f7 !important;
      cursor: row-resize;
    }
  }
}