@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.hackathonCardContainer {
  display: flex;
  width: 100%;
  height: 198px;
  box-shadow: 0px 2px 18px 0px #18191c08;
  background: map-get(i.$appBackGroundColor, "white");
  border: 1px solid map-get(i.$appColor, "grey/300");
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  .hackathonPoster {
    width: 13%;
    height: 198px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    object-fit: cover;
  }
  .hackathonCardBody {
    width: 87%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    // border: 1px solid map-get(i.$appColor, "grey/300");
    // border-left: unset;
    // border-top-right-radius: 8px;
    // border-bottom-right-radius: 8px;
    .hackathonCardBodyContent {
      display: flex;
      flex-direction: column;
      gap: 2px;
      .hackathonTitleSec {
        display: flex;
        justify-content: space-between;
        .hackathonTitleDiv {
          display: flex;
          gap: 16px;
          align-items: center;
          .hackathonTitle {
            margin: 0;
            padding: 0;
            font-family: Inter;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .hackathonNewBadge {
            width: 42px;
            height: 22px;
            border-radius: 16px;
            padding: 2px 8px;
            background: linear-gradient(
              112.74deg,
              #ff5533 17.95%,
              #b43b23 86.74%
            );
            display: flex;
            justify-content: center;
            align-items: center;
            @include m.fontSize(text/xs/medium);
            color: map-get(i.$appColor, "white");
          }
        }
        .toggleBtnContainer {
          flex-direction: row-reverse;
          align-items: center;
          .toggleBtnLabel {
            font-size: 12px;
            font-weight: 400;
            line-height: 28px;
            color: map-get(i.$appColor, "grey/500");
          }
        }
      }
      .hackathonOrganizer {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: map-get(i.$appColor, "grey/500");
      }
    }
    .hackathonCardBodyContent2 {
      gap: 12px;
      .hackathonStartdateSec,
      .hackathonDetails,
      .hackathonLocationSec,
      .hackathonLinkSec {
        display: flex;
        gap: 8px;
        align-items: center;
      }
      .hackathonLocationSec {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .hackathonStartdate,
      .hackathonLocation,
      .hackathonEntryFee,
      .hackathonEntryCategory,
      .hackathonLink {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: map-get(i.$appColor, "grey/700");
      }
      .hackathonEntryFee,
      .hackathonLink {
        color: map-get(i.$appColor, "grey/500");
        text-decoration: none;
      }
      .hackathonLink {
        width: 70%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .hackathonLink:hover {
        text-decoration: underline;
        color: #0073bb;
      }
    }
  }

  .hackathonCardRecommendedSec {
    position: absolute;
    width: 114px;
    top: -20px;
    right: -22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hackathonCardRecommended {
    background: linear-gradient(90deg, #ff392b 0%, #89514d 100%);
    color: white;
    width: 180px;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.1px;
    padding: 5px 20px;
    text-align: center;
    transform: rotate(37.14deg);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  .hackathonCardDeleteSec {
    position: absolute;
    bottom: 10px;
    right: 24px;
  }
}

// @media screen and (max-width: 832px) {
//   .hackathonCardContainer {
//     flex-direction: column;
//   }
// }
