.vidCardContainer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    border-radius: 8px;
    background: #FFFFFF;
    width: 240px;
    min-height: 100px;

    .qnum {
        display: flex;
        gap: 67px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #101828;


    }

    .bottomCardContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .timeContainer {
            display: flex;
            flex-direction: column;

            .allottedTime {
                margin-bottom: 0;
                font-family: Inter;
                font-size: 10px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                color: #667085;
            }

            .timeInMinutes {
                margin-bottom: 0;
                font-family: Inter;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                text-align: left;
                color: #344054;


            }
        }

        .questionStatusUnAnsContainer,
        .questionStatusAnsContainer {
            display: flex;
            padding: 2px 8px 2px 8px;
            gap: 0px;
            border-radius: 16px;

            .statusErrText,
            .statusSucText {
                font-family: Inter;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                text-align: center;
                //color: #B42318;
            }
        }

        .questionStatusUnAnsContainer {
            background: #FEF3F2;

            .statusErrText {
                color: #B42318;
            }
        }

        .questionStatusAnsContainer {
            background: #ECFDF3;

            .statusSucText {
                color: #027A48;

            }
        }
    }


}

.vidCardContainer:hover{
    display: flex;
    background: #F9FAFB;
    width: 240px;
    min-height: 100px;
    padding: 16px;
    gap: 8px;
    border-radius: 8px;
    background: #F9FAFB;
    transform: translate3D(0,-1px,0) scale(1.03);

}