@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.testDueDate {
  text-align: left;
  margin: 7px 25px 0 0px;
  @include m.fontSize(text/sm/regular);

  .testDueDateLabel {
    color: map-get(i.$appColor, "grey/500");
  }
}
