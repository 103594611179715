.loginpg {
  margin: 0;
  width: 60%;
  float: left;
  height: 100vh;
  position: absolute;
  background-size: cover;
  background-image: url("../../assets/images/ShiftingBGGradient.png");
}

.loginForm {
  margin: 0px;
  width: 40%;
  float: right;
  height: 100vh;
  text-align: center;
  background: #f2f3f5;
}

.logo1 {
  width: 30%;
  height: 50px;
  margin-top: 250px;
  align-content: center;
}

.tabs {
  font-size: 16px;
  margin-top: 51x;
  font-weight: 700;
  color: #202124;
  font-style: normal;
  font-family: "Inter";
}

.signInForm {
  margin-top: 20px;
}

#signInBtn {
  border: none;
  padding: 0px;
  margin-top: 49px;
}
