@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.studentTestContainerMain {
  width: 100%;
  overflow: hidden;
  background: #f2f4f7;
  height: calc(100vh - 52px);

  .studentTestContainerBodyPage,
  .studentTestContainerBodyPageForPractice {
    height: 90vh;
    width: 100%;
    display: flex;
    padding: 4px 24px 0px 24px;

    .studentTestContainerBodyPageDiv {
      width: 70%;
      height: calc(100vh - 64px);
    }

    .studentTestContainerQuestionPage {
      width: 30%;
      margin: 0 0 0 12px;
      height: calc(100vh - 64px);
    }

    .cjjWdp {
      border: none;
      position: relative;
      opacity: 0 !important;
      width: auto !important;
      padding: 5px 8px !important;
    }

    .cjjWdp:hover {
      border: none !important;
      transition: none !important;
    }

    .hEACtv {
      cursor: row-resize;
      margin: 0px !important;
      background-color: #f2f4f7 !important;
    }
  }

  .studentTestContainerBodyPageForPractice {
    justify-content: space-around;
  }
}
