@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.halfDonutChartMainContainer {
  margin: auto;
  width: 300px;
  height: 150px;
  position: relative;

  .halfDonutChartInnerDiv {
    top: 60%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

    .innerCircle {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .semicircle {
        width: 240px;
        height: 120px;
        display: flex;
        padding-top: 10%;
        align-items: center;
        justify-content: space-around;
        border-top-left-radius: 120px;
        border-top-right-radius: 120px;
        color: map-get(i.$appColor, "error/500");
        @include m.fontSize("display/lg/semibold");
        background: map-get(i.$appBackGroundColor, "grey/100");
      }
    }
  }
}
