@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.profileContainerLearner,.profileContainerAdmin{
    background: map-get(i.$appColor,"grey/100" );
    // position: fixed;
    width: 100%;
    max-height:calc(100vh - 52px);
    overflow: auto;
    .profileBackBtnSec{
        width: 100%;
        height: 18px;
        padding: 0 32px;
        margin: 4px 0 0 0;
       .profileBackBtn{ 
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 42px;
            .profileBackBtnText{
                margin: 0;
                color: map-get(i.$appColor, "grey/500");
                @include m.fontSize(text/xs/regular);
            }
        }
    }
    .profileInfoSection{
        display: flex;
        gap: 16px;
        width: 100%;
        height: calc(100vh - 74px);
    }
}

.profileContainerAdmin{
    background: map-get(i.$appColor,"grey/100" );
    position: relative;
    width: 100%;
    top: 7vh;
    max-height:calc(100vh - 52px);
    overflow: auto;

}