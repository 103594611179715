.attendanceReportSessionName {
    float: left;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    font-style: normal;
    font-family: Inter;
    margin: 0 0 0 40px;
    color: var(--knock-gray-900, #1A1F36);
}
.attendanceRecordBack{
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 0;

}

.attendanceReportSessionField {
    font-size: 16px;
    text-align: left;
    color: #474747;
    font-weight: 400;
    line-height: 30px;
    font-family: Inter;
    font-style: normal;
    margin: 25px 0 0 50px;
}

.selectFileFormat {
    outline: none;
    padding: 8px 16px;
    margin: 0 0 0 16px;
}

.sessionAttendanceConfig {
    font-size: 16px;
    text-align: left;
    color: #373739;
    font-weight: 700;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    margin: 15px 0 0 40px;
}

.sessionAttendanceConfigInstruction {
    text-align: left;
    margin: 15px 0 0 60px;
}

.multi-range-slider-black .bar-inner {
    flex-grow: 1;
    display: flex;
    flex-shrink: 1;
    position: relative;
    border: none !important;
    background-color: lime;
    box-shadow: none !important;
    justify-content: space-between;
}

.multi-range-slider-black .thumb::before {
    content: '';
    z-index: 1;
    width: 20px;
    cursor: pointer;
    position: absolute;
    border: none !important;
    height: 20px !important;
    background-color: inherit;
    box-shadow: none !important;
    border-radius: 50% !important;
    margin: -6px -12px !important;
}

.multi-range-slider-black .bar-right {
    width: 25%;
    border: none !important;
    box-shadow: none !important;
    background-color: #f0f0f0;
    border-radius: 0px 10px 10px 0px;
}

.MultiRangeSlider {
    margin: 40px 0 0 52px;
}

.resetAttendanceBtn {
    bottom: 55px;
    right: 130px;
    border: none;
    font-size: 16px;
    color: #373739;
    font-weight: 700;
    text-align: right;
    padding: 8px 10px;
    font-family: Inter;
    font-style: normal;
    position: relative;
    border-radius: 8px;
    line-height: normal;
    background: #E5E5EA;
}

.multi-range-slider-black .bar-left {
    width: 25%;
    padding: 4px 0px;
    border: none !important;
    background-color: #f0f0f0;
    box-shadow: none !important;
    border-radius: 10px 0px 0px 10px;
}

.multi-range-slider-black {
    padding: 0px;
    width: 300px;
    display: flex;
    user-select: none;
    position: relative;
    border-radius: 10px;
    flex-direction: column;
    border: solid 1px gray;
    -webkit-user-select: none;
    background: none !important;
    box-shadow: none !important;
    -webkit-touch-callout: none;
}

.multi-range-slider-black .thumb .caption * {
    top: 7px;
    right: -10px;
    height: 30px;
    font-size: 75%;
    min-width: 30px;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 5px;
    line-height: 30px;
    line-height: 20px;
    font-family: Inter;
    position: absolute;
    font-style: normal;
    text-align: center;
    border-radius: 15px;
    white-space: nowrap;
    color: #000 !important;
    box-shadow: none !important;
    background: #FFF !important;
}

.attendanceLabel {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: Inter;
    font-style: normal;
    margin: -30px 0 0 0;
}

.dot {
    width: 20px;
    height: 20px;
    display: flex;
    border-radius: 50%;
    margin: 10px 0 0 20px;
    background-color: #bbb;
}

.attendanceUploadWrapper {
    width: 662px;
    overflow: auto;
    border-radius: 10px;
    padding-bottom: 16px;
    background: #FFFFFF;
    box-sizing: border-box;
    margin: 30px 0 10px 49px;
    border: 1px solid #F2F4F7;
}

.attendanceUploadWrapper2 {
    width: 600px;
    margin: 30px;
    height: 300px;
    display: grid;
    border-radius: 50px;
    place-items: center;
    box-sizing: border-box;
    border: 1px dashed rgba(9, 9, 83, 0.5);
}

.attendanceUploadFileBtnSec {
    width: 100px;
    height: 100px;
    display: grid;
    margin-top: 20px;
    border-radius: 50%;
    place-items: center;
    background: rgba(6, 113, 224, 0.2);
}

.attendanceUploadFileBtn {
    width: 50px;
    height: 50px;
    display: grid;
    cursor: pointer;
    border-radius: 50%;
    place-items: center;
    background: #0671E0;
}

#attendanceUploadFileBtnIcon {
    width: 16px;
    height: 16px;
    color: #FFFFFF;
}

#attendanceUploadFileInput {
    opacity: 0;
    width: 50px;
    height: 50px;
    bottom: 100px;
    cursor: pointer;
    position: relative;
}

#attendanceUploadDragText {
    bottom: 50px;
    font-size: 16px;
    font-weight: 700;
    color: #373739;
    line-height: 19px;
    position: relative;
    font-style: normal;
    font-family: 'Inter';
}

#attendanceUploadDragTextCaption {
    position: relative;
    bottom: 55px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(55, 55, 57, 0.5);
}

.attendanceFileUploadStatus {
    float: left;
    margin: 0 31px;
    border-radius: 10px;
    background-color: #E5E5EA;
    width: -webkit-fill-available;
}

.attendanceFileUploadMain {
    float: left;
    font-size: 16px;
    color: #373739;
    font-weight: 700;
    margin-left: 30px;
    line-height: 24px;
    font-family: Inter;
    font-style: normal;
}

.attendanceFileUploadBtn {
    float: right;
    margin: 15px 35px 0px 0;
}

.attendanceFileUploadSubmitBtn {
    gap: 10px;
    border: none;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    text-align: right;
    padding: 8px 10px;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    display: inline-flex;
    background: #0671E0;
    align-items: flex-start;
}

.attendanceFileUploadCancelBtn {
    gap: 10px;
    border: none;
    font-size: 16px;
    color: #373739;
    font-weight: 700;
    text-align: right;
    padding: 8px 10px;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    display: inline-flex;
    align-items: flex-start;
}