@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.achievementContainer {
  // width: 848px;
  padding-bottom: 32px;
  #achievement {
    @include m.fontSize(text/md/regular);
    color: map-get(i.$appColor, "grey/900");
    min-height: 80px;
    max-height: 300px;
    overflow: auto;
  }
}
