@use "../../../Abstracts/mixins" as m;
@use "../../../Abstracts/Variables/index" as i;

.mockTestResultsMainContainer {
  gap: 20px;
  height: 68vh;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  margin-top: 24px;
  scrollbar-width: none;

  .mockTestResultsGrid,
  .mockTestResultsGrid:hover {
    width: 400px;
    height: 206px;
    padding: 20px;
    cursor: pointer;
    border-radius: 8px;
    background: map-get(i.$appColor, "white");
  }

  .mockTestResultsGrid:hover {
    background: map-get(i.$appColor, "grey/100");
  }

  .mockTestResultsGridHeader {
    display: flex;
    justify-content: space-between;
    @include m.fontSize(text/xl/semibold);
    color: map-get(i.$appColor, "grey/700");

    .mockTestResultsGridHeaderTitle {
      width: 300px;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    svg {
      height: 30px;
      width: 31.81px;

      path {
        stroke: map-get(i.$appColor, "grey/700");
      }
    }
  }

  .mockTestResultsGridScoreDiv,
  .mockTestResultsGridCompletionDiv {
    margin-top: 12px;
    @include m.fontSize(text/md/medium);
    color: map-get(i.$appColor, "grey/500");

    .mockTestResultsGridScore,
    .mockTestResultsGridCompletedDate {
      @include m.fontSize(text/lg/medium);
      color: map-get(i.$appColor, "grey/700");
    }

    .mockTestResultsGridCompletedDate {
      display: flex;
      justify-content: space-between;
    }
  }

  .mockTestLibraryGridFooter {
    float: right;
    margin-top: 16px;
  }
}
