@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.batchMainContainer {
  width: 100%;
  overflow: auto;
  background: #fdfaf9;
  scrollbar-width: none;
  height: calc(100vh - 52px);

  .batchLabel {
    margin-left: 80px;
    @include m.fontSize(text/xl/medium);
    color: map-get(i.$appColor, "grey/700");
  }

  .batchInnerDiv {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 80px;
    padding: 20px 0 0 0;
    justify-content: flex-start;
    min-height: calc(100vh - 369px);

    .cardContainer {
      width: 320px;
      cursor: pointer;
      margin-bottom: 20px;

      .css-fk5k9k-MuiPaper-root-MuiCard-root {
        border-radius: 20px;
        box-shadow: 0px 2px 20px 0px #e9d4d41f;
        box-shadow: 0px -2px 20px 0px #e9d4d41f;
      }
    }
  }

  .cardInnerDiv {
    text-align: left;
    border-radius: 10px;
    font-family: "Inter";

    .cardContentForTypeAndImage {
      position: relative;

      .cardBatchImage {
        object-fit: fill;
        border-bottom: 1px solid map-get(i.$appColor, "grey/100");
      }

      .cardBatchType {
        top: 10px;
        right: 10px;
        z-index: 10;
        padding: 5px;
        position: absolute;
      }
    }

    .cardContent {
      padding: 16px 24px;
    }

    .cardContentBatchName {
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      @include m.fontSize(text/xl/medium);
      color: map-get(i.$appColor, "grey/900");
    }

    .cardContentBatchDate {
      @include m.fontSize(text/xs/medium);
      color: map-get(i.$appColor, "grey/500");
    }

    .cardContentDesc {
      max-height: 79px;
      overflow: hidden;
      padding-top: 10px;
      display: -webkit-box;
      text-overflow: ellipsis;
      // -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/700");
    }
  }
}
