@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;



.parentInfoForm {
    display: flex;
    flex-direction: column;
    gap: 39px;
    margin: 0 28px;

    .parentInfoNameSec {
        display: flex;
        gap: 40px;
        width: 100%;
    }

    #parentInfoFName,
    #parentInfoLName,
    #parentInfoEmail,
    .parentInfoPhone,
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        @include m.fontSize(text/md/regular);
        color: map-get(i.$appColor, "grey/900");
    }

    #parentInfoFName,
    #parentInfoLName {
        width: 50%;
    }




    .parentInfoPhoneSec {
        display: flex;
        border-radius: 4px;
        width: 50%;

        .parentInfoCode {
            padding: 10px 12px 10px 14px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid map-get(i.$appColor, "grey/300");
            border-radius: 8px 0 0 8px;
            @include m.fontSize(text/md/regular);
        }

        .parentInfoPhone {
            width: calc(100% - 54px);
            height: 40px;
            padding: 10px 0px 10px 14px;
            border: 1px solid map-get(i.$appColor, "grey/300");
            border-radius: 0px 4px 4px 0px;
            border-left: none;
            outline: none;
        }
    }

    .parentInfoRightForm {
        margin: 0;
        width: 25%;
    }
}

.parentInfoBtnContainer {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin: 40px 0 24px 0;
    padding: 0 24px;
}

.parentInfoBtnSec {
    margin-right: 24px;
}