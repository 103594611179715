@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.adminTestModeSection {
  display: flex;
  flex-direction: column;

  .adminTestModeSectionRadioDiv {
    gap: 15px;
    display: flex;

    .adminTestModeLabel {
      margin: 3px 0 10px 0;
      @include m.fontSize(text/sm/semibold);
      color: map-get(i.$appColor, "grey/900");
    }
  }

  input[type="radio"] {
    cursor: pointer;
    accent-color: map-get(i.$appColor, "brand/600");
  }
}
