.listLpViewContainer,.listBatchViewContainer {
    position: relative;
    // top: 10vh;
    width: 100%;
    // overflow-y: scroll;
    // .scrollableTableListView{
        
    //     min-height: 100px;
    //     max-height: 500px;
    //     overflow-y: scroll;
    // }
    .batchListHeader {
        // position: sticky;
        min-height: 48px;
        max-height: 48px;
        background: #F9FAFB;
        .batchListHeaderCol {
         
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #667085;
        }
    }

    .batchListBodyRow:hover{
        background-color: #FCFCFD;
    }

    .batchListBodyRow{
        cursor: pointer;
    }


    .batchListBodyRowName {
        //styleName: Text sm/Medium;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #344054;
    }

    .batchListBodyRowValue {
        //styleName: Text sm/Regular;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #344054;
    }
}
// .listBatchViewContainer{
//     width: 100%;

// }
// .listLpViewContainer{
//     width: 100%;
//     // margin-left: 20%;
// }
// .width: 100%;