.warningSvgIcon {
  margin-right: 20px;
}

.warningSvgIconForCodingTable {
  margin-right: 25px;
}

.warningSvgIconForAssignmentSubmission {
  margin-right: 8px;
}
