@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;
/* publish content div */

.publish-content-div {
  width: 536px;
}

.deadline-div ,.weightage-div{
  margin: 0 0 16px 0;
}

/* deadline label div */

.deadline-label-div,.weightage-label-div {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
  text-align: left;
  margin: 8px 0 0 0;
}

.mandate{
  color: #f04438;
}

/* calender date field*/

.publish_calender .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 16px;
  color: #667085;
  font-weight: 400;
  padding: 10px 14px;
}

/* calender width and height */

.publish_calender .css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 268px;
  height: 44px;
}

/* calender field radious */

.publish_calender .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 8px 0 0 8px;
  border: 1px solid #d0d5dd;
}

/* Calender Div */

.publish_calender {
  display: inline-block;
}

/* Timer Div */

.Publish_timer {
  display: inline-block;
}

/* Timer field */

.Publish_timer .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 16px;
  color: #667085;
  font-weight: 400;
  padding: 10px 14px;
}

/* Timer width and height */

.Publish_timer .css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 268px;
  height: 44px;
}

/* Timer field radious */

.Publish_timer .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 0 8px 8px 0;
  border: 1px solid #d0d5dd;
}

/* test type div label */

.test-type-div-label {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
  text-align: left;
  margin: 8px 0 0 0;
}

/* all div styles for test mode */

.proctor-div {
  width: 536px;
  display: flex;
  margin: 0 0 12px 0;
  border-radius: 12px;
  justify-content: center;
  border: 1px solid #d0d5dd;
}

.proctor-div-head {
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  color: #475467;
}

.proctor-div-desc {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #667085;
}

.proctor-div-lables {
  margin: 10px;
  width: 400px;
}

.proctor-div-image {
  margin: 10px;
}

.proctor-div-checkbox {
  width: 5%;
  height: 5%;
  margin: 15px 0 0 0;
}

.proctor-div-checkbox span {
  padding: 0;
}

.proctor-div-checkbox .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.checked {
  border-color: #f96343;
}

// .checked .proctor-div-image svg {
//     stroke: #F96343;
// }

.checked .proctor-div-head {
  color: #f96343;
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  color: #ffffff;
  background-color: #344054 !important;

  // border: 1px solid rgba(0, 0, 0, 0.6);
}

.publish-content-div .css-23p0if-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected):hover {
  color: #ffffff;
  background-color: #344054 !important;
}

.publish-content-div .css-23p0if-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: #ffffff !important;
  background: #f96343 !important;
}

.publish-content-div .css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: #ffffff !important;
  background: #f96343 !important;
}

.publish-content-div .css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  color: #ffffff !important;
  background-color: #f96343 !important;
}

.publish-content-div .css-1rtg91x-MuiDateCalendar-root {
  width: 300px !important;
  height: 300px !important;
}

.publish-content-div .css-umzx0k-MuiClock-pin,
.publish-content-div .css-d0vs79-MuiClockPointer-root {
  background-color: #f96343 !important;
}

.publish-content-div .css-f53ilk-MuiClockPointer-thumb {
  background-color: #f96343 !important;
  border: 16px solid #f96343 !important;
}

.publish-content-div .css-zddqwy-MuiClockNumber-root.Mui-selected {
  background-color: #f96343 !important;
}

.publish-content-div .css-1h2qg9i-MuiClockPointer-root {
  background-color: #f96343 !important;
}

.publish-content-div .css-12t0dn4-MuiClockPointer-thumb {
  background-color: #f96343 !important;
  border: 16px solid #f96343 !important;
}

/* Timer */

.publish-content-div .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 0px !important;
  height: 425px !important;
}

/* Weightage */
 .weightage-input-div{
    text-align: left;
 }
.weightage-div{
  display: flex;
.weightage-input{
  width: 57px;
  height: 32px;
  padding: 10px 14px 10px 14px;
  border-radius: 8px ;
  border: 1px solid #D0D5DD;  
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  outline: none;
  color: #667085;
  margin: 0px 10px;
}
   .weightage-label-target{
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    text-align: left;
    margin: 8px 0 0 0;
   }
   .weightage-label-pin-test{
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    text-align: left;
    margin: 8px 0 0 0;
   }
   .weightage-pin-test-chkbx{
    Checkbox{
    margin: 10px 0 0 0;
    height: 14px;
    width: 14px;
    }
   }
}
.weightage-input:focus{
  border-color: #f96343;
}
.target-input:focus{
  border-color: #f96343;
}
