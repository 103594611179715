@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

#zoomPopupDialogContainer {
  .MuiDialog-paper {
    max-width: 920px !important;
    max-height: 620px;
    min-height: 248px;
    box-shadow: 0px 20px 24px -4px #10182814;
    border-radius: 12px;
    padding: 24px;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .zoomPopupDialogTitleSec {
      display: flex;
      gap: 8px;
      margin: 0;
      padding: 0;
      .zoomPopupDialogTitleDiv {
        display: flex;
        flex-direction: column;
        div {
          display: flex;
          align-items: center;
          gap: 5px;
          .zoomPopupDialogTitle {
            padding: 0;
            margin: 0;
            @include m.fontSize(text/lg/semibold);
            color: map-get(i.$appColor, "grey/900");
          }
          span{
            @include m.fontSize(text/sm/regular);
            color: map-get(i.$appColor, "grey/900");
          }
        }
        .zoomPopupDialogTitleCaption {
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appColor, "grey/500");
        }
      }
    }
  }
  #zoomPopupDialogContentContainer {
    padding: 0;
    margin: 0;
    // overflow: hidden;
  }
  #zoomPopupDialogActionContainer {
    display: flex;
    justify-content: center;
    .zoomPopupDialogActionSec {
      display: flex;
      gap: 16px;
      border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
      border-radius: 12px;
      padding: 16px;
      box-shadow: 0px 8px 8px -4px #10182808;
      box-shadow: 0px 20px 24px -4px #10182814;
    }
  }
}

#popupContainer {
  backdrop-filter: blur(2px);
  .MuiPaper-root {
    width: 600px;
    min-height: 400px;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .popupTitle {
      padding: 0;
      .popupTitleComContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .popupTitleComTitleSec {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .popupTitleComTitle {
            @include m.fontSize(text/lg/semibold);
            color: map-get(i.$appColor, "grey/900");
          }
        }
        .popupTitleComCaption {
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appColor, "grey/700");
        }
      }
    }
    #popupBody {
      padding: 0;
      #popupBodyTextField {
        // width: 552px;
        height: 204px !important;
      }
    }
    #popupActions {
      display: flex;
      gap: 12px;
      padding: 0;
      button {
        width: 270px;
      }
    }
  }
}
