@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.browserSupportMainContainer {
  height: 100vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  @include m.fontSize(text/md/regular);
  color: map-get(i.$appColor, "grey/700");

  .browserSupportLogo {
    top: 30%;
    left: 46%;
    position: absolute;
  }
}
