@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.notifyBodyMainContainer {
  .instructionPageNotifyMsgForAudioAccess,
  .instructionPageNotifyMsgForCameraAccess,
  .mcqAndDescPageNotifyMsgForSuccessWhileSubmitting {
    text-align: center;
  }

  .after-submit-container{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px 0 10px 0;

    .after-submit-header{
      @include m.fontSize(text/xl/semibold);
      line-height: 40px;
      color: map-get(i.$appBackGroundColor, "grey/700");
    }
  
    .after-submit-msg{
      // @include m.fontSize(text/md/regular);
      color: map-get(i.$appBackGroundColor, "grey/700");
      line-height: 40px;
      font-weight: 600;
    }
  }

  .notify-div-box-circle{
    display: flex;
    justify-content: center;
    margin: 10px 0 10px 0;

    span{
      height: 58px !important;
      width: 58px !important;
      margin: 3px;
      color: #F55533 !important;
    }

    .css-1rn30mb-MuiCircularProgress-root{
        color: #F55533 !important;
    }
  }

  .right-icon-container{
    height: 78px;
    width: 78px;
    border-radius: 50%;
    padding: 20px;
    background: map-get(i.$appBackGroundColor, "success/100");
  }

  .after_success{
    text-align: center;
  }

  .modalInstructionBodyunorderedListWithTestCasesFailed {
    padding-right: 20px;
    padding-left: 0px !important;

    .modalInstructionBodyTestCasesFailedTextList {
      margin: 10px 0 0 30px;
    }

    div.modalInstructionBodyTestCasesFailedTextList {
      margin: 10px 0px;
    }
  }

  .modalInstructionBodyunorderedListWithoutBulletStyle {
    list-style-type: none;
  }

  .proctoringDataWarning {
    display: flex;
    text-align: center;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: space-between;
  }
}
