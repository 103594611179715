@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.mockTestMainContainer {
  // display: flex;
  // padding: 8px 90px;
  // flex-direction: column;
  // height: calc(100vh - 40px);
  // @include m.fontSize(text/sm/regular);
  // color: map-get(i.$appColor, "grey/700");
  // background: map-get(i.$appColor, "grey/100");

    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 80px;
    padding-right: 80px;
    box-sizing: border-box;
    justify-content: center;
    height: calc(100vh - 40px);
    color: map-get(i.$appColor, "grey/700");
    background: map-get(i.$appColor, "grey/100");

  .mockTestBackDiv {
    width: 75px;
    cursor: pointer;

    .previousIcon {
      margin: 0px;
    }
  }

  .mockTestLabel {
    // margin-top: 16px;
    @include m.fontSize(display/xs/medium);
    // @include m.fontSize(display/sm/medium);
    color: map-get(i.$appColor, "grey/700");
  }

  .mockTestNavBar {
    display: flex;
    justify-content: space-between;

    .mockTestNavBarPath {
      display: flex;

      .mockTestNavBarLabel,
      .mockTestNavBarLabelActive {
        cursor: pointer;
        margin-top: 16px;
        padding: 9px 16px;
        @include m.fontSize(text/sm/medium);
        color: map-get(i.$appColor, "grey/700");
      }

      .mockTestNavBarLabelActive {
        color: map-get(i.$appColor, "brand/500");
        border-bottom: 2px solid map-get(i.$appColor, "brand/500");
      }
    }

    .mocktestSearchBox {
      float: right;
      margin-top: 9px;

      .mockTestLibrarySearch,
      .mockTestLibrarySearch::placeholder {
        width: 239px;
        height: 30px;
        outline: none;
        border-radius: 8px;
        padding: 8px 34px 8px 12px;
        @include m.fontSize(text/sm/regular);
        color: map-get(i.$appColor, "grey/500");
        background: map-get(i.$appColor, "white");
        border: 1px solid map-get(i.$appColor, "grey/200");
      }

      .mockTestLibrarySearch::placeholder {
        padding: 0;
        border: none;
      }

      svg {
        top: 8px;
        right: 25px;
        float: right;
        position: relative;
      }
    }
  }

  hr {
    margin: 0;
  }
}

.mockTestMainContainerForAdmin {
  height: 92vh;
  margin: 0px 16px;
  background: map-get(i.$appColor, "white");
}
