@use "../../Abstracts/Variables/index" as i;
@use "../../Abstracts/mixins" as m;

.batchTrainersContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .batchTrainersSec {
    padding: 0 24px 16px 24px;
  }
  .modalContainer {
    .modalContent{
      width: 400px;
    }
    p {
      margin: 0;
    }
    .contentTitle {
      @include m.fontSize(text/lg/semibold);
      color: map-get(i.$appColor, "grey/900");
    }
    .content {
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/500");
    }
    button {
      width: 170px;
    }
  }
}
