@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.modalMainContainer,
.modalMainContainerForProctor,
.modalMainContainerFullScreen,
.modalMainContainerForProctorBottom {
  .modal-content {
    right: 0;
    width: 0px;
    border: none;
    min-width: -webkit-fill-available;
  }

  .modalHeader,
  .modalHeaderWithTitleSvgOnly {
    border-bottom: 0px;
    padding: 24px 24px 20px 24px;

    .modalTitle {
      margin-bottom: 0px;
      @include m.fontSize(text/lg/semibold);

      .modalHeaderTitleSvg {
        padding-bottom: 20px;
      }
    }

    .btn-close,
    .btn-close:focus,
    .btn-close:hover {
      width: 24px;
      height: 24px;
      padding: 6px;
      box-shadow: none;
      color: map-get(i.$appColor, "grey/500");
    }
  }

  .modalHeaderWithTitleSvgOnly {
    justify-content: center;
  }

  .modalHeaderForLeaderBoard {
    right: 0;
    z-index: 10;
    border: none;
    position: absolute;
  }

  .modalBody {
    padding: 0px 24px;
    @include m.fontSize(text/sm/regular);

    .instructionPageBodyUl {
      width: 100%;
    }
  }

  .modalFooter {
    padding: 24px;
    border-top: 0px;
  }

  .modalFooter.fill {
    padding: 24px;
    display: flex;
    border-top: 0px;
    flex-wrap: nowrap;

    button,
    button:focus {
      min-width: 49%;
    }
  }
}

.modalMainContainerFullScreen {
  gap: 24px;
  top: 50px !important;
  border-radius: 10px 10px 0px 0px;
}

.modalMainContainerForProctor {
  .modal-dialog {
    margin: 0;
    height: 0px;
    display: block;
  }
}

.modalMainContainerForProctorBottom {
  .modal-dialog {
    height: 0px;
    display: flex;
    margin-right: 0;
    align-items: flex-end;
  }
}
