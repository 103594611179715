.rejectOptionContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    

}

#pendingtestsOfLearners{
    position: relative;
    right: 0px;
    padding-bottom: 15px;

}

.nestedCheckOptionforReject{

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 33px; 
}

.nestedOption{

}
// .rejectOptionsforBulkUsers{
//     position: relative;
//     // right: 110px;
// }

.headingExtendedTime{
    display: flex;
    padding-top: 15px;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #373739;
    line-height: 19px;
    font-style: normal;
    font-family: "Inter";
}
.RejectDatePickerDiv{
    display: flex;
    padding-top: 10px;
    margin-left: 33px;
}

.rejectBulkConfirmsection{
    display: flex;
    padding-top: 50px;
}

.rejectBulkUsers .css-1xp24qo{
    height: fit-content !important;
    width: max-content !important;
}
.pendingtestlabel,.selectExtendlabel,.confirmselectedOptionlabel,.rejectOptionsforBulkUsers{
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #373739;
    line-height: 19px;
    font-style: normal;
    font-family: "Inter";
}
.rejectbulkDatePicker,.rejectBulk-save-section{
    margin: 8px 0px 8px 0px;
}
.rejectBulkbtnsec{
    position: relative;
    display: flex;
    gap: 8px;
    margin-left: 8px;
}
.rejectBulkUsers .rejectOptionContainer.react-datepicker-popper{
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(24px, 80px);
}