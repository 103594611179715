@use '../Abstracts/mixins' as m;
@use '../Abstracts/Variables/index' as i;

.Leetcode_box{
    background-color: #FFFFFF;
    width: 1024px;
    border: 1px solid #E3E8EF;
    border-radius: 20px;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 0 12px;

    .platform_name{
        margin: 20px 0 20px 40px;
    }

    .Leetcode_parent_box{
        display: flex;

        .Leetcode_left_box{
            display: flex;
            flex-direction: column;
            width: 65%;

            .profile_box{
                margin: 32px 0 0 40px;
                display: flex;
                align-items: center;

                .profile{

                }

                .rank_box{
                    margin: 0 0 0 20px;

                    .username{
                        color: map-get(i.$appBackGroundColor, "grey/700");
                        @include m.fontSize(text/xl/medium);
                        margin: 0 0 8px 0;
                    }

                    .userrank{
                        display: flex;
                        align-items: center;

                        .userrank_rank{
                            @include m.fontSize(display/sm/medium);
                            color: map-get(i.$appBackGroundColor, "brand/500");
                            margin: 0 0 0 5px;
                        }
                    }
                }
            }

            .skill_box{
                // margin: 40px 24px 32px 40px;
                margin: 40px 0px 32px 40px;
                display: flex;
                flex-direction: row;
                max-height: 130px;
                overflow: scroll;

                .skill_stats_box.intermediate{
                    margin: 0 24px;
                }

                .skill_stats_box{

                    .skill_stats_header{
                        color: map-get(i.$appBackGroundColor, "grey/700");
                        @include m.fontSize(text/sm/semibold);
                    }

                    .skill_stats_names.advanced{
                        background: map-get(i.$appBackGroundColor, "success/50");
                    }

                    .skill_stats_names.intermediate{
                        background: map-get(i.$appBackGroundColor, "warning/50");
                    }

                    .skill_stats_names.fundamental{
                        background: map-get(i.$appBackGroundColor, "error/50");
                    }

                    .skill_stats_names{
                        margin: 8px 0 0 0;
                        padding: 12px;
                        width: max-content;
                        border-radius: 8px;

                        .stats_name{
                            display: flex;
                            align-items: center;
                            color: map-get(i.$appBackGroundColor, "grey/700");
                            @include m.fontSize(text/sm/regular);
                            margin: 0 0 8px 0;

                            .stats_name_count{
                                color: map-get(i.$appBackGroundColor, "grey/500");
                                @include m.fontSize(text/sm/regular);
                                margin: 0 0 0 4px;
                            }
                        }
                    }
                }
            }

        }

        .Leetcode_right_box{
            width: 31%;
            // margin: 32px 66px 32px 50px;
            margin: 32px;

            .right_content_box{
                display: flex;
                flex-direction: column;
                width: fit-content;

                .circle_bar_box{
                    width: 314px;
                    height: 160px;
                    position: relative;

                    .top_circle_bar{
                        position: absolute;
                        bottom: 15px;
                    }
        
                    .middle_circle_bar{
                        position: absolute;
                        left: 20px;
                        bottom: 15px;
                    }
        
                    .bottom_circle_bar{
                        position: absolute;
                        left: 40px;
                        bottom: 15px;
                    }

                    .circle_bar_percentage{
                        position: absolute;
                        // bottom: 10px;
                        // left: 100px;
                        bottom: 5%;
                        left: 38%;
                        color: map-get(i.$appBackGroundColor, "grey/700");
                        @include m.fontSize(display/sm/medium);
                    }
                }

                .complexity_box{
                    width: 314px;
                    height: 59px;
                    margin: 39px 0 0 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .complexity_type_box.medium{
                        margin: 0 17px 0 17px;
                    }

                    .complexity_type_box{
                        width: 94px;
                        background-color: #FAFAFA;
                        border-radius: 8px;

                        .complexity_name{
                            text-align: center;
                            @include m.fontSize(text/xs/medium);
                            padding: 8px 0 6px 0;
                        }

                        .complexity_name.easy{
                            color: map-get(i.$appBackGroundColor, "success/500");
                        }

                        .complexity_name.medium{
                            color: map-get(i.$appBackGroundColor, "warning/500");
                        }

                        .complexity_name.hard{
                            color: map-get(i.$appBackGroundColor, "error/500");
                        }

                        

                        .complexity_qn_count{
                            text-align: center;
                            color: map-get(i.$appBackGroundColor, "grey/700");
                            @include m.fontSize(text/sm/semibold);
                            padding: 0 0 8px 0;
                        }
                    }
                }
            }
        }
    }
}

.admin_nodata_msg{
    text-align: center;
    @include m.fontSize(display/xs/regular);
    color: map-get(i.$appColor, "grey/700");
    margin: 10%;
}