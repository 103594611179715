.studentListSearchBox {
    gap: 10px;
    border: none;
    width: 186px;
    height: 34px;
    outline: none;
    margin: 0 15px;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 4px;
    font-style: normal;
    font-family: 'Inter';
    background: #FFFFFF;
    padding: 9px 4px 9px 35px;
    border: 1px solid #E4E5E7;
}

.studentListTable{
    position: relative;
    height: 70vh;
}

.offcanvas.offcanvas-end{
    top: auto !important;
}

.buttonsTwo{
    position: absolute;
    display: flex;
    justify-content: end;
    bottom: 10px;
    right: 15px;
}

.studentListSearchBox::placeholder {
    width: 47px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #BDC1C6;
    line-height: 20px;
    font-style: normal;
    font-family: 'Inter';
}

div.studentListTable .css-b62m3t-container {
    z-index: 3;
    position: relative;
    box-sizing: border-box;
}

div.studentListTable .sc-fHjqPf.cFKLhN.rdt_TableBody {
    display: flex;
    flex-direction: column;
}

div.studentListTable .sc-gsFSXq.MbeHM.rdt_TableHeadRow {
    position: fixed;
}