@use "../../Abstracts/mixins" as m;
@use "../../Abstracts//Variables/index" as i;

.floatingActionBtnContainer {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 120;
  #floatingActionBtn {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    box-shadow: 0px 1px 2px 0px #1018280f;
    box-shadow: 0px 1px 3px 0px #1018281a;
    background: map-get(i.$appColor, "error/500");
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    #floatingActionBtnIcon {
      color: map-get(i.$appColor, "white");
    }
  }
  .issueFormContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 16px;
    width: 470px;
    height: 470px;
    border-radius: 10px;
    z-index: 1051;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .issueFormTitleSec {
      display: flex;
      justify-content: space-between;
      align-items: center;
      #issueFormTitle {
        @include m.fontSize(text/lg/semibold);
        color: map-get(i.$appColor, "grey/900");
        // #issueFormCloseBtnSec {
        //   #issueFormCloseBtn {
        //   }
        // }
      }
    }
    .issueFormFieldSec {
      display: flex;
      flex-direction: column;
      gap: 12px;
      #issueFormCategoryField,
      #issueFormSummaryField,
      #issueFormDescriptionField,
      #issueFormCategoryItem {
        @include m.fontSize(text/md/regular);
        color: map-get(i.$appColor, "grey/900");
      }
      //   #issueFormCategoryField {
      //     #issueFormCategoryItem {
      //     }
      //   }
      //   #issueFormSummaryField {
      //   }
      //   #issueFormDescriptionField {
      //   }
    }

    .issueFormButtonSec {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
    }
  }
  .tooltipBox{
    width: 412px;
    padding: 12px 16px;
  }
}
