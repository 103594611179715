@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.studentTestBodyPageSingleViewMainContainer {
  width: 100%;

  .loading-chart{
    display: flex;
    justify-content: center;
  }

  .studentTestBodyPageSingleViewQuestionHeader {
    .studentTestBodyPageSingleViewQuestionHeaderDiv {
      margin: 24px;

      .studentTestBodyPageSingleViewQuestionHeaderTitleDiv {
        gap: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .studentTestBodyPageSingleViewQuestionHeaderTitleReviewLater {
          padding: 0;
        }

        .studentTestBodyPageSingleViewQuestionHeaderTitleQnNo {
          gap: 5px;
          width: 100%;
          display: flex;
          font-size: 16px;
          font-weight: 700;
          color: #344054;
          font-family: "Inter";

          .studentTestBodyPageSingleViewQuestionHeaderTitleQnName,
          .studentTestBodyPageSingleViewQuestionHeaderTitleQnNameForVideo {
            width: 100%;
            font-weight: 500;
            color: #344054;
            font-family: "Inter";
          }

          .studentTestBodyPageSingleViewQuestionHeaderTitleQnNameForVideo {
            text-align: center;
          }
        }

        .studentTestBodyPageSingleViewQuestionHeaderTitleQnMark {
          width: 12%;
          font-size: 14px;
          font-weight: 400;
          color: #667085;
          font-family: "Inter";
        }
      }
    }

    .studentTestBodyPageSingleViewQuestionDetailBox {
      .studentTestBodyPageSingleViewQuestionDescription {
        width: 80%;
        font-size: 14px;
        font-weight: 400;
        color: #344054;
        margin: 0 0 0 60px;
        font-family: "Inter";
      }

      .studentTestBodyPageSingleViewQuestionLinks {
        margin-left: 7.5%;
      }

      .studentTestBodyPageSingleViewQuestionAnsDiv {
        width: 80%;
        margin: 0 0 0 60px;

        .studentTestBodyPageSingleViewQuestionAnsDivInputWrapper {
          position: relative;

          .studentTestBodyPageSingleViewQuestionAnsTextArea {
            width: 98%;
            width: 95%;
            resize: none;
            display: block;
            font-size: 15px;
            font-weight: 400;
            padding-top: 10px;
            font-style: normal;
            padding-left: 10px;
            margin-bottom: 40px;
            padding-right: 10px;
            margin-bottom: 25px;
            border-radius: 10px;
            font-family: "Inter";
            padding-bottom: 30px;
            box-sizing: border-box;
            border: 1px solid rgba(55, 55, 57, 0.2);
          }
        }

        .studentTestBodyPageSingleViewQuestionAnsDivInputWrapper::after {
          right: 6%;
          bottom: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #9c9da0;
          line-height: 10px;
          font-style: normal;
          position: absolute;
          font-family: "Inter";
          transform: translateY(-50%);
          content: attr(data-required);
        }
      }

      .studentTestBodyPageSingleViewQuestionOption {
        min-height: 44px;
        max-width: 800px;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        margin: 0px 100px 12px 60px;

        .studentTestBodyPageSingleViewQuestionOptionInputLabel {
          min-height: 20px;
          margin: 10px;
          display: flex;
          justify-content: space-between;
          max-width: 776px;
          align-items: center;

          .studentTestBodyPageSingleViewQuestionOptionLabel {
            font-size: 14px;
            font-weight: 400;
            color: #101828;
            margin: 0 0 0 8px;
            font-family: "Inter";
          }
        }

        .studentTestBodyPageSingleViewQuestionOptionInput {
          margin: 0;
          width: 16px;
          height: 16px;
          // color: #d0d5dd !important;
        }

        .studentTestBodyPageSingleViewQuestionOptionInput:checked {
          width: 16px;
          height: 16px;
          // accent-color: #f96343 !important;
        }
      }
    }
  }

  .input-label-mcq-container{
    display: flex;
    align-items: center;
  }

  .studentTestBodyPageSingleViewQuestionDetailBoxOptionsDivEachOptionAdmin {
    z-index: 3;
    width: 57vw;
    display: flex;
    margin-bottom: 5px;
    padding: 10px 15px;
    position: relative;
    flex-direction: row;
    justify-content: left;
    border-width: 2px 2px;
    align-items: flex-start;
    background-color: white;
  }

  .practicemode-ans-box-false {
    max-width: 800px;
    min-height: 158px;
    border-radius: 8px;
    border: 1px solid map-get(i.$appBackGroundColor, "grey/100");
    background-color: #fdf5f4;
    margin: 40px 0 0 60px;

    .ans-expla-container {
      display: flex;
      align-items: center;
      padding: 40px 24px;

      .ans-expla-icon-container {
        height: 78px;
        width: 78px;
        border-radius: 50%;
        padding: 20px;
        background: map-get(i.$appBackGroundColor, "error/100");
      }

      .ans-expla-text-container {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 40px;

        .ans-expla-res-container {
          @include m.fontSize(text/sm/semibold);
          color: map-get(i.$appBackGroundColor, "grey/700");
        }
        .ans-expla-restext-container {
          margin: 12px 0 0 0;
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appBackGroundColor, "grey/500");
        }
      }
    }
  }
  .practicemode-ans-box-true {
    width: 800px;
    height: 158px;
    border-radius: 8px;
    border: 1px solid map-get(i.$appBackGroundColor, "grey/100");
    background-color: #f4fdf7;
    margin: 40px 0 0 60px;

    .ans-expla-container {
      display: flex;
      align-items: center;
      padding: 40px 24px;

      .ans-expla-icon-container {
        height: 78px;
        width: 78px;
        border-radius: 50%;
        padding: 20px;
        background: map-get(i.$appBackGroundColor, "success/100");
      }

      .ans-expla-text-container {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 40px;

        .ans-expla-res-container {
          @include m.fontSize(text/sm/semibold);
          color: map-get(i.$appBackGroundColor, "grey/700");
        }
        .ans-expla-restext-container {
          margin: 12px 0 0 0;
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appBackGroundColor, "grey/500");
        }
      }
    }
  }

  .studentTestBodyPageSingleViewButtonGroup {
    .studentTestBodyPageSingleViewButtonGroupDiv {
      bottom: 0;
      width: 100%;
      display: flex;
      margin: 48px 0 32px 0;
      justify-content: space-between;

      div {
        margin: 0 24px;
      }
    }

    .single-qn-submit-container {
      display: flex;
      justify-content: flex-end;
      margin: 0 0 20px 0;

      .single-qn-submit-div {
        margin: 0 100px;
      }
    }

    .single-qn-after-submit-container {
      display: flex;
      justify-content: center;

      .single-qn-submit-div {
        text-align: center;

        .after-submit-header {
          @include m.fontSize(text/xl/semibold);
          line-height: 40px;
          color: map-get(i.$appBackGroundColor, "grey/700");
        }

        .after-submit-msg {
          @include m.fontSize(text/md/regular);
          line-height: 40px;
          color: map-get(i.$appBackGroundColor, "grey/700");
        }

        .after-submit-btn-div{
          display: flex;
          justify-content: space-evenly;
          margin: 14px 0 0 0;
        }
      }
    }
  }

  .half-donut-chart-div {
    margin: 64px 0 0 0;
  }
}
