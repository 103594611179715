@use "../../Abstracts/Variables/index" as i;
@use "../../Abstracts/mixins" as m;

.batchDetailsContainer {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .batchDetailsBodySec {
    gap: 24px;
    display: flex;
    flex-direction: column;

    .batchDetailsFormSec {
      gap: 8px;
      display: flex;
      padding: 0 28px;

      .batchDetailsForm {
        gap: 32px;
        width: 100%;
        display: flex;
        flex-direction: column;

        #bdDescription,
        .MuiFormControl-root input {
          @include m.fontSize(text/md/regular);
          color: map-get(i.$appColor, "grey/900");
        }

        .MuiFormControl-root {
          fieldset {
            border: 1px solid map-get(i.$appColor, "grey/400");
          }
        }

        .bdTopSec {
          display: flex;
          justify-content: space-between;

          .bdTopLeftSec {
            gap: 32px;
            width: 75%;
            display: flex;
            flex-direction: column;

            .bdNameAndTypeSec {
              width: 100%;
              display: flex;
              position: relative;

              .MuiFormControl-root {
                width: 40%;
              }

              .bdTypeSec {
                bottom: 0;
                right: 30px;
                position: absolute;

                .bdTypeLabel {
                  margin: 0;
                  padding: 0;
                  font-size: 12px;
                  font-weight: 400;
                  text-align: left;
                  line-height: 24px;
                  font-family: Inter;
                  color: map-get(i.$appColor, "grey/500");
                }
              }
            }

            .bdDescriptionSec {
              width: 80%;

              .bdDescription {
                #bdDescription {
                  min-height: 100px;
                }
              }
            }
          }

          .bdTopRightSec {
            width: 25%;
            height: 200px;
          }
        }

        .mockTestCreateFormTags {
          .css-1nmdiq5-menu {
            z-index: 2;

            .css-jyjuk6-MenuList {
              scrollbar-width: none;
            }
          }
        }

        .bdStartDateSec {
          .bdStartDate {
            width: 35%;

            input {
              height: 40px;
              padding: 0 0 0 15px;
            }

            label {
              top: -6px;
              position: absolute;
            }
          }
        }

        .bdSlackNameSec {
          .bdSlackName {
            width: 35%;
          }
        }

        .bdRoleTitleSec {
          .bdRoleTitle {
            width: 35%;
          }
        }

        .bdPathSec {
          gap: 32px;
          padding: 20px;
          display: flex;
          border-radius: 8px;
          width: fit-content;
          flex-direction: column;
          border: 1px solid map-get(i.$appColor, "grey/300");
          background: map-get(i.$appBackGroundColor, "grey/50");

          .bdPath {
            gap: 20px;
            display: flex;
            align-items: center;

            .inputDropdownInputContainer {
              width: 400px;

              .inputDropdownInputSec {
                min-width: none;
                max-width: 400px;
              }
            }

            .bdPathLabel,
            .bdPathNameLabel {
              @include m.fontSize(text/sm/medium);
              color: map-get(i.$appColor, "grey/700");
            }

            .bdPathNameLabel span {
              font-size: 14px;
              font-weight: 300;
              text-align: left;
              line-height: 20px;
              font-family: Inter;
            }

            .inputDropdownMenuContainer {
              width: 400px;
              position: absolute;

              ul {
                height: 250px;
              }
            }

            .bdPathArrowIcon {
              height: 70px;

              svg {
                margin-top: 35px;
              }
            }

            .inputFieldContainer {
              width: 400px;

              .inputWrapper {
                border-right: unset;
                border-top-right-radius: unset;
                border-bottom-right-radius: unset;
              }
            }

            .bdRenameSec {
              display: flex;

              .bdPathCheckBtn {
                width: 44px;
                height: 44px;
                outline: none;
                display: flex;
                align-items: center;
                background: #ffffff;
                justify-content: center;
                border-radius: 0px 8px 8px 0px;
                box-shadow: 0px 1px 2px 0px #1018280d;
                border: 1px solid map-get(i.$appColor, "grey/300");
              }

              .bdPathCheckBtn:hover {
                background: map-get(i.$appBackGroundColor, "grey/50");
              }

              .bdPathCheckBtn:disabled {
                svg {
                  cursor: not-allowed;
                }
              }

              .bdPathCheckBtn.allowed {
                svg {
                  cursor: pointer;
                  path {
                    stroke: map-get(i.$appColor, "success/500");
                  }
                }
              }
            }
          }
        }
      }
    }

    .batchDetailsBtnSec {
      width: 100%;
      display: flex;
      padding: 0 24px;
      justify-content: flex-end;
    }
  }
}

.settingsRightHeadSec {
  height: 56px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;

  .personalInfoTitleSec {
    padding: 0;
    height: unset;
    width: fit-content;
  }
}
