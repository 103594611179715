@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.rightMainContainer {
  height: 89vh;
  overflow: hidden;

  .rightMainBack {
    height: 16px;
    padding-top: 10px;
  }

  .rightMainHeader {
    display: flex;
    margin: 19px 0 0 52px;
    justify-content: space-between;

    .rightMainHeaderTestLabel {
      max-width: 588px;
      text-align: left;
      @include m.fontSize(display/xs/bold);
    }

    .rightMainHeaderInnerDiv {
      display: flex;
      justify-content: space-between;
    }
  }
}
