@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.downloadSvgIconForUploadAssignment {
  margin-top: 20px;
}

.downloadSvgIcon:hover {
  fill: map-get(i.$appBackGroundColor, "grey/50");
}
