.trainers-div{
    width: 1024px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 20px; 
}

.trainers-card{
    width: 500px;
    height: 158px;
    border-radius: 20px;
    background-color: #FFFFFF;
    // margin: 0 0 0 16px;

    .trainers-card-grid{
        display: flex;
        margin: 16px 0 0 16px;
        .trainer-card-avatar{
            width: 48px;
            height: 48px;
        }
    }

    .trainers-card-content{
        padding: 0;
        width: 248px;
        height: 126px;
        margin: 0 0 0 16px;

        .trainer-name{
            text-align: left;
            font-family: 'Inter';
            font-size: 18px;
            font-weight: 500;
            color: #344054;
        }

        .trainers-card-content-whatsapp-box, .trainers-card-content-gmail-box, .trainers-card-content-slack-box{
            display: flex;
            justify-content: flex-start;
            margin: 10px 0 0 0;
            align-items: center;

            .trainers-card-content-box-data{
                margin: 0 0 0 8px;
                font-family: 'Inter';
                font-size: 14px;
                font-weight: 400;
                color: #475467;
            }

            .trainers-card-content-box-svg{
                margin: 0 0 0 4px;

                .trainers-card-content-box-svg-iconbtn{
                    padding: 0;
                    height: 16px;
                    width: 16px;
                }
            }

        }
    }
    .trainers-remove-icon-box{
        position: relative;
        span{
            position: absolute;
            left: 130px;
        }
    }
}