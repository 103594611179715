@use "../../../Abstracts/mixins" as m;
@use "../../../Abstracts/Variables/index" as i;

.mockTestLibraryMainContainer {
  gap: 20px;
  height: 72vh;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  margin-top: 24px;
  scrollbar-width: none;
  align-content: flex-start;

  .mockTestLibraryGrid,
  .mockTestLibraryGrid:hover {
    width: 400px;
    padding: 20px;
    cursor: pointer;
    max-height: 294px;
    border-radius: 8px;
    background: map-get(i.$appColor, "white");
  }

  .mockTestLibraryGrid:hover {
    background: map-get(i.$appColor, "grey/100");

    .mockTestLibraryGridFooter {
      float: right;
      display: block;
    }
  }

  .mockTestLibraryGridHeader {
    display: flex;
    justify-content: space-between;
    @include m.fontSize(text/xl/semibold);
    color: map-get(i.$appColor, "grey/700");

    .mockTestLibraryGridHeaderTitle {
      width: 300px;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    svg {
      height: 30px;
      width: 31.81px;

      path {
        stroke: map-get(i.$appColor, "grey/700");
      }
    }
  }

  .mockTestLibraryGridSubHeader {
    display: flex;
    margin-top: 16px;
    justify-content: space-between;

    .mockTestLibraryGridSubHeaderTestTitle {
      display: flex;
      @include m.fontSize(text/md/medium);
      color: map-get(i.$appColor, "grey/700");

      .mockTestLibraryGridSubHeaderTestQuestionCount {
        margin-left: 8px;
        @include m.fontSize(text/md/regular);
        color: map-get(i.$appColor, "grey/700");
      }
    }

    .mockTestLibraryGridSubHeaderTestDuration {
      padding: 2px 10px;
      border-radius: 16px;
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/700");
      background: map-get(i.$appColor, "grey/100");
    }
  }

  .mockTestLibraryGridTestDescription {
    max-height: 80px;
    margin-top: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @include m.fontSize(text/sm/medium);
    color: map-get(i.$appColor, "grey/700");
  }

  .mockTestLibraryGridTestTagsDiv {
    gap: 10px;
    display: flex;
    margin-top: 12px;

    .mockTestLibraryGridTestTags {
      padding: 4px 8px;
      border-radius: 8px;
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/700");
      background: map-get(i.$appColor, "brand/25");
      font-style: italic;
    }
  }

  .mockTestLibraryGridFooter {
    display: none;
  }
}
