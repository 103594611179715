.notReadOnly .ql-toolbar {
  display: none;
}

.ce-block__content .tox-tinymce{
  border: none !important;
}

.ce-block__content .tox .tox-editor-header{
  z-index: 0 !important;

}

.notReadOnly .ql-container {
  border: none !important;
}

.notReadOnly .ql-editor {
  padding: 0 !important;
}
