.modalTitle {
  font-size: 24px;
  color: #373739;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  font-family: "Inter";
  margin: 0 0 30px 0px;
}

.modalButtonDivForTestCasesFailed2000 {
  display: flex;
  margin: 0 107.73px 28px 0;
  flex-direction: row-reverse;
}

.modalButtonDiv {
  display: flex;
  margin: 28px 0 0 0;
  flex-direction: row-reverse;
}

.modalButtonDivForAutoSubmit {
  display: flex;
  margin: 28px 28px 28px 0;
  flex-direction: row-reverse;
}

.modalButton {
  height: 40px;
  border: none;
  color: #fff;
  min-width: 40px;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  border-radius: 5px;
  line-height: normal;
  margin: 0 29.2px 0 0;
  font-family: "Inter";
  background: #f55533;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.modalButtonDivForTimeLapsed {
  display: flex;
  margin: 0px 57.73px 25px 0;
  flex-direction: row-reverse;
}

.modalButtonDivForWarning {
  float: right;
  margin: 24.38px 70px 25px 0;
}

.modalButton {
  color: #fff;
  border-radius: 5px;
  font-family: "Inter";
  border: none !important;
  background: #f55533 !important;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.modalBtnWarning {
  right: 125px;
  bottom: 50px;
  display: flex;
  position: absolute;
}

.modalWarningTitle {
  right: 150px;
  display: flex;
  font-size: 24px;
  color: #373739;
  font-weight: 700;
  position: absolute;
  font-style: normal;
  line-height: normal;
  font-family: "Inter";
  padding: 10px 0 30px 0px;
}

.modalAutoSubmitTitle {
  font-size: 24px;
  color: #373739;
  font-weight: 700;
  text-align: center;
  font-style: normal;
  line-height: normal;
  font-family: "Inter";
  padding: 0px 0 30px 0px;
}

.modalMsgForTimeLapsed {
  top: 20px;
  right: 30px;
  width: 353px;
  color: #000;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  position: absolute;
  font-family: "Inter";
  margin: 33px 21px 0 226px;
}

.timeSvg {
  margin: 64px 0 0 0;
}

.modalMsg {
  color: #000;
  font-size: 16px;
  line-height: 24px;
  padding-left: 20px;
  font-family: "Inter";
}

.modalMsgWarning {
  top: 70px;
  right: 30px;
  width: 253px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #373739;
  line-height: 24px;
  position: absolute;
  font-style: normal;
  font-family: "Inter";
}

.modalMsgAutoSubmit {
  top: 70px;
  right: 30px;
  width: 353px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #373739;
  line-height: 24px;
  position: absolute;
  font-style: normal;
  font-family: "Inter";
}

.modalMsgForTestCasesFailed2000 {
  top: 70px;
  right: 30px;
  width: 353px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #373739;
  line-height: 24px;
  position: absolute;
  font-style: normal;
  font-family: "Inter";
}

.modalButtonSuccess {
  display: flex;
  margin: 0 25px 30px 0;
  flex-direction: column;
}

.SuccessImg {
  width: 350px;
  height: 260px;
  padding: 27px 0px 0px 47px;
}

.modalSuccessTitle {
  right: 125px;
  display: flex;
  font-size: 24px;
  color: #373739;
  font-weight: 700;
  position: absolute;
  font-style: normal;
  line-height: normal;
  font-family: "Inter";
  padding: 10px 0 30px 0px;
}

.modalMsgForSuccess {
  top: 75px;
  right: 0px;
  width: 353px;
  color: #000;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  position: absolute;
  font-family: "Inter";
}

.modal-content {
  right: 75px;
  min-width: 700px;
  border-radius: 10px;
  border: 2px solid #f55533;
}

.autoSubmitSvg {
  padding: 50px 0 0 20px;
}
