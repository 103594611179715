@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

#releasePopupContainer {
  .MuiPaper-root {
    max-width: 646px;
    max-height: 294px;
    padding: 24px;
    display: flex;
    gap: 20px;
    border-radius: 12px;
    .releasePopupContentSec {
      display: flex;
      gap: 32px;
      .releasePopupContent {
        width: 50%;
        display: flex;
        gap: 20px;
        div {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .releasePopupContentTitle {
            @include m.fontSize("text/lg/semibold");
            color: map-get(i.$appColor, "grey/900");
          }
          .releasePopupContentBody {
            @include m.fontSize("text/sm/regular");
            color: map-get(i.$appColor, "grey/700");
          }
        }
      }
    }
    .releasePopupActionSec{
        display: flex;
        gap: 13px;
        button{
            width: 598px;
        }
    }
  }
}
