#instructionMain {
    display: flex;
    gap: 40px;
    width: 40%;
    margin: 5px 0;
    padding: 24px 0px 24px 0px;
    height: 100vh;
    overflow: auto;
    // background: #383838;
    background: #ffffff;
    border: none;
    // box-shadow: 0px 24px 48px -12px #1018282E;

    // .tox-editor-header {
    //     z-index: 1 !important;
    // }

    .instructionMainContainer {
        // display: flex;
        // width: 641px;
        // height: 100vh;
        // position: relative;
        // top: 0;
        // // left: 725px;
        // padding: 24px 0px 24px 0px;
        // gap: 40px;
        // // opacity: 0px;
        // background: #383838;
        // box-shadow: 0px 24px 48px -12px #1018282E;

        .header {
            display: flex;
            justify-content: space-between;
            padding: 0px 28px 0px 0px;

            .assignmentname {
                display: flex;
                padding: 0px 24px 0px 24px;
                gap: 8px;
                //styleName: Text lg/Semibold;
                font-family: Inter;
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #101010;


            }

        }


        .body {
            // display: flex;
            // justify-content: space-between;
            padding: 0px 28px 0px 24px;
        }

    }
}