@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.job-card-admin,
.jobCardMainContainer {
  gap: 12px;
  width: 48%;
  display: flex;
  padding: 24px;
  cursor: pointer;
  border-radius: 8px;
  background: #ffffff;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 2px 18px 0px #18191c08;

  .job-header {
    display: flex;
    font-family: "Inter";
    justify-content: space-between;

    .job-name {
      margin: 0;
      display: block;
      font-size: 20px;
      font-weight: 500;
      color: #18191c;
      max-width: 300px;
      overflow: hidden;
      line-height: 28px;
      position: relative;
      white-space: nowrap;
      font-family: "Inter";
      text-overflow: ellipsis;
    }

    .job-company {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      color: #667085;
      line-height: 28px;
      font-family: "Inter";
    }
  }

  .skills-heading-container {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    color: #344054;
    line-height: 20px;
    font-family: "Inter";
    align-items: baseline;

    .skills-heading {
      margin-right: 8px;
    }

    .skills-content {
      font-size: 12px;
      font-weight: 400;
      color: #344054;
      overflow: hidden;
      margin-bottom: 0;
      line-height: 28px;
      white-space: nowrap;
      font-family: "Inter";
      text-overflow: ellipsis;
    }
  }

  .locationCtcContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .job-info {
    gap: 8px;
    margin: 0;
    display: flex;
    font-size: 16px;
    color: #18191c;
    line-height: 24px;
    align-items: center;
    font-family: "Inter";

    .briefcase-icon,
    .location-icon {
      fill: none;
      width: 18px;
      height: 16px;
      stroke: #667085;
      stroke-width: 1.6;
      stroke-linecap: round;
      stroke-linejoin: round;
    }

    .experience,
    .location {
      font-size: 14px;
      font-weight: 400;
      color: #344054;
      line-height: 20px;
      font-family: "Inter";
    }
  }

  .jobCardBottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .jobCardBottomInfo {
      gap: 8px;
      display: flex;
      flex-direction: column;
    }

    .buttonsm {
      color: map-get(i.$appBackGroundColor, "brand/500");
      background: map-get(i.$appBackGroundColor, "brand/50");
      border: 1px solid map-get(i.$appBackGroundColor, "brand/50");
    }
  }
}

.job-card-admin {
  border: 1px solid #d0d5dd;
}
