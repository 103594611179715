@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.checkboxContainer {
  display: flex;
  position: relative;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 0;
  gap: 8px;
  .checkboxInputSmall,
  .checkboxInputMedium {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    opacity: 0;
  }
  /* common  */
  .checkboxSmall,
  .checkboxMedium {
    transition: border-color 0.3s ease;
    position: relative;
    top: 2px;
  }
  #checkboxIconSmall,
  #checkboxIconMedium {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: map-get(i.$appColor, "brand/600");
  }

  .checkboxLabelSectionSmall,
  .checkboxLabelSectionMedium {
    display: flex;
    flex-direction: column;
  }

  /* If checkbox disabled */
  .checkboxInputSmall:disabled ~ .checkboxLabelSectionSmall #checkboxLabel,
  .checkboxInputMedium:disabled ~ .checkboxLabelSectionMedium #checkboxLabel {
    color: map-get(i.$appColor, "grey/300");
    cursor: not-allowed;
  }

  /* size small */
  .checkboxSmall {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid map-get(i.$appColor, "grey/300");
    border-radius: 4px;
  }
  .checkboxInputSmall:checked + .checkboxSmall {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .checkboxInputSmall:hover + .checkboxSmall {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .checkboxInputSmall:focus + .checkboxSmall {
    border: 1px solid map-get(i.$appColor, "brand/300");
    box-shadow: 0px 0px 0px 4px #f4ebff;
  }
  .checkboxInputSmall:disabled + .checkboxSmall {
    background: map-get(i.$appColor, "grey/100");
    border: 1px solid map-get(i.$appColor, "grey/200");
  }

  .checkboxInputSmall:checked:focus + .checkboxSmall {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .checkboxInputSmall:checked:disabled + .checkboxSmall {
    background: map-get(i.$appColor, "grey/100");
    border: 1px solid map-get(i.$appColor, "grey/200");
  }
  .checkboxInputSmall:checked:disabled
    + .checkboxSmall
    #checkboxIconSmall
    path {
    stroke: map-get(i.$appColor, "grey/200");
  }

  .checkboxInputSmall {
    width: 16px;
    height: 16px;
  }
  #checkboxIconSmall {
    width: 12px;
    height: 12px;
  }
  .checkboxLabelSectionSmall {
    .checkboxLabelSmall {
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/700");
    }

    .checkboxSupportTextSmall {
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/500");
    }
  }

  /* size medium */
  .checkboxMedium {
    width: 20px;
    height: 20px;
    border: 1px solid map-get(i.$appColor, "grey/300");
    border-radius: 6px;
  }
  .checkboxInputMedium:checked + .checkboxMedium {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .checkboxInputMedium:hover + .checkboxMedium {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .checkboxInputMedium:focus + .checkboxMedium {
    border: 1px solid map-get(i.$appColor, "brand/300");
    box-shadow: 0px 0px 0px 4px #f4ebff;
  }
  .checkboxInputMedium:disabled + .checkboxMedium {
    background: map-get(i.$appColor, "grey/100");
    border: 1px solid map-get(i.$appColor, "grey/200");
  }

  .checkboxInputMedium:checked:focus + .checkboxMedium {
    background: map-get(i.$appColor, "brand/50");
    border: 1px solid map-get(i.$appColor, "brand/600");
  }
  .checkboxInputMedium:checked:disabled + .checkboxMedium {
    background: map-get(i.$appColor, "grey/100");
    border: 1px solid map-get(i.$appColor, "grey/200");
  }
  .checkboxInputMedium:checked:disabled
    + .checkboxMedium
    #checkboxIconMedium
    path {
    stroke: map-get(i.$appColor, "grey/200");
  }
  .checkboxInputMedium {
    width: 20px;
    height: 20px;
  }
  #checkboxIconMedium {
    width: 14px;
    height: 14px;
  }
  .checkboxLabelSectionMedium {
    .checkboxLabelMedium {
      @include m.fontSize(text/md/medium);
      color: map-get(i.$appColor, "grey/700");
    }
    .checkboxSupportTextMedium {
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/500");
    }
  }
}
