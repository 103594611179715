@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.registrationPageMainContainer {
  display: flex;
  height: 92.5vh;
  padding: 24px 0;
  justify-content: space-around;
  background: map-get(i.$appBackGroundColor, "grey/200");

  .registrationPageContainer {
    width: 50%;
    height: 86vh;
    padding: 24px;
    overflow: auto;
    border-radius: 12px;
    background: map-get(i.$appBackGroundColor, "white");

    .registrationPageHeader {
      @include m.fontSize(text/lg/semibold);
      color: map-get(i.$appColor, "grey/900");

      .registrationPageSubHeader {
        margin-top: 8px;
        @include m.fontSize(text/sm/regular);
        color: map-get(i.$appColor, "grey/700");
      }
    }

    .registrationPageWebCamMainDiv {
      display: flex;
      margin: 20px 0;
      justify-content: space-around;

      .registrationPageWebCamDiv {
        display: flex;
        flex-direction: column;

        .registrationPageWebCam {
          border-radius: 8px;
        }

        .buttonSecondarysm,
        .buttonSecondaryGreysm {
          margin-top: 10px;
        }
      }
    }

    .registrationPageInstruction {
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/700");

      .registrationPageInstructionUnorderedList {
        margin: 20px 0;
      }
    }

    .registrationPageFooter {
      height: 15vh;
      display: flex;
      align-items: flex-end;
      justify-content: space-evenly;

      .buttonsm,
      .buttonSecondaryGreysm {
        width: 48%;
      }
    }
  }
}
