.jobapplymainpagelearner {
  gap: 20px;
  display: flex;
  margin: 0 80px;
  overflow: hidden;
  background: #f2f4f7;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100vw - 408px);
  height: calc(100vh - 40px);
}

.jobapplymainpageadmin {
  gap: 20px;
  display: flex;
  margin-top: 7vh;
  overflow: hidden;
  margin-left: 80px;
  align-items: center;
  background: #ffffff;
  flex-direction: column;
  height: calc(100vh - 54px);
}

.headerJobs {
  width: 80%;
}

.headerTextForJobs {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 0;
  line-height: 18px;
  font-family: "Inter";
  text-decoration-skip-ink: none;
  text-underline-position: from-font;
}

.searchContAdmin,
.searchContLearner {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.bottomCont {
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.bottomContAdmin {
  gap: 32px;
  width: 94%;
  display: flex;
  position: relative;
}

.cardCont {
  gap: 16px;
  width: 75%;
  height: 80vh;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  position: relative;
  padding-bottom: 20px;
  scrollbar-width: none;
  align-content: flex-start;
}

.noJobsFound {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-div {
  position: relative;
  margin-right: 60px;
}
