@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.codingOutputMainContainer {
  .codingOutputHeader {
    height: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 8px 8px 0 0;
    justify-content: space-between;
    background: map-get(i.$appBackGroundColor, "grey/50");

    .codingOutputHeaderTitle {
      height: 14px;
      margin-left: 20px;
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/700");
    }
  }

  .codingOutputTestcasesDiv {
    display: flex;
    align-items: center;

    .codingOutputTestcasesDivBox {
      height: 22px;
      margin: 12px 0 9px 20px;

      .codingOutputTestcasesDivBoxTab {
        display: flex;
        min-width: 54px;
        min-height: 22px;
        align-items: center;

        .codingOutputTestcasesBoxTab,
        .codingOutputTestcasesBoxTabPass,
        .codingOutputTestcasesBoxTabFail,
        .codingOutputTestcasesBoxTabActive,
        .codingOutputTestcasesBoxTabActivePass,
        .codingOutputTestcasesBoxTabActiveFail {
          overflow: unset;
          min-width: auto;
          min-height: auto;
          padding: 2px 8px;
          position: relative;
          margin: 0 12px 0 0;
          border-radius: 16px;
          text-transform: none;
          @include m.fontSize(text/xs/medium);
          color: map-get(i.$appColor, "grey/700");
          background: map-get(i.$appBackGroundColor, "grey/100");
        }

        .codingOutputTestcasesBoxTab:hover {
          background: map-get(i.$appBackGroundColor, "grey/200");
        }

        .codingOutputTestcasesBoxTabActive {
          border: 1px solid map-get(i.$appColor, "grey/500");
        }

        .codingOutputTestcasesBoxTabPass,
        .codingOutputTestcasesBoxTabActivePass {
          color: map-get(i.$appColor, "success/700");
          border: 1px solid map-get(i.$appColor, "success/700");
          background: map-get(i.$appBackGroundColor, "success/50");
        }

        .codingOutputTestcasesBoxTabFail,
        .codingOutputTestcasesBoxTabActiveFail {
          color: map-get(i.$appColor, "error/700");
          border: 1px solid map-get(i.$appColor, "erroe/700");
          background: map-get(i.$appBackGroundColor, "error/50");
        }

        .codingOutputTestcasesBoxTabPass,
        .codingOutputTestcasesBoxTabFail {
          border: none;
        }

        .codingOutputTestcasesBoxTabActive:hover {
          background: map-get(i.$appBackGroundColor, "grey/200");
        }

        .codingOutputTestcasesBoxTabPass:hover,
        .codingOutputTestcasesBoxTabActivePass:hover {
          background: map-get(i.$appBackGroundColor, "success/200");
        }

        .codingOutputTestcasesBoxTabFail:hover,
        .codingOutputTestcasesBoxTabActiveFail:hover {
          background: map-get(i.$appBackGroundColor, "error/200");
        }

        .codingOutputTestcasesBoxTabCloseSvgIcon {
          padding: 0;
          left: 52px;
          bottom: 6px;
          position: absolute;
        }
      }
    }
  }

  .codingOutputTestcasesContainer {
    margin-left: 20px;
    position: relative;

    .codingOutputTestcasesInputContainer {
      .codingOutputTestcasesInnerInputContainer {
        .codingOutputTestcasesInnerInputContainerDiv {
          display: flex;
          flex-direction: column;

          .codingOutputTestcasesInputVariable {
            height: 18px;
            font-size: 12px;
            text-align: left;
            font-weight: 400;
            width: fit-content;
            margin: 9px 0 4px 0;
            font-family: "Inter";
            display: inline-block;
            color: map-get(i.$appColor, "grey/900");
          }

          .codingOutputTestcasesInputValue {
            height: 40px;
            max-width: 47vw;
            border-radius: 8px;
            padding: 10px 0 10px 20px;
            @include m.fontSize(text/sm/regular);
            color: map-get(i.$appColor, "grey/900");
            border: 1px solid map-get(i.$appColor, "grey/300");
          }
        }
      }
    }

    .codingOutputTestcasesOutputContainer {
      .codingOutputTestcasesActualOutputContainerDiv {
        display: flex;
        flex-direction: column;

        .codingOutputTestcasesActualOutputName {
          height: 18px;
          font-size: 12px;
          text-align: left;
          font-weight: 400;
          color: #101828;
          width: fit-content;
          margin: 8px 0 4px 0;
          font-family: "Inter";
          display: inline-block;
        }

        .codingOutputTestcasesActualOutputValue {
          max-width: 47vw;
          min-height: 40px;
          border-radius: 8px;
          line-break: anywhere;
          padding: 10px 0 10px 20px;
          white-space: break-spaces;
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appColor, "grey/900");
          border: 1px solid map-get(i.$appColor, "grey/300");
        }
      }

      .codingOutputTestcasesExpectedOutputContainerDiv {
        display: flex;
        flex-direction: column;

        .codingOutputTestcasesExpectedOutputName {
          height: 18px;
          font-size: 12px;
          text-align: left;
          font-weight: 400;
          color: #101828;
          width: fit-content;
          font-family: "Inter";
          margin: 8px 0 4px 0;
          display: inline-block;
        }

        .codingOutputTestcasesExpectedOutputValue {
          max-width: 47vw;
          min-height: 40px;
          border-radius: 8px;
          line-break: anywhere;
          white-space: break-spaces;
          padding: 10px 0 10px 20px;
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appColor, "grey/900");
          border: 1px solid map-get(i.$appColor, "grey/300");
        }
      }
    }

    .codingOutputButtonDiv {
      top: 31px;
      right: 71px;
      position: absolute;
      display: inline-block;

      svg {
        path {
          stroke: #e54b2a;
        }
      }
    }
  }
}
