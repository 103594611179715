@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.codingPageLearnerSideBreadCrumbsMainContainer {
  display: flex;
  margin-left: 12px;
  align-content: center;

  .codingPageLearnerSideBreadCrumbsSeparator,
  .codingPageLearnerSideBreadCrumbsSeparatorForStudentList {
    @include m.fontSize(text/xl/regular);
    color: map-get(i.$appColor, "grey/300");
  }

  .codingPageLearnerSideBreadCrumbsSeparatorForStudentList {
    margin-left: 12px;
  }

  .codingPageLearnerSideBreadCrumbsDiv {
    display: flex;
    align-content: center;
    @include m.fontSize(text/sm/medium);
    color: map-get(i.$appColor, "grey/700");

    .codingPageLearnerSideBreadCrumbsDivModuleName {
      margin: 5px 0 3px 12px;
    }

    svg {
      margin: 10px 8px;
    }
  }
}
