@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.certificateFormContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;
  // width: 848px;
  #certificateFormName,
  #certificateFormOrganisation,
  #certificateFormYear {
    @include m.fontSize(text/md/regular);
    color: map-get(i.$appColor, "grey/900");
  }
  .certificateFormOrganisationField {
    width: 75%;
  }
  .certificateFormYearField {
    width: 50%;
  }
}
