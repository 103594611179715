.addEventTitle {
    font-size: 20px;
    text-align: left;
    color: #373739;
    font-weight: 700;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    margin: 61px 0 0 66px;
}

.selectStudentTitle {
    font-size: 20px;
    text-align: left;
    color: #373739;
    font-weight: 700;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    position: relative;
    height: 3vh;
}

.createSessionForm {
    width: 468px;
    margin: 54px 0 0 76px;
}

.formLabelForSession {
    display: flex;
    font-size: 16px;
    color: #373739;
    font-weight: 700;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    justify-content: start;
}

#edit-name,
#sessionName,
#edit-org_by,
#edit-expiry,
#sessionOrgBy,
#edit-students,
#sessionDuration,
#addStudentForSession,
#addExpiryTimeForSession,
#sessionLink {
    height: 56px;
    border: 1px solid #C6C5CA !important;
}

#addStudentForSession {
    font-size: 16px;
    color: #373739;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    font-family: Inter;
    background: var(--bg-light, #FFF);
}

#edit-students:hover,
#addStudentForSession:hover {
    cursor: pointer;
}

#viewUserList {
    position: absolute;
    height: 93vh;
    width: 100%;
    margin: 7vh 0 0 0;

}

#edittab,
#tableForStudentList {
    width: 100%;
    font-size: 14px;
    color: #373739;
    font-weight: 500;
    line-height: 20px;
    font-family: Inter;
    font-style: normal;
    table-layout: fixed;
    border-collapse: collapse;
}

#edittab td,
#edittab th,
#tableForStudentList td,
#tableForStudentList th {
    overflow-wrap: break-word;
    border: 1px solid rgba(55, 55, 57, 0.20);
}

tfoot td,
#edittab th,
#tableForStudentList th {
    text-align: center;
    align-content: center;
}

.datePickerforSession {
    flex: 1 0 0;
    height: 56px;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: var(--Input-Background, #FFF);
    border: 1px solid var(--community-grey-grey-100, #C6C5CA);
}

div.datePickerforSession .react-datepicker__input-container input {
    width: 435px;
    border: none;
    font-size: 16px;
    overflow: hidden;
    color: #373739;
    font-weight: 400;
    padding: 0px 16px;
    line-height: 24px;
    font-family: Inter;
    font-style: normal;
    white-space: nowrap;
    letter-spacing: 0.5px;
    text-overflow: ellipsis;
    font-feature-settings: 'liga' off;
}

div.datePickerforSession .react-datepicker__input-container .react-datepicker-ignore-onclickoutside {
    outline: none;
}

.calendarIcon {
    z-index: 1;
    float: right;
    cursor: pointer;
    margin: -25px 0 0 90%;
}

.mb3 {
    padding-bottom: 10px;
}

.radioBtnforSession {
    display: flex;
}

.radioBtnForSessionValue {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: Inter;
    font-style: normal;
    margin: -5px 0 0 61px;
}

.radioBtnForSessionValue2 {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: Inter;
    font-style: normal;
    margin: -5px 0 0 72.33px !important;
}

.form-check-label {
    cursor: pointer;
    margin-bottom: 0;
    padding-left: 7px;
}

.tableForSelectStudent {
    height: 65%;
    overflow: auto;
}

.viewUserList {
    position: relative;
    height: 93vh !important;
    overflow: hidden;
}

.tableHeaderForCheckBox {
    width: 74px;
    height: 44px;
}

.offcanvas-body {
    flex-grow: 1;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}