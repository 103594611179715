@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

.modalContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(52, 64, 84, 0.7);
  backdrop-filter: blur(8px);
  z-index: 1000;

  .modalContent_leaderboard {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    background: map-get(i.$appBackGroundColor, "white");
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    padding: 24px;
    border-radius: 12px;
    gap: 32px;
    position: relative;
    min-width: 400px;
    width: 100%;
    height: calc(100vh - 12vh);
    overflow: auto;
    .modalCloseIcon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: map-get(i.$appBackGroundColor, "white");
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    padding: 24px;
    border-radius: 12px;
    gap: 32px;
    position: relative;
    min-width: 400px;
    .modalTitle {
      @include m.fontSize(text/xl/medium);
      color: map-get(i.$appColor, "grey/900");
      margin: 0;
    }
    // .modalCloseIcon {
      // position: absolute;
      // top: 10px;
      // right: 10px;
    // }
  }
}
