// .question-Box-Show-This-Yes{

// }

// .question-Box-Show-This-No{
//     width: 40px;
//     height: 40px;
//     margin: 20px 0 0 12px;
// }

.mcq-Question-Number-Box{
    padding: 0;
    height: 40px;
    width: 40px;

    span{
        font-size: 15px;
    }
}