.adminTestBackBtn {
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 20px;
}

.adminTestBack {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  text-transform: uppercase;
}

.langAndBplate {
  display: flex;
}

.addBtnToAddTopicAndComplexity {
  top: 18px;
  left: 20px;
  display: flex;
  position: relative;
  text-align: justify;
  justify-content: flex-start;
}

.tableForTopicAndComplexity .ant-table-cell {
  border: 1px solid #bdc1c6;
}

.tableForTopicAndComplexity {
  margin-top: 20px;
  margin-left: 23px;
}

#identifier {
  width: 99%;
  margin-top: 70px;
}

#ccQuestionLangName {
  gap: 10px;
  width: 862px;
  height: 42px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

#customSwitch {
  width: 50px;
  height: 20px;
  box-shadow: none;
}

.ccQuestionUpdateBtn {
  float: left;
  margin: 60px 10px 0 25px;
}

#ccQuestionLangNameFunctionName {
  gap: 10px;
  width: 200px;
  height: 34px;
  outline: none;
  border-radius: 4px;
  background: #ffffff;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

#ccMultiSelect {
  margin: 0;
  padding: 0;
  height: 34px;
  width: 400px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.faTrashCan {
  width: 16px;
  height: 16px;
  color: tomato;
}

.form-check-input:checked {
  border-color: #f55533 !important;
  background-color: #f55533 !important;
}

.ccErrors {
  margin: 0px;
  color: red;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-style: normal;
  font-family: "Inter";
  font-family: "Inter";
}

.edit_delete {
  width: 80px;
  display: flex;
  justify-content: space-around;
}

.returnType .css-tj5bde-Svg {
  margin-top: 0px !important;
}

.que-ent-inp-par-sec-var-typ .css-tj5bde-Svg {
  margin-top: 0px !important;
}

.edit_delete_edit:hover {
  background-color: green;
}

.edit_delete_delete:hover {
  background-color: red;
}

.ccQuestionLangName {
  float: left;
  width: 862px;
  height: 52px;
  text-align: left;
  margin: 25px 0 0 23px;
}
.ccQuestionAnsExp {
  float: left;
  width: 862px;
  // height: 52px;
  text-align: left;
  margin: 25px 0 0 23px;
}

.ccQuestionAnsExp > label {
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}
.ccQuestionLangName > label {
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}

.ccquestion_langname-function-name {
  float: left;
  width: 862px;
  height: 52px;
  text-align: left;
  margin: 25px 0 0 23px;
}

.ccquestion_langname-function-name > label {
  width: 34px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 6px;
  font-style: normal;
  font-family: "Inter";
}

.ccQuestionDescription {
  width: 99%;
  float: left;
  text-align: left;
  margin: 25px 0 0 23px;
}

.ccQuestionDescription > label {
  width: 68px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 6px;
  font-family: "Inter";
}

.questionToggle {
  display: flex;
  width: 99% !important;
  float: left !important;
  text-align: left !important;
  margin: 25px 0 0 23px !important;
}

.questionToggle > label {
  width: 100px;
  height: 14px;
  font-size: 12px;
  padding-top: 6px;
  font-weight: 600;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter";
}

.Io {
  float: left;
  width: 100%;
  margin: 25px 0 0 0;
}

.inputVariablesDiv {
  width: 862px;
  margin-left: 23px;
}

.inputVariablesDivLabel {
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  margin-bottom: 5px;
  font-style: normal;
  font-family: "Inter";
}

.functionForBoilerplate {
  float: left;
  width: 200px;
  text-align: left;
}

.ccEditorTinymce {
  float: left;
  width: 862px;
  margin: 0px 0 0 23px;
}

.returnType {
  float: left;
  width: 210px;
  text-align: left;
  margin-left: 20px;
}

.returnTypeDivLabel {
  float: left;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 5px;
  font-family: "Inter";
}

.ccMultiSelect {
  height: 54px;
  display: flex;
  text-align: left;
  margin: 0 0 0 23px;
}

.ccmultiSelect {
  float: left;
}

.ccmultiSelect > label {
  margin: 0 0 0 15px;
}

.ccMultiSelect label {
  width: 132px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter";
}

.ccBasicMultiSelect {
  width: 400px;
  height: 34px;
  margin: 0 0 0 23px;
  border-radius: 4px;
  background: #ffffff;
}

.evaluationRules {
  width: 400px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-left: 50px;
  font-style: normal;
  font-family: "Inter";
}

.generateBoilerplate {
  margin-left: 50px;
  margin-bottom: 20px;
}

.generateBoilerplate .btn.btn-primary {
  border: none;
  color: #ffffff;
  border-radius: 4px;
  background: #f55533;
}

.ccQuestionSaveBtn {
  float: left;
  margin: 60px 20px;
}

.ccQuestionSaveBtn > button {
  float: right;
}

.select_modules {
  z-index: 5;
  float: left;
  height: 54px;
  width: 400px;
  margin-left: 23px;
  position: relative;
}

.select_modules > label {
  float: left;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 6px;
  font-family: "Inter";
}

.io {
  float: left;
  width: 100%;
  margin: 25px 0 0 0;
}

table.iotable > tbody > tr {
  height: 46px;
}

table.iotable > tbody > tr > td {
  height: 46px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  font-style: normal;
  font-family: "Inter";
}

table.iotable > tbody > tr > td > textarea {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  font-style: normal;
  font-family: "Inter";
}

.question_io {
  margin: 0;
  display: inline-block;
}

.faCheck {
  width: 16px;
  height: 16px;
  color: green;
}

.faXmark {
  width: 16px;
  height: 16px;
  color: tomato;
}

.noTestCases {
  margin-left: 30px;
}
