@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.rejectSvgIcon {
  cursor: pointer;
  margin-right: 8px;
}

.rejectSvgIcon:hover {
  fill: map-get(i.$appColor, "error/700");
}
