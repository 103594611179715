.adminTestContainer {
  margin-top: 8vh;
}

#adminTestBtnContainer {
  display: flex;
  position: relative;
  margin-right: 20px;
  flex-direction: row;
  justify-content: space-between;
}

.adminTestTypeHead,
.adminTestQnListHead {
  height: 27px;
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  text-align: left;
  line-height: 27px;
  margin-left: 20px;
  font-style: normal;
  font-family: "Inter";
}

.adminTestTypeHead {
  width: 40%;
}

.adminTestSearchBox,
.adminTestQnSearchBox {
  gap: 10px;
  border: none;
  width: 186px;
  height: 34px;
  outline: none;
  display: block;
  margin: 0 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  border-radius: 4px;
  font-family: "Inter";
  background: #ffffff;
  padding: 9px 4px 9px 35px;
  border: 1px solid #e4e5e7;
}

.adminTestSearchIcon,
.adminTestQnSearchIcon {
  width: 12.44px;
  height: 12.44px;
  color: #bdc1c6;
}

.adminTestSearchIcon {
  top: 12px;
  left: 40px;
  position: relative;
}

.adminTestSearchBox::placeholder {
  width: 47px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #bdc1c6;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.adminTestTopicSelect {
  z-index: 3;
  width: 200px;
  font-size: 12px;
  text-align: left;
  font-weight: 600;
  line-height: 15px;
  font-family: "Inter";
}

.adminTestTopicSelect .css-1s2u09g-control,
.adminTestTopicSelect .css-1pahdxg-control {
  min-height: 34px;
}

#adminAddTestBtn {
  height: 34px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-left: 15px;
  line-height: 20px;
  font-style: normal;
  border-radius: 4px;
  font-family: "Inter";
  background: #0d6efd;
}

.adminTestBtnSec {
  right: 20px;
  bottom: 15px;
  display: flex;
  position: absolute;
  background: #ffffff;
}

.adminTestBtn2Sec {
  display: flex;
  margin-top: 3vh;
  padding: 0 0 10px 0;
}

.adminTestViewTestSection label span {
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter" !important;
}

.adminTestViewTestSection div span {
  font-weight: 500;
}

.adminTestSecondaryBtn {
  margin: 0 10px;
}

.adminTestListTable {
  top: 60px !important;
  padding-bottom: 20px;
}

.adminTestListTable .rdt_TableHeadRow {
  top: 14vh;
  z-index: 2;
  position: fixed !important;
}

.adminTestListTable .rdt_TableBody {
  top:0px;
  position: relative !important;
}
.adminTestListTable .rdt_TableBody .rdt_TableRow div div{ 
  text-align: left;
  white-space: pre-wrap !important;
}


.adminTestListTable .dlfHg div {
  text-align: left;
  white-space: pre-wrap !important;
}

.adminTestListTable .rdt_TableRow {
  cursor: pointer;
}

.adminTestSaveBtn,
.adminTestNextBtn {
  order: 5;
  flex: none;
  height: 34px;
  flex-grow: 0;
  border: none;
  display: flex;
  color: #ffffff;
  margin-left: 10px;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  background: #f55533;
  padding: 7px 20px 7px 20px;
}

.adminTestCancelBtn {
  order: 1;
  flex: none;
  width: 86px;
  flex-grow: 0;
  height: 34px;
  display: flex;
  color: #202124;
  padding: 7px 20px;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border: 1px solid #bdc1c6;
}

.adminTestDesSection,
.adminTestTypeSection,
.adminTestNameSection,
.adminTestDurationSection,
.adminTestInstructionSection,
.adminTestWeightageSection {
  display: flex;
  flex-direction: column;
}

:disabled {
  cursor: not-allowed;
}

.adminTestDesLabel,
.adminTestTypeLabel,
.adminTestNameLabel,
.adminTestDurationLabel,
.adminTestInstructionLabel,
.adminTestWeightageLabel {
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  margin-bottom: 10px;
  font-family: "Inter";
}

.adminTestInstructionLabel,
.adminTestWeightageLabel {
  margin-top: 10px;
}

.adminTestInstructionSection .tox-tinymce {
  width: 50%;
}

.adminTestDesMaxChar {
  margin-left: 300px;
}

.adminTestName,
.adminTestDuration,
.adminTestWeightage {
  width: 50%;
  height: 32px;
  padding-left: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #bdc1c6;
}

.adminTestDurationNum,
.adminTestDurationUnit {
  width: 24%;
  height: 32px;
  border-radius: 4px;
  padding-left: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #bdc1c6;
}

.adminTestDurationUnit {
  margin-left: 18px;
}

.adminTestDurationUnit option {
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  font-style: normal;
  font-family: "Inter";
}

.adminTestDes {
  width: 50%;
  resize: none;
  height: 105px;
  border-radius: 4px;
  margin-bottom: 10px;
  background: #ffffff;
  padding: 7px 0px 7px 10px;
  border: 1px solid #bdc1c6;
}

.adminTestDes::placeholder,
.adminTestName::placeholder,
.adminTestDuration::placeholder,
.adminTestDurationNum::placeholder,
.adminTestDurationUnit::placeholder {
  font-size: 14px;
  color: #bdc1c6;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.adminOptionalText {
  font-size: 12px;
  font-weight: 400;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter";
}

.adminTestType {
  width: 50%;
  margin-bottom: 10px;
}

#adminTestReplaceQnSection {
  display: inline;
}

#adminTestReplaceQnSection
  .MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  font-family: "Inter";
}

.adminTestBackBtn {
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 20px;
}

.adminTestBack {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  text-transform: uppercase;
}

.adminTestConfigSec {
  overflow: auto;
  margin-top: 20px;
  min-height: 50vh;
  max-height: calc(100vh - 33vh);
}

.adminTestConfigContainer .adminTestSection {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.adminTestConfigContainer .adminTestSectionName {
  margin: 10px;
  width: 250px;
  padding: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  background: #f2f3f5;
}

.adminTestConfigContainer .adminTestSectionName::placeholder {
  font-size: 12px;
  color: #bdc1c6;
  font-weight: 400;
  line-height: 18px;
  font-style: normal;
  font-family: "Inter";
}

.adminTestConfigContainer .adminTestSectionName:hover {
  outline: none;
  color: #202124;
  border-radius: 4px;
  background: #ffffff;
  border-color: #0c70e4;
  border: 1px solid #bdc1c6;
}

.adminTestConfigContainer .adminTestSectionName:disabled:hover {
  border: none;
  outline: none;
  color: #202124;
  background: none;
}

.adminTestConfigContainer .ant-table-content table {
  width: 96%;
  border-spacing: 0 3px;
  margin: -5px 20px 12px;
  border-collapse: separate;
}

.adminTestConfigContainer .ant-table-thead,
.adminTestConfigContainer .ant-table-tbody .ant-table-cell {
  border-radius: 4px;
  background: #f2f3f5;
  box-shadow: inset 0px -1px 0px #e4e5e7;
}

.adminTestConfigContainer .rmsc .dropdown-container {
  border: none;
}

.adminTestConfigContainer .adminTestTopicConfigData .ant-table-thead {
  height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #bdc1c6;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
  background: #f2f3f5;
  text-align: center;
  text-transform: uppercase;
}

.adminTestConfigContainer .ant-empty-description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 20px;
  font-style: normal;
  font-family: "Inter";
}

.adminTestConfigContainer .ant-empty-image {
  margin: 0;
  height: 0;
  padding: 0;
}

.adminTestConfigContainer
  .adminTestTopicConfigData
  .ant-table-tbody
  .ant-table-cell {
  height: 40px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #e4e5e7;
}

.adminTestMoveIcon {
  float: left;
  cursor: grab;
  color: #26344a;
  margin: 20px 10px 0 10px;
}

.adminTestConfigContainer .adminTestConfig,
.adminTestConfigContainer .adminTestConfig:disabled {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #202124;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
}

.adminTestSectionIconSec {
  display: flex;
  justify-content: flex-end;
}

.adminTestAddLevelBtn,
.adminTestAddSectionBtn {
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  color: #0c70e4;
  line-height: 20px;
  font-style: normal;
  font-family: "Inter";
}

.adminTestAddSectionBtn {
  width: 100px;
  text-align: left;
  margin-left: 20px;
}

.adminTestAddLevelBtn {
  width: 95px;
  text-align: left;
  margin-left: 20px;
}

.adminTestSectionExpandIcon {
  cursor: pointer;
  color: #202124;
  margin: 18px 0px 0 0;
}

.adminTestConfigData {
  padding: 0;
  width: 100%;
  height: 40px;
  border: none;
  font-size: 14px;
  color: #202124;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  background: #ffffff;
}

.adminTestConfigLangIconSec {
  margin-left: 5px;
  width: fit-content;
  border-radius: 20px;
  display: inline-flex;
  background: #e9ecef;
}

.adminTestConfigCloseSec {
  padding: 0 5px;
}

.adminTestConfigCloseSec:hover {
  background: #f55533;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

#adminTestConfigCloseIcon {
  width: 16px;
  height: 16px;
}

#adminTestConfigJsIcon {
  width: 21px;
}

#adminTestConfigDataTrashIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #f55533;
  margin-top: -5px;
}

#adminTestConfigDataPreviewFxQn {
  width: 24px;
  height: 16px;
  cursor: pointer;
  color: #0c70e4;
  margin-top: -5px;
}

#adminTestSectionTrashIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: #f55533;
  margin: 17px 10px 10px 10px;
}

#adminTestQnListPopup {
  width: 90%;
  margin: 10px 0;
}

#adminTestQnListPopup .ezsSEa > div {
  white-space: pre-wrap !important;
}

.adminTestQnListHeadSec {
  width: 50%;
  display: flex;
}

.adminTestQnCount {
  font-size: 12px;
  font-weight: 400;
  color: #202124;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter";
  margin: 17px 0 0 10px;
}

.adminTestQnSearchBox {
  margin-top: 10px;
}

.adminTestQnSearchIcon {
  top: 22px;
  left: 40px;
  position: relative;
}

.adminTestQnListHead {
  margin-top: 10px;
}

.warning-icon-lp {
  color: #f57070;
}

.infoPopup {
  padding: 10px;
  overflow: auto;
  color: #ffffff;
  width: fit-content;
  overflow-x: hidden;
  border-radius: 4px;
  background: #202124;
  box-shadow: 0px 4px 4px rgba(32, 33, 36, 0.2);
}

.warningData-tooltip {
  padding: 10px;
  overflow: auto;
  color: #ffffff;
  width: fit-content;
  overflow-x: hidden;
  border-radius: 4px;
  background: #202124;
}

.warningData-info {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  font-style: normal;
  margin: 0 0px 5px 0;
  font-family: "Inter";
}

.ant-tooltip {
  display: inline-block;
}

.adminTestConfigLabel {
  padding: 3px;
  font-size: 12px;
  color: #000000;
  font-weight: 500;
  margin-left: 5px;
  line-height: 15px;
  border-radius: 100px;
  font-family: "Inter";
  background: #e9ecef;
}

#adminTestConfigLabelXMark {
  top: 3px;
  left: 2px;
  padding: 3px;
  cursor: pointer;
  position: relative;
}

#adminTestConfigLabelXMark:hover {
  z-index: 2;
  color: "#FFFFFF";
  background: rgb(245, 85, 51);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.adminTestNewSec {
  // width: 100vw;
  display: flex;
  text-align: left;
  flex-direction: column;
}

.adminTestNewSec-left-sec {
  padding: 2vw;
  display: flex;
  min-width: 60vw;
  flex-direction: column;
}

.adminTestNewSec-right-sec {
  padding: 2vw;
  display: flex;
  max-width: 40vw;
  flex-direction: column;
}

.adminTestNewSec-left-sec-header {
  margin-bottom: 3vh;
}

.adminTestNewSec-right-sec-header {
  margin-bottom: 3vh;
}

.dTlPae {
  z-index: 2;
  width: 100%;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.cFKLhN {
  top: 1vh;
  z-index: 1;
  display: flex;
  max-height: 70vh;
  overflow: scroll;
  position: relative;
  flex-direction: column;
}

.adminTestAutoEvalSec {
  display: flex;
}

#adminTestAutoEvalLabel {
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #202124;
  margin: 5px 0 0 0;
  line-height: 14px;
  font-style: normal;
  font-family: "Inter";
}

#adminTestAutoEval {
  cursor: pointer;
}

.adminTestGivenQnCount {
  text-align: right;
  margin-right: 35px;
}

.adminTestToggleBtnSec {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
