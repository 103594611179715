.editDelete {
  width: 80px;
  display: flex;
  justify-content: space-around;
}

.editDeleteEdit:hover {
  background-color: green;
}

.editDeleteDelete:hover {
  background-color: red;
}

.faTrashCan {
  width: 16px;
  height: 16px;
  color: tomato;
}
