@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

.inputFieldContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 320px;
  .inputFieldLabel {
    margin: 0;
    @include m.fontSize(text/sm/medium);
    color: map-get(i.$appColor, "grey/700");
  }
  .inputWrapper {
    height: 44px;
    border-radius: 8px;
    border: 1px solid map-get(i.$appColor, "grey/300");
    background: map-get(i.$appBackGroundColor, "white");
    .inputLeftWrapper {
      padding: 10px 0 10px 14px;
      display: flex;
      gap: 8px;
      align-items: center;
      .inputField {
        border: none;
        outline: none;
        width: 100%;
        height: 24px;
        @include m.fontSize(text/md/regular);
        color: map-get(i.$appColor, "grey/900");
      }
      .inputField::placeholder {
        @include m.fontSize(text/md/regular);
        color: map-get(i.$appColor, "grey/500");
      }
    }
  }
  .inputWrapper.focused {
    border: 1px solid map-get(i.$appColor, "brand/300");
    box-shadow: 0px 0px 0px 4px #f4ebff;
    // box-shadow: 0px 1px 2px 0px #1018280d;
  }
  .inputWrapper.disabled {
    background: map-get(i.$appBackGroundColor, "grey/50");
    border: 1px solid map-get(i.$appColor, "grey/300");
    box-shadow: 0px 1px 2px 0px #1018280d;
    cursor: not-allowed;
    svg {
      cursor: not-allowed;
    }
    .inputField {
      background: unset;
      color: map-get(i.$appColor, "grey/500");
    }
  }

  .inputFieldHintText {
    @include m.fontSize(text/sm/regular);
    color: map-get(i.$appColor, "grey/500");
  }
}

.inputFieldContainer.destructive {
  .inputWrapper {
    border-color: map-get(i.$appColor, "error/300");
  }
  .inputWrapper.focused {
    box-shadow: 0px 0px 0px 4px #fee4e2;
    // box-shadow: 0px 1px 2px 0px #1018280D;
  }
  .inputFieldHintText {
    color: map-get(i.$appColor, "error/500");
  }
}
