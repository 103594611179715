.dashBoardForm {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 70px;
  margin-left: 20px;



  // .widgetForm.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  //   max-width: calc(-120px + 80vw) !important;
  //   max-height: calc(-119px + 100vh) !important;
  //   min-width: calc(-120px + 80vw) !important;
  //   min-height: calc(-119px + 100vh) !important;
  // }




  // .tableForWidgets.ant-table-cell {
  //   border: 1px solid #bdc1c6;
  // }
  .ant-table-cell {
    border: 1px solid #bdc1c6;
    padding-left: 20px;
  }

  .dashBoardSave {
    position: absolute;
    display: flex;
    gap: 10px;
    bottom: 10px;
    right: 20px;
  }

  .addAndSelect,
  .selectViewers {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 15px;
  }

  #dashboardDescription {
    background: rgb(255, 255, 255);
    gap: 10px;
    min-height: 100px;
    outline: none;
    padding: 7px 0px 7px 10px;
    width: 862px;
    border: 1px solid rgb(189, 193, 198);
    border-radius: 4px;
    height: 54px;
  }

  #dashboardName {
    background: rgb(255, 255, 255);
    gap: 10px;
    height: 40px;
    outline: none;
    padding: 7px 0px 7px 10px;
    width: 862px;
    border: 1px solid rgb(189, 193, 198);
    border-radius: 4px;
  }

  .dashBoardNameContainer,
  .dashBoardDescriptionContainer,
  .selectWidgetContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
  }



  .widgetSelect,
  .dashboarddescription,
  .dashBoardName {
    color: #202124;
    float: left;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 6px;
  }
}

.widgetForm .MuiPaper-root
//.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper 
{
  max-width: calc(-120px + 80vw) !important;
  max-height: calc(-119px + 100vh) !important;
  min-width: calc(-120px + 80vw) !important;
  min-height: calc(-119px + 100vh) !important;
  }