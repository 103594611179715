@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

#waringPopupContainer {
  .MuiPaper-root {
    min-width: 544px;
    min-height: 208px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    border-radius: 12px;
    .waringPopupContentSec {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 100%;
      .waringPopupContent {
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-width: 424px;
        min-height: 84px;
        .waringPopupContentTitle {
          @include m.fontSize(text/lg/semibold);
          color: map-get(i.$appColor, "grey/900");
        }
        .waringPopupContentBody {
          @include m.fontSize(text/md/regular);
          color: map-get(i.$appColor, "grey/500");
        }
      }
      .waringPopupContentBtnSec {
        display: flex;
        justify-content: flex-end;
        button{
            min-width: 58px;
            height: 44px;
        }
      }
    }
  }
}
