
.tooltip-box-sessions-box{

    .tooltip-box-sessions-div{
        // height: 88px;
        width: 197px;
        display: flex;
        justify-content: space-between;
        background-color: #FFFFFF;
        margin: 12px 12px 6px 12px;
        
        .tooltip-box-sessions-name{
            height: 15px;
            width: 98px;
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 500;
            color: #101828;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        
        .tooltip-box-sessions-attendance-status-noresponse{
            height: 18px;
            border-radius: 16px;
            background-color: #F2F4F7;
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 500;
            color: #344054;
            padding: 0 8px 0 8px;
        }

        .tooltip-box-sessions-attendance-status-present{
            height: 18px;
            border-radius: 16px;
            background-color: #ECFDF3;
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 500;
            color: #027A48;
            padding: 0 8px 0 8px;
        }

        .tooltip-box-sessions-attendance-status-absent{
            height: 18px;
            border-radius: 16px;
            background-color: #FEF3F2;
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 500;
            color: #B42318;
            padding: 0 8px 0 8px;
        }
    }

    .tooltip-box-date-time-div{
        display: flex;
        margin: 0 12px 12px 12px;

        .tooltip-box-startdate-div{
            font-family: 'Inter';
            font-size: 10px;
            font-weight: 400;
            color: #344054;
        }

        .tooltip-box-time-div{
            font-family: 'Inter';
            font-size: 10px;
            font-weight: 300;
            color: #667085;
            margin: 0 0 0 5px;
        }
    }
}

