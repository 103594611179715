@use "../../Abstracts/Variables/index" as i;
@use "../../Abstracts/mixins" as m;

.ccSecQnsUtilsContainer {
  display: grid;
  // justify-content: space-between;
  gap: 21.33px;
  // flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
  p {
    margin: 0;
    padding: 0;
  }
  .ccSecQnsUtilContainer {
    // width: 224px;
    height: 100px;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    color: map-get(i.$appColor, "grey/200");
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    // flex: 1 0 224px;
    .ccSecQnsUtilTitle,
    .ccSecQnsUtilCount {
      font-family: Inter;
      font-size: 16px;
      line-height: 30px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    .ccSecQnsUtilTopSec {
      display: flex;
      justify-content: space-between;
      .ccSecQnsUtilTitle {
        font-weight: 500;
      }
    }
    .ccSecQnsUtilBottomSec {
      display: flex;
      gap: 21px;
      justify-content: space-between;
      .ccSecQnsUtilCount {
        font-weight: 600;
        align-self: flex-end;
        margin-top: 20px;
      }
      .categoryContainer {
        display: flex;
        list-style-type: none;
        gap: 8px;
        margin: 0;
        .categoryItem {
          height: 48px;
          //   .categoryCount {
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          text-align: right;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          text-transform: capitalize;

          //   }
          .categoryItemName {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            background: linear-gradient(180deg, #daf7eb 0%, #7bd6b0 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;
            left: 0;
            bottom: 10px;
          }
        }
        hr {
          border: 1px solid;
          background: conic-gradient(
            from 90deg at 50% 100%,
            #ffffff 0deg,
            #999999 360deg
          );
          align-self: center;
          height: 70%;
        }
      }
    }
  }
  .ccSecQnsUtilContainer.totalCount {
    background: linear-gradient(138.29deg, #555772 5.92%, #292a38 96.16%);
  }
  .ccSecQnsUtilContainer.attemptCount {
    background: linear-gradient(138.29deg, #0099ff 5.92%, #00008b 96.16%);
  }
  .ccSecQnsUtilContainer.solvedCount {
    background: linear-gradient(101.73deg, #05c273 7.7%, #027a48 97.02%);
  }
  .ccSecQnsUtilContainer.firstCount {
    background: linear-gradient(138.29deg, #3a8aad 5.92%, #133c4d 96.16%);
  }
  .ccSecQnsUtilContainer:hover {
    border: 1.1px solid;
    border-color: linear-gradient(103.23deg, #8ccdd3 3.04%, #999999 93.29%);
    transform: scale(1.05);
  }
  .ccSecQnsUtilContainer.blur {
    opacity: 0.5;
    .categoryItemName {
      background: none !important;
      -webkit-text-fill-color: white !important;
    }
  }
}
