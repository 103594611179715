@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.tableContainer {
  //   height: calc(100vh - 115px);
  overflow-y: auto;
  p {
    margin: 0;
    padding: 0;
  }
  .tableSec {
    width: 100%;
    border-collapse: collapse;
    .tableHeadRow {
      background: map-get(i.$appBackGroundColor, "white");
      border-bottom: 0.6px solid;
      border-color: map-get(i.$appColor, "grey/300") !important;
      .tableHead {
        text-align: left;
        @include m.fontSize(text/xs/regular);
        color: map-get(i.$appColor, "grey/900");
        padding: 12px 24px;
        border: unset;
      }
    }
    .tableBodyRow {
      background: map-get(i.$appBackGroundColor, "white");
      cursor: pointer;
      .tableData {
        padding: 12px 24px;
        border-bottom: 1px;
        @include m.fontSize(text/sm/medium);
        color: map-get(i.$appColor, "grey/700");
      }
    }
    .tableBodyRow:nth-child(odd) {
      background-color: map-get(i.$appBackGroundColor, "grey/50");
    }
    .tableBodyRow:nth-child(even) {
      background-color: map-get(i.$appBackGroundColor, "white");
    }
    .tableBodyRow:hover {
      background-color: map-get(i.$appBackGroundColor, "grey/100");
      border-width: 1px 0px;
      border-style: solid;
      border-color: map-get(i.$appColor, "grey/300");
    }
  }
}
