.notification {
  top: 5px;
  z-index: 2;
  right: 5px;
  position: fixed;
  margin-top: 50px;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 8px 16px 8px;
  border-left: 8px solid #f55533;
  background: var(--Gray-White, #fff);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}

.notificationforpresent {
  top: 5px;
  z-index: 2;
  right: 5px;
  position: fixed;
  margin-top: 50px;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 8px 16px 8px;
  border-left: 8px solid #2ac351;
  background: var(--Gray-White, #fff);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}

.notifyPara {
  margin: 0px !important;
}

.notifyBody {
  width: 440px;
  display: flex;
  text-align: justify;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}

.notifyHeading {
  margin: 0px !important;
}

.responseButtons {
  gap: 8px;
  width: 440px;
  display: flex;
  padding-top: 8px;
  flex-direction: row;
  align-items: flex-start;
}

.btnSmallPresent {
  border: none;
  display: flex;
  padding: 12px;
  color: #fff;
  flex-shrink: 0;
  font-size: 16px;
  min-width: 30px;
  max-height: 30px;
  font-weight: 400;
  border-radius: 4px;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  background: #2ac351;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}
.btnSmallAbsent {
  border: none;
  display: flex;
  padding: 12px;
  color: #fff;
  flex-shrink: 0;
  font-size: 16px;
  min-width: 30px;
  max-height: 30px;
  font-weight: 400;
  border-radius: 4px;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  background: #f55533;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.btnSmallIgnore {
  display: flex;
  padding: 12px;
  color: #fff;
  flex-shrink: 0;
  font-size: 16px;
  min-width: 30px;
  max-height: 30px;
  font-weight: 400;
  border-radius: 4px;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  background: #d9d9d9;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #dddee1;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.08);
}

.close-button {
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  position: absolute;
}