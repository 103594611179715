@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.scoreLogMainContainer {
  width: 460px;
  height: 100vh;
  overflow: auto;
  margin: 16px 20px;

  .scoreLogHeader {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;

    .scoreLogHeaderTitle {
      @include m.fontSize(text/lg/medium);
      color: map-get(i.$appColor, "grey/900");
    }

    svg {
      cursor: pointer;
    }
  }

  .scoreLogBody {
    display: flex;
    margin-bottom: 24px;
    align-items: center;

    .scoreLogBodyTitle {
      @include m.fontSize(text/lg/medium);
      color: map-get(i.$appColor, "grey/700");
    }

    .scoreLogBodyScore {
      margin-left: 8px;
      @include m.fontSize(text/lg/medium);
      color: map-get(i.$appColor, "grey/900");
    }
  }

  .vertical-timeline {
    margin: 0;
    padding: 0;

    .vertical-timeline-element-icon {
      width: 0px;
      height: 0px;
      margin-left: 20px;
      background: transparent;

      svg {
        display: unset;
        height: 16px;
        width: 16px;
        margin-top: -2px;
      }
    }

    .verticalTimeLineElement {
      width: 460px;
      height: 42px;
      margin-bottom: 16px;

      .vertical-timeline-element-content {
        padding: 0;
        box-shadow: none;
        margin: 0 0 0 43px;
      }

      .verticalTimeLineDateStyle {
        padding: 0;

        .verticalTimeLineElementDateAndTime {
          margin: 0;
          @include m.fontSize(text/xs/regular);
          color: map-get(i.$appColor, "grey/500");
        }
      }

      .verticalTimeLineElementScoreLog {
        margin: 0;
        @include m.fontSize(text/md/medium);
        color: map-get(i.$appColor, "grey/900");
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
