@use "../Abstracts/Variables/index" as i;
@use "../Abstracts/mixins" as m;

.buttonGroupContainer {
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0px 1px 2px 0px #1018280d;
  height: 40px;
  border-collapse: collapse;
  .buttonGroupBtn {
    @include m.fontSize(text/sm/medium);
    color: map-get(i.$appColor, "grey/700");
    cursor: pointer;
    padding: 20px 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    background: map-get(i.$appColor, "white");
    height: 40px;
    outline: none;
    border: 1px solid map-get(i.$appColor, "grey/300");
  }
  .buttonGroupBtn:disabled,.buttonGroupBtn.unPressed {
    color: map-get(i.$appColor, "grey/300");
    svg {
      path {
        stroke: map-get(i.$appColor, "grey/300");
      }
    }
  }
  .buttonGroupBtn.styledBtn {
    border-right: none;
  }
  .buttonGroupBtn:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .buttonGroupBtn:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
