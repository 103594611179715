@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.codingHeaderAdminSideMainContainer {
  margin: 8px 24px;

  .codingHeaderAdminSideHeader {
    display: flex;

    .buttonsm {
      margin-right: 12px;
    }
  }
}

.overlayInfoForProctorData {
  display: flex;
  flex-direction: column;

  .overlayInfoForProctorDataInnerDiv {
    display: flex;
    gap: 10px;
  }
}

#proctoringDetailContainer {
  width: 85%;
  height: 560px;
  overflow: auto;
  margin: 50px 50px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);

  .proctoringTitleSection {
    height: 20px;
    display: flex;
    justify-content: flex-end;

    .proctoringCloseIcon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      color: #202124;
      margin: 5px 10px 5px 0;
    }
  }

  .proctoringForm {
    width: 90%;
    margin: 10px 25px;

    .details-img-seperate {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 0 0 10px 0;

      .student-details-proctor {
        width: 70%;

        .userName {
          width: 20%;
          height: 14px;
          font-size: 12px;
          font-weight: 600;
          color: #202124;
          line-height: 14px;
          font-style: normal;
          align-items: center;
          font-family: "Inter";
          display: inline-block;
        }

        .userName-durn {
          font-size: 12px;
          font-weight: 600;
          color: #202124;
          margin: 0 0 0 5px;
          line-height: 14px;
          font-style: normal;
          align-items: center;
          font-family: "Inter";
        }

        .proctoringDetails {
          height: 90px;
          margin: 0 0 0 0;
        }
      }
    }

    .collapsible_userDetails {
      width: 75%;
      margin: 1% 0 5% 0;
      display: inline-block;
      background: #f2f3f5;

      .timeline-header {
        font-size: 12px;
        font-weight: 600;
        color: #202124;
        line-height: 14px;
        font-style: normal;
        padding: 0 0 0 5px;
        font-family: "Inter";
        display: inline-block;
      }

      .timeline-header-arrowIcon {
        float: right;
        font-size: 12px;
        font-weight: 600;
        color: #202124;
        line-height: 14px;
        font-style: normal;
        align-items: center;
        font-family: "Inter";
        margin: 5px 5px 0px 0px;
      }

      .activity-log {
        font-size: 12px;
        color: #202124;
        font-weight: 500;
        font-style: normal;
        margin: 10px 0 0 0;
        font-family: "Inter";
        background: #ffffff;
        border: 2px solid #f2f3f5;
      }

      .activity-log-timestamp {
        width: 25%;
        text-align: center;
        vertical-align: top;
        display: inline-block;
      }

      .activity-log-eventtext {
        width: 70%;
        text-wrap: pretty;
        margin: 0 0 0 10px;
        display: inline-block;
      }
    }

    .proctoringImagesTitleContainer {
      display: flex;
      

      .proctoringImagesTitleSec,
      .proctoringImgTimestampSec {
        gap: 10px;
        display: flex;
        align-items: center;      
      }
     
      .proctoringImagesTitleSec{
        .proctoringImagesSelectAll{
          display: flex;
          align-items: center;
          gap:8px;
       
      }
      .proctoringImagesShowViolations{
        display: flex;
        gap:10px;
        margin-top:10px;    
      }
      
    }
      .proctoringTimeStamp {
        height: 20px;
        height: 14px;
        font-size: 12px;
        font-weight: 600;
        color: #202124;
        line-height: 14px;
        margin: 0 0 0 4px;
        font-style: normal;
        width: fit-content;
        align-items: center;
        font-family: "Inter";
      }
    }

    .ProctoringImagesContainer {
      width: 100%;
      height: 280px;
      overflow: scroll;
      overflow-y: auto;
      margin: 20px 0 0 0;

      .proctoringImgDiv {
        height: 220px;
        display: table-cell;

        .proctoringImgTimestampSec {
          padding: 5px 0;
          background: #f2f3f5;
          justify-content: center;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;

          .proctoringImgDivHedaer {
            gap: 5px;
            display: flex;
          }
        }
      }

      .proctoringImgDiv > img {
        width: 280px;
        height: 220px;
        font-size: 25px;
        cursor: pointer;
        line-height: 100px;
        background: blue;
        display: inline-block;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 0 0 3px #fff inset;
      }

      .proctoringFaceDetected {
        border: 3px solid rgb(224, 248, 6);
      }
    }

    .proctoringLine {
      margin: 20px 0 0 10px;
    }
  }
}
