@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.resume_withbtn {
  border: 1px solid map-get(i.$appColor, "grey/200");
  border-radius: 10px;
  margin: 0 0 20px 0;

  .download_resume {
    padding: 0 24px 0 0;
    margin: 8px 0 0 0;
    display: flex;
    flex-direction: row-reverse;

    button {
      border: none;
    }
  }
  .resume {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    margin: 0px 66px;

    .resume_body {
      width: 100%;
      z-index: 1;

      .resume_date {
        @include m.fontSize(text/xs/regular);
        color: map-get(i.$appBackGroundColor, "grey/500");
        text-align: end;
        margin: 0 0 11px 0;
      }

      .header {
        // max-width: 880px;
        // height: 113px;
        background: map-get(i.$appBackGroundColor, "grey/100");
        margin: 0 0 24px 0;
        padding: 12px 24px;
        display: flex;
        justify-content: space-between;
        border-radius: 8px;

        .personal_detail {
          width: 89%;

          .name_role {
            display: flex;
            flex-direction: column;
            margin: 0 0 4px 0;

            .personal_detail_name {
              display: flex;
              align-items: center;

              .name {
                @include m.fontSize(display/xs/semibold);
                color: map-get(i.$appColor, "grey/700");
                margin: 0 0 4px 0;
              }

              .profile_intro {
                margin: 0 0 0 8px;
              }
            }

            .role {
              @include m.fontSize(display/xs/semibold);
              color: map-get(i.$appColor, "grey/700");
              font-weight: 300;
              font-style: italic;
            }
          }

          .social_details {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .mobile {
              @include m.fontSize(text/xs/regular);
              color: map-get(i.$appColor, "grey/700");
              display: flex;
              align-items: center;

              svg {
                margin: 0 4px 0 0;
              }
            }

            .email {
              @include m.fontSize(text/xs/regular);
              color: map-get(i.$appColor, "grey/700");
              display: flex;
              align-items: center;
              margin: 0 0 0 12px;

              svg {
                margin: 0 4px 0 0;
              }
            }

            .social_links {
              margin: 0 0 0 16px;

              svg:hover path:nth-of-type(1) {
                fill: map-get(i.$appColor, "grey/900");
              }
            }

            .linkedin {
              margin: 0 0 0 16px;

              svg:hover {
                rect {
                  fill: map-get(i.$appColor, "grey/900");
                }
              }
            }
          }
        }

        .profile {
          // width: 92px;
          // height: 92px;
          width: 11%;
        }
      }

      .body {
        display: flex;

        .body_left_pane {
          // width: 480px;
          width: 61%;
          margin: 0 20px 0 0;

          .left_pane_title {
            @include m.fontSize(text/xs/semibold);
            color: map-get(i.$appColor, "brand/500");
            margin: 0 0 4px 0;
          }

          .left_pane_section_carrer {
            margin: 0 0 16px 0;

            .carrer_objective {
              @include m.fontSize(text/sm/regular);
              color: map-get(i.$appColor, "grey/700");
            }
          }

          .left_pane_section_education {
            margin: 0 0 20px 0;

            .education_details {
              display: flex;
              margin: 0 0 12px 0;

              .institutions {
                width: 80%;

                .institution_name {
                  @include m.fontSize(text/md/medium);
                  color: map-get(i.$appColor, "grey/700");
                  margin: 0 0 6px 0;
                }

                .institution_course {
                  @include m.fontSize(text/sm/regular);
                  color: map-get(i.$appColor, "grey/500");
                }
              }

              .year_score {
                width: 20%;

                .year {
                  @include m.fontSize(text/xs/medium);
                  color: map-get(i.$appColor, "grey/500");
                  margin: 0 0 6px 0;
                }

                .score {
                  @include m.fontSize(text/sm/medium);
                  color: map-get(i.$appColor, "grey/700");
                }
              }
            }
          }

          .left_pane_section_projects {
            margin: 0 0 20px 0;

            .projects {
              margin: 0 0 8px 0;

              .project_name {
                @include m.fontSize(text/md/medium);
                color: map-get(i.$appColor, "grey/700");
                margin: 0 0 6px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                  margin: 0 0 0 8px;
                }

                .project_duration {
                  @include m.fontSize(text/xs/medium);
                  color: map-get(i.$appColor, "grey/500");
                }
              }

              .project_tags {
                @include m.fontSize(text/xs/medium);
                color: map-get(i.$appColor, "brand/500");
                margin: 0 0 8px 0;

                span {
                  margin: 0 8px 0 0;
                }
              }

              .project_detail {
                @include m.fontSize(text/sm/regular);
                color: map-get(i.$appColor, "grey/700");
                white-space: "pre-line";
              }

              .project_detail_view {
                @include m.fontSize(text/xs/regular);
                color: map-get(i.$appColor, "grey/900");
                font-style: italic;
                cursor: pointer;
              }
            }
          }

          .left_pane_section_internship {
            margin: 0 0 20px 0;

            .internship_details {
              display: flex;
              margin: 0 0 8px 0;

              .internship_institutions {
                width: 80%;

                .internship_name {
                  @include m.fontSize(text/md/medium);
                  color: map-get(i.$appColor, "grey/700");
                }

                .internship_course {
                  @include m.fontSize(text/sm/medium);
                  color: map-get(i.$appColor, "grey/500");
                  font-style: italic;
                }
              }

              .internship_month {
                width: 20%;

                .month {
                  @include m.fontSize(text/xs/medium);
                  color: map-get(i.$appColor, "grey/500");
                }

                .city {
                  @include m.fontSize(text/sm/regular);
                  color: map-get(i.$appColor, "grey/700");
                }
              }
            }

            .internship_description {
              @include m.fontSize(text/sm/regular);
              color: map-get(i.$appColor, "grey/700");
            }
          }

          .left_pane_section_certificates {
            .certificate_details {
              display: flex;
              margin: 0 0 8px 0;

              .certificates {
                width: 80%;

                .certificate_name {
                  @include m.fontSize(text/md/medium);
                  color: map-get(i.$appColor, "grey/700");
                }

                .certificate_institute {
                  @include m.fontSize(text/sm/medium);
                  color: map-get(i.$appColor, "grey/500");
                  font-style: italic;
                }
              }

              .certificate_year {
                width: 20%;
                text-align: center;

                .year {
                  @include m.fontSize(text/xs/medium);
                  color: map-get(i.$appColor, "grey/500");
                }
              }
            }

            .achivements_details {
              margin: 0 0 5px 0;

              .achivements {
                .achivements_name {
                  @include m.fontSize(text/sm/regular);
                  color: map-get(i.$appColor, "grey/700");
                }
              }
            }
          }
        }

        .body_right_pane {
          // width: 380px;
          width: 37%;

          .right_pane_section {
            .right_pane_title {
              @include m.fontSize(text/md/semibold); //16,600
              color: map-get(i.$appColor, "gray/700");
            }

            .wrap_geval_box {
              display: flex;
              flex-wrap: wrap;

              .geval_box {
                // width: 120px;
                margin: 12px 0 0 6px;
                border-radius: 8px;
                border: 1px solid map-get(i.$appBackGroundColor, "grey/300");
                flex: 1;

                .geval_header {
                  height: 40px;
                  background: map-get(i.$appBackGroundColor, "grey/100");
                  padding: 8px;
                  border-radius: 8px 8px 0 0;

                  .header_title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include m.fontSize(text/sm/semibold); //14,600
                    color: map-get(i.$appColor, "gray/500");

                    span {
                      margin: 0 8px 0 0;
                    }
                  }
                }

                .geval_body {
                  @include m.fontSize(text/xl/semibold); //20,600
                  font-style: italic;
                  color: map-get(i.$appBackGroundColor, "grey/700");
                  margin: 13px 7px 7px 0;
                  text-align: right;
                }

                // .geval_footer {}
              }
            }
          }

          .skill_section {
            margin: 28px 0 0 0;

            .skill_section_title {
              @include m.fontSize(text/md/semibold); //16,600
              color: map-get(i.$appBackGroundColor, "grey/700");
            }

            .skill_box {
              border: 1px solid map-get(i.$appBackGroundColor, "grey/300");
              border-radius: 6px;
              padding: 8px;
              margin: 0 0 20px 0;

              .skill_header {
                display: flex;
                justify-content: space-between;
                padding: 0 8px;
                margin: 0 0 8px 0;

                .skill_name {
                  @include m.fontSize(text/md/semibold);
                  color: map-get(i.$appBackGroundColor, "grey/900");

                  span {
                    margin: 0 0 0 8px;

                    svg {
                      margin: 0 0 5px 0;
                    }
                  }
                }

                .skill_percentage {
                  @include m.fontSize(text/md/semibold);
                  font-style: italic;
                  color: map-get(i.$appBackGroundColor, "brand/500");
                }
              }

              .skill_test_details {
                padding: 0 8px;

                .skill_tests {
                  @include m.fontSize(text/sm/regular);
                  color: map-get(i.$appBackGroundColor, "grey/700");
                }
              }

              .skill_remark {
                // margin: 10px 0 0 0;

                .remark_title {
                  @include m.fontSize(text/xs/semibold);
                  font-style: italic;
                  color: map-get(i.$appColor, "grey/500");
                  margin: 4px 0 6px 0;
                }

                .remark {
                  @include m.fontSize(text/xs/semibold);
                  color: map-get(i.$appColor, "grey/700");
                  padding: 0 8px;
                  text-align: justify;
                }
              }

              .skill_endline {
                width: 100%;
                margin: 12px 0 12px 0;
                border: 1px solid map-get(i.$appColor, "grey/300");
              }
            }
          }

          .overall_remark {
            background: map-get(i.$appBackGroundColor, "brand/50");
            margin: 0 0 6px 0;
            padding: 16px;
            border-radius: 8px;
            position: relative;

            .quotes {
              position: absolute;
              right: 30px;
              top: -10px;
            }

            .overall_remark_title {
              @include m.fontSize(text/md/semibold);
              color: map-get(i.$appBackGroundColor, "grey/700");
              margin: 0 0 8px 0;
            }

            .overall_remark_points {
              @include m.fontSize(text/xs/regular);
              color: map-get(i.$appBackGroundColor, "grey/700");
            }
          }
        }
      }

      .footer {
        height: 38px;
        display: flex;
        margin: 0 0 7px 0;
        justify-content: space-between;
        align-items: center;

        span {
          @include m.fontSize(text/xs/regular);
          color: map-get(i.$appBackGroundColor, "grey/500");
        }
      }
    }

    .exclude_svg {
      position: absolute;
      z-index: 0;
    }
  }
  .footer {
    height: 38px;
    display: flex;
    margin: 0 66px 7px 66px;
    justify-content: space-between;
    align-items: center;

    span {
      @include m.fontSize(text/xs/regular);
      color: map-get(i.$appBackGroundColor, "grey/500");
    }
  }
}
