.tablefortimespentcontainer,
.tablefortimespentcont {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 12px;
    flex-wrap: wrap;

    .pointmodalContainer,
    .pointmodalCont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .leftsideoptions{
            display: flex;
            justify-content: flex-start;
            // justify-items: flex-start;
            align-items: center;
            gap: 16px;
        }


        .testPointlabelEnable,
        .timeSpentlabelEnable,
        .profileinuser {
            //styleName: Text lg/Semibold;
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
            color: #101828;
        }

        .timeSpentlabelDisable,
        .testPointlabelDisable,
        .profileinuserDisable {
            //styleName: Text lg/Regular;
            font-family: Inter;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            color: #667085;
            cursor: pointer;


        }
    }




    .timespenttextcont {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 1024px;
        height: 79px;
        background: #ffffff;
        gap: 0px;
        border-radius: 8px 8px 0px 0px;
        border: 1px 1px 0px 0px;


        .timespenttext {
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
            color: #344054;
            margin-left: 26px;
        }
    }






    .tablewithtotal-table-container,
    .tablewithtotalpopup-table-container {

        // // max-height: 550px;
        // border: 1px solid #f9fafb;
        // width: 1024px;
        // overflow-y: auto;
        .tablewithtotal-table {
            .tablewithtotal-table-th {
                height: 44px;
                background-color: #f9fafb;

                .tablewithtotal-table-th-tr {
                    .sticky-header {
                        position: sticky;
                        top: -1px;
                        background-color: #f9fafb;
                    }

                    th {
                        height: 44px;
                        min-width: 100px;
                        max-width: 250px;
                        padding: 12px 12px;
                        font-family: 'Inter';
                        font-size: 12px;
                        font-weight: 500;
                        color: #344054;
                    }

                    .tablewithtotal-table-th-tr-pointsscoredcell {
                        background: linear-gradient(94.9deg, #FF7153 0.38%, #D84829 103.07%);
                        font-family: 'Inter';
                        font-size: 14px;
                        font-weight: 600;
                        color: #F2F4F7;
                        border-radius: 8px;
                        border: 1px solid #f9fafb;
                    }
                }
            }

            .tablewithtotal-table-tb {
                .tablewithtotal-table-tb-tr {
                    td {
                        max-height: 72px;
                        padding: 14px 24px;
                        font-family: 'Inter';
                        font-size: 14px;
                        font-weight: 400;
                        color: #344054;
                    }

                    .tablewithtotal-table-tb-tr-1sttd {
                        font-family: 'Inter';
                        font-size: 16px;
                        font-weight: 500;
                        color: #101828;
                        min-width: 100px;
                        max-width: 250px;

                        svg {
                            path {
                                stroke: #DD3612;
                            }
                        }

                        .tablewithtotal-table-tb-tr-1sttd-totaldiv {
                            display: flex;

                            .tablewithtotal-table-tb-tr-1sttd-totaldiv-testsvg {
                                display: flex;
                                width: 40px;
                                height: 40px;
                                margin: 0 12px 0 0;
                                border: 1px solid #f9fafb;
                                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);
                                justify-content: center;
                                align-items: center;
                                border-radius: 8px;
                            }

                            .tablewithtotal-table-tb-tr-1sttd-totaldiv-name-date-div {
                                display: flex;
                                flex-direction: column;
                                max-width: 400px;
                                text-overflow: ellipsis;
                                // width: max-content;

                                .tablewithtotal-table-tb-tr-1sttd-totaldiv-name-date-div-name {
                                    // max-width: 611px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .tablewithtotal-table-tb-tr-1sttd-totaldiv-name-date-div-date-lp {
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #667085;

                                    .line-div {
                                        margin: 0 4px;
                                    }
                                }
                            }
                        }
                    }
                }

            }

            .tablewithtotal-table-tb-total {

                .tablewithtotal-table-tb-total-tr {

                    td {
                        height: 72px;
                        min-width: 191px;
                        font-family: 'Inter';
                        font-size: 20px;
                        font-weight: 600;
                        color: #344054;
                        background: #ffffff;
                    }

                    .tablewithtotal-table-tb-total-tr-1sttd {
                        padding: 16px 0 16px 24px;
                    }

                    .tablewithtotal-table-tb-total-tr-2ndtd {
                        padding: 16px 0 16px 0;
                    }
                }
            }
        }
    }

    .tablewithtotal-table-container {
        border: 1px solid #EAECF0;
        width: 1024px;
        overflow-y: auto;
    }

    .tablewithtotalpopup-table-container {
        width: calc(100vw - 90px);
        min-height: 550px;
        border: 1px solid #EAECF0;
        margin-left: 30px;
        margin-right: 30px;
        padding-bottom: 30px;
    }
}

.tablefortimespentcont {


    flex-direction: column;

    .pointmodalCont {
        padding: 20px 40px 20px 40px;
        gap: 10px;
        display: flex;
        background: #FFFFFF;

        .testPointlabelEnable,
        .timeSpentlabelEnable,
        .profileinuser {
            font-family: Inter;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #f55533;
            padding: 9px 16px 9px 16px;
            gap: 8px;
            border-bottom: 2px solid #F96343
        }

        .timeSpentlabelDisable,
        .testPointlabelDisable,
        .profileinuserDisable {
            font-family: Inter;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #00000099;



        }
    }
}