.sidebar {
  z-index: 5;
  overflow-y: auto;
  scrollbar-width: none;
  background-color: #fff;

  .jobContent {
    .jobHeader {
      display: flex;
      font-family: "Inter";
      justify-content: space-between;

      .jobName {
        display: block;
        font-size: 20px;
        font-weight: 500;
        color: #18191c;
        overflow: hidden;
        max-width: 300px;
        line-height: 28px;
        position: relative;
        white-space: nowrap;
        font-family: "Inter";
        text-overflow: ellipsis;
      }

      .jobCompany {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: #667085;
        line-height: 28px;
        font-family: "Inter";
      }
    }

    .skillsHeadingContainer {
      display: flex;
      font-size: 12px;
      font-weight: 500;
      color: #344054;
      line-height: 20px;
      align-items: center;
      font-family: "Inter";

      .skillsHeading {
        margin-right: 8px;
      }

      .skillsContent {
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        color: #344054;
        line-height: 28px;
        white-space: nowrap;
        font-family: "Inter";
        text-overflow: ellipsis;
      }
    }

    .jobInfo {
      gap: 8px;
      margin: 0;
      display: flex;
      font-size: 16px;
      color: #18191c;
      line-height: 24px;
      align-items: center;
      font-family: "Inter";

      .locationIcon,
      .briefcaseIcon {
        fill: none;
        width: 18px;
        height: 16px;
        stroke: #667085;
        stroke-width: 1.6;
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .location,
      .experience {
        font-size: 14px;
        font-weight: 400;
        color: #344054;
        line-height: 20px;
        font-family: "Inter";
      }

      .reactQuill {
        margin: 12px 0 0 0;

        p {
          padding: 8px 0px;
          text-align: justify;
        }

        .ql-toolbar {
          display: none;
        }

        .ql-container {
          border: none;
        }

        .ql-editor {
          padding: 0;
        }
      }
    }

    .styledLine {
      width: 100%;
      height: 1px;
      margin: 20px 0;
      background-color: rgba(0, 0, 0, 0.18);
    }
  }

  .backButton {
    display: flex;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: #667085;
    line-height: 18px;
    align-items: center;
    font-family: "Inter";

    .chevronIcon {
      width: 14px;
      height: 14px;
    }
  }

  .applyBtn {
    gap: 8px;
    margin: 0;
    display: flex;
    font-size: 16px;
    color: #18191c;
    line-height: 24px;
    align-items: center;
    margin-bottom: 20px;
    font-family: "Inter";
    justify-content: flex-end;
  }
}
