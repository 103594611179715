@use "../../../Abstracts/mixins" as m;
@use "../../../Abstracts/Variables/index" as i;

.mockTestCreateMainContainer {
  display: flex;
  text-align: left;
  flex-direction: column;

  .mockTestCreateForm {
    display: flex;
    padding: 0 2vw;
    min-width: 60vw;
    flex-direction: column;

    .mockTestCreateAISection,
    .mockTestCreateDesSection,
    .mockTestCreateNameSection,
    .mockTestCreateTagsSection,
    .mockTestCreateDurationSection,
    .mockTestCreateFormTypeSection,
    .mockTestCreateInstructionSection {
      display: flex;
      flex-direction: column;

      .mockTestCreateAILabel,
      .mockTestCreateDesLabel,
      .mockTestCreateNameLabel,
      .mockTestCreateTagsLabel,
      .mockTestCreateTypeLabel,
      .mockTestCreateDurationLabel,
      .mockTestCreateInstructionLabel {
        height: 14px;
        font-size: 12px;
        font-weight: 600;
        color: #202124;
        line-height: 14px;
        font-style: normal;
        margin-bottom: 10px;
        font-family: "Inter";

        .mockTestCreateDesLabelOptionalText {
          font-size: 12px;
          font-weight: 400;
          color: #202124;
          line-height: 14px;
          font-style: normal;
          font-family: "Inter";
        }
      }

      .mockTestCreateType,
      .mockTestCreateFormTags {
        width: 50%;
        margin-bottom: 10px;

        .css-1nmdiq5-menu {
          z-index: 3;

          .css-jyjuk6-MenuList {
            scrollbar-width: none;
          }
        }
      }

      .mockTestCreateName,
      .mockTestCreateDuration,
      .adminTestWeightage {
        width: 50%;
        height: 32px;
        padding-left: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        background: #ffffff;
        border: 1px solid #bdc1c6;
      }

      .mockTestCreateDurationNum,
      .mockTestCreateDurationUnit {
        width: 24%;
        height: 32px;
        padding-left: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        background: #ffffff;
        border: 1px solid #bdc1c6;
      }

      .mockTestCreateDurationUnit {
        margin-left: 18px;
      }

      .mockTestCreateDurationUnit option {
        font-size: 12px;
        font-weight: 600;
        color: #202124;
        font-style: normal;
        font-family: "Inter";
      }

      .mockTestCreateDes {
        width: 50%;
        resize: none;
        height: 105px;
        border-radius: 4px;
        margin-bottom: 10px;
        background: #ffffff;
        padding: 7px 0px 7px 10px;
        border: 1px solid #bdc1c6;
      }

      .mockTestCreateDes::placeholder,
      .mockTestCreateName::placeholder,
      .mockTestCreateDuration::placeholder,
      .mockTestCreateDurationNum::placeholder,
      .mockTestCreateDurationUnit::placeholder {
        font-size: 14px;
        color: #bdc1c6;
        font-weight: 400;
        line-height: 20px;
        font-style: normal;
        font-family: "Inter";
      }

      .tox-tinymce {
        width: 50%;
      }
    }

    .mockTestCreateAISection {
      flex-direction: row;

      .mockTestCreateAILabel {
        margin: 4px 0 10px 0;
      }
    }
  }

  .mockTestCreateFooter {
    display: flex;
    justify-content: flex-end;

    .buttonsm {
      margin-left: 20px;
    }
  }
}
