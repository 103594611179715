@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.complexityLabel {
  width: 63px;
  height: 22px;
  cursor: pointer;
  text-align: center;
  @include m.fontSize(text/xs/medium);

  .complexityLabelEasy,
  .complexityLabelHard,
  .complexityLabelMedium {
    border-radius: 16px;
    padding: 2px 8px 2px 8px;
  }

  .complexityLabelEasy {
    color: map-get(i.$appColor, "success/500");
    background: map-get(i.$appBackGroundColor, "success/50");
  }

  .complexityLabelMedium {
    color: map-get(i.$appColor, "orange/500");
    background: map-get(i.$appBackGroundColor, "orange/50");
  }

  .complexityLabelHard {
    color: map-get(i.$appColor, "error/500");
    background: map-get(i.$appBackGroundColor, "error/50");
  }
}
