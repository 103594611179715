.session-table-container {
  max-width: 1600px;
  margin: 30px;
  padding: 10px 0px 10px 0px;
}

.session-table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  color: #373739;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.session-head{
  cursor: pointer;
}

.button-containeronline{
  float: right;
  margin-top: 10px;
  display: flex;
}

.session-table .session-tableHead,
.session-table .session-tableData {
  border: 1px solid rgba(55, 55, 57, 0.2);
  text-align: center;
  align-content: center;

  overflow-wrap: break-word;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 20px 25px 25px 20px;
}

.vinn {
  display: flex;
}

.attndancesearch-bar {
  display: flex;
  align-items: center;
}

.searchattendees {
  border: none;
  background-color: #f2f4f7;
  border-radius: 5px;
  width: 240px;
  height: 40px;
  font-family: "Gill Sans", sans-serif;
}

.attendancesearch-icon {
  margin-left: 5px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.06);

  border-radius: 5px;
}

.send-report-button {
  border: none;

  padding: 3px 8px;
  cursor: pointer;
  background-color: white;

  color: #0671e0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.export-button {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 5px 10px;

  cursor: pointer;
}

.session-table-container {
  margin-top: 10px;
}

.session-table {
  width: 100%;
  border-collapse: collapse;
}

.session-table .session-tableData,
.session-tableHead {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  /* text-align: left; */
}

.session-table .session-tableHead:first-child,
.session-tableData:first-child {
  text-align: center;
}

.session-table td input[type="checkbox"] {
  margin: 0;
}

.offline-table {
  margin: 20px;
  margin-right: 8%;
  padding-bottom: 18px;
  margin-left: 3.1%;
  border: 2px solid #ddd;
}

.dynamic-width-select {
  width: 500px;
  transition: width 0.2s ease;
  max-width: 2000;
}

.status-cell {
  cursor: pointer;
  color: white;
  border: 1px solid #ddd;
  text-align: center;
  border: 1px solid #ddd;
  padding: 5px;
}

.status-cell.status-2 {
  background-color: #f55533;
}

.status-cell.status-1 {
  background-color: #2ac351;
}

.status-cell.status-0 {
  background-color: rgba(55, 55, 57, 0.2);
}

.button-container {
  float: right;
  margin-top: 20px;
  display: flex;
}

.attendanceOfflineSessioname {
  display: flex;
  margin: 25px;
  gap: 20px;
}

.attendanceOnlineBack {
  margin-top: 10px;
}
