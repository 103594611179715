@use "../../../Abstracts/mixins" as m;
@use "../../../Abstracts/Variables/index" as i;

.mockTestBtnDiv {
  gap: 20px;
  right: 20px;
  bottom: 15px;
  display: flex;
  position: absolute;
  background: #ffffff;
  justify-content: space-around;
}
