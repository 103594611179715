@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.commentSvgIcon {
  cursor: pointer;
  margin-right: 12px;
}

.commentSvgIcon:hover {
  fill: map-get(i.$appColor, "grey/200");
}
