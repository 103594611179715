.mainDropDown {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -50px;
  top: 38px;
  width: 160px;
  // height: 180px;
  //   top: 324px;
  //   left: 4769px;
  z-index: 5;
  gap: 0px;
  border-radius: 8px;
  //   border: 1px;
 
  background: #ffffff;
  border: 1px solid #f2f4f7;
  //   box-shadow: 0px 4px 6px -2px #10182808;

  box-shadow: 0px 12px 16px -4px #10182814;
  .dashBoardNameindropdown,
  .optionContainer {
    display: flex;
    // width: 160px;
    // height: 44px;
    // padding: 10px 14px 10px 14px;
    gap: 8px;
    margin-top: 10px;
    margin-left: 14px;
    justify-content: space-between;
    .dashboardNameText {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #101828;
    }
    .option {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #344054;

    }
    .iconsvgmenu {
      margin-right: 16px;
    }
  }
}

