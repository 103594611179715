@use "../../../Abstracts/mixins" as m;
@use "../../../Abstracts/Variables/index" as i;

.mockTestListMainContainer {
  margin-top: 8vh;

  .mockTestListHeader {
    display: flex;
    justify-content: space-between;

    .mockTestListHeaderLabel {
      height: 27px;
      font-size: 22px;
      font-weight: 700;
      color: #000000;
      text-align: left;
      line-height: 27px;
      font-style: normal;
      font-family: "Inter";
    }

    .mockTestListHeaderRightSideDiv {
      display: flex;

      .mockTestListHeaderRightSearchIcon {
        top: 12px;
        left: 40px;
        width: 12.44px;
        height: 12.44px;
        color: #bdc1c6;
        position: relative;
      }

      .mockTestListHeaderSearchBox {
        gap: 10px;
        border: none;
        width: 186px;
        height: 34px;
        outline: none;
        display: block;
        margin: 0 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        font-style: normal;
        border-radius: 4px;
        font-family: "Inter";
        background: #ffffff;
        padding: 9px 4px 9px 35px;
        border: 1px solid #e4e5e7;
      }

      .mockTestListHeaderSearchBox::placeholder {
        width: 47px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #bdc1c6;
        line-height: 20px;
        font-style: normal;
        font-family: "Inter";
      }

      .mockTestListHeaderTopicSelect {
        z-index: 3;
        width: 200px;
        font-size: 12px;
        text-align: left;
        font-weight: 600;
        line-height: 15px;
        font-family: "Inter";

        .css-1s2u09g-control,
        .css-1pahdxg-control {
          min-height: 34px;
        }
      }

      .buttonsm {
        margin-left: 20px;
      }
    }
  }

  .mockTestListTable {
    .rdt_TableHeadRow {
      top: 14vh;
      z-index: 2;
    }

    .dlfHg div {
      text-align: left;
      white-space: pre-wrap;
    }

    .rdt_TableRow {
      cursor: pointer;
    }
    .gcugTW,
    .jUywsP {
      padding-right: 0px;
    }

    .gcugTW {
      margin-top: 0px;
    }

    .kdgCYE {
      height: 78vh;
      display: flex;
      overflow: hidden;
      scrollbar-width: none;
      flex-direction: column;
    }

    .kdgCYE:hover {
      overflow: auto;
    }
  }

  .jzrolw {
    overflow: hidden;
  }

  .jzrolw:hover {
    overflow: auto;
  }
}

.mockTestActiveInput {
  cursor: pointer;
}
