@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.monthPickerMainContainer {
  .monthPickerInputBox {
    height: 32px;
    width: 120px;
    outline: none;
    text-align: center;
    padding: 10px 14px;
    border-radius: 8px;
    @include m.fontSize(text/sm/medium);
    border: 1px solid map-get(i.$appColor, "grey/200");
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    color: #000;
    font-size: 0.8rem;
    position: relative;
    border-radius: 10px;
    display: inline-block;
    border: 1px solid map-get(i.$appColor, "grey/200");
    background: map-get(i.$appBackGroundColor, "white");

    .react-datepicker__month-container {
      width: 331px;
      height: 232px;

      .react-datepicker-year-header {
        @include m.fontSize("text/sm/semibold");
        color: map-get(i.$appColor, "grey/700");
      }

      .react-datepicker__month {
        height: 170px;
        text-align: center;

        .react-datepicker__month-wrapper {
          display: flex;
          border-radius: 8px;
          justify-content: space-around;
          color: map-get(i.$appColor, "grey/600");
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected {
          border-radius: 8px;
          color: map-get(i.$appColor, "white");
          background: map-get(i.$appBackGroundColor, "brand/500");
        }
      }

      .react-datepicker__month .react-datepicker__month-text,
      .react-datepicker__month .react-datepicker__quarter-text {
        width: 53px;
        padding: 8px;
        @include m.fontSize("text/sm/semibold");
      }
    }

    .react-datepicker__navigation-icon {
      top: 12px;
      left: 10px;
      color: map-get(i.$appColor, "grey/300");
    }

    .react-datepicker__navigation--next {
      right: 20px;
    }

    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
      height: 52px;
      padding: 16px 12px;
      border-radius: 10px 10px 0 0;
      border: 1px solid map-get(i.$appColor, "grey/200");
      background: map-get(i.$appBackGroundColor, "white");
    }
  }
}
