
.progresscircle-div{

    .progresscircle-div-title-box{
        width: 68px;
        height: 21px;
        margin: 12px 15px 4px 15px;

        .progresscircle-div-title{
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 600;
            color: #101828;
        }
    }

    .progresscircle-div-box{
        width: 64px;
        height: 64px;
        margin: 0 17px 0 17px;
        position: relative;
        display: inline-flex;

        span{
            height: 58px !important;
            width: 58px !important;
            margin: 3px;
            color: #F55533 !important;
        }

        .css-1rn30mb-MuiCircularProgress-root{
            color: #F55533 !important;
        }
    }

    .progresscircle-div-box-percentage-box{
        width: 29px;
        height: 20px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 22px;
        left: 18px;
        right: 0;
        bottom: 0;

        .progresscircle-div-box-percentage{
            font-family: 'Inter';
            font-size: 14px;
            font-weight: 500;
            color: #344054;
        }
    }
}