.dashboard-batch-dropdown-div {
  width: 1024px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .naviagtorfortestscorestimespent {
    .testscorestimespentcontainer {
      gap: 4px;
      gap: 4px;
      height: 36px;
      padding: 4px;
      display: flex;
      border-radius: 100px;
      background: #ffffff;

      .optionsfornavigate {
        gap: 10px;
        height: 28px;
        cursor: pointer;
        border-radius: 100px;
        background: #ffffff;
        padding: 4px 12px 4px 12px;

        .optiontextfornavigate {
          display: flex;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #344054;
          line-height: 20px;
          font-family: Inter;
        }
      }

      .optionsfornavigate:hover {
        background: #f9fafb;
      }

      .optionsfornavigatecurrent {
        gap: 10px;
        height: 28px;
        cursor: pointer;
        border-radius: 100px;
        background: #f2f4f7;
        padding: 4px 12px 4px 12px;

        .optiontextfornavigatecurrent {
          display: flex;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          color: #344054;
          line-height: 20px;
          font-family: Inter;
        }
      }
    }
  }

  .dashboard-batch-dropdown {
    width: 220px;
    height: 44px;
    border-radius: 8px;
    background-color: #ffffff;

    .batch-dropdown-selectbox {
      width: 220px;
      height: 44px;
      font-size: 16px;
      font-weight: 500;
      color: #101828;
      border-radius: 8px;
      font-family: "inter";
    }

    .batch-dropdown-arrowbacktobatch-icon {
      height: 20px;
      width: 20px;
    }
  }
}

.batch-dropdown-selectbox-menuitem {
  width: 220px !important;
  height: 44px !important;
  display: flex !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #101828 !important;
  font-family: "Inter" !important;
  justify-content: space-between !important;
}

.batch-dropdown-selectbox-menuitem.Mui-selected {
  background-color: #f9fafb !important;
}

.batch-dropdown-item-name {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.batch-dropdown-selectbox-listitemicon {
  width: 20px !important;
  height: 20px !important;
  color: #f55533 !important;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.batch-dropdown-selectbox-menuitemback {
  width: 220px !important;
  height: 44px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #101828 !important;
  padding: 10px 14px !important;
  font-family: "Inter" !important;

  .batch-dropdown-selectbox-listitemarrowicon {
    height: 20px !important;
    min-width: 20px !important;

    svg {
      width: 20px !important;
      height: 20px !important;

      path {
        fill: #667085 !important;
      }
    }
  }
}
