@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.codingStatusLabel,
.codingStatusLabelForCodingTable,
.codingStatusLabelForCodingRightMain {
  gap: 0px;
  margin: 10px 12px;
  text-align: center;
  @include m.fontSize(text/xs/medium);

  .testPending {
    border-radius: 16px;
    padding: 2px 8px 2px 8px;
    color: map-get(i.$appColor, warning/700);
    background: map-get(i.$appBackGroundColor, warning/50);
  }

  .testSubmitted {
    @extend .testPending;
    color: map-get(i.$appColor, success/700);
    background: map-get(i.$appBackGroundColor, success/50);
  }

  .testInProgress {
    @extend .testPending;
    color: map-get(i.$appColor, "orange/700");
    background: map-get(i.$appBackGroundColor, "orange/50");
  }

  .testEvaluated {
    @extend .testPending;
    color: map-get(i.$appColor, "grey/700");
    background: map-get(i.$appBackGroundColor, "grey/50");
  }
}

.codingStatusLabel,
.codingStatusLabelForCodingTable {
  margin: 0px;
  padding: 1% 0;
}

.codingStatusLabelForCodingTable {
  width: 25%;
}
