@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.evaluationLearnerChange {
  height: 32px;
  display: flex;
  position: relative;
  align-items: center;

  .css-1nmdiq5-menu {
    top: 100%;
    z-index: 2;
    width: 100%;
    margin-top: 8px;
    position: absolute;
    border-radius: 4px;
    margin-bottom: 8px;
    box-sizing: border-box;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  }
}

.codeEditorLanguageSelectorAdmin {
  height: 32px;
  border-radius: 8px;
  @include m.fontSize(text/sm/medium);
  color: map-get(i.$appColor, "grey/700");
  border: 1px solid map-get(i.$appColor, "grey/300");
  background: map-get(i.$appBackGroundColor, "white");

  .css-1dimb5e-singleValue {
    color: map-get(i.$appColor, "grey/700");
  }
}

.codeEditorLanguageSelectorAdmin .css-15lsz6c-indicatorContainer,
.codeEditorLanguageSelectorAdmin .css-1xc3v61-indicatorContainer {
  padding: 0px 8px;

  .css-tj5bde-Svg {
    color: map-get(i.$appColor, "grey/500");
  }
}

.codeEditorLanguageSelectorAdmin .css-qbdosj-Input,
.codeEditorLanguageSelectorAdmin .css-dkx05r-control,
.codeEditorLanguageSelectorAdmin .css-18hfk19-control,
.codeEditorLanguageSelectorAdmin .css-1fdsijx-ValueContainer {
  margin: 0px;
  padding: 0px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 8px;
}
