@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.workContainer {
  height: 72px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border: 1px solid map-get(i.$appColor, "grey/300");
  border-radius: 8px;
  .workBtnSec {
    display: flex;
    gap: 16px;
    align-items: center;
    .workEditBtn,
    .workDeleteBtn {
      cursor: pointer;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .workEditBtn:hover,
    .workDeleteBtn:hover {
      border-radius: 8px;
      background: map-get(i.$appColor, "grey/100");
    }
  }
}
