.extDialogContainer .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    margin: 0;
    font-family: "Inter";
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    padding: 16px 24px;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    text-align: center;
}




.extDialogContainer .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    margin: 32px;
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    /* max-height: calc(100% - 64px); */
    /* max-width: 600px; */
    padding: 20px;
    height: 550px;
}


.comments-score-section-score-reject-box-score-button-section-exdialog {
    // display: flex;
    // /* justify-content: flex-end; */
    // align-items: center;
    // margin: 30.18px 18px 0 0;
    margin: 10px 18px 0 0;
}



.comments-score-section-score-reject-box-score-button-section-cancel-button-exdialog {
  border: 1px solid #000000;
  /* width: 113px; */
  height: 39px;
  font-size: 16px;
  margin-right: 25px;
  font-weight: 700;
  color: #373739;
  line-height: 19px;
  border-radius: 5px;
  font-style: normal;
  text-align: center;
  font-family: 'Inter';
  background:none;
  width:70px
  /* box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2), 0 6px 6px 0 rgba(0,0,0,0.19); */
}


.comments-score-section-score-reject-box-score-button-section-cancel-button-exdialog:hover {
  border: 1px solid #F55533;
  /* width: 113px; */
  height: 39px;
  font-size: 16px;
  margin-right: 25px;
  font-weight: 700;
  color: #F55533;
  line-height: 19px;
  border-radius: 5px;
  font-style: normal;
  text-align: center;
  font-family: 'Inter';
  width:70px
}

.comments-score-section-score-reject-box-score-button-section-reject-button-exdialog {
  /* width: 166px; */
  height: 39px;
  border: 1px solid #F55533;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 19px;
  margin-left: 10px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
  font-family: 'Inter';
  background: #F55533;
  width:70px
  /* box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2), 0 6px 6px 0 rgba(0,0,0,0.19); */
}


.comments-score-section-score-reject-box-score-button-section-reject-button-exdialog:hover {
  /* width: 166px; */
  height: 39px;
  border: 1px solid #F55533;
  font-size: 16px;
  font-weight: 700;
  color:  #F55533;
  line-height: 19px;
  margin-left: 10px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
  font-family: 'Inter';
  background: #FFFFFF;
  width:70px
}


.extdialog-details-row{
  display: flex;
}


.extdialog-details-row-label{
width: 150px;
border: 1px solid black;
padding: 5px;
}

.extdialog-details-row-value{
  border: 1px solid black;
  flex-shrink: 1;
  width: 275px;
  padding: 5px;
  }



  .comments-score-section-score-reject-box-score-button-section-reject-button-exdialog-disabled{


      /* width: 166px; */
  height: 39px;
  border: 1px solid grey;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 19px;
  margin-left: 10px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
  font-family: 'Inter';
  background: gray;
  width:70px
  /* box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2), 0 6px 6px 0 rgba(0,0,0,0.19); */
  }

  .extdialog-datepicker-div{
    margin: 10px 0 0 0;
    position: relative;

    .react-datepicker__tab-loop{
      width: fit-content;

      .react-datepicker-popper{
        position: unset !important;
        inset: unset !important;
        transform: unset !important;

        .react-datepicker__input-time-container{

          .react-datepicker-time__input-container{

            .react-datepicker-time__input{

              input{
                border: 1px solid;
              }
            }
          }
        }
      }
    }
  }