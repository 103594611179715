@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.userReportMainContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  position: relative;
  justify-content: center;

  .userReportHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .userReportSubHeader {
      gap: 16px;
      display: flex;
      margin-left: 71px;
      align-items: center;
      justify-content: flex-start;
      @include m.fontSize(text/xl/medium);
      color: map-get(i.$appColor, "grey/500");

      .userReportProfilePage,
      .userReportTimeSpentPage,
      .userReportTestResultsPage {
        padding: 9px 16px;
        color: map-get(i.$appColor, "brand/500");
        border-bottom: 2px solid map-get(i.$appColor, "brand/500");
      }
    }
  }
}
