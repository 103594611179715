@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.inputGroupFieldSec {
  display: flex;
  flex-direction: column;
  gap: 6px;
  .inputGroupFieldTitleSec {
    display: flex;
    gap: 6px;
    #inputGroupFieldTitle {
      display: flex;
      align-items: center;
      @include m.fontSize(text/xs/regular);
      color: map-get(i.$appColor, "grey/900");
    }
  }
  .inputGroupFieldFieldSec {
    display: flex;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #98a2b3;
    .inputGroupFieldCode {
      width: 73px;
      display: flex;
      border-right: 1px solid #98a2b3;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/600");
    }
    #inputGroupField {
      padding: 10px 14px 10px 14px;
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/900");
    }
  }
}
