.sandpackEditorTemplateSelectSec {
  width: 100%;
  display: flex;
  margin-left: 15px;
}

#sandpackEditorTemplateSelectLabel {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-family: "Inter";
}

#sandpackEditorTemplateSelect
.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list
  li {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-family: "Inter" !important;
}

.sandpackEditorContainer1 {
  top: 7vh;
  height: 100vh;
  position: relative;
  overflow: hidden !important;
}

.sandpackEditorContainer2 {
    top: 7vh;
    height: 100vh;
    position: relative;
    overflow: hidden !important;
}

.profileSandpack{
  position: absolute;
  top: 0px;
  left: 20px;
  z-index: 5;
}

.sandpackEditorSec .sp-c-ikJbEZ.sp-layout {
  background: unset;
}

.sandpackEditor .fileExplorerDiv {
  height: 93vh;
  display: flex;
  position: relative;
}

.sandpackEditor .sc-bwzfXH.hEACtv.sc-bdVaJa.cjjWdp {
  height: 2px !important;
  background-color: grey !important;
}

.sandpackEditor .sc-bwzfXH.hEACtv.sc-bdVaJa.cjjWdp:hover {
  height: 5px !important;
  background-color: #f55533 !important;
}

.sandpackEditor .sc-htpNat.fXAXjb.sc-bdVaJa.cjjWdp {
  width: 2px !important;
  background-color: grey !important;
}


.sandpackEditor .ͼ1 .cm-content {
  margin-bottom: 50px;

}
.sandpackEditor .sc-htpNat.fXAXjb.sc-bdVaJa.cjjWdp:hover {
  width: 5px !important;
  background-color: #f55533 !important;
}

.sandpackEditor .cjjWdp {
  padding: 0px 0px !important;
  position: relative !important;
  background: #ffffff !important;
  box-shadow: inset -9px 0 18px -18px rgba(0, 0, 0, 0.3),
    inset -9px 0 18px -6px rgba(0, 0, 0, 0.1) !important;
}

.sp-c-ikJbEZ {
  display: unset !important;
}

.sandpackEditorFileContainer {
  width: 100%;
}

.sandpackEditorFileSec {
  display: flex;
  justify-content: flex-end;
}

#sandpackCodeEditor {
  overflow: auto;
}
.sandpackEditor .css-19kzrtu {
  height: 100vh !important;
}

.sp-icon-standalone.sp-c-bxeRRt.sp-c-gMfcns.sp-c-dEbKhQ.sp-c-bcibQq.sp-button
  ::after {
  gap: 4px;
  bottom: 8%;
  width: 72px;
  height: 30px;
  display: flex;
  border-radius: 52px;
  background: #424242;
  align-items: flex-start;
}

.sp-icon-standalone.sp-c-bxeRRt.sp-c-gMfcns.sp-c-dEbKhQ.sp-button {
  gap: 4px;
  bottom: 8%;
  width: 72px;
  height: 30px;
  display: flex;
  border-radius: 52px;
  background: #424242;
  align-items: flex-start;
}

#sandpackEditorFileInsertIcon,
#sandpackEditorFolderInsertIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.sp-c-euXojQ.sp-preview.sp-stack {
  height: 60vh !important;
}

.fileexplorercontainer {
  top: 0vh;
  position: relative;
}

.cm-line {
  font-size: 12px;
  font-family: "Inter";
}

.outputandpreview {
  position: relative;
  margin-left: 3px !important;
}

.listitem > div > span > span {
  font-weight: bold;
  color: red !important;
}

.listitem {
  background: #f55533 !important;
}

#sandpackCodeEditor .sp-c-bxeRRt svg {
  color: #f55533;
}

.codeeditorandoutputandpreview {
  right: 4%;
  width: 104%;
  position: relative;
}

.sandpackConsoleSec{
  margin-top: 20px;
  color: black;
}

#sandpackcont {
  margin-top: 36px !important;
}

.backfromsandpack {
  left: 6px;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
}

.cm-gutter.cm-lineNumbers {
  font-size: 1.1em !important;
  background-color: #1f1f1f !important;
}

.sandpackEditor .fXAXjb::after {
  opacity: 0 !important;
}
.sandpackEditor .cjjWdp::after {
  opacity: 0 !important;
}

.fXAXjb {
  border: 0 !important;
  margin: 23px -5px 0 -5px;
}

.fileExplorercustomsandpack
  > div
  > div
  > ul
  > li
  > div
  > span
  > span:last-child:active {
  color: #f55533 !important;
}

.sandpackEditorContainer2 .cjjWdp {
  opacity: 1 !important;
}

.sandpackEditorContainer1 .cjjWdp {
  opacity: 1 !important;
}

.sandpackEditorContainer2 .fXAXjb {
  border: 0 !important;
  margin: 0px -5px 0 -5px;
}

.sandpackEditorContainer1 .fXAXjb {
  border: 0 !important;
  margin: 0px -5px 0 -5px;
}

.verticalresizerforsp {
  height: 60px !important;
}

.sandpackEditor .sp-c-gGbYbQ {
  margin-left: 2px !important;
  background-color: #323131;
}
.sandpackEditor .sp-c-gtcpyq .cm-scroller {
  background-color: #1f1f1f !important;
}

.sandpackEditor .ͼc5q .cm-lineNumbers .cm-gutterElement {
  background-color: #1f1f1f !important;
}

.sandpackEditor .css-heg063-MuiTabs-flexContainer {
  background-color: #181818 !important;
}

.sandpackEditor .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-align: left;
  line-height: 20px;
  letter-spacing: 0em;
  font-size: 14px !important;
  color: #c3c5c8 !important;
  font-weight: 500 !important;
  font-family: Inter !important;
}

.sandpackEditor .css-11yukd5-MuiTabs-indicator {
  background-color: #f55533 !important;
}

.sandpackEditor .fileExplorerpane {
  background-color: rgb(21, 21, 21) !important;
}

.sandpackEditor .ͼ5y8 .cm-gutters {
  padding-left: 0 !important;
}

.sandpackEditor .fileExplorercustomsandpack > div {
  max-width: 1300px !important;
}

.sp-c-ikJbEZ {
  border: none !important;
}

.sandpackEditor .sp-c-bxeRRt.sp-c-kuvqxy.sp-tab-button {
  background: #252525 !important;
  border-right: 2px solid #2b2b2b !important;
}

.sandpackEditor .sp-c-gGbYbQ {
  padding: 0px !important;
}
.sandpackEditor .ant-typography{
  height: 100vh !important;
}


.fileExplorercustomsandpack .loadinfileexp {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sandpackCodeEditorSection{
  position: absolute;
  width: 100vw;
}

.editor-pane{
  position: relative;
}

.sandpackRunSec{
  position: absolute;
  z-index: 2;
  /* left:940px;
  bottom:100px; */
  right: 20px;
  bottom: 20px;
  width: 72px;
  height: 44px;
  padding: 12px 0px 0px 0px;
  border-radius: 52px;
  background: #424242;
  cursor: pointer;
}

.openinNewTab{
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  bottom: 20px;
  right: 20px; */
  position: absolute;
  bottom: -40px;
  right: 20px;
  z-index: 3;
  /* border: 1px solid #f55533;
  background: #f55533; */
  border-radius: 25px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.outputsandpacktab{
  position: relative;
}

.openinnewtab{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    /* border: 1px solid #f2f4f7; */
    /* background: #f2f4f7; */
    /* border-radius: 25px; */
    cursor: pointer;
    width: 30px;
    height: 30px;
    right: 20px;
    top: 12px;
    position: absolute;
}

.helpIcon{
  display: flex;
  position: absolute;
  right: 0px;
  top: 63px;
  z-index: 5;
  height: 41px;;
  padding: 12px;
  gap: 4px;
  border-radius: 12px 0px 0px 12px;
  background: #FCEBE8;
  text-align: center; 
  cursor: pointer;
}

.helpIcon svg {
  transition: stroke 0.3s ease-in-out;
}

.helpIcon svg:hover {
  stroke: #98a2b3;
}

.helpText{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #F96343;

}
.helpIcon .helpText {
  opacity: 0;
  display: none;
  transition: opacity 0.3s ease-in-out, display 0.3s ease-in-out;
}

.helpIcon:hover .helpText {
  opacity: 1;
  display: block;
  transition: opacity 0.3s ease-in-out, display 0.3s ease-in-out;
}

.recommendedwebsitescont{
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 6;
}


#sandpackRunIcon{
  position: relative;
  left:12px;
}

#sandpackRunText{
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  position: relative;
  left: 14px;
  top: 1px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.sandpackConsoleClearBtn{
  cursor: pointer;
  float: right;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  border: none;
  border-radius: 2px;
}