.downloadOptions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  z-index: 1;
  left: 15px;
  width: fit-content;
  padding-right: 5px;
  height: 93px;
  gap: 0px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px #10182814;
  .option {
    // width: Fill (158px) px;
    height: 44px;
    padding: 10px 14px 10px 14px;
    gap: 8px;
    display: flex;
    // margin-top: 10px;
    // margin-left: 14px;
    .svgiconfordownload {
      margin-right: 8px;
    }
    .optiontext {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #101828;
        z-index: 5;
    }
  }
  .option:hover{
    background-color: #f9fafb;
    cursor: pointer;
  }
}