.maindashboardtable{
    margin-left: 3%;
    margin-right: 3%;
    position: absolute;
    top: 9vh;
    width: 94%;

    .jss11{
        background: none;
    }
    .jss25{
        background: none;
    }
}