@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.common-testpage-questions-div {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 10px;

  .common-testpage-submitted-questions-details {
    display: flex;
    background-color: #f9fafb;
    align-items: center;
    border-radius: 10px 10px 0 0;

    .submitted-questions-details-table-firstdiv {
      width: 50%;
      height: 48px;
      text-align: center;
      padding: 14px 0 14px 0;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      color: #344054;
      border: 1px solid #eaecf0;
      border-radius: 10px 0 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .submitted-questions-details-table-seconddiv {
      width: 50%;
      height: 48px;
      text-align: center;
      padding: 14px 0 14px 0;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      color: #344054;
      border: 1px solid #eaecf0;
      border-radius: 0 10px 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .completed-question-details-div {
    display: flex;

    .completed-question-checkbox-div {
      display: flex;
      width: 50%;
      height: 48px;
      padding: 14px 0 14px 24px;
      border: 1px solid #eaecf0;

      // .completed-question-allfilter-checkbox-span{
      //     width: 20px;
      //     height: 20px;
      //     border-radius: 6px;

      .completed-question-allfilter-checkbox {
        width: 20px;
        height: 20px;
        border-radius: 6px;
        border: 1px solid #d0d5dd;
        accent-color: #f55533;
        flex-shrink: 0;
      }
      // }

      .completed-question-allfilter-checkbox-label {
        margin: 0 0 0 12px;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 500;
        color: #344054;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .completed-question-count-div {
      width: 50%;
      height: 48px;
      padding: 14px 0 14px 72px;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      color: #344054;
      border: 1px solid #eaecf0;
    }
  }

  .common-testpage-questions-header-div {
    min-height: 52px;
    background-color: #f9fafb;
    border-radius: 10px 10px 0 0;

    .common-testpage-questions-header-accordian {
      border-radius: 10px 10px 0 0;

      .common-testpage-questions-header-accordian-summary {
        padding: 0;
        min-height: 52px;

        div {
          margin: 0;
          display: flex;
          justify-content: space-between;
          background-color: #f9fafb;
          border-radius: 10px 10px 0 0;

          .common-testpage-questions-header-title {
            height: 24px;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 500;
            color: #101828;
            margin: 14px 0 14px 24px;
          }

          .common-testpage-questions-header-legend {
            height: 36px;
            width: 36px;
            margin: 8px 24px 0 0;
          }
        }
      }

      .common-testpage-questions-header-accordian-summary.Mui-expanded {
        min-height: 52px;
      }

      .common-testpage-questions-header-accordian-details {
        width: 264px;
        margin: 0 42px 0 42px;

        div {
          display: flex;
          align-items: center;
          margin: 0 0 12px 0;

          .common-testpage-questions-header-accordian-icon1 {
            width: 20px;
            height: 20px;
            background-color: #f2f4f7;
            border-radius: 50%;
            margin: 0;
          }
          .common-testpage-questions-header-accordian-icon2 {
            width: 20px;
            height: 20px;
            background-color: #f2f4f7;
            border-radius: 50%;
            border: 1.4px solid #f96343; //brand 500
            margin: 0;
          }
          .common-testpage-questions-header-accordian-icon3 {
            width: 20px;
            height: 20px;
            background: linear-gradient(
              220.31deg,
              #fdc622 15.37%,
              #9b8a36 92.13%
            );
            border-radius: 50%;
            margin: 0;
          }
          .common-testpage-questions-header-accordian-icon4 {
            width: 20px;
            height: 20px;
            background: linear-gradient(
              223.96deg,
              #fb6514 15.05%,
              #95670c 83.82%
            );
            border-radius: 50%;
            margin: 0;
          }
          .common-testpage-questions-header-accordian-icon5 {
            width: 20px;
            height: 20px;
            background: linear-gradient(45deg, #1fa064 0%, #01e078 100%);
            border-radius: 50%;
            margin: 0;
          }

          .common-testpage-questions-header-accordian-detail {
            height: 18px;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 500;
            color: #344054;
            margin: 0 0 0 12px;
          }
        }
      }
    }
  }

  .common-testpage-questions-list-div {
    // width: 264px;
    width: 100%;
    padding: 20px 42px 0 42px;

    .common-testpage-section-name-div {
      .common-testpage-section-title {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 500;
        color: #101828;
      }

      .common-testpage-section-name {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        color: #101828;
        margin: 0 0 0 8px;
      }
    }

    .common-testpage-questions-lists {
      display: flex;
      flex-wrap: wrap;
      max-width: 270px;
      margin: 0 0 10px 0;
      gap: 10px;

      .common-testpage-questions-lists-btn {
        border-radius: 50%;
        width: 43px;
        height: 43px;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
      }
    }
  }

  .mcq-Comments-Score-Section-ScoreGiveZero {
    margin: 20px 0 20px 24px;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    color: #344054;

    .filter-CheckBox-DivBox {
      width: 16px;
      height: 16px;
      margin: 0 8px 0 0;
      border: 1px solid #d0d5dd;
      accent-color: #f55533;
    }
  }

  .score-save-btn {
    float: right;
    margin: 24px 10px 0 0;
  }

  .video-record-div {
    height: calc(100% - 60px);
    position: relative;

    .video-recorder {
      width: 100%;
      padding: 36px 40px;
      transition: filter 0.3s;
    }

    .video-recorder.blurred {
      filter: blur(10px);
    }

    .cam-blur-btn {
      height: 25px;
      width: 25px;
      padding: 0;
      position: absolute;
      top: 40px;
      right: 45px;

      svg {
        height: 25px;
        width: 25px;

        g {
          rect {
            stroke: none;
          }
        }

        rect {
          fill: none;
        }

        g {
          path {
            stroke: #ffffff;
          }
        }
      }
    }

    .studentTestVideoTranscript {
      margin: 20px;
      padding: 10px;
      overflow: auto;
      max-height: 30vh;
      border-radius: 8px;
      border: 1px solid #d0d5dd;
    }

    .interviewMultiFaceDeducted {
      gap: 12px;
      display: flex;
      padding: 16px;
      border-radius: 8px;
      flex-direction: column;
      background: map-get(i.$appBackGroundColor, "warning/100");
      border: 1px solid map-get(i.$appBackGroundColor, "warning/100");
      margin: 0 35px 0 35px;

      .interviewMultiFaceDeductedHeader {
        gap: 13.5px;
        display: flex;
        align-items: center;
        @include m.fontSize(text/sm/semibold);
        color: map-get(i.$appBackGroundColor, "grey/900");
      }

      .interviewMultiFaceDeductedBody {
        @include m.fontSize(text/sm/regular);
        color: map-get(i.$appBackGroundColor, "grey/700");
      }
    }

  }

  .video-record-buttons {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
  }
}
