.adminAssignmentContainer {
    margin: 0;
    padding: 60px 0 20px 0;
}
.adminAssignmentContainerSandpack{
    height: 100vh;
    position: relative;
    overflow-y: hidden;
}

.adminAssignmentTopicSec,
.adminAssignmentNameSec,
.adminAssignmentInstructionSec,
.adminAssignmentEmbeddedLinkSec,
.adminAssignmentMaxtimeSec,
.adminAssignmentMarkSec {
    display: flex;
    flex-direction: column;
}

.adminAssignementTopicLabel,
.adminAssignmentNameLabel,
.adminAssignmentInstructionLabel,
.adminAssignmentEmbeddedLinkLabel,
.adminAssignmentMaxtimeLabel,
.adminAssignmentMarkLabel {
    font-family: "Inter";
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    color: #202124;
    text-align: left;
    margin: 25px 0 0 20px;
}

.adminAssignmentTopicSec .css-1nmdiq5-menu {
    width: 350px;
}

.adminAssignmentTopicSec {
    z-index: 5;
    position: relative;
}

.adminAssignmentName,
.adminAssignmentEmbeddedLink,
.adminAssignmentMaxtime,
.adminAssignmentMark {
    margin: 10px 0 0 20px;
    background: #fff;
    border: 1px solid #bdc1c6;
    border-radius: 4px;
    height: 34px;
    outline: none;
    padding: 7px 0 7px 10px;
}

.adminAssignmentName,
.adminAssignmentInstructionSec div {
    width: 836px
}

.adminAssignmentInstructionSec div {
    margin: 10px 0 0 20px;
}

.adminAssignmentEmbeddedLinkInputSec,
.adminAssignmentSandpackContainer,
.adminAssignmentBackBtn {
    text-align: left;
}

.adminAssignmentEmbeddedLink {
    height: 32px;
    outline: none;
    padding: 7px 0 7px 10px;
    width: 500px;
}

.adminAssignmentEmbeddedLinkViewSec {
    width: 862px;
    height: auto;
    margin: 25px 0 0 23px;
    text-align: left;
}

.adminAssignmentEmbeddedLinkList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.adminAssignmentEmbeddedLinkList li {
    width: 500px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
    display: inline-block;
    overflow: hidden;
    word-wrap: break-word;
}

#adminAssignmentEmbeddedLinkItemTrashIcon,
.adminAssignmentOpenSanpackBtn {
    margin-left: 22px;
}

#adminAssignmentEmbeddedLinkAddBtn {
    background: #1475cf;
    border-radius: 50%;
    color: #fff;
    margin: 0 0 0 15px;
    cursor: pointer;
}

.adminAssignmentMark,
.adminAssignmentMaxtime {
    width: 150px
}

.adminAssignmentSandpackSec label {
    margin: 10px 0 0 10px;
}

.adminAssignmentSandpackSec label span:nth-child(2) {
    font-family: "Inter";
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    color: #202124;
    text-align: left;
}

.adminAssignmentRefFileContainer {
    display: flex;
    margin: 20px;
}

.adminAssignmentRefFileInputLabel {
    font-size: 14px;
    color: rgb(20, 117, 207);
    font-family: "Inter";
    cursor: pointer;
}

.adminAssignmentRefFileSec,
.adminAssignmentRefFileSec2 {
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.adminAssignmentRefFileSec2 {
    left: 100px;
}

.adminAssignmentRefFileInput,
.adminAssignmentRefFileInput2 {
    width: 200px;
    position: absolute;
    top: 0px;
    opacity: 0;
    z-index: 2;
}

// .adminAssignmentRefFileInput {
//     left: 100px;
// }

.adminAssignmentRefFileInputCaption {
    font-family: "Inter";
    font-size: 12px;
    height: 20px;
    width: 404px;
    margin: 10px 0 0 40px;
}

.adminAssignmentBtnSec {
    text-align: left;
}

.adminAssignmentSaveBtn {
    border: none;
    border-radius: 4px;
    color: #fff;
    height: 34px;
    width: 120px;
    background: #f55533;
    margin: 40px 0 0 20px;
}
.adminAssignmentUpdateBtn{
    border: none;
    border-radius: 4px;
    color: #fff;
    height: 34px;
    width: 100px;
    background: #f55533;
    margin: 40px 0 0 20px;

}



.adminAssignmentFileLists>li {
    list-style: none;
    margin: 1.2em 0;
    background-color: #f55e3038;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 1.2em 1.5em;
}

.adminAssignmentFileLists>li>svg,
.adminAssignmentFiletrashIcon {
    color: #f55321;
}

.adminAssignmentFileLists>li>svg:first-child {
    font-size: 1.2em;
    margin-right: 0.8em;
}

.adminAssignmentFileListName {
    flex: 1;
    font-size: 0.9em;
    margin: 0;
    text-decoration: none;
    color: #000000;
    font-size: 14px;
    font-family: "Inter";
    cursor: pointer;
}

.adminAssignmentFileListName:hover {
    color: #0d6efd;
    text-decoration: underline;
}

.adminAssignmentFileCheckisDownload {
    margin: 0 0 0 20px;
}

.adminAssignmentInfoIconforDownload {
    font-size: 14px;
    color: #0d6efd;
    cursor: pointer;
}

.adminAssignmentUploadFileIcon {
    margin: 0 20px 0 0;
    color: #1475cf;
}