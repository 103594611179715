@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.interviewMainContainer {
  margin: 0px 80px;
  padding-bottom: 39px;
  height: calc(100vh - 52px);

  .interviewTestName {
    margin-top: 12px;
    @include m.fontSize(display/xs/medium);
    color: map-get(i.$appColor, "grey/700");
  }

  .interviewInnerMainContainer {
    gap: 45px;
    display: flex;
    margin-top: 20px;
    border-radius: 8px;
    justify-content: space-between;
    box-shadow: 0px 4px 6px -2px #ff553308;
    box-shadow: 0px 12px 16px -4px #ff553314;
    box-shadow: 0px 4px 0.4px 0px #ffffff40 inset;

    .interviewBody {
      width: 100%;
      display: flex;
      overflow-y: auto;
      padding-bottom: 24px;
      scrollbar-width: none;
      flex-direction: column;
      scroll-behavior: smooth;
      height: calc(100vh - 185px);
      border-radius: 8px 8px 16px 16px;
      background: map-get(i.$appColor, "grey/100");

      .interviewBodyHeader {
        display: flex;
        padding: 13px 15px;
        align-items: center;
        background: #dee4ee;
        border-radius: 8px 8px 0 0;
        justify-content: space-between;

        .interviewBodyHeaderQuestionNo,
        .interviewBodyHeaderCurrentQnTime,
        .interviewBodyHeaderCurrentQnTimeCounter {
          @include m.fontSize(text/sm/medium);
          color: map-get(i.$appColor, "grey/900");
        }

        .interviewBodyHeaderCurrentQnTime {
          color: map-get(i.$appColor, "grey/500");
        }

        .interviewBodyHeaderCurrentQnTimeCounter {
          color: map-get(i.$appColor, "grey/700");
        }
      }

      .interviewBodyMain {
        padding: 24px;

        .interviewBodyMainAnswerDiv,
        .interviewBodyMainFeedbackDiv,
        .interviewBodyMainQuestionDiv {
          gap: 24px;
          float: left;
          display: flex;
          max-width: 90%;
          width: fit-content;
          border-radius: 8px;
          padding: 20px 16px;
          background: map-get(i.$appColor, "white");
          border: 1px solid map-get(i.$appColor, "grey/100");

          .interviewBodyMainAnswer,
          .interviewBodyMainFeedback,
          .interviewBodyMainQuestion {
            display: flex;
            align-items: center;
            @include m.fontSize(text/lg/medium);
            color: map-get(i.$appColor, "grey/700");
          }

          .interviewBodyMainLogoDiv {
            min-width: 72px;
            min-height: 72px;
            display: flex;
            align-items: center;
            justify-content: center;

            .interviewBodyMainLogo.pulse {
              padding: 5px;
              border-radius: 50%;
              animation: pulse 1.5s infinite;
            }
          }
        }

        .interviewBodyMainAnswerDiv {
          float: right;
          margin: 16px 0;
          background: map-get(i.$appColor, "grey/50");

          .interviewBodyMainUserImg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
        }
      }

      .interviewBodyMain > div:nth-child(odd) {
        width: 100%;
        justify-content: flex-start;
      }

      .interviewBodyMain > div:nth-child(even) {
        justify-content: flex-end;
      }

      .interviewBodyFooter {
        width: 100%;
        display: flex;
        padding-right: 24px;
        justify-content: flex-end;
      }
    }

    .interviewRightDiv {
      gap: 24px;
      width: 347px;
      display: flex;
      flex-direction: column;
      background: map-get(i.$appColor, "white");

      .interviewCam {
        width: 347px;
        border-radius: 12px;
        border: 1px solid map-get(i.$appColor, "grey/300");
      }

      .interviewCam.warn {
        border: 4px solid map-get(i.$appColor, "brand/700");
      }

      .interviewMultiFaceDeducted {
        gap: 12px;
        display: flex;
        padding: 16px;
        border-radius: 8px;
        flex-direction: column;
        background: map-get(i.$appColor, "warning/100");
        border: solid 1px map-get(i.$appColor, "warning/100");

        .interviewMultiFaceDeductedHeader {
          gap: 13.5px;
          display: flex;
          align-items: center;
          @include m.fontSize(text/sm/semibold);
          color: map-get(i.$appColor, "grey/900");
        }

        .interviewMultiFaceDeductedBody {
          @include m.fontSize(text/sm/regular);
          color: map-get(i.$appColor, "grey/700");
        }
      }
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0px #ffffff;
    }
    50% {
      transform: scale(1.2);
      box-shadow: 0 0 0 20px #ffede9;
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 20px #ffd9d2;
    }
  }
}
