@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.inputDropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: fit-content;
  .inputDropdownInputContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    .inputDropdownLabel {
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/700");
      margin: 0;
    }
    .inputDropdownHintText {
      @include m.fontSize(text/sm/regular);
      color: map-get(i.$appColor, "grey/500");
    }
    .inputDropdownInputSec {
      height: 44px;
      padding: 10px 14px 10px 14px;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid map-get(i.$appColor, "grey/300");
      background: #ffffff;
      box-shadow: 0px 1px 2px 0px #1018280d;
      cursor: pointer;
      .inputDropdownInputWrapper {
        width: 90%;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        .inputDropdownInput {
          border: none;
          outline: none;
          width: 100%;
          @include m.fontSize(text/md/medium);
          color: map-get(i.$appColor, "grey/900");
        }
        .inputDropdownInput::placeholder {
          @include m.fontSize(text/md/regular);
          color: map-get(i.$appColor, "grey/500");
        }
        .inputDropdownSupportText {
          @include m.fontSize(text/md/regular);
          color: map-get(i.$appColor, "grey/500");
        }
      }
      .inputDropdownIcon {
        width: 10%;
      }
    }
    .inputDropdownInputSec.focused {
      border: 1px solid map-get(i.$appColor, "brand/300");
      background: #ffffff;
      box-shadow: 0px 0px 0px 4px #f4ebff;
      // box-shadow: 0px 1px 2px 0px #1018280d;
    }
    .inputDropdownInputSec.disabled {
      cursor: not-allowed;

      svg {
        cursor: not-allowed;
        path {
          stroke: map-get(i.$appColor, "grey/300");
        }
      }

      .inputDropdownInput {
        background: unset;
      }
    }
  }
  .inputDropdownMenuContainer {
    position: absolute;
    z-index: 10;
    background: #ffffff;
    top: 52px;
    width: 320px;
    min-height: fit-content;
    max-height: 320px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid map-get(i.$appColor, "grey/100");
    .inputDropdownMenuList {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 44px;
        padding: 10px 14px 10px 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .inputDropdownMenuItemContent {
          width: 90%;
          display: flex;
          gap: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          .inputDropdownMenuItemLabel {
            margin: 0;
            padding: 0;
            @include m.fontSize(text/md/medium);
            color: map-get(i.$appColor, "grey/900");
          }
          .inputDropdownMenuItemSupportText {
            @include m.fontSize(text/md/regular);
            color: map-get(i.$appColor, "grey/500");
          }
        }
        .inputDropdownMenuItemIcon {
          width: 10%;
        }
      }
      .inputDropdownMenuItem:hover,
      .inputDropdownMenuItem.selected,
      .inputDropdownMenuItem:focus,
      .inputDropdownMenuItem.focusedItem {
        background: map-get(i.$appBackGroundColor, "grey/50");
      }
    }

    .inputDropdownNoData {
      text-align: center;
      padding: 0;
      margin: 0;
      height: 44px;
      padding: 10px 14px 10px 14px;
      @include m.fontSize(text/md/medium);
      color: map-get(i.$appColor, "grey/900");
    }
  }
}

.inputDropdownContainer.destructive {
  .inputDropdownHintText {
    color: map-get(i.$appColor, "error/500");
  }
  .inputDropdownInputSec {
    border-color: map-get(i.$appColor, "error/300");
  }
  .inputDropdownInputSec.focused {
    box-shadow: 0px 0px 0px 4px #fee4e2;
  }
}
