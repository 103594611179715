.container-top {
    width: 68%;
    padding: 5px;
    align-content: stretch;

    /* display: flex; */
    /* flex-direction: column-reverse; */
}

.container-top-child {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    align-content: flex-start;
    justify-content: space-between;
}

.test-duration-h1 {
    margin-right: 5%;
    text-align: right;
}

.single-question-top {
    width: 33px;
    height: 33px;
    margin-right: 15px;
    border: 1px solid black;
    background-color: orange;
}

.multiple-question-top {
    width: 35px;
    height: 35px;
    border: none;
    cursor: pointer;
    margin-right: 15px;
    display: inline-block;
}

.bar1-div,
.bar2-div,
.bar3-div {
    width: 30px;
    height: 3px;
    margin: 8px 0;
    transition: 0.4s;
    background-color: #333;
}

/* .duration-span{
    right: 33px;
    color: white;
    font-size: 24px;
    padding: 5px 10px;
    position: relative;
    border-radius: 25px;
    background-color: rgb(56, 25, 233);
} */

#score-mock {
    color: blue;
    font-size: 23px;
}

/* .test-container {
    height: 100%;
    display: flex;
    border-radius: 5px;
} */

.test-container {
    width: 100%;
    height: 93vh;
    display: flex;
    border-radius: 5px;
}

/* .test-container-child-left {
    flex: 1 1;
    width: 70%;
    z-index: 1;
    height: 95vh;
    display: inline-block;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
} */


.test-container-child-left {
    z-index: 1;
    width: 70vw;
    height: 93vh;
    display: flex;
    position: relative;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);

    /* top: 6vh; */
    /* height: 300px; */
    /* margin-right: 1%; */
    /* display: inline-block; */
}

.test-question {
    height: 100%;
}

#question-title {
    font-size: 18px;
}

.singleview-question-box-details-options-div-each-option-admin {
    z-index: 3;
    display: flex;
    min-width: 57vw;
    margin-bottom: 5px;
    padding: 10px 15px;
    position: relative;
    flex-direction: row;
    justify-content: left;
    border-width: 2px 2px;
    align-items: flex-start;
    background-color: white;

    /* margin-right: 5%; */
    /* border-radius: 5px; */
    /* border-style:solid; */
    /* border-color: rgb(178, 177, 177); */
}

.singleview-question-box-details-options-div-each-option {
    z-index: 3;
    display: flex;
    min-width: 57vw;
    position: relative;
    padding: 10px 15px;
    margin-bottom: 5px;
    margin-bottom: 2px;
    border-width: 2px 2px;
    justify-content: left;
    align-items: flex-start;
    background-color: white;
    border-color: rgb(178, 177, 177);

    /* margin-right: 5%; */
    /* border-radius: 5px; */
    /* border-style:solid; */
}

.singleview-question-box-details-options-div-each-option:hover {
    padding: 10px 15px;
    margin-bottom: 5px;
    border-width: 2px 2px;
    justify-content: left;
    background-color: white;
    border-color: rgb(178, 177, 177);
    box-shadow: 0px 0px 8px 1px rgba(0, 119, 0, 0.2);

    /* margin-right: 5%; */
    /* border-radius: 5px; */
    /* border-style:solid; */
}



input[type='radio'] {
    width: 20px;
    height: 20px;
    margin-top: 0.5vh;
    accent-color: #0671E0;
    vertical-align: middle;
    box-shadow: 2px solid red !important;

    /* accent-color: green; */
}

.options-text-checkbox {
    top: -4px;
    position: relative;

    /* font-size: 15px;
    vertical-align: middle;
    margin-left: 5px; */
}

.test-container-child-right {
    width: 30%;
    position: relative;
}

.question-status {
    margin: 5px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    text-align: center;
}

#answer-status-id {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 7px 2px 20px 10px;
}

.bottom-container-for-buttons {
    width: 90%;
    padding: 5px;
    display: flex;
    justify-content: center;
}

.bottom-container-for-buttons-left-for-traversal {
    display: flex;
    margin-right: 1%;
    padding-right: 5%;
    text-align: right;
    justify-content: flex-end;

    /* gap: 10%; */
}

#answered-id {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
}

#skip-btn {
    border: none;
    width: 150px;
    color: white;
    margin-left: 3%;
    padding: 5px 25px;
    border-radius: 20px;
    background-color: orange;
}

#previous-btn {
    margin-left: 3%;
    font-size: 16px;
    color: #0671E0;
    font-weight: 700;
    line-height: 19px;
    font-style: normal;
    text-align: center;
    border-radius: 5px;
    padding: 10px 10px;
    font-family: 'Inter';
    border-color: #0671E0;
    background-color: white;
    border: 2px solid #0671E0;

    /* width: 89px; */
    /* height: 35px; */
    /* display: flex; */
    /* align-items: center; */
}

#resubmit-btn {
    border: none;
    width: 150px;
    color: white;
    margin-left: 3%;
    padding: 5px 25px;
    border-radius: 20px;
    background-color: orange;
}

#postcomment-btn {
    border: none;
    width: 150px;
    color: white;
    margin-left: 3%;
    padding: 5px 25px;
    border-radius: 20px;
    background-color: rgb(7, 183, 7);
}

#next-btn {
    color: white;
    margin-left: 3%;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    font-style: normal;
    border-radius: 5px;
    padding: 10px 10px;
    text-align: center;
    font-family: 'Inter';
    border-color: #0671E0;
    border: 2px solid #0671E0;
    background-color: #0671E0;

    /* width: 89px; */
    /* display: flex; */
    /* height: 35px; */
    /* align-items: center; */
}

#finish-btn {
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin: 4px 12px;
    text-align: center;
    border-radius: 8px;
    text-decoration: none;
    display: inline-block;
    background-color: #F55533;

    /* padding: 20px; */
}

.test-container-multiple {
    width: 100%;
    height: 93vh;
    display: flex;
}

/* .test-container-multiple-left {
    z-index: 1;
    width: 70%;
    height: 95vh;
    display: inline-block;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
} */

.test-container-multiple-left {
    flex: 1 1;
    z-index: 1;
    width: 70vh;
    height: 93vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
}

.test-question {
    overflow: hidden;
}

.multiview-leftcontainer-question-area {
    width: 100%;
    height: 86vh;
    display: flex;
    flex-direction: column;

    /* overflow-y: scroll; */
}

.multiview-leftcontainer-question-area-div {
    height: 87vh;
    display: flex;
    overflow: scroll;
    flex-direction: column;
}

.singleview-question-area-main {
    height: 87vh;
    overflow-x: hidden;
    overflow-y: scroll;

    /* height: 88vh;
    overflow: scroll;
    width: 95%; */
    /* width: 95%;*/
    /* overflow: scroll; */
}

.singleview-question-area {
    width: 95%;
}

.test-container-multiple-right {
    float: right;
    width: 30vw;
    position: relative;
    height: 93dvh;
    display: flex;
    flex-direction: column;
}

.test-column-multiple {
    border-radius: 10px;
    background-color: white;
}

.div-skip-multiple {
    text-align: right;
    margin-right: 20px;
}

#skip-btn-multiple {
    border: none;
    color: white;
    padding: 5px 25px;
    font-size: 1.1rem;
    border-radius: 20px;
    background-color: rgb(4, 210, 134);
}

.question-status-multiple {
    margin: 5px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    text-align: center;
    text-decoration: none;
}

.test-finish-div-multiple {
    bottom: 0;
    position: absolute;
    text-align: center;
}

#finish-btn-multiple {
    border: none;
    color: white;
    margin-left: 3%;
    padding: 5px 25px;
    font-size: 1.1rem;
    border-radius: 20px;
    background-color: rgb(226, 42, 42);
}

.section-name {
    color: rgb(226, 42, 42)
}

.test-container-child-right-question-list {
    margin: 5px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    text-align: center;
    text-decoration: none;
}

.test-container-child-right-section-name {
    font-size: 15px;
    text-align: left;
    font-weight: bold;
    margin: 7px 2px 10px 10px;
}

.test-container-child-right-section-div {
    padding: 5%;
    margin: 5px 2px 20px 5px;
}

textarea {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    /* width: 100%; */
}

.footer-for-buttons {
    width: 100%;
    bottom: 25px;
    position: fixed;

    /* justify-content: center; */
}

.single-question-container {
    width: 100%;
    height: 93vh;
    display: flex;
    text-align: left;
    position: absolute;
    height: -moz-fit-content;

    /* top: 7vh; */
    /* height: fit-content; */
}

.section-title {
    font-size: 25px;
    margin-bottom: 15px;
}


.student-multiple-question-container {
    width: 100%;
    height: 93vh;
    display: flex;
    position: relative;

    /* top: 7vh; */
}

.admin-multiple-question-container {
    top: 7vh;
    width: 100%;
    height: 93vh;
    display: flex;
    position: relative;
}

.filter-checkbox-div {
    float: left;
}

.filter-checkbox-div-box {
    top: 3px;
    width: 20px;
    height: 20px;
    position: relative;
}

.textarea-answer {
    width: 98%;
    width: 95%;
    resize: none;
    display: block;
    font-size: 15px;
    font-weight: 400;
    padding-top: 10px;
    font-style: normal;
    padding-left: 10px;
    margin-bottom: 40px;
    padding-right: 10px;
    margin-bottom: 25px;
    border-radius: 10px;
    font-family: 'Inter';
    padding-bottom: 30px;
    box-sizing: border-box;
    border: 1px solid rgba(55, 55, 57, 0.2);
}

.comments-entrybox {
    width: 100%;
    width: 100%;
    resize: none;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
}

.saq-laq-marks-entry {
    width: 40px;
    text-align: right;

    /* border: 3px; */
    /* border-color: black; */
}


#update-scores-btn {
    border: none;
    width: 150px;
    color: white;
    margin-left: 3%;
    padding: 5px 25px;
    border-radius: 20px;
    background-color: rgb(226, 42, 42);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.show-saq-laq-answer-div {
    display: flex;
}

.comments-previous-display-eachcomment {
    margin: 20px auto;
    position: relative;
}

.test-container-child-right-status-timer {
    gap: 3%;
    height: 6vh;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    background: #F2F4F7;
    justify-content: center;
    align-content: space-around;

    /* padding-left: 25%; */
}

.admin-marks {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    font-family: 'Inter';
    color: rgba(55, 55, 57, 0.5);

    /* identical to box height, or 150% */
}

.admin-marks-input {
    display: flex;
    min-width: 25vh;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    flex-wrap: nowrap;
    position: relative;
    font-style: normal;
    font-family: 'Inter';
    align-items: flex-start;
    justify-content: space-around;
    color: rgba(55, 55, 57, 0.5);

    /* left: 80%; */
    /* display: flex; */
}

.admin-marks-input input {
    width: 67px;
    height: 24px;
    border-radius: 3px;
    background: #F2F4F7;
    border: 1px solid #373739;

    /* left: 768px; */
    /* top: 197px; */
    /* position: absolute; */
}

.test-container-child-left-header {
    width: 100%;
    height: 6vh;
    display: flex;
    padding-left: 5vh;
    padding-right: 2%;
    position: relative;
    align-items: center;
    background: #F2F4F7;
    justify-content: space-between;
}

.singleview-question-box-title-qnno {
    font-size: 16px;
    font-weight: 700;
    color: #373739;
    line-height: 19px;
    font-style: normal;
    font-family: 'Inter';
}

.test-container-child-left-header-section-title-label {
    font-size: 16px;
    font-weight: 700;
    color: #373739;
    line-height: 19px;
    font-style: normal;
    font-family: 'Inter';
}

.test-container-child-left-header-section-title-value {
    font-size: 16px;
    font-weight: 400;
    color: #373739;
    line-height: 19px;
    font-style: normal;
    font-family: 'Inter';
}

.test-container-child-left-header-section-viewstyle {
    gap: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    /* left: 250px; */
    /* position: relative; */
}

.singleview-question-box-title-marks {
    width: 11.5vw;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    position: relative;
    font-family: 'Inter';
    color: rgba(55, 55, 57, 0.5);
}

.singleview-question-box-title-review-later {
    cursor: pointer;
}

.singleview-question-box-details-name {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    font-style: normal;
    font-family: 'Inter';

    /* margin-left: 7.5%; */
}

.options-text-radio {
    top: 1px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    text-align: left;
    line-height: 24px;
    position: relative;
    font-style: normal;
    margin-left: 0.6vw;
    margin-bottom: 0.2vw;
    font-family: 'Inter';
}

.singleview-question-box-header-div {
    width: 70vw;
    display: flex;

    /* height: 87vh; */
    /* overflow: scroll; */
}

.singleview-question-box-header-div hr {
    position: relative;
}

.singleview-question-box-title-roller {
    top: -15px;
    position: relative;
}

.singleview-question-box-details {
    display: flex;
    min-width: 85%;
    margin-left: 4vw;
    margin-bottom: 1vh;
    flex-direction: column;
    align-items: flex-start;

    /* margin-top: 2vh; */
}

.time-left-label {
    font-size: 16px;
    font-weight: 400;
    color: #373739;
    line-height: 19px;
    font-style: normal;
    font-family: 'Inter';
}

.duration-span-min {
    padding: 11%;
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    line-height: 15px;
    font-style: normal;
    text-align: center;
    border-radius: 30%;
    font-family: 'Inter';
    background: rgba(255, 255, 255, 0.5);
}

.duration-span-sec {
    padding: 11%;
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    line-height: 15px;
    font-style: normal;
    border-radius: 30%;
    text-align: center;
    font-family: 'Inter';
    min-width: fit-content;
    background: rgba(255, 255, 255, 0.5);
}

.test-container-child-right-section-div-legandinfo-singleview {
    top: 5%;
    right: 20px;
    text-align: right;
    position: relative;

    /* right: 87%; */
}

.test-container-child-right-section-div-legandinfo-listview {
    top: 5%;
    right: 20px;
    text-align: right;
    position: relative;

    /* right: -87%; */
}

.multiview-question-box-details-name {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-left: 7.5%;
    line-height: 24px;
    font-style: normal;
    font-family: 'Inter';
}

.multiview-question-box-details-options-div {
    display: flex;
    min-width: 85%;
    margin-top: 2vh;
    /* margin-left: 4vw; */
    margin-bottom: 1vh;
    flex-direction: column;
    align-items: flex-start;
}

.test-container-child-left-header-back-block {
    /* display: unset; */
    display: flex;
    max-height: 5vh;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    color: #0671E0;
    line-height: 24px;
    font-style: normal;
    align-items: center;
    font-family: 'Inter';
    align-content: center;
    /* display: -webkit-inline-box; */

    /* gap: 20%; */
    /* flex-wrap: nowrap; */
    /* align-items: center; */
    /* flex-direction: row; */
    /* justify-content: center; */
}

.Summary-with-filters-label {
    height: 6vh;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: #373739;
    margin-bottom: 8%;
    line-height: 24px;
    font-style: normal;
    font-family: 'Inter';
    border-bottom: 1px solid #3737390f;
}

.Summary-with-filters-label-heading {
    top: 30%;
    left: 9%;
    position: relative;
}

.test-details-inner-info {
    width: 300px;
    padding-left: 25px;
    margin-bottom: 10px;
    display: inline-block;
}

.test-details-inner-label {
    margin: 0px;
    float: left;
    padding: 0px;
    min-width: 170px;
    text-align: -webkit-left;
}

.question-filters-div {
    width: 300px;
    padding-left: 15%;
    margin-bottom: 10px;
    display: inline-block;
}

.question-report-filters-div {
    width: 350px;
    margin-bottom: 5px;
    padding-left: 14.5%;
    display: inline-block;
}

.question-report-filters-div-label {
    margin: 0px;
    float: left;
    padding: 0px;
    min-width: 61%;
    font-size: 16px;
    font-weight: 700;
    color: #373739;
    padding-top: 0.5%;
    line-height: 19px;
    font-style: normal;
    font-family: 'Inter';
    text-align: -webkit-left;
}

.question-report-filters-div-value {
    color: #373739;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    font-family: 'Inter';
}

.Summary-with-filters-total-score-select {
    width: 300px;
    padding-left: 10%;
    margin-bottom: 5px;
    display: inline-block;
}

.question-filters-div-label {
    margin: 0px;
    float: left;
    padding: 0px;
    padding-top: 1%;
    font-size: 16px;
    font-weight: 700;
    color: #373739;
    min-width: 65.5%;
    line-height: 19px;
    font-style: normal;
    font-family: 'Inter';
    text-align: -webkit-left;
}

.single-question-container-description {
    margin-left: 7.5%;
}

.test-container-child-left-header-back-and-select {
    gap: 15%;
    display: flex;
    align-items: center;
}

.Summary-with-filters-total-score {
    padding-left: 5%;
    text-align: left;
    padding-right: 40%;
}

.question-report-stats-filters-div {
    max-width: 100%;
    padding-left: 1%;
    padding-right: 40%;
}

#mouse-over-popover .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    opacity: 1;
    top: 202px;
    width: 40vw;
    left: 2132px;
    padding: 10px;
    transform: none;
    position: relative;
    transform-origin: 235.5px 0px;
    transition: opacity 324ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 216ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.test-container-child-right-section-div-parent {
    position: sticky;
}

.des-inputwrapper {
    position: relative;
}

.des-inputwrapper::after {
    right: 6%;
    bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #9C9DA0;
    line-height: 10px;
    font-style: normal;
    position: absolute;
    font-family: 'Inter';
    transform: translateY(-50%);
    content: attr(data-required);
}


.mcq-header-section-scoring-section {
    gap: 40px;
    z-index: 10;
    float: right;
    display: flex;
    margin-right: 20px;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.mcq-header-section {
    z-index: 6;
    width: 100%;
    height: 7vh;
    /* display: none; */
    position: absolute;
    border-radius: 0px;
    align-items: center;
    flex-direction: row;
    align-items: center;
    background: #0D0D3D;
    justify-content: space-between;
    justify-content: space-between;
}


.mcq-header-section-logo-section {
    gap: 5px;
    height: 34px;
    width: 175px;
    display: flex;
    margin-left: 34px;
    align-items: center;

    /* height: 3vh;
        gap: 5px;
        display: flex;
        margin-left: 34px;
        align-items: center; */
}

.comments-score-section-score-reject-section {
    height: 3vh;
}


.mcq-header-section-scoring-section-admin-score {
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 19px;
    font-style: normal;
    text-align: center;
    font-family: 'Inter';

    /* font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF; */
}

.mcq-score-section-score-reject-section {
    z-index: 4;
}


.mcq-comments-score-section-score-reject-button-inactive {
    width: 96px;
    height: 39px;
    font-size: 16px;
    color: #0D0D3D;
    font-weight: 700;
    line-height: 19px;
    border-radius: 5%;
    border-width: 2px;
    text-align: center;
    font-style: normal;
    font-family: 'Inter';
    background: #F55533;
    border-color: #F55533;
}

.mcq-comments-score-section-score-reject-button-active {
    width: 96px;
    height: 39px;
    font-size: 16px;
    font-weight: 700;
    color: #F55533;
    line-height: 19px;
    border-radius: 5%;
    border-width: 2px;
    font-style: normal;
    text-align: center;
    font-family: 'Inter';
    background: #0D0D3D;
    border-color: #F55533;
}


.mcq-comments-score-section-score-reject-button-inactive:hover {
    width: 96px;
    height: 39px;
    font-size: 16px;
    font-weight: 700;
    color: #F55533;
    border-width: 2px;
    line-height: 19px;
    border-radius: 5%;
    font-style: normal;
    text-align: center;
    font-family: 'Inter';
    background: #0D0D3D;
    border-color: #F55533;
}

.mcq-comments-score-section-score-submit-button-active {
    width: 96px;
    height: 39px;
    font-size: 16px;
    margin: 5px 30px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 19px;
    border-radius: 5px;
    font-style: normal;
    text-align: center;
    font-family: 'Inter';
    background: #7e7d7d;
    border: 1px solid #7e7d7d;
    padding: 7px 20px !important;
}

#report-learner-select {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 150%;
    font-style: normal;
    font-family: 'Inter';
    letter-spacing: 0.015em;
}

.question-links {
    margin-left: 7.5%;
}

.evaluation-learner-change-mcq-des {
    top: 0.1vh;
    height: 6vh;
    bottom: 5px;
    display: flex;
    position: relative;
    align-items: center;
}

.codeEditorLanguageSelector {
    height: 40px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    color: #373739;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    font-style: normal;
    background: #FAFAFA;
    margin: 8px 0 8px 50px;
    border: none !important;
    letter-spacing: 0.0044em;
    font-family: 'Inter' !important;
}

.mcq-header-section-scoring-section-admin-proctoring-button {
    bottom: 3px;
    position: relative;
}

.test-container-multiple-right-header {
    height: 56px;
    color: #373739;
    font-style: normal;
    font-family: 'Inter';
    border-bottom: 1px solid #3737390f;
}

.outside-container-testpage {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    /* padding: 20px; */
    /* margin: 5% 3% 1% 3%; */
    /* border-radius: 10px; */
    /* border: 1px solid rgb(224, 222, 222); */
}

.test-container-child-left-header-section-viewstyle-card {
    cursor: pointer;
}

.test-container-child-left-header-section-viewstyle-list {
    cursor: pointer;
}

.mcq-comments-score-section-score-submit-button-inactive {
    width: 96px;
    height: 39px;
    font-size: 16px;
    margin: 5px 20px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 19px;
    border-radius: 5px;
    font-style: normal;
    text-align: center;
    font-family: 'Inter';
    background: #F55533;
    border: 1px solid #F55533;
    padding: 7px 20px !important;
}

.mcq-comments-score-section-score-submit-button-inactive:hover {
    width: 96px;
    height: 39px;
    margin: 5px 25px;
    font-size: 16px;
    font-weight: 700;
    color: #F55533;
    line-height: 19px;
    border-radius: 5px;
    font-style: normal;
    text-align: center;
    font-family: 'Inter';
    background: #FFFFFF;
    border: 1px solid #F55533;
    padding: 7px 20px !important;
}

.multiview-leftcontainer-question-area-div::-webkit-scrollbar {
    visibility: hidden;

    /* z-index: 6; */
    /* display:none; */
    /* position: relative; */
}

.multiview-question-box {
    display: flex;
    padding-right: 10px;
    flex-direction: column;
}

.mcq-fullcontainer {
    /* top: 7vh;
    width: 100%;
    height: 93vh;
    display: flex;
    overflow: hidden;
    position: relative; */

    top: 7vh;
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    overflow-x: hidden;
}

.admin-mcq-fullcontainer {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: relative;

    /* top: 7vh; */
}

.singleview-question-box {
    display: flex;
    padding-right: 10px;
    flex-direction: column;
}

.multi-question-container-description {
    width: 85%;
    flex-grow: 0;
    display: flex;
    flex-shrink: 0;
    margin-left: 7.5%;
    text-align: left;
    text-align: justify;
    flex-direction: column;
}

.admin-marks-input-div {
    display: flex;
    width: 65.8vw;
    justify-content: flex-end;
}

.admin-marks-input-div-items {
    gap: 10px;
    display: flex;
    max-width: 37vh;
    margin-right: 12vh;
    flex-direction: row-reverse;

    /* justify-content: flex-start; */
}

.mcq-comments-score-section-score-give-zero {
    width: 350px;
    margin-top: 30px;
    padding-left: 10%;
    margin-bottom: 10px;
}

.correction-easy-medium-hard-section {
    gap: 30px;
    width: 350px;
    display: flex;
    padding-left: 10%;
    margin-bottom: 30px;
}

.mcqUtilCommentsCount {
    position: relative;
    right: 8px !important;
    bottom: 9px !important;
}

.proctoring-cam-show-div {
    display: none;
}

.textarea-answer-div {
    margin-top: 1vh;
}

.test-container-child-left-test-question {
    width: 100%;
    height: 94vh;
}

.evaluation-learner-change-select {
    top: 0.7vh;
    position: relative;
}

.multiview-leftcontainer-question-area-loop {
    display: flex;
    flex-direction: column;

    /* width: 95%; */
    /* height: 80vh; */
    /* height: 78vh; */
}

.multiview-leftcontainer-question-area-loop-question-block {
    display: flex;
    flex-direction: column;
}

.test-container-child-left-header-section-title2 {
    display: flex;
    margin-left: 5%;
    margin-top: 2vh;
    margin-bottom: 3vh;
    align-items: center;
    justify-content: flex-start;
}

.singleview-question-box-title {
    gap: 0.5vw;
    width: 65vw;
    display: flex;
    margin-top: 2%;
    font-size: 16px;
    margin-left: 5%;
    color: #373739;
    font-weight: 700;
    line-height: 19px;
    font-style: normal;
    font-family: 'Inter';
    align-items: baseline;
}

.singleview-question-box-title-qnname {
    width: 120%;
    font-size: 16px;
    font-weight: 700;
    color: #373739;
    line-height: 19px;
    font-style: normal;
    text-align: justify;
    font-family: 'Inter';
}

.singleview-question-box-title-qnmarks {
    width: 13vw;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    position: relative;
    font-family: 'Inter';
    color: rgba(55, 55, 57, 0.5);
}

.singleview-question-box-title-question-links {
    display: flex;
    margin-top: 3vh;
}

.test-container-child-left-header-section-title-name {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    color: #373739;
    line-height: 19px;
    font-style: normal;
    text-align: justify;
    font-family: 'Inter';
}

.evaluation-learner-change-buttons-prev {
    height: 6vh;
    display: flex;
    position: relative;

    /* top: -2px; */
}

.evaluation-learner-change-select {
    top: 0px;
    height: 5vh;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
}

.evaluation-learner-change-buttons-next {
    height: 6vh;
    display: flex;
    position: relative;

    /* top: -2px; */
    /* margin-left: -1.7vh; */
}

.evaluation-learner-change-select-flex {
    height: 6vh;
    display: flex;
}

.mcq-desc-header-answer-comment-div {
    display: flex;
    min-width: 25vh;
    align-items: center;
    justify-content: flex-end;
}

.test-container-child-left-header-scoring-and-comments {
    margin-left: 2vw;
}

.laq-saq-admin-answers-answer {
    resize: none;
    display: block;
    min-width: 57vw;
    font-size: 15px;
    font-weight: 400;
    padding-top: 10px;
    font-style: normal;
    padding-left: 10px;
    margin-bottom: 40px;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom: 25px;
    font-family: 'Inter';
    padding-bottom: 30px;
    box-sizing: border-box;
    border: 1px solid rgba(55, 55, 57, 0.2);
}

.laq-saq-admin-answers {
    display: flex;
    margin-top: 5vh;
    flex-direction: column;
    align-items: flex-start;
}

.laq-saq-admin-answers-label {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 600;
    color: #373739;
    line-height: 19px;
    font-style: normal;
    text-align: justify;
    font-family: 'Inter';
}

.test-container-child-left-header-back-block-label-admin {
    top: -12px;
    position: relative;
}

.test-container-child-left-header-back-block-icon {
    display: flex;
    max-height: 5vh;
    flex-wrap: nowrap;
    position: relative;
    align-items: center;

    /* top: -7px; */
}


.comments-score-section-score-reject-button {
    width: 96px;
    height: 39px;
    top: -0.25px;
    margin: 5px 0;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 19px;
    border-radius: 5px;
    font-style: normal;
    text-align: center;
    position: relative;
    font-family: 'Inter';
    background: #F55533;
    border: 1px solid #F55533;
    padding: 7px 20px !important;
}

.duration-span {
    width: 7vw;
}


.adminHeaderLogo-with-version{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 10px;

}

.appVersion{
    font-size: 10px;
}