@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.skill_div{

    .skill_name{
        @include m.fontSize(text/md/medium);
        color: map-get(i.$appColor, "grey/700");
        margin: 0 0 4px 0;
    }

    .skill_fields_div{
        background: map-get(i.$appBackGroundColor, "grey/50");
        border-radius: 8px;
        padding: 16px 24px;
        border: 1px solid map-get(i.$appColor, "grey/300");

        .skill_inputfield{
            display: flex;
            justify-content: space-between;
    
            .agc_div{
                width: 260px;

                .label_gact{
                    @include m.fontSize(text/sm/medium);
                    color: map-get(i.$appColor, "grey/700");
                    margin: 0 0 4px 0;
                }
    
                .gcat_input_div{
                    max-width: 260px;
                    height: 44px;
                    @include m.fontSize(text/md/medium);
                    color: map-get(i.$appColor, "grey/700");
                    border: 1px solid map-get(i.$appColor, "grey/300");
                    background: map-get(i.$appColor, "white");
                    padding: 10px 14px;
                    border-radius: 8px;
    
                    .editable_input_span{
                        max-width: 130px;
                        width: 90px;
                        height: 22px;
                        border: none;
                        outline: none;
                        text-align: end;
                    }
    
                    .noneditable_input_span{
                        max-width: 130px;
                    }
                }
            }
            
        }
    
        .skillfeedback_div{
            margin: 24px 0 0 0;
    
            .skill_feedback{
                width: 100%;
                border: 1px solid map-get(i.$appBackGroundColor, "grey/300");
                color: map-get(i.$appColor, "grey/700");
                @include m.fontSize(text/md/normal);
                border-radius: 8px;
                background: #FFFFFF;
                min-height: 80px !important;
                padding: 10px 14px;
                resize: none;
                outline: none;
            }
        }
    }

}