@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.backSvgIcon,
.backSvgIconForCodingProblem {
  float: left;
  cursor: pointer;
}

.backSvgIconForCodingProblem {
  margin-top: 12px;
}
