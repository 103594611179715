.VideoSectionsViewContainer {
  position: relative;
  width: 86vw;
  height: 80vh;
  padding: 24px 40px 24px 40px;
  border-radius: 8px;
  top: 6vh;
  left: 7vw;
  bottom: 6vh;
  background: #F2F4F7;
  overflow-y: auto;

  .sectionNameLabelofVid {
    position: relative;
    top: 7px;
    left: 0px;
    color: #344054;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Inter';
    line-height: 16px;
    margin-right: 8px;

    .sectionNameofVid {
      //styleName: Text md/Regular;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #101828;

    }
  }

  .VideoSectionsView {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-columns: repeat(auto-fill, 240px);
    column-gap: 24px;
    row-gap: 18px;
    position: relative;

  }
  .vidCardDisable{
    cursor: not-allowed;
  }

  .vidCard{
    cursor: pointer;
  }
}