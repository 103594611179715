.dashboardmenuconatinerblock,.dashboardmenuconatinerflex {
  width: 300px ;
  // height: 400px;
  padding: 20px 0px 0px 0px;
  gap: 0px;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 32px 64px -12px #10182824;
  z-index: 5;
  margin: 0px;
  width: max-content;
  position: absolute;
  overflow-y: auto;
  display: none;

  .refreshiconfordashboards{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    
  }
 
  


  .yourdashboardhead,.shareddashboardhead{
    display: flex;
    position: relative;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    text-transform: math-auto;
    color: #344054;
    margin-left: 24px;
    margin-bottom: 10px;
    height: 20px;
  }
  .yourdashboardoptionscontainer,.shareddashboardoptionscontainer{
    display: flex;
    position: relative;
    flex-direction: column;
    // margin-left: 32px;
    margin-top: 12px;
    margin-bottom: 12px;
    align-items: flex-start;

    .dashboardoption{
        display: flex;
        align-items: center;
        padding-right: 12px;
        padding-left: 32px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 0;
        cursor: pointer;

    .dashboardoptiontext{
        color: #000000;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-transform: math-auto;
        margin-left: 8px;
    }
    .favsvg{
        margin-left: 21px;
    }
    }


  }
  .viewalldashboarddiv{
    padding-top: 5px;
    padding-bottom: 5px;
    // margin-bottom: 5px;
  .viewalldashboards{
    margin-left: 32px;
    margin-bottom: 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-transform: math-auto;
    color: #344054;
    cursor: pointer;
  }
}
  .createDashboardoption{
    display: flex;
    margin-right: 20px;
    align-items: baseline;
    padding-top: 5px;
    padding-bottom: 10px;
    .createdashboard{
    margin-left: 32px;
    margin-bottom: 0px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-transform: math-auto;
    color: #344054;
    margin-right: 21px;
    cursor: pointer;

    }

  }

  .dashboardoption:hover,.viewalldashboarddiv:hover,.createDashboardoption:hover{
    width:100%;
    background: #F2F4F7;
    
  }

  .defaultoptionsblock{
    border-top: 1px solid #D0D5DD;
    padding-top: 12px;
  }
}

#dashboard:hover .dashboardmenuconatinerflex{
    display: flex;
  
}

#dashboard:hover .dashboardmenuconatinerblock{
  display: block;


}


//styleName: Text sm/Semibold;


