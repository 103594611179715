@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.toastContainer.MuiSnackbar-root {
  padding: 24px;
  background: white;
  display: flex;
  align-items: center;
  height: 80px;
  border: 1px solid map-get(i.$appColor, "grey/100");
  border-radius: 20px;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;

  .toastContentContainer {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 0;
    .toastContent {
      margin: 0;
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/900");
    }
  }
}
