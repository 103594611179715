.contentName {
  float: left;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  font-style: normal;
  width: fit-content;
  font-family: "Inter";
  text-decoration: none;
}

.the-content {
  margin: 35px 0 0 0;
  padding: 0 0 10px 0;
}

#contentDesc {
  display: flex;
  margin-left: 1%;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  font-style: normal;
  font-family: "Inter";
  text-decoration: none;
}

.ql-editor {
  font-size: 14px;
  font-style: normal;
  font-family: "Inter";
  text-decoration: none;
}

.viewPdfBtn {
  z-index: 1;
  width: 50px;
  height: 25px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #fd933c;
  box-sizing: border-box;
  margin: 5px 0 0 0;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.02);
}

.viewEmbeddedBtn {
  z-index: 1;
  width: 82px;
  height: 24px;
  bottom: 70px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #fd933c;
  box-sizing: border-box;
  margin: 350px 0 0 -150px;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.02);
}

.viewVideoBtn {
  width: 82px;
  height: 24px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  border-radius: 4px;
  font-style: normal;
  margin: 12% 0% 0 20%;
  font-family: "Inter";
  background: #fd933c;
  box-sizing: border-box;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.02);
}

.viewFileBtn {
  width: 82px;
  height: 24px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #fd933c;
  box-sizing: border-box;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.02);
}

.backBtn {
  width: 82px;
  height: 24px;
  color: white;
  font-size: 12px;
  position: fixed;
  font-weight: 500;
  line-height: 15px;
  border-radius: 4px;
  font-style: normal;
  margin: 1.3% 0 0 88%;
  font-family: "Inter";
  background: #fd933c;
  box-sizing: border-box;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.02);
}

.nextBtn {
  width: 152px;
  height: 32px;
  float: right;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #fd933c;
  box-sizing: border-box;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.02);
}

.nextBtn1 {
  width: 52px;
  height: 24px;
  color: white;
  position: fixed;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin: 1% 0 0 94%;
  border-radius: 4px;
  font-style: normal;
  font-family: "Inter";
  background: #fd933c;
  box-sizing: border-box;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.02);
}

div.blurred-editor div.ql-toolbar {
  display: none;
}

.pdfImage {
  height: 50px;
  padding: 10px;
  border-radius: 50px;
  border: solid 1px grey;
}

.linkIconView {
  height: 25px;
  padding: 10px;
  color: #add8e6;
  position: absolute;
  border-radius: 50%;
  margin: 400px 0% 0 42%;
  border: solid 1px grey;
}

.pdfTitleImage {
  height: 40px;
  padding: 10px;
  margin-right: 1%;
  border-radius: 50px;
  border: solid 1px grey;
}

.arrowLeftLongIcon {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.frameView {
  margin-top: -1%;
  margin-right: 25%;
}

.pdfTitle {
  font-size: 16px;
  margin-left: 4%;
  font-weight: 400;
  text-align: left;
  font-family: "Inter";
}

.mp4Title {
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  font-family: "Inter";
  display: inline-block;
}

.content-description {
  display: flex;
  justify-content: center;
}

.content-first-div {
  width: 1024px;
}

.content-first-div > .tox-tinymce {
  border: none;
}

.content-iframe-div > .imageDiv {
  display: flex;
  flex-direction: column;
}
