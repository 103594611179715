@use "../../Abstracts/mixins" as m;
@use "../../Abstracts/Variables/index" as i;

.codingHeaderLearnerSideMainContainer {
  margin: 8px 24px;

  .codingHeaderLearnerSideSubmitDiv {
    display: flex;
    align-items: center;

    .codingHeaderLearnerSideInterviewTimerDiv {
      gap: 13px;
      display: flex;
      padding: 8px 12px;
      border-radius: 8px;
      align-items: center;
      margin: 8px 56px 0 0;
      justify-content: center;
      @include m.fontSize(text/md/medium);
      color: map-get(i.$appColor, "grey/900");
      border: 2px solid map-get(i.$appColor, "grey/200");
      background: linear-gradient(180deg, #ffffff 0%, #fff5f5 100%);
    }

    .codingHeaderLearnerSideTimerDiv {
      height: 36px;
      display: flex;
      min-width: 113px;
      padding: 4px 12px;
      border-radius: 8px;
      margin-right: 20px;
      color: map-get(i.$appColor, "grey/900");
      border: 1px solid map-get(i.$appColor, "grey/300");
      background: map-get(i.$appBackGroundColor, "white");

      svg {
        margin-top: 4px;
      }

      .codingHeaderLearnerSideTimerInnerDiv {
        min-width: 61px;
        height: 28px;
        margin: 2px 0 0px 6px;
        @include m.fontSize(text/md/medium);
      }
    }
  }

  .codingHeaderLearnerSideSubmissionDiv {
    display: flex;
  }
}
