@use "../Abstracts/Variables/index" as i;
.noofuserssection {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  z-index: 8;
  .righttools {
    display: flex;
    gap: 16px;
    .noofuserdiv {
      position: relative;
      display: flex;
      top: 7px;
      width: 72px;
      height: 22px;
      padding: 2px 8px 2px 8px;
      gap: 0px;
      border-radius: 16px;
      background: #fcebe8;
      .noofusers {
        width: 56px;
        height: 18px;
        gap: 0px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        color: #e54b2a;
      }
    }
    .userstext {
      display: flex;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
  .lefttools {
    display: flex;
    gap: 16px;
    .buttonsm,
    .buttonsm:hover {
      min-width: 142px;
    }
    .addusericon,
    .usersdownloadicon,
    .userdisableicon,
    .userenableicon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      padding: 8px;
      gap: 8px;
      border-radius: 8px;
      background: map-get(i.$appBackGroundColor, "white");
      border: 1px solid map-get(i.$appBackGroundColor, "grey/300");
      box-shadow: 0px 1px 2px 0px #1018280d;
      // margin-left: 16px;
    }
    .rejectbutton {
      position: relative;
      top: 4px;
      display: flex;
    }
    .addusericon:hover,
    .userdisableicon:hover,
    .userenableicon:hover,
    .usersdownloadicon:hover {
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--Gray-50, #f9fafb);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    .css-1ygcj2i-MuiTableCell-root {
      padding: 0px 0px 0px 16px !important;
      font-family: Inter !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      line-height: 18px !important;
      text-align: left !important;
      color: #667085 !important;
    }
    .serialNumber {
      margin-left: 8px;
    }
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
      padding: 0px !important;
    }
    .css-8p7opg-MuiTableRow-root td:after {
      background-color: map-get(i.$appBackGroundColor, "white") !important;
    }
    .actvendinactive {
      display: flex;
    }
    .activeusers {
      width: 76px;
      height: 36px;
      padding: 6px 14px 10px 14px;
      gap: 8px;
      border-radius: 8px 0px 0px 8px;
      border: 1px 0px 0px 0px;
      background: #f2f4f7;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
      /* typography */
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #12b76a;
    }
    .activeusersdisable {
      width: 76px;
      height: 36px;
      padding: 6px 14px 10px 14px;
      gap: 8px;
      border-radius: 8px 0px 0px 8px;
      border: 1px 0px 0px 0px;
      background: #f2f4f7;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
      /* typography */
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #d0d5dd;
    }
    .inactiveusers {
      width: 88px;
      height: 36px;
      padding: 6px 14px 10px 14px;
      gap: 8px;
      border-radius: 0px 8px 8px 0px;
      border: 1px 0px 0px 0px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
      /* typography */
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      // color: #d0d5dd;
      color: #db3a2d;
    }
    .inactiveusersdisable {
      width: 88px;
      height: 36px;
      padding: 6px 14px 10px 14px;
      gap: 8px;
      border-radius: 0px 8px 8px 0px;
      border: 1px 0px 0px 0px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
      /* typography */
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #d0d5dd;
      //   color:  #db3a2d;
    }
    .fb-div {
      position: relative;
      .fb-div-input {
        width: 105px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
    .addfbbtn {
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      display: flex;
      width: 70px;
      height: 35px;
      justify-content: center;
      align-items: center;
      padding: 5px 5px 5px 5px;
      border-radius: 8px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);
      margin: 0 0 0 16px;
    }
  }
}