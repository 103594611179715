@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.textareaContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  .textareaSec {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .textareaLabel {
      padding: 0;
      margin: 0;
      @include m.fontSize(text/sm/medium);
      color: map-get(i.$appColor, "grey/700");
    }
    .textareaField {
      min-height: 128px;
      width: 100%;
      border: 1px solid map-get(i.$appColor, "grey/300");
      resize: none;
      background: map-get(i.$appBackGroundColor, "white");
      border-radius: 8px;
      padding: 10px 14px;
      box-shadow: 0px 1px 2px 0px #1018280d;
      @include m.fontSize(text/md/regular);
      color: map-get(i.$appColor, "grey/900");
      outline: unset;
    }
    .textareaField:focus {
      border-color: map-get(i.$appColor, "brand/300");
      box-shadow: 0px 0px 0px 4px #f4ebff;
      // box-shadow: 0px 1px 2px 0px #1018280D;
    }
    .textareaField:disabled {
      border-color: map-get(i.$appColor, "grey/300");
      background: map-get(i.$appBackGroundColor, "grey/50");
    }
    .textareaField:disabled .textareaField:focus {
      box-shadow: unset;
    }
  }
  .textareaHint {
    @include m.fontSize(text/sm/regular);
    color: map-get(i.$appColor, "grey/500");
  }
}

.textareaContainer.destructive {
  .textareaSec {
    .textareaField {
      border-color: map-get(i.$appColor, "error/300");
    }
    .textareaField:focus {
      box-shadow: 0px 0px 0px 4px #fee4e2;
      // box-shadow: 0px 1px 2px 0px #1018280D;
    }
  }
  .textareaHint {
    color: map-get(i.$appColor, "error/500");
  }
}
