@use "../Abstracts/mixins" as m;
@use "../Abstracts/Variables/index" as i;

.socialProfilesContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .socialProfilesSec {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 24px;
    width: 75%;
    min-height: 70vh;
    .socialProfileLinkedInSec,
    .socialProfileGithubSec {
      width: 75%;
    }
  }
  .socialProfilesBtnSec{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 24px 10px;
  }
}
